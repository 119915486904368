<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-form
        ref="form"
        v-if="
          CheckGetPermission('school.change_installmentsettings') ||
          CheckGetPermission('school.add_installmentsettings')
        "
        class="d-flex flex-column"
        style="row-gap: 1.5rem"
      >
        <fieldset class="pa-3 border" style="border-radius: 5px">
          <legend class="v-label px-2">
            {{ $t("school.new_student.student_data") }}
          </legend>
          <v-row class="row-nm" style="row-gap: 1.5rem">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="new_student.student_name.first_name"
                density="compact"
                :label="$t('school.new_student.first_name')"
                :placeholder="$t('school.new_student.enter_first_name')"
                hide-details="auto"
                :rules="[$required, $max_length(15)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="new_student.student_name.father_name"
                density="compact"
                :label="$t('school.new_student.father_name')"
                :placeholder="$t('school.new_student.enter_father_name')"
                :rules="[$required, $max_length(15)]"
                hide-details="auto"
                @update:model-value="
                  new_student.parent_name.first_name =
                    new_student.student_name.father_name
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="new_student.student_name.grandfather_name"
                density="compact"
                :label="$t('school.new_student.grandfather_name')"
                :placeholder="$t('school.new_student.enter_grandfather_name')"
                :rules="[$required, $max_length(15)]"
                hide-details="auto"
                @update:model-value="
                  new_student.parent_name.father_name =
                    new_student.student_name.grandfather_name
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="new_student.student_name.family_name"
                density="compact"
                :label="$t('school.new_student.family_name')"
                :placeholder="$t('school.new_student.enter_family_name')"
                :rules="[$required, $max_length(15)]"
                hide-details="auto"
                @update:model-value="
                  new_student.parent_name.family_name =
                    new_student.student_name.family_name
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </fieldset>
        <fieldset class="pa-3 border d-flex" style="border-radius: 5px">
          <legend class="v-label px-2">
            {{ $t("school.new_student.parent_data") }}
          </legend>
          <v-row class="row-nm" style="row-gap: 1.5rem">
            <v-slide-x-transition>
              <VCol v-if="selected" cols="12" md="4">
                <VAutocomplete
                  v-model="new_student.parent_name.fk_parent"
                  prepend-inner-icon="mdi-text"
                  item-value="id"
                  item-title="name"
                  :items="parent_list"
                  :label="$t('school.parent.select_parent_name')"
                  hide-details="auto"
                  density="compact"
                  :disabled="!selected"
                  :rules="selected ? [$required] : ''"
                >
                </VAutocomplete>
              </VCol>
            </v-slide-x-transition>
            <v-slide-x-reverse-transition>
              <div v-if="selected2" class="d-flex w-75">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="new_student.parent_name.first_name"
                    density="compact"
                    :label="$t('school.new_student.first_name')"
                    :placeholder="$t('school.new_student.enter_first_name')"
                    :rules="[$required, $max_length(15)]"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="new_student.parent_name.father_name"
                    density="compact"
                    :label="$t('school.new_student.father_name')"
                    :placeholder="$t('school.new_student.enter_father_name')"
                    :rules="[$required, $max_length(15)]"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="new_student.parent_name.family_name"
                    density="compact"
                    :label="$t('school.new_student.family_name')"
                    :placeholder="$t('school.new_student.enter_family_name')"
                    :rules="[$required, $max_length(15)]"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </div>
            </v-slide-x-reverse-transition>
            <v-fade-transition>
              <VBtn
                v-show="selected || selected2"
                variant="elevated"
                size="small"
                color="grey-lighten-2"
                class="my-2 mx-3"
                @click.prevent="showAndHide()"
              >
                <VIcon
                  :icon="selected ? 'mdi-plus-thick ' : 'mdi-minus-thick'"
                  :color="selected ? 'success ' : 'error'"
                ></VIcon>
              </VBtn>
            </v-fade-transition>
          </v-row>
        </fieldset>
        <fieldset class="pa-3 border" style="border-radius: 5px">
          <legend class="v-label px-2">
            {{ $t("school.new_student.student_academic_info") }}
          </legend>
          <v-row class="row-nm" style="row-gap: 1.5rem">
            <!-- <v-col cols="12" md="4">
              <v-text-field
              hide-details="auto"
                v-model="new_student.phone_number"
                density="compact"
                :label="$t('school.new_student.phone_number')"
                :placeholder="$t('school.new_student.enter_phone_number')"
                :rules="[$required, $max_length(15)]"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4">
              <VTextField
                :placeholder="
                  $t('global.personal_information.phone_number_placeholder')
                "
                v-model="new_student.phone_number"
                :onkeypress="$only_phone_number"
                prepend-inner-icon="mdi-cellphone-text"
                :label="$t('school.new_student.phone_number')"
                density="compact"
                :rules="[$numeric, $max_length(14), $min_length(9)]"
              ></VTextField>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                hide-details="auto"
                color="indigo"
                v-model="new_student.gender_section"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-google-classroom"
                :items="[
                  { id: 1, name: 'بنين' },
                  { id: 2, name: 'بنات' },
                ]"
                :label="$t('school.new_student.gender_section')"
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                hide-details="auto"
                color="indigo"
                v-model="new_student.language_section"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-google-classroom"
                :items="[
                  { id: 1, name: 'عربي' },
                  { id: 2, name: 'انجليزي' },
                ]"
                :label="$t('school.new_student.language_section')"
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                hide-details="auto"
                color="indigo"
                v-model="new_student.fk_class"
                density="compact"
                item-title="name_ar"
                item-value="id"
                prepend-inner-icon="mdi-google-classroom"
                :items="classes"
                :label="$t('school.class.select')"
                @update:modelValue="new_student.fk_division = null"
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                hide-details="auto"
                color="indigo"
                v-model="new_student.fk_division"
                density="compact"
                item-title="name"
                item-value="id"
                :items="class_divisions"
                :no-data-text="$t('school.division.not_found')"
                prepend-inner-icon="mdi-format-list-bulleted"
                :label="$t('school.division.select')"
                :rules="[$required]"
              />
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        (update && CheckGetPermission('school.change_installmentsettings')) ||
        CheckGetPermission('school.add_installmentsettings')
      "
      class="px-8"
    >
      <v-btn
        v-if="CheckGetPermission('school.add_installmentsettings')"
        @click="saveNewStudent"
        class="bg-indigo"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="resetForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
    <!-- ############### Dialog Delete  ################### -->
    <!-- <v-dialog
        v-model="del_dialog"
        transition="dialog-top-transition"
        width="auto"
      >
        <v-card>
          <v-card-title>
            {{ $t("global.confirmation") }}
          </v-card-title>
          <v-card-text>
            {{ $t("global.alert.confirm_delete") }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="info"
              @click="(del_dialog = false), (selected_fee_type = '')"
              >{{ $t("global.cancel") }}</v-btn
            >
            <v-btn color="red" @click="deleteFeeType(selected_fee_type)">{{
              $t("global.delete")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "FeeType",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.parent_list.length) await this.getParents();
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      new_student: { student_name: {}, parent_name: {} },
      is_loading: false,
      parent_list: [],
      selected: true,
      selected2: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
    }),
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    class_divisions() {
      if (this.new_student.fk_class) {
        return this.getClassDivisions(this.new_student.fk_class);
      }
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    getParents() {
      this.axios(`${this.base_url}parent/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          page_size: 10000,
        },
      }).then((response) => {
        this.parent_list = response.data?.results;
      });
    },
    async saveNewStudent() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        const load_data = { ...this.new_student };
        // console.log(load_data);
        load_data.student_name = [
          load_data?.student_name?.first_name,
          load_data?.student_name?.father_name,
          load_data?.student_name?.grandfather_name,
          load_data?.student_name?.family_name,
        ]?.join(" ");
        load_data.fk_parent = this.selected
          ? load_data.parent_name?.fk_parent
          : null;
        load_data.parent_name = this.selected2
          ? [
              load_data?.parent_name?.first_name,
              load_data?.parent_name?.father_name,
              load_data?.parent_name?.family_name,
            ]?.join(" ")
          : null;
        // console.log(load_data);
        this.is_loading = true;
        await this.axios["post"](`${this.base_url}student/`, load_data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    showAndHide() {
      if (this.selected) {
        this.selected = !this.selected;
        setTimeout(() => {
          this.selected2 = !this.selected2;
        }, 350);
      } else {
        this.selected2 = !this.selected2;
        setTimeout(() => {
          this.selected = !this.selected;
        }, 350);
      }
    },
    resetForm() {
      this.new_student = { parent_name: {}, student_name: {} };
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped>
fieldset {
  min-height: 85px;
}
</style>

<template>
  <v-card class="justify-center">
    <v-card-text class="card-view pb-4">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-autocomplete
              color="indigo"
              :items="buses"
              v-model="student_bus.fk_bus"
              density="compact"
              item-title="itinerary"
              item-value="id"
              prepend-inner-icon="mdi-bus-school"
              :label="$t('school.student_bus_data.chose_bus')"
              :rules="[$required]"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="row-nm pt-4">
          <v-col cols="12" md="8" sm="9">
            <fieldset class="px-4 border" style="border-radius: 5px">
              <legend class="v-label px-2">
                {{ $t("school.student.students") }}
              </legend>
              <v-row class="row-nm pt-3">
                <v-col cols="12" md="6" sm="12">
                  <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_class"
                    density="compact"
                    item-title="name_ar"
                    item-value="id"
                    prepend-inner-icon="mdi-google-classroom"
                    :items="classes"
                    :label="$t('school.class.select')"
                    @update:modelValue="filter_data.fk_division = undefined"
                    :rules="[$required]"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_division"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    clearable
                    :items="class_divisions"
                    :no-data-text="$t('school.division.not_found')"
                    prepend-inner-icon="mdi-format-list-bulleted"
                    :label="$t('school.division.select')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col cols="2" class="mt-auto pb-4 pt-3">
            <v-btn
              @click="checkFilterData"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-progress-linear
      color="indigo "
      :indeterminate="loading"
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div v-show="students?.length >= 1" class="card-table">
        <v-card class="mt-4" v-if="students?.length >= 1">
          <v-row class="mt-2 mx-1">
            <!-- <v-col cols="12" md="6" sm="6">
              <v-text-field
                color="indigo"
                v-model="search_student"
                @input="searchStudentBus"
                density="compact"
                append-inner-icon="mdi-magnify"
                prepend-inner-icon="mdi-school"
                :label="$t('school.student.student_search')"
              />
            </v-col> -->
          </v-row>
          <v-divider />
          <v-table dense density="compact">
            <thead>
              <tr>
                <th>#</th>
                <th class="px-0">
                  <v-text-field
                    color="indigo"
                    v-model="search_student"
                    @input="!search_student ? searchStudentBus() : ''"
                    @keyup.enter="searchStudentBus()"
                    density="compact"
                    variant="solo-filled"
                    hide-details
                    single-line
                    append-inner-icon="mdi-magnify"
                    prepend-inner-icon="mdi-account-search"
                    :label="$t('school.student.student_name')"
                  />
                </th>
                <th>
                  <v-checkbox
                    color="indigo"
                    v-model="select_all_students"
                    density="compact"
                    hide-details
                    :label="$t('school.student_bus_data.is_subscribed')"
                    style="min-width: 100px"
                    :indeterminate="isIndeterminate"
                    @change="toggleAll(select_all_students, 'subscribed')"
                  >
                  </v-checkbox>
                </th>
                <!-- <th>{{ $t("school.student_bus_data.bus_fee") }}</th>
                    <th>
                      {{ $t("school.student_fees.installments_number") }}
                    </th> -->
                <th class="text-center">{{ $t("global.note") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in student_bus.students" :key="index">
                <td>{{ index + 1 }}</td>
                <td style="min-width: 300px">{{ student.name_ar }}</td>
                <td class="text-center">
                  <v-checkbox
                    style="min-width: 20px"
                    v-model="student.subscribed"
                    color="indigo"
                    density="compact"
                    hide-details
                    @change="
                      updateMaster('subscribed'),
                        !student.subscribed
                          ? (this.$refs[`field${index}`][0]?.validate(),
                            this.$refs[`field${index}`][1]?.validate())
                          : ''
                    "
                  >
                  </v-checkbox>
                </td>
                <!-- <td>
                      <v-text-field
                        :ref="`field${index}`"
                        v-model="student.fee"
                        :disabled="!student.subscribed"
                        :placeholder="$t('school.student_bus_data.bus_fee')"
                        type="number"
                        density="compact"
                        class="my-1"
                        hide-details="auto"
                        :rules="
                          student.subscribed
                            ? [$required, $numeric, $max_length(7)]
                            : [$numeric, $max_length(7)]
                        "
                      >
                      </v-text-field>
                    </td> -->
                <!-- <td>
                      <v-text-field
                        :ref="`field${index}`"
                        v-model="student.installments_number"
                        :disabled="!student.subscribed"
                        :placeholder="
                          $t('school.student_fees.installments_number')
                        "
                        type="number"
                        density="compact"
                        class="my-1"
                        hide-details="auto"
                        :rules="
                          student.subscribed
                            ? [
                                $required,
                                $numeric,
                                $max_value(10),
                                $min_value(0),
                              ]
                            : [$numeric, $max_value(10), $min_value(0)]
                        "
                      >
                      </v-text-field>
                    </td> -->
                <td>
                  <v-text-field
                    :ref="`field${index}`"
                    v-model="student.note"
                    :disabled="!student.subscribed"
                    density="compact"
                    :placeholder="$t('global.note')"
                    hide-details="auto"
                    class="my-1"
                    :rules="[$max_length(250)]"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions
            v-if="
              CheckGetPermission('bus_system.add_studentbus') ||
              CheckGetPermission('bus_system.change_studentbus')
            "
          >
            <v-btn
              v-if="
                CheckGetPermission('bus_system.add_studentbus') ||
                CheckGetPermission('bus_system.change_studentbus')
              "
              @click="checkWarning"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.save") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn @click="cleanStudentBusForm" class="mx-3" size="small">
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-expand-transition>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="show_warning"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-center">
        <v-icon class="text-warnning px-2">mdi-alert</v-icon>
        <span style="font-size: 20px !important">{{
          $t("alert.warnning.title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <span class="v-label">{{
          $t("school.student_bus_data.max_student")
        }}</span>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="show_warning = false">{{ $t("globals.cancel") }}</v-btn>
        <v-btn class="bg-indigo" size="small" @click="saveStudentBus">{{
          $t("globals.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- ############### end Dialog Delete  ################### -->
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers } from "@vuelidate/validators";
export default {
  props: {
    id: String,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllBuses();
      if (this.divisions.length == 0) await this.getDivisions({});
      if (this.class_assign_hall.length == 0) await this.getClassAssignHall();
      await this.getClasses();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      show_warning: false,
      is_clean: false,
      select_all_students: false,
      isIndeterminate: false,
      buses: [],
      students: [],
      loading: false,
      filter_data: {},
      search_student: "",
      student_bus: {
        fk_bus: undefined,
        data_entry: undefined,
        students: [],
      },
      is_loading: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      // buses:(state)=>state.school.buses,
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      user: (state) => state.User,
    }),
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    class_divisions() {
      if (this.filter_data.fk_class) {
        return this.getClassDivisions(this.filter_data.fk_class);
      }
    },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      // getAllBuses: "school/getAllBuses",
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    searchStudentBus() {
      if (this.search_student != "") {
        this.student_bus.students = this.students.filter((student) => {
          return student.name_ar.includes(this.search_student);
        });
      } else {
        this.student_bus.students = this.students;
      }
    },
    // selectAllStudents(value) {
    //   this.students.forEach((studetn) => {
    //     studetn.subscribed = value;
    //   });
    // },
    toggleAll(All, checkedField) {
      this.students.forEach((item) => {
        item[checkedField] = All;
      });
      this.updateMaster(checkedField);
    },
    updateMaster(checkedField) {
      const data = this.students;
      const totalChecked = data.reduce((total, item) => {
        return total + (item[checkedField] ? 1 : 0);
      }, 0);
      const totalItems = data.length;
      this.select_all_students = totalChecked === totalItems;
      this.isIndeterminate = totalChecked > 0 && totalChecked < totalItems;
    },
    // async getStudentBus(){
    //     await this.axios.get(`${this.base_url}/student-bus/list/`,
    //     {
    //         headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
    //         params:{
    //             year:localStorage.getItem('current_year'),
    //             class:this.filter_data.fk_class,
    //             division:this.filter_data.fk_division,
    //         }
    //     }).then(response=>{
    //     })
    // },
    async getStudents() {
      this.select_all_students = false;
      this.students = [];
      this.student_bus.students = [];
      if (this.filter_data.fk_class && this.student_bus.fk_bus) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}/student-bus/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              class: this.filter_data.fk_class,
              division: this.filter_data.fk_division,
              bus: this.student_bus.fk_bus,
            },
          })
          .then((response) => {
            this.students = response.data;
            this.student_bus.students = this.students;
            this.updateMaster("subscribed");
          })
          .catch((error) => {});
      }
      this.loading = false;
    },
    async getAllBuses() {
      await this.axios
        .get(`${this.base_url}/bus/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 10000000,
          },
        })
        .then((response) => {
          this.buses = response.data.results;
        });
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      // if (this.student_bus.fk_bus && this.filter_data.fk_class) {
      if (valid) {
        this.getStudents();
      }
    },
    checkWarning() {
      let bus = this.buses.find((el) => el.id == this.student_bus.fk_bus);
      if (
        bus.student_count >
        this.students.filter((el) => el.subscribed == true).length
      ) {
        this.show_warning = true;
      } else {
        if (this.CheckGetPermission("bus_system.add_studentbus"))
          this.saveStudentBus();
        else if (this.CheckGetPermission("bus_system.change_studentbus"))
          this.updateStudentBus();
      }
    },
    async saveStudentBus() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        var student_bus_data = [];
        this.students.forEach((student) => {
          if (student.subscribed || (student.id && !student.subscribed)) {
            student_bus_data.push({
              fk_bus: this.student_bus.fk_bus,
              fk_student: student.fk_student,
              note: student.note,
              id: student.id,
              subscribed: student.subscribed,
              fee: student.fee,
              installments_number: student.installments_number,
              fk_class: this.filter_data.fk_class,
            });
          }
        });
        await this.axios
          .post(`${this.base_url}/student-bus/`, student_bus_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.getStudents();
            this.$emit("successAlert", this.$t("global.success.save"));
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async updateStudentBus() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        var student_bus_data = [];
        this.students.forEach((student) => {
          if (student.subscribed || (student.id && !student.subscribed)) {
            student_bus_data.push({
              fk_bus: this.student_bus.fk_bus,
              fk_student: student.fk_student,
              note: student.note,
              id: student.id,
              subscribed: student.subscribed,
              fee: student.fee,
              installments_number: student.installments_number,
              fk_class: this.filter_data.fk_class,
            });
          }
        });
        await this.axios
          .put(`${this.base_url}/student-bus/0/`, student_bus_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.getStudents();
            this.$emit("successAlert", this.$t("global.success.save"));
          })
          .catch((error) => {
            // console.log(error);
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    cleanStudentBusForm() {
      this.$refs.form.reset();
      this.getAllBuses();
      this.student_bus.students = [];
      this.students = [];
      this.is_clean = true;
    },
  },
};
</script>

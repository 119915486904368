<template>
  <VCard class="justify-center">
    <VCardText
      class="card-fields"
      v-if="
        CheckGetPermission('school.add_news') ||
        (is_update && CheckGetPermission('school.change_news'))
      "
    >
      <VForm ref="form">
        <VRow>
          <VCol cols="12" sm="8">
            <VRow>
              <VCol cols="12" md="6">
                <VCardItem class="pa-0">
                  <VTextField
                    ref="hall_name"
                    v-model="fields_data.title"
                    prepend-inner-icon="mdi-format-text"
                    :label="$t('school.news.title')"
                    :placeholder="$t('school.news.placeholder_title')"
                    density="compact"
                    clearable
                    :rules="[$required, $max_length(100)]"
                    counter="100"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" md="12">
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="fields_data.description"
                    clearable
                    :label="$t('school.notification.topic')"
                    counter="250"
                    no-resize
                    density="compact"
                    rows="5"
                    prepend-inner-icon="mdi-text"
                    :rules="[$max_length(250)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VCol>
          <VCol cols="12" sm="4">
            <ImageInput
              :image="fields_data.image"
              :text="$t('school.news.news_image')"
              @ImageSelected="handlingImage"
              :messages="
                fields_data.file_name
                  ? $t('school.document.current_file') +
                    ':' +
                    decodeURIComponent(subject.file_name?.split('/').pop())
                  : ''
              "
            ></ImageInput>
          </VCol>
          <!-- <VRow>
            <VCol class="pt-0 px-3" cols="12" md="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="fields_data.floor_number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('school.hall_data.hall_floor_number')"
                  :placeholder="
                    $t('school.hall_data.placeholder_news_floor_number')
                  "
                  density="compact"
                  type="number"
                  clearable
                  :rules="[
                    $required,
                    $numeric,
                    $max_value(
                      this.fields_data.fk_building
                        ? this.building.find(
                            (build) => build.id == this.fields_data.fk_building
                          ).floors_number
                        : 30
                    ),
                  ]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="fields_data.seats_number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('school.hall_data.hall_seats_number')"
                  :placeholder="
                    $t('school.hall_data.placeholder_news_seats_number')
                  "
                  density="compact"
                  type="number"
                  clearable
                  :rules="[$required, $numeric, $max_value(200)]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" class="pa-0 ma-0" md="8">
              <VCardItem class="px-3">
                <VTextarea
                  v-model="fields_data.note"
                  clearable
                  :label="$t('global.note')"
                  counter="250"
                  no-resize
                  density="compact"
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :rules="[$max_length(250)]"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow> -->
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_news') ||
        (is_update && CheckGetPermission('school.change_news'))
      "
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_news')"
        class="bg-indigo"
        @click="saveNews()"
        :loading="is_loading"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_news')"
        class="bg-success"
        @click="saveNews(id)"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <!-- <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      fields_data: {},
      is_update: false,
      is_loading: false,
      image_reset: 1,
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      if (this.id !== undefined) {
        await this.getNews();
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    // ...mapState({
    //   building: (state) => state.school.building,
    //   // user: (state) => state.User,
    // }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    // ...mapActions({
    //   getBuilding: "school/getBuilding",
    // }),
    handlingImage(event) {
      this.fields_data.image = event;
    },
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "NewsList" });
      } else {
        this.$router.push({ name: "NewsAdd" });
        this.is_update = false;
        this.fields_data.image = null;
        this.$refs.form.reset();
      }
    },
    async saveNews(method = null) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (
          typeof this.fields_data.image == "string" &&
          this.fields_data.image !== "no_img"
        ) {
          this.fields_data.image = null;
        }
        this.is_loading = true;
        await this.axios[typeof method == "string" ? "put" : "post"](
          `${this.base_url}news/${method ? method + "/" : ""}`,
          this.fields_data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    // async updateHall() {
    //   const { valid } = await this.$refs.form.validate();

    //   if (valid) {
    //     this.is_loading = true;
    //     await this.axios
    //       .put(`${this.base_url}/hall/${this.fields_data.id}/`, this.hall, {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then(() => {
    //         // this.$router.push({ name: "hallslist" });
    //         this.$emit("successAlert", this.$t("global.success.data_updated"));
    //         // setTimeout(() => {
    //         // }, 2000);
    //         this.resetForm();
    //       })
    //       .catch(() => {
    //         this.$emit(
    //           "errorAlert",
    //           this.$t("global.error.failure.error_in_data")
    //         );
    //       });
    //   }
    //   this.is_loading = false;
    // },

    async getNews() {
      await this.axios(`${this.base_url}news/${this.id}/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.fields_data = response?.data;
        })
        .catch((err) => {});
      this.is_update = true;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>

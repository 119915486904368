<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-form
        ref="form"
        v-if="
          (update && CheckGetPermission('school.change_notifications')) ||
          CheckGetPermission('school.add_notifications')
        "
      >
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="5" lg="5">
            <v-text-field
              v-model="notifs.title"
              counter="100"
              type="input"
              density="compact"
              clearable
              prepend-inner-icon="mdi-rename-box"
              :label="$t('school.notification.title')"
              :rules="[$required, $max_length()]"
            />
          </v-col>
          <VCol cols="12" xs="12" sm="12" md="8" lg="8">
            <VCardItem class="pa-0">
              <VTextarea
                v-model="notifs.description"
                clearable
                :label="$t('school.notification.topic')"
                prepend-inner-icon="mdi-note-outline"
                counter="250"
                no-resize
                density="compact"
                rows="4"
                :rules="[$max_length(250)]"
              >
              </VTextarea>
            </VCardItem>
          </VCol>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        (update && CheckGetPermission('school.change_notifications')) ||
        CheckGetPermission('school.add_notifications')
      "
      class="px-8"
    >
      <v-btn
        v-if="!update && CheckGetPermission('school.add_notifications')"
        @click="saveNotification()"
        class="bg-indigo"
        size="small"
        :loading="is_loading['0']"
      >
        <span class="text-white">
          {{ $t("school.notification.save_send") }}
        </span>
        <v-icon icon="mdi-send-check" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="!update && CheckGetPermission('school.add_notifications')"
        @click="saveNotification(1)"
        class="bg-indigo"
        size="small"
        :loading="is_loading['1']"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="!update && CheckGetPermission('school.add_notifications')"
        @click="saveNotification(2)"
        class="bg-indigo"
        size="small"
        :loading="is_loading['2']"
      >
        <span class="text-white">
          {{ $t("school.notification.send") }}
        </span>
        <v-icon icon="mdi-send" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="update && CheckGetPermission('school.change_notifications')"
        @click="updateNotification(selected_notif)"
        class="bg-success"
        size="small"
        :loading="is_loading['0']"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="update && CheckGetPermission('school.change_notifications')"
        @click="updateNotification(selected_notif, (state = 1))"
        class="bg-success"
        size="small"
        :loading="is_loading['1']"
      >
        <span class="text-white">
          {{ $t("school.notification.update_send") }}
        </span>
        <v-icon icon="mdi-send-check" color="white" end></v-icon>
      </v-btn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!update ? 'golden' : 'red'"
          end
        ></VIcon>
      </VBtn>
      <!-- <v-btn @click="resetForm" class="mx-3" size="small">
          {{ $t("global.clear") }}
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn> -->
    </v-card-actions>
    <v-divider class="mt-5" />
    <v-card class="card-table">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="notifications"
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_notifications')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editNotification(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_notifications')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="getReadyToDelete(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
              <span v-if="key === 'send'">
                <span
                  v-if="CheckGetPermission('school.add_notifications')"
                  style="color: #009; border-radius: 4px"
                >
                  <v-icon
                    @click="sendNotification(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-send"
                  />
                </span>
                <!-- <v-btn
                  v-if="!update && CheckGetPermission('school.add_notifications')"
                  @click="saveNotification(2)"
                  class="bg-indigo"
                  size="small"
                  :loading="is_loading"
                >
                  <span class="text-white">
                    {{ $t("school.notification.send") }}
                  </span>
                  <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn> -->
              </span>
              <span v-else> {{ val }}</span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="notifications.length"
            :newItem="[perPage]"
          ></pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (selected_notif = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteNotification(selected_notif)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "FeeType",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      del_dialog: false,
      update: false,

      notifications: [],
      selected_notif: undefined,
      txt_search: undefined,
      search: null,
      perPage: 10,
      page: 1,
      is_loading: {},
      notifs: {
        title: undefined,
        description: undefined,
        id: undefined,
      },
      selectedHead: ["title", "description", "send", "actions"],
      headers: [
        { title: this.$t("school.notification.title"), key: "title" },
        { title: this.$t("school.notification.topic"), key: "description" },
        {
          title: this.$t("school.notification.send"),
          key: "send",
          sortable: false,
        },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    // notifications() {
    //   let list = [];
    //   this.notifs_list.forEach((element) => {
    //     let subscribe_type = this.subscribsType_list.find(
    //       (item) => item.id == element.subscribe_type
    //     );
    //     if (subscribe_type != undefined) subscribe_type = subscribe_type.name;
    //     list.push({
    //       name: element.name,
    //       reducible: element.reducible,
    //       installment_available: element.installment_available,
    //       subscribe_type: subscribe_type,
    //       note: element.note,
    //       id: element.id,
    //     });
    //   });
    //   return list;
    // },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      //   this.v$.notifs.$reset();
      this.update = false;
    },
    editNotification(id) {
      this.selected_notif = id;
      this.update = true;
      let notif = this.notifications.find((item) => item.id == id);
      if (notif) {
        this.notifs = { ...notif };
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getReadyToDelete(id) {
      let notif = this.notifications.find((item) => item.id == id);
      if (notif) {
        if (!notif.fk_branch == null) {
          this.$emit("errorAlert", this.$t("global.error.failure.cant_delete"));
        } else {
          this.selected_notif = id;
          this.del_dialog = true;
        }
      }
    },
    async sendNotification(id) {
      await this.axios
        .post(
          `${this.base_url}notifications/send/`,
          { id: id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_sent"));
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("global.error.failure.send"));
        });
    },
    async saveNotification(state = 0) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading[state] = true;
        await this.axios
          .post(`${this.base_url}notifications/?state=${state}`, this.notifs, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit(
              "successAlert",
              this.$t(
                `global.success.${
                  !state
                    ? "data_sent_saved"
                    : state == "1"
                    ? "data_saved"
                    : "data_sent"
                }`
              )
            );

            this.resetForm();
            if (state == 1 || state == 0) this.getData();
          })
          .catch((error) => {
            if (error.response.status == "400") {
              this.$emit("errorAlert", this.$t("global.error.failure.save"));
            }
            this.$emit("errorAlert", this.$t("global.error.failure.send"));
            if (state == 0) {
              this.getData();
              this.resetForm();
            }
          });
      }
      this.is_loading[state] = false;
    },
    async updateNotification(id, state = 0) {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.notifs.id != undefined) {
        this.is_loading[state] = true;
        // if(!this.v$.$error){
        // this.notifs.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}notifications/${id}/?state=${state}`,
            this.notifs,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getData();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading[state] = false;
    },
    async deleteNotification(id) {
      await this.axios
        .delete(`${this.base_url}notifications/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.getData();
        })
        .catch((error) => {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        });
      this.del_dialog = false;
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/notifications/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data) this.notifications = response.data;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

<template>
  <VCard
    v-if="
      CheckGetPermission('school.change_school') ||
      CheckGetPermission('school.add_school') ||
      CheckGetPermission('school.view_school')
    "
  >
    <VForm ref="form" class="">
      <!-- <v-card-title class="px-0"> -->
      <VTabs v-model="tab" color="blue" class="px-4">
        <VTab v-for="(value, index) in tabs" :key="index" :value="index">
          {{ value }}
        </VTab>
      </VTabs>
      <!-- </v-card-title> -->
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item :value="0">
            <VRow>
              <VCol cols="12" md="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.school_name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('school.school_data.school_name')"
                        :placeholder="
                          $t('school.school_data.placeholder_school_name')
                        "
                        density="compact"
                        :rules="[
                          $required,
                          $ar_letters_only,
                          $max_length(),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.school_name_en"
                        prepend-inner-icon="mdi-alpha-e"
                        :label="$t('school.school_data.school_name_en')"
                        :placeholder="
                          $t('school.school_data.placeholder_school_name_en')
                        "
                        density="compact"
                        :rules="[
                          $required,
                          $en_letters_only,
                          $max_length(),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" md="6">
                    <v-text-field
                      v-model="school.establish_date"
                      density="compact"
                      :placeholder="$t('school.school_data.establish_date')"
                      clearable
                      prepend-inner-icon="mdi-calendar-clock-outline"
                      id="establish_date"
                      readonly
                      :rules="[$required]"
                    >
                    </v-text-field>
                    <Datetime
                      type="date"
                      element="establish_date"
                      :color="'#0747a6'"
                      @input="(e) => getPermissionTime(e, 'establish_date')"
                      :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                      :placeholder="
                        $t('school.teacher_data.date_of_employment')
                      "
                    ></Datetime>
                      <!-- :calendar="date_type == 'h' ? 'hijri' : ''" -->
                    <!-- <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.establish_date"
                        :label="$t('school.school_data.establish_date')"
                        density="compact"
                        :rules="[$required]"
                        type="date"
                        :format-date="dateFormat"
                      >
                      </VTextField>
                    </VCardItem> -->
                  </VCol>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.headmaster"
                        prepend-inner-icon="mdi-account-box"
                        :label="$t('school.school_data.headmaster')"
                        :placeholder="
                          $t('school.school_data.placeholder_headmaster')
                        "
                        density="compact"
                        :rules="[
                          $required,
                          $ar_letters_only,
                          $max_length(),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VRadioGroup
                        hide-details="auto"
                        inline
                        :label="$t('school.school_data.school_type')"
                        v-model="school.school_type"
                        :rules="[$required]"
                      >
                        <VRadio
                          :label="$t('school.school_data.civil')"
                          :value="1"
                        ></VRadio>
                        <VRadio
                          :label="$t('school.school_data.governmental')"
                          :value="2"
                        ></VRadio>
                      </VRadioGroup>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" md="6">
                    <VCardItem class="pa-0">
                      <VRadioGroup
                        hide-details="auto"
                        inline
                        :label="$t('school.school_data.students_type')"
                        v-model="school.students_type"
                        :rules="[$required]"
                      >
                        <VRadio
                          v-for="item in student_type"
                          :key="item"
                          :label="item.name"
                          :value="item.id"
                        ></VRadio>
                      </VRadioGroup>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" class="pa-0 ma-0" md="12" lg="12">
                    <VCardItem class="px-3">
                      <VTextarea
                        v-model="school.note"
                        clearable
                        :label="$t('school.school_data.note')"
                        :rules="[$max_length(250)]"
                        counter="250"
                        no-resize
                        density="compact"
                        rows="3"
                        prepend-inner-icon="mdi-note-outline"
                      ></VTextarea>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol class="pt-0 px-7" cols="12" md="4" lg="4">
                <ImageInput
                  :reset="image_reset"
                  :image="school.school_logo"
                  :text="$t('school.school_data.school_logo')"
                  @ImageSelected="saveSchoolLogo"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="1">
            <VRow>
              <VCol cols="12" md="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="countries"
                        v-model="school.country"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.school_data.country')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-flag"
                        :rules="[$required]"
                        @update:modelValue="
                          (school.governorate = undefined),
                            (school.directorate = undefined)
                        "
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="
                          school.country
                            ? filterGovernorates(school.country)
                            : []
                        "
                        v-model="school.governorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.school_data.governorate')"
                        persistent-hint
                        density="compact"
                        :rules="[$required]"
                        @update:modelValue="school.directorate = undefined"
                        prepend-inner-icon="mdi-city"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="filterDirectorates(school.governorate)"
                        v-model="school.directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.school_data.directorate')"
                        persistent-hint
                        density="compact"
                        :rules="[$required]"
                        prepend-inner-icon="mdi-map-outline"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.place"
                        :label="$t('school.school_data.place')"
                        :placeholder="
                          $t('school.school_data.placeholder_place')
                        "
                        density="compact"
                        :rules="[$required, $max_length(), $min_length(3)]"
                        prepend-inner-icon="mdi-map-marker"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.coordinates"
                        prepend-inner-icon="mdi-map"
                        :label="$t('school.school_data.coordinates')"
                        :placeholder="
                          $t('school.school_data.placeholder_coordinates')
                        "
                        density="compact"
                        clearable
                        :rules="[$max_length()]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol class="pt-0 px-7" cols="12" md="4" lg="4">
                <ImageInput
                  :reset="image_reset"
                  :image="school.coordinates_image"
                  :text="$t('school.school_data.coordinates_image')"
                  @ImageSelected="saveCoordinatesImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="2">
            <VRow>
              <VCol cols="12" md="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.ministry_name"
                        prepend-inner-icon="mdi-domain"
                        :label="$t('school.school_data.ministry_name')"
                        :placeholder="
                          $t('school.school_data.placeholder_ministry_name')
                        "
                        density="compact"
                        :rules="[
                          $required,
                          $ar_letters_only,
                          $max_length(),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.education_office"
                        prepend-inner-icon="mdi-domain"
                        :label="$t('school.school_data.education_office')"
                        :placeholder="
                          $t('school.school_data.placeholder_education_office')
                        "
                        density="compact"
                        :rules="[
                          $required,
                          $ar_letters_only,
                          $max_length(),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="school.license_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.school_data.license_number')"
                        :placeholder="
                          $t('school.school_data.placeholder_license_number')
                        "
                        density="compact"
                        type="number"
                        :rules="[
                          $required,
                          $numeric,
                          $max_length(9),
                          $min_length(3),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol class="pt-0 px-7" cols="12" md="4" lg="4">
                <ImageInput
                  :reset="image_reset"
                  :image="school.license_image"
                  :text="$t('school.school_data.license_image')"
                  @ImageSelected="saveLicenseImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="3">
            <VRow v-for="(contact, index) in school.contacts" :key="index">
              <VCol cols="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="contact_type_list"
                    v-model="contact.contact_type"
                    item-title="name"
                    item-value="id"
                    :label="$t('school.school_data.contact_type')"
                    hide-details="auto"
                    persistent-hint
                    density="compact"
                    :rules="[$required]"
                    :prepend-inner-icon="
                      contact.contact_type == 1
                        ? 'mdi mdi-phone'
                        : contact.contact_type == 2
                        ? 'mdi mdi-email'
                        : contact.contact_type == 3
                        ? 'mdi mdi-cellphone'
                        : contact.contact_type == 4
                        ? 'mdi mdi-facebook'
                        : contact.contact_type == 5
                        ? 'mdi mdi-whatsapp'
                        : 'mdi-dialpad'
                    "
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol cols="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="contact.contact"
                    :prepend-inner-icon="
                      contact.contact_type == 1
                        ? 'mdi mdi-phone'
                        : contact.contact_type == 2
                        ? 'mdi mdi-email'
                        : contact.contact_type == 3
                        ? 'mdi mdi-cellphone'
                        : contact.contact_type == 4
                        ? 'mdi mdi-facebook'
                        : contact.contact_type == 5
                        ? 'mdi mdi-whatsapp'
                        : 'mdi-dialpad'
                    "
                    :label="$t('school.school_data.contact')"
                    hide-details="auto"
                    density="compact"
                    :rules="[
                      $required,
                      ...(contact.contact_type == 2
                        ? [$email, $max_length(64)]
                        : contact.contact_type == 4
                        ? [$max_length(100)]
                        : [
                            $numeric,
                            $max_length(14),
                            contact.contact_type == 1
                              ? $min_length(6)
                              : $min_length(9),
                          ]),
                    ]"
                  >
                    <!-- :rules="[(value) => checkContactType(value, contact.contact_type)]" -->
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="mt-3 mx-9 d-flex flex-wrap" elevation="4">
              <VBtn
                variant="text"
                size="x-small"
                class="text-success my-2 mx-3"
                icon="mdi-plus-thick"
                @click.prevent="addContact()"
              >
                <!-- icon="mdi-plus-thick" -->
                <v-icon color="success"></v-icon>
              </VBtn>
              <VBtn
                v-if="school?.contacts?.length > 1"
                variant="text"
                size="x-small"
                class="text-error my-2 mx-3"
                icon="mdi-minus-thick"
                @click.prevent="removeContact()"
              >
              </VBtn>
              <!-- <VBtn
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="my-2 mx-3"
                @click.prevent="addContact()"
              >
                <VIcon icon="mdi-plus-thick" color="success"></VIcon>
              </VBtn>
              <VBtn
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="my-2 mx-3"
                v-if="school.contacts.length > 1"
                @click.prevent="removeContact()"
              >
                <VIcon icon="mdi-minus-thick" color="error"></VIcon>
              </VBtn> -->
            </VRow>
          </v-window-item>
        </v-window>
      </v-card-text>
      <!-- <VRow class="mt-4 px-8 d-flex flex-wrap" elevation="4"> -->
      <VCardActions
        v-if="
          checkRole(['programmer', 'big_admin']) &&
          ((is_update && CheckGetPermission('school.change_school')) ||
            CheckGetPermission('school.add_school'))
        "
        class="mt-4 px-8"
      >
        <VBtn
          :disabled="disable_add"
          v-if="
            !is_update &&
            checkRole(['programmer', 'big_admin']) &&
            CheckGetPermission('school.add_school')
          "
          class="bg-indigo"
          @click.prevent="saveSchool()"
          size="small"
          :loading="is_loading"
        >
          <span class="text-white">
            {{ $t("global.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="
            is_update &&
            checkRole(['programmer', 'big_admin']) &&
            CheckGetPermission('school.change_school')
          "
          class="bg-success"
          @click.prevent="updateSchool()"
          size="small"
          :loading="is_loading"
        >
          <span class="text-white">
            {{ $t("global.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="!is_update"
          class="mx-3"
          size="small"
          @click.prevent="resetForm()"
        >
          {{ $t("global.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
      <!-- </VRow> -->
    </VForm>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";
export default {
  data() {
    return {
      image: require("@/assets/avatar.jpg"),
      school: {
        school_id: undefined,
        school_name: "",
        school_name_en: "",
        establish_date: undefined,
        ministry_name: undefined,
        education_office: undefined,
        school_number: undefined,
        license_number: "",
        license_image: "",
        school_type: undefined,
        country: undefined,
        governorate: undefined,
        directorate: undefined,
        headmaster: undefined,
        note: undefined,
        place: undefined,
        coordinates: undefined,
        coordinates_image: "",
        students_type: undefined,
        school_logo: "",
        data_entry: undefined,
        updated_by: undefined,
        contacts: [
          { contact_type: undefined, contact: undefined },
          { contact_type: undefined, contact: undefined },
        ],
      },
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      image_reset: 1,
      disable_add: false,
      updated_id: undefined,
      tab: undefined,
      tabs: [
        this.$t("school.school_data.main_data"),
        this.$t("school.school_data.location_data"),
        this.$t("school.school_data.ministry_data"),
        this.$t("school.school_data.contact_data"),
      ],
      is_loading: false,
      date_type: localStorage.getItem("date"),
    };
  },
  components: {
    ImageInput,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.contact_type_list.length) await this.getContactTypes();
      if (!this.student_type.length) await this.getStudentType();
      if (!this.countries.length) await this.getCountries();
      if (!this.governorates.length) await this.getGovernorates({});
      if (!this.directorates.length) await this.getDirectorate({});
      if (this.school_data == undefined) await this.getSchoolData(true);

      await this.getBrenches({ all: true, is_main: 1 });
      // the (1) is answer for is main

      if (this.school_data == undefined || this.school_data.length == 0) {
        this.is_update = false;
        // this.data_excist=2
      } else {
        this.is_update = true;
        // this.data_excist=1

        window.setTimeout(() => {
          this.school.school_id = this.school_data[0].id;
          this.school.school_name = this.school_data[0].arabic_name;
          this.school.school_name_en = this.school_data[0].english_name;
          this.school.establish_date = new Date(
            this.school_data[0].date_of_establishment
          )
            .toISOString()
            .split("T")[0];
          this.school.school_number = this.school_data[0].school_number;
          this.school.ministry_name = this.school_data[0].ministry_name;
          this.school.education_office = this.school_data[0].education_office;
          this.school.license_number =
            this.school_data[0].license_number.toString();
          this.school.school_type = this.school_data[0].education_type;
          let dir = this.directorates.find(
            (direct) => direct.id == this.branch[0].fk_directorate
          );
          if (dir) this.convertCountrySelectIntoNames(dir.id);
          this.school.headmaster = this.school_data[0].school_boss;
          this.school.note = this.school_data[0].note;
          this.school.place = this.branch[0].place;
          this.school.coordinates = this.branch[0].coordinates;
          this.school.students_type = this.branch[0].students_type;
          this.school.school_logo = this.school_data[0].logo;
          this.school.license_image = this.school_data[0].license_image;
          // this.school.coordinates_image = this.branch[0].coordinates_image;
          if (this.branch[0].coordinates_image)
            this.school.coordinates_image =
              this.branch[0].coordinates_image.search(this.base_url)
                ? `${this.base_url}${this.branch[0].coordinates_image}`
                : this.branch[0].coordinates_image;
          if (this.branch[0].id != undefined) {
            this.getContact(this.branch[0].id);
          }
        }, 300);
        window.setTimeout(() => {
          this.showSchoolForm();
        }, 500);
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      contact_type_list: (state) => state.globals.contacts_type,
      student_type: (state) => state.globals.student_type,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      school_data: (state) => state.school.school_data,
      branch: (state) => state.school.branch,
      contact: (state) => state.school.contact,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
  methods: {
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getSchoolData: "school/getSchoolData",
      getBrenches: "school/getBrenches",
      getContact: "school/getContact",
      getContactTypes: "globals/getContactTypes",
      getStudentType: "globals/getStudentType",
    }),
    convertCountrySelectIntoNames(directorate, gov = 0, con = 0) {
      let direct = this.directorates.find((dir) => dir.id == directorate);
      let governorate = undefined;
      let country = undefined;
      if (gov != 0)
        governorate = this.governorates.find((govern) => govern.id == gov);
      if (direct != undefined) {
        this.school.directorate = direct.name_ar;
        if (gov == 0)
          governorate = this.governorates.find(
            (gov) => gov.id == direct.fk_governorate
          );
      }
      if (con != 0) country = this.countries.find((contry) => contry.id == con);
      if (governorate != undefined) {
        this.school.governorate = governorate.id;
        if (con == 0)
          country = this.countries.find(
            (contry) => contry.id == governorate.fk_country
          );
      }
      if (country != undefined) this.school.country = country.id;
    },
    getPermissionTime(e, field) {
      let date = this.toEnglishString(e);
      switch (field) {
        case "establish_date":
          this.school.establish_date = date;
          break;
      }
    },
    showSchoolForm() {
      this.school.contacts = [];
      if (this.contact)
        this.contact.forEach((element) => {
          if (
            this.branch[0].id != undefined &&
            element.fk_branch == this.branch[0].id
          )
            this.school.contacts.push({
              contact: element.branch_contact,
              contact_type: element.type,
              id: element.id,
            });
        });
    },
    addContact() {
      this.school.contacts.push({
        contact_type: undefined,
        contact: undefined,
      });
    },
    removeContact() {
      this.school.contacts.pop();
    },
    resetForm() {
      this.$refs.form.reset();
      this.school.school_logo = "";
      this.school.coordinates_image = "";
      this.school.license_image = "";
      this.image_reset++;
    },
    dateFormat() {
      let date_parts = this.school.establish_date.split("-");
      let new_formatt = `${date_parts[0]}-${date_parts[1]}-${date_parts[2]}`;
      return new_formatt;
    },
    // هذه الدالة يمكن استخدامها بعد تحديد شكل عرض الصفحة
    /*async resetPage(){
            await this.getSchoolData()
            window.setTimeout(()=>{
                this.school.school_id=this.school_data[0].id
                this.school.school_name=this.school_data[0].arabic_name
                this.school.school_name_en=this.school_data[0].english_name
                this.school.establish_date=new Date(this.school_data[0].date_of_establishment).toISOString().split("T")[0]
                this.school.school_number=this.school_data[0].school_number
                this.school.ministry_name=this.school_data[0].ministry_name
                this.school.education_office=this.school_data[0].education_office
                this.school.license_number=this.school_data[0].license_number.toString()
                this.school.school_type=this.school_data[0].education_type
                let dir=this.directorates.find(direct=>direct.id==this.branch[0].fk_directorate)
                this.convertCountrySelectIntoNames(dir.id)
                this.school.headmaster=this.school_data[0].school_boss
                this.school.note=this.school_data[0].note
                this.school.place=this.branch[0].place
                this.school.coordinates=this.branch[0].coordinates
                this.school.students_type=this.branch[0].students_type
                this.school.school_logo=this.school_data[0].logo
                this.school.license_image=this.school_data[0].license_image
                this.school.coordinates_image=this.branch[0].coordinates_image
                },300)
        },*/
    saveLicenseImage(event) {
      this.school.license_image = event;
    },
    saveCoordinatesImage(event) {
      this.school.coordinates_image = event;
    },
    saveSchoolLogo(event) {
      this.school.school_logo = event;
    },
    async saveSchool() {
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.school.school_name &&
        this.school.country &&
        this.school.ministry_name &&
        this.school.contacts[0].contact_type
      ) {
        this.is_loading = true;
        this.disable_add = true;
        this.school.data_entry = this.user;
        await this.axios
          .post(this.base_url + "/school-data/", this.school, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data == 1) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
              this.disable_add = false;
            } else {
              this.$emit("successAlert", this.$t("global.success.save"));
              this.getContact(response.data);
              this.getSchoolData(true);
              this.is_update = true;
            }
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
            this.disable_add = false;
          });

        setTimeout(() => {
          this.convertCountrySelectIntoNames(
            this.school.directorate,
            this.school.governorate,
            this.school.country
          );
        }, 500);
      } else {
        if (valid && !this.school.school_name) {
          this.tab = 0;
        } else if (valid && !this.school.country) {
          this.tab = 1;
        } else if (valid && !this.school.ministry_name) {
          this.tab = 2;
        } else if (valid && !this.school.contacts[0].contact_type) {
          this.tab = 3;
        }
      }
      this.is_loading = false;
    },
    async updateSchool() {
      const { valid } = await this.$refs.form.validate();
      this.is_loading = true;
      if (!this.school.school_logo) {
        this.school.school_logo = "no_img";
      }
      if (!this.school.coordinates_image) {
        this.school.coordinates_image = "no_img";
      }
      if (!this.school.license_image) {
        this.school.license_image = "no_img";
      }

      if (valid && this.school.school_id != undefined) {
        let dir = this.directorates.find(
          (dire) => dire.name_ar == this.school.directorate
        );
        if (dir != undefined) this.school.directorate = dir.id;
        this.school.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/school-data/${this.school.school_id}/`,
            this.school,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data == -1) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            } else {
              this.$emit(
                "successAlert",
                this.$t("global.success.data_updated")
              );
              this.getSchoolData(true);
            }
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });

        setTimeout(() => {
          this.convertCountrySelectIntoNames(
            this.school.directorate,
            this.school.governorate,
            this.school.country
          );
        }, 300);
      } else {
      }
      this.is_loading = false;
    },
  },
};
</script>
<style scoped>
.v-application__wrap {
  background-color: #999;
}
img {
  max-width: 270px;
}
</style>

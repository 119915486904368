<template>
  <v-card>
    <v-card-text class="card-fields">
      <v-form
        v-if="
          (update && CheckGetPermission('school.change_activity')) ||
          CheckGetPermission('school.add_activity')
        "
        ref="form"
        @submit.prevent="saveActivity"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              color="indigo"
              v-model="activity.name"
              clearable
              counter="100"
              density="compact"
              prepend-inner-icon="mdi-soccer"
              :label="$t('school.activity.name')"
              :rules="[$required, $max_length(100)]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="activity.type"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-podium-gold"
              clearable
              :items="activitiesTypes"
              :label="$t('school.activity.type')"
              :rules="[$required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              color="indigo"
              v-model="activity.student_count"
              hide-details="auto"
              density="compact"
              counter="10"
              type="number"
              clearable
              :placeholder="$t('school.student.students_count_placeholder')"
              prepend-inner-icon="mdi-numeric"
              :label="$t('school.student.students_count')"
              :rules="[$required, $max_length(10), $numeric]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              ref="start"
              v-model="activity.start_date"
              density="compact"
              :placeholder="$t('school.activity.start_date')"
              clearable
              prepend-inner-icon="mdi-calendar-clock-outline"
              id="start_date"
              readonly
              :rules="[$required, start_date_rule]"
              @change="validateDate()"
            >
            </v-text-field>
            <Datetime
              v-model="date_start"
              type="date"
              element="start_date"
              :color="'#0747a6'"
              @input="(e) => getStartTime(e)"
              :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
              :placeholder="$t('school.homework.deadline')"
              :calendar="dateType"
            >
            </Datetime>

            <!--<v-text-field
              color="indigo"
              v-model="activity.start_date"
              type="datetime-local"
              clearable
              density="compact"
              :label="$t('school.activity.start_date')"
              :rules="[$required, start_date_rule]"
            /> -->
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              ref="end"
              v-model="activity.end_date"
              density="compact"
              :placeholder="$t('school.activity.end_date')"
              clearable
              prepend-inner-icon="mdi-calendar-clock-outline"
              id="end_date"
              readonly
              :rules="[$required, end_date_rule]"
              @change="validateDate()"
            >
            </v-text-field>
            <Datetime
              v-model="date_end"
              type="date"
              element="end_date"
              :color="'#0747a6'"
              @input="(e) => getEndTime(e)"
              :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
              :placeholder="$t('school.homework.deadline')"
              :calendar="dateType"
            >
            </Datetime>
            <!-- <v-text-field
              color="indigo"
              v-model="activity.end_date"
              type="datetime-local"
              :min="activity.start_date"
              clearable
              density="compact"
              :label="$t('school.activity.end_date')"
              :rules="[$required, end_date_rule]"
            /> -->
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              color="indigo"
              v-model="activity.responsible"
              clearable
              counter="100"
              density="compact"
              prepend-inner-icon="mdi-account"
              :label="$t('school.activity.responsible')"
              :rules="[$required, $max_length(100)]"
            />
          </v-col>
          <!-- <v-col cols="12" md="3">
                <v-autocomplete
                  color="indigo"
                  v-model="activity.type"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-podium-gold"
                  clearable
                  :items="activitiesTypes"
                  :label="$t('school.activity.type')"
                  :rules="[$required]"
                ></v-autocomplete>
            </v-col> -->
          <v-col cols="12" md="3">
            <v-text-field
              color="indigo"
              v-model="activity.fee"
              clearable
              counter="8"
              density="compact"
              type="number"
              prepend-inner-icon="mdi-cash-100"
              :label="$t('school.activity.fee')"
              :rules="[$required, $max_value(99999999)]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              color="indigo"
              v-model="activity.location"
              clearable
              counter="100"
              density="compact"
              prepend-inner-icon="mdi-map-marker-outline"
              :label="$t('school.activity.location')"
              :rules="[$required, $max_length(100)]"
            />
          </v-col>
          <!-- <v-col cols="12" md="3">
                                <v-select
                                    color="indigo"
                                    v-model="activity.fk_branch" 
                                    density="compact"
                                    item-title="name_ar"
                                    item-value="id"
                                    prepend-inner-icon="mdi-format-list-bulleted"
                                    clearable
                                    :items="branches"
                                    :label="$t('school.branch_data.select')"
                                    :rules="[$required]"
                                />
                            </v-col> -->
          <v-col cols="12">
            <v-textarea
              color="indigo"
              v-model="activity.note"
              hide-details="auto"
              density="compact"
              counter="250"
              rows="3"
              clearable
              prepend-inner-icon="mdi-note-outline"
              :label="$t('global.note')"
              :rules="[$max_length(250)]"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        (update && CheckGetPermission('school.change_activity')) ||
        CheckGetPermission('school.add_activity')
      "
      class="px-8"
    >
      <v-btn
        v-if="!update && CheckGetPermission('school.add_activity')"
        @click="saveActivity"
        :loading="is_loading"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="update && CheckGetPermission('school.change_activity')"
        @click="updateActivity(selectedActivity)"
        :loading="is_loading"
        class="bg-success"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <VBtn class="mx-3" size="small" @click="cleanActivityForm">
        {{ !update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <v-btn @click="cleanActivityForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn> -->
    </v-card-actions>
    <!-- ##########    data Table Server ########### -->
    <v-divider></v-divider>
    <v-card class="card-table" v-if="CheckGetPermission('school.view_activity')">
      <!-- ######## Header Of Data Table Server ####### -->
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            class="search"
            ref="text"
            v-model="txt_search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :headers="selectedHeaders"
        :items="tableList"
        :items-per-page="perPage"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>

              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  v-if="CheckGetPermission('school.change_activity')"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="selectActivity(item.raw)"
                >
                  <v-icon color="warnning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  v-if="CheckGetPermission('school.delete_activity')"
                  class="v-btn-icon"
                  @click="(selectedActivity = item.raw.id), (dialog = true)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selectedActivity = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteActivity(selectedActivity)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ############### Dialog Delete  ###################
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title class="text-center">
          <v-icon class="text-warnning px-2">mdi-alert</v-icon>
          <span style="font-size: 20px !important">{{
            $t("global.confirmation")
          }}</span>
        </v-card-title>
        <v-card-text>
          <span class="v-label">{{ $t("global.alert.confirm_delete") }}</span>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="(dialog = false), (selectedActivity = '')">{{
            $t("global.cancel")
          }}</v-btn>
          <v-btn
            class="bg-error"
            size="small"
            @click="deleteActivity(selectedActivity)"
            >{{ $t("global.delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <div></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Datetime from "vue3-datetime-js";
export default {
  name: "Activity",
  components: { Datetime },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // if (this.academicYears.length == 0) await this.getAcademicYears();
      if (this.activities.length == 0) await this.getActivities(true);
      if (this.activitiesTypes.length == 0) await this.getActivitiesTypes();
      if (this.branches.length == 0) await this.getBrenches({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      search: null,
      txt_search: null,
      uniqeYearMessage: null,
      uniqeYearMMessage: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      user: null,
      is_loading: false,
      selectedHead: [
        "name",
        "type_name",
        "responsible",
        "fee",
        "start_date",
        "end_ddate",
        "location",
        "actions",
      ],
      selectedActivity: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      activity: {
        type: undefined,
        name: "",
        responsible: "",
        start_date: undefined,
        end_date: undefined,
        fee: undefined,
        student_count: undefined,
        location: "",
        note: "",
        // fk_branch: null,
        // fk_year: undefined,
        data_entry: undefined,
        updated_by: undefined,
      },

      start_date_rule: (value) =>
        this.activity.end_date == undefined ||
        value <= this.activity.end_date ||
        this.$t("global.error.validation.end_date"),
      end_date_rule: (value) => {
        if (value && this.activity.start_date != undefined) {
          return (
            value >= this.activity.start_date ||
            this.$t("global.error.validation.start_date")
          );
        }
      },

      headers: [
        { title: this.$t("school.activity.name"), key: "name" },
        { title: this.$t("school.activity.type"), key: "type_name" },
        { title: this.$t("school.activity.responsible"), key: "responsible" },
        { title: this.$t("school.activity.start_date"), key: "start_date" },
        { title: this.$t("school.activity.end_date"), key: "end_date" },
        { title: this.$t("school.activity.fee"), key: "fee" },
        {
          title: this.$t("school.student.students_count"),
          key: "student_count",
        },
        { title: this.$t("school.activity.location"), key: "location" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      // academicYears:(state)=>state.school.academic_years,
      activitiesTypes: (state) => state.globals.activities_types,
      activities: (state) => state.school.activities,
      branches: (state) => state.school.branch,
    }),
    tableList() {
      var list = JSON.parse(JSON.stringify(this.activities));
      list.forEach((element) => {
        let type = element.type;
        // let fk_year=element.fk_year
        element.type_name = this.getActivityTypeName(type)?.name;
        element.type = this.getActivityTypeName(type)?.id;
        // element.fk_year=this.getAcademicYearName(fk_year)
      });
      return list;
    },
    dateType() {
      return localStorage.getItem("date") == "h" ? "hijri" : "";
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },

  methods: {
    ...mapActions({
      // getAcademicYears:"school/getAcademicYears",
      getActivities: "school/getActivities",
      getActivitiesTypes: "globals/getActivitiesTypes",
      getBrenches: "school/getBrenches",
    }),
    getActivityTypeName(id) {
      var item = this.activitiesTypes.find((item) => item.id === id);
      return item ? { name: item.name, id: item.id } : "";
    },
    getStartTime(e) {
      let date = this.toEnglishString(e);
      this.activity.start_date = date;
      this.validateDate();
    },
    getEndTime(e) {
      let date = this.toEnglishString(e);
      this.activity.end_date = date;
      this.validateDate();
    },
    // getAcademicYearName(id){
    //     var item=this.academicYears.find(item=>item.id===id)
    //     return item?item.year+'-'+item.year_m:''
    // },
    selectActivity(item) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.selectedActivity = item.id;
      this.activity = { ...item };
      this.update = true;
      // var temp_activity = this.activities.find(
      //   (element) => element.id == item.id
      // );
      // delete temp_activity.id;
      // this.activity = Object.assign({}, temp_activity);
    },
    async saveActivity() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        // this.activity.fk_year=localStorage.getItem('current_year')
        this.activity.data_entry = this.user;
        await this.axios
          .post(`${this.base_url}api/activities/`, this.activity, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.cleanActivityForm();
            this.getActivities(true);
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    cleanActivityForm() {
      this.update = false;
      this.$refs.form.reset();
    },
    async updateActivity(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.activity.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}api/activities/${id}/`, this.activity, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.cleanActivityForm();
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.getActivities(true);
      }
      this.is_loading = false;
    },
    deleteActivity(id) {
      this.axios
        .delete(`${this.base_url}api/activities/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.dialog = false;
          this.getActivities(true);
          this.cleanActivityForm();
        })
        .catch((error) => {
          if (error.response?.status == 418)
            this.$emit("protectedAlert", error.response?.data?.data);
          else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
    },
    validateDate() {
      if (this.activity.start_date && this.activity.end_date) {
        this.$refs.start.validate();
        this.$refs.end.validate();
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

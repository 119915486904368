<template>
  <v-card class="card-table">
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="txt_search"
        @input="!txt_search ? getNewsList((page = 1)) : ''"
        @keyup.enter="getNewsList((page = 1))"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_news')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="addNews">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <v-data-table-server
      :headers="selectedHeaders"
      :items="news_list"
      :items-per-page="perPage"
      item-value="name"
      item-key="id"
      fixed-footer
      fixed-header
      density="compact"
      class="elevation-1"
      :loading="loading"
      v-model:sort-by="sortBy"
      @update:options="getNewsList()"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <!-- @update:options="getNewsList((page = 1))" -->
      <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details density="compact"> </v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <!-- <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
            </v-checkbox> -->
            <span v-if="key === 'actions'">
              <span
                v-if="CheckGetPermission('school.change_news')"
                class="me-1"
                style="color: #e5b254; border-radius: 4px"
              >
                <v-icon
                  @click="editNews(item.raw.id)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-pencil-outline"
                />
              </span>
              <span
                v-if="CheckGetPermission('school.delete_news')"
                style="color: #d05251; border-radius: 4px"
              >
                <v-icon
                  @click="(news_del_id = item.raw.id), (del_dialog = true)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-trash-can-outline"
                />
              </span>
            </span>
            <span v-else-if="key == 'image'">
              <button @click="showNewsImage(val)">
                <VAvatar
                  :image="
                    val != null
                      ? val.search(base_url) != -1
                        ? val
                        : base_url + val
                      : false
                  "
                  :icon="val == null ? 'mdi-image-off-outline' : ''"
                  class=""
                  size="small"
                ></VAvatar>
              </button>
            </span>
            <span v-else> {{ val }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="totalItems"
          :newItem="[perPage]"
        ></pagination>
      </template>
    </v-data-table-server>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(del_dialog = false), (news_del_id = undefined)"
          >{{ $t("global.cancel") }}</v-btn
        >
        <v-btn color="red" @click="deleteNews">{{ $t("global.delete") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </VCard> -->
  <VDialog v-model="dialog" max-width="500px" max-height="500px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <!-- <VImg :src="dialog_image" :width="400"></VImg> -->
      <VCardItem>
        <img :src="dialog_image" class="mx-auto w-75" />
      </VCardItem>
      <VCardActions>
        <VBtn class="bg-info" @click="dialog = false">{{
          $t("global.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>
export default {
  data() {
    return {
      news_list: [],
      del_dialog: false,
      news_del_id: undefined,

      dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      perPage: 10,
      length: 0,
      page: 1,
      pagination: {},
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      loading: false,
      headers: [
        { title: this.$t("school.notification.title"), key: "title" },
        { title: this.$t("school.notification.topic"), key: "description" },
        {
          title: this.$t("school.news.news_image"),
          key: "image",
          sortable: false,
        },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["title", "description", "image", "actions"],
      txt_search: undefined,
      loading: false,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getNewsList();
      setTimeout(() => {
        this.$store.commit("updateLoadingValue", false);
      }, 1500);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    addNews() {
      this.$router.push("add");
    },
    editNews(id) {
      this.$router.push(`${id}/edit/`);
    },
    showNewsImage(value) {
      this.dialog_text = [];
      this.dialog_image = "";
      if (value != null) {
        if (value?.search(this.base_url) == -1)
          this.dialog_image = this.base_url + value;
        else this.dialog_image = value;
      }
      this.dialog_title = this.$t("school.news.news_image");
      this.dialog = true;
    },
    async deleteNews() {
      if (this.news_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}news/${this.news_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.news_del_id = undefined;
        this.getNewsList();
      }
    },
    async getNewsList() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}news/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: this.page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
        })
        .then((response) => {
          this.news_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getNewsList('page');
    },
    page() {
      this.getNewsList('page3');
    },
  },
};
</script>
<style scoped></style>

<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = []),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="
              (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = [])
            "
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            @update:model-value="
              (filter_data.fk_student = null),
                (student_list = []),
                getStudents()
            "
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_semester"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-book-education"
            :items="semesters"
            :label="$t('school.reports.select_semester')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="5" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="student_list"
            :label="$t('school.reports.select_student')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>

        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header
      rep_en
      :data="filter_data"
      :subtitle="'( ' + filter_data?.fk_semester?.name_ar + ' )'"
    ></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card
          v-if="items.length < 1 || items[0]?.subject_data < 1"
          class="pa-2"
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <v-row
            v-if="items?.length > 0"
            class="mb-1 mt-2 borders justify-center pt-2 pb-2 mr-1 ml-1"
          >
            <v-col cols="auto" class="flex-grow-1">
              <h3 class="fontsize">
                {{ $t("school.reports.student_name") }} :
                {{ items[0]?.student_name }}
              </h3>
            </v-col>

            <v-col cols="3">
              <h3 class="fontsize">
                {{ $t("school.reports.class") }} :
                {{ filter_data.fk_class?.name_ar }}
              </h3>
            </v-col>

            <v-col cols="3">
              <h3 class="fontsize">
                {{ $t("school.reports.division") }} :
                {{ filter_data.fk_division?.name }}
              </h3>
            </v-col>
          </v-row>
          <v-table density class="rounded border">
            <thead>
              <!-- <tr>
                <th class="py-1 px-2" :colspan="selectedHeaders?.length">
                  <div class="d-flex text-white">
                    <h3>
                      {{ $t("school.reports.student_name") }} :
                      {{ items[0].student_name }}
                    </h3>
                    <v-spacer></v-spacer>
                    <h3>
                      {{ $t("school.reports.class") }} :
                      {{ items[0].class_name }}
                    </h3>
                    <v-spacer></v-spacer>
                    <h3>
                      {{ $t("school.reports.semester") }} :
                      {{ items[0].semester_name }}
                    </h3>
                  </div>
                </th>
              </tr> -->
              <tr>
                <th :rowspan="selectedHeaders[0].row">
                  {{ selectedHeaders[0].title }}
                </th>
                <th colspan="2" class="border">
                  {{ filter_data?.fk_semester?.name_ar }}
                </th>
                <th colspan="2" class="border">مجموع الدرجات</th>
                <th :rowspan="selectedHeaders[selectedHeaders?.length - 1].row">
                  {{ selectedHeaders[selectedHeaders?.length - 1].title }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="header in selectedHeaders.filter(
                    (head) =>
                      head.key != 'estamate_subject_ar' &&
                      head.key != 'subject_name'
                  )"
                  :key="header.key"
                  class="border"
                >
                  {{ header.title }}
                </th>
              </tr>
              <!-- <tr>
                <th class="background-cell" colspan="3">
                  {{ $t("school.reports.student_name") }}
                  {{ filter_data.fk_student?.name_ar }}
                </th>
                <th colspan="2" class="background-cell"></th>
                <th colspan="5" class="background-cell">
                  {{ filter_data.fk_class?.name_ar }}/
                  {{ filter_data.fk_division?.name }}
                </th>
              </tr>
              <tr>
                <th v-for="header in headers" :key="header.key" class="border">
                  {{ header.title }}
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items[0]?.subjects" :key="key">
                <template
                  v-for="(header, index) in selectedHeaders"
                  :key="index"
                >
                  <!-- <template v-if="index === 0">
                    <td class="text-center border">{{ key + 1 }}</td>
                  </template> -->
                  <template v-if="header.key === 'writing'">
                    <td class="text-center border">
                      {{
                        item?.total ? tafqeet(item.total) + " " + "درجة" : "-"
                      }}
                    </td>
                  </template>
                  <td
                    v-else
                    :class="`text-center border ${
                      index == selectedHeaders?.length - 2
                        ? ' background-cell'
                        : ''
                    }`"
                  >
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </td>
                </template>
              </tr>
              <tr class="tfoot">
                <td class="text-center border background-cell">
                  {{ $t("school.reports.total") }}
                </td>
                <td colspan="2" class="text-center border background-cell">
                  {{ totalMarks() }} <v-icon>mdi-circle-small</v-icon>
                  {{ tafqeet(totalMarks()) }}
                </td>
                <td class="text-center border background-cell">
                  {{ $t("school.reports.percentage") }}
                </td>
                <td class="text-center border background-cell">
                  %{{ getPercentageValue() }}
                </td>
                <td class="text-center border background-cell">
                  {{ $t("school.reports.general_estimate") }} :
                  {{ estimateChecker(getPercentageValue()) }}
                </td>
                <!-- <td class="text-center border background-cell">dsf</td> -->
              </tr>
              <!-- <tr v-for="item in items[0]?.subject_data" :key="item">

                <td
                  class="text-center border"
                  v-if="selectedHead.includes('subject')"
                >
                  {{ item.subject_name }}
                  {{ item.subject_optional == true ? "*" : "" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('collector')"
                >
                  {{ item.collector }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('final_exam')"
                >
                  {{ item.final_exam }}
                </td>
                <td
                  class="text-center border background-cell"
                  v-if="selectedHead.includes('total')"
                >
                  {{ getTotal(item.collector, item.final_exam) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('writing')"
                >
                  {{ tafqeet(getTotal(item.collector, item.final_exam)) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_actual_lecture')"
                >
                  {{ item.count_of_actual_lecture }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_attendence')"
                >
                  {{ item.count_of_attendence }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_absentce')"
                >
                  {{ item.count_of_absentce }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_permission')"
                >
                  {{ item.count_of_permission }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_not_permission')"
                >
                  {{ item.count_of_not_permission }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center border background-cell">
                  {{ $t("school.reports.total_degrees") }}
                </td>
                <td class="text-center border background-cell">
                  {{ subjectsTotal }}
                </td>
                <td colspan="7" class="text-center border">
                  {{ tafqeet(subjectsTotal) }}
                </td>
              </tr>
              <tr>
                <td class="text-center border background-cell">
                  {{ $t("school.reports.percentage") }}
                  {{ getPercentageValue(subjectsTotal, subjectsCount) }}%
                </td>
                <td colspan="5" class="text-center border background-cell"></td>
                <td colspan="3" class="text-center border background-cell">
                  {{ $t("school.reports.estimate") }}
                </td>
                <td colspan="2" class="text-center border background-cell">
                  {{ items[0]?.estimate }}
                </td>
              </tr> -->
            </tbody>
          </v-table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
import tafqeet from "@/assets/Tafqeet";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // this.year_data = localStorage.getItem("current_year_name_h");
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      await this.getSemesters();
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      await this.getClassAssignHall();
      await this.getStudents();
      await this.getDivisions({});
      await this.getAllEstimations();
      // await this.getAcademicYears();
      // await this.getYears();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = this.getYearName(
      // localStorage.getItem("current_year")
      // );
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // year_data: null,
      // school: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "subject_name",
        "collector",
        "final_exam",
        "writing",
        "total",
        "estamate_subject_ar",
        // "count_of_actual_lecture",
        // "count_of_attendence",
        // "count_of_absentce",
        // "count_of_permission",
        // "count_of_not_permission",
      ],
      headers: [
        {
          title: this.$t("school.reports.subject"),
          key: "subject_name",
          row: 2,
        },
        { title: this.$t("school.reports.collector"), key: "collector" },
        { title: this.$t("school.reports.final_exam"), key: "final_exam" },
        { title: this.$t("school.reports.numbering"), key: "total" },
        { title: this.$t("school.reports.writing"), key: "writing" },
        {
          title: this.$t("school.reports.estimate"),
          key: "estamate_subject_ar",
          row: 2,
        },
        // {
        //   title: this.$t("school.reports.count_of_actual_lecture"),
        //   key: "count_of_actual_lecture",
        // },
        // {
        //   title: this.$t("school.reports.count_of_attendence"),
        //   key: "count_of_attendence",
        // },
        // {
        //   title: this.$t("school.reports.count_of_absentce"),
        //   key: "count_of_absentce",
        // },
        // {
        //   title: this.$t("school.reports.count_of_permission"),
        //   key: "count_of_permission",
        // },
        // {
        //   title: this.$t("school.reports.count_of_not_permission"),
        //   key: "count_of_not_permission",
        // },
      ],
      // perPage: 25,
      // length: 0,
      // totalItems: 0,
      // itemsPerPage: [
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      // page: 1,
      // pagination: {},
      student_list: [],
      filter_data: {},
      // subjectsTotal: 0,
      // subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      semesters: (state) => state.school.semesters,
      divisions: (state) => state.school.divisions,
      estimates: (state) => state.school.estimates,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    tafqeet,
    totalMarks() {
      return this.items[0]?.subjects.reduce(
        (sum, subject) => sum + subject.total,
        0
      );
    },
    getPercentageValue() {
      const total = this.items[0]?.subjects?.length;
      return parseFloat((this.totalMarks() * 2) / total).toFixed(2);
    },
    estimateChecker(degree) {
      return this.estimates.find(
        (estimate) =>
          degree >= estimate.degree_min && degree <= estimate.degree_max
      )?.name_ar;
    },
    // getPercentageValue(sum, count) {
    //   const total = ((sum * 2) / count) * 100;
    //   return parseFloat(total.toFixed(2) / 100).toFixed(2);
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getSubjectsTotal(subjects) {
    //   const { sum, count } = subjects[0].subject_data.reduce(
    //     (accumulator, obj) => {
    //       if (obj.subject_optional == false)
    //         return {
    //           sum: accumulator.sum + obj.final_exam + obj.collector,
    //           count: accumulator.count + 1,
    //         };
    //       return {
    //         sum: accumulator.sum,
    //         count: accumulator.count,
    //       };
    //     },
    //     { sum: 0, count: 0 }
    //   );
    //   this.subjectsTotal = sum;
    //   this.subjectsCount = count;
    // },
    // getTotal(collector, finalExam) {
    //   return collector + finalExam;
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // getSemesterName(semester_id) {
    //   if (semester_id) {
    //     const objectSemester = this.semesters.find(
    //       (objectSemester) => objectSemester.id === semester_id
    //     );
    //     return objectSemester.name_ar;
    //   }
    //   return "-------------";
    // },
    // getDivisionName(division_id) {
    //   const division = this.divisions.find(
    //     (division) => division.id === division_id
    //   );
    //   return division.name;
    // },
    async getStudents() {
      if (this.filter_data.fk_class?.id && this.filter_data.fk_division?.id) {
        let list = await this.axios.get(`${this.base_url}student-list/`, {
          params: {
            class: this.filter_data.fk_class?.id,
            division: this.filter_data.fk_division?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // year: localStorage.getItem("current_year"),
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.student_list = list.data;
      }
    },
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      // getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
      // getAllMonths: "school/getAllMonths",
      getSemesters: "school/getSemesters",
      // getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
      getAllEstimations: "school/getAllEstimations",
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable() {
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/certificate`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            // page: page,
            // page_size: this.perPage,
            // class_number: this.filter_data.fk_class?.id,
            // year: localStorage.getItem("current_year"),
            semester: this.filter_data.fk_semester?.id,
            student: this.filter_data.fk_student?.id,
            // division: this.filter_data.fk_division?.id,
          },
        })
        .then((response) => {
          if (response) {
            this.items = response.data;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
            // this.getSubjectsTotal(this.items);
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // watch: {
  //   perPage() {
  //     this.getData();
  //   },
  //   page() {
  //     this.getData(this.page);
  //   },
  // },
};
</script>
<style scoped>
.fontsize {
  font-size: 14px;
  text-align: center;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
#myTable th {
  height: 30px;
}
#myTable td {
  height: 30px;
}
#myTable .tfoot td {
  height: 40px;
}
/* @media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
} */
thead tr th:nth-of-type(-n + 5),
.background-cell {
  background: #b1cdb6;
}
thead tr th:nth-of-type(6) {
  border: none !important;
}
/* :nth-of-type(n + 7) */
thead tr th {
  width: fit-content;
  /* width: 30px; */
  font-size: 0.9rem;
  font-weight: bold;
}
</style>

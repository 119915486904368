<template>
  <VCard class="pb-4 pa-0">
    <VForm
      ref="form"
      v-if="
        (is_update && CheckGetPermission('school.change_student')) ||
        CheckGetPermission('school.add_student')
      "
    >
      <VTabs v-model="tab" color="blue" class="px-4">
        <VTab v-for="(value, index) in tabs" :key="index" :value="index">
          {{ value }}
        </VTab>
      </VTabs>
      <VCardText>
        <v-window v-model="tab">
          <v-window-item :value="0">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.name_ar"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('global.personal_information.name_ar')"
                        :placeholder="
                          $t('global.personal_information.name_ar_placehoder')
                        "
                        density="compact"
                        :rules="[$required, $max_length(150), $ar_letters_only]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.name_en"
                        prepend-inner-icon="mdi-alpha-e"
                        :label="$t('global.personal_information.name_en')"
                        :placeholder="
                          $t('global.personal_information.name_en_placehoder')
                        "
                        density="compact"
                        :rules="[$max_length(150), $en_letters_only]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="student.nationality"
                        prepend-inner-icon="mdi-passport"
                        item-value="id"
                        item-title="nati_name_ar"
                        :items="countries"
                        :label="$t('global.personal_information.nationality')"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="[]"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" xs="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VRadioGroup
                        inline
                        :label="$t('global.personal_information.gender')"
                        v-model="student.gender"
                        :rules="[$required]"
                      >
                        <VRadio
                          v-for="item in gender"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          color="blue"
                        >
                        </VRadio>
                      </VRadioGroup>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <v-text-field
                        v-model="student.birthdate"
                        density="compact"
                        class="cursor-pointer"
                        :placeholder="
                          $t('global.personal_information.birth_date')
                        "
                        clearable
                        hide-details="auto"
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        id="date"
                        :rules="[ $max_date({}, this.max_date)]"
                      >
                        <!-- :error-messages="
                      v$.filter_data.day_date.$errors.map((e) => e.$message)
                    " -->
                      </v-text-field>
                      <Datetime
                        style="cursor-pointer"
                        type="date"
                        element="date"
                        density="compact"
                        :color="'#0747a6'"
                        @input="(e) => getPermissionTime(e)"
                        :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                        :placeholder="
                          $t('school.student_attendance.permission_time')
                        "
                      >
                      </Datetime>
                      <!-- <VTextField
                        v-model="student.birthdate"
                        :label="$t('global.personal_information.birth_date')"
                        density="compact"
                        type="date"
                        :rules="[$required, $max_date(this.max_date)]"
                      >
                      </VTextField> -->
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.birth_place"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('global.personal_information.birth_place')"
                        density="compact"
                        :rules="[ $max_length(100)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="student.fk_country"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-flag"
                        :items="countries"
                        :label="$t('global.personal_information.country')"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="[]"
                        @update:modelValue="student.fk_governorate = undefined"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="student.fk_governorate"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-city"
                        :items="getGovernoratesByCountry(student.fk_country)"
                        :label="$t('global.personal_information.governorate')"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="[]"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.address"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('global.personal_information.address')"
                        density="compact"
                        :rules="[$max_length(100)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent_relation"
                        prepend-inner-icon="mdi-human-male-boy"
                        :label="$t('school.student.parent_relation')"
                        :placeholder="
                          $t('school.student.parent_relation_placeholder')
                        "
                        density="compact"
                        :rules="[ $max_length(100)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        :placeholder="
                          $t(
                            'global.personal_information.phone_number_placeholder'
                          )
                        "
                        :onkeypress="$only_phone_number"
                        v-model="student.phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('global.personal_information.phone_number')"
                        density="compact"
                        :rules="[
                          $required,
                          $numeric,
                          $max_length(14),
                          $min_length(9),
                        ]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        :placeholder="
                          $t(
                            'global.personal_information.home_number_placeholder'
                          )
                        "
                        v-model="student.home_number"
                        :onkeypress="$only_phone_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('global.personal_information.home_number')"
                        density="compact"
                        :rules="[$numeric, $max_length(14), $min_length(6)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="3" lg="3" class="mt-5">
                <ImageInput
                  :reset="image_reset"
                  :image="student.student_image"
                  :text="$t('school.student.student_image')"
                  @ImageSelected="saveStudentImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="1">
            <VRow>
              <VCol class="pt-0" cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow>
                  <VCol class="pt-0" cols="12" xs="12" sm="12" md="5" lg="5">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="classes"
                        v-model="student.class.fk_class"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.class.name')"
                        prepend-inner-icon="mdi-google-classroom"
                        persistent-hint
                        density="compact"
                        @update:modelValue="
                          checkClassDocument(),
                            (student.class.fk_division = undefined),
                            (info_dialog = student.class.fk_class
                              ? true
                              : false)
                        "
                        :rules="[$required]"
                      >
                      </VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0" cols="12" xs="12" sm="12" md="5" lg="5">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="getDivisionsByClass(student.class.fk_class)"
                        v-model="student.class.fk_division"
                        item-title="name"
                        item-value="id"
                        :label="$t('school.division.name')"
                        :no-data-text="$t('school.division.not_found')"
                        prepend-inner-icon="mdi-format-list-bulleted"
                        persistent-hint
                        density="compact"
                        @update:modelValue="checkStudentCountInHall()"
                        :rules="[$required]"
                      >
                      </VAutocomplete>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    class="pa-0 ma-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <VCardItem class="px-3">
                      <VTextarea
                        v-model="student.note"
                        clearable
                        :label="$t('global.note')"
                        counter="250"
                        no-resize
                        density="compact"
                        rows="3"
                        :rules="student.note ? [$max_length(250)] : ''"
                      ></VTextarea>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol class="pt-0" cols="12" xs="12" sm="12" md="3" lg="3">
                <ImageInput
                  style="height: 100%"
                  :reset="image_reset"
                  :image="student.registration_form"
                  :text="$t('school.student.registration_form')"
                  @ImageSelected="saveRegistrationForm"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="2">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <VRow>
                        <VAutocomplete
                          v-model="fk_parent"
                          prepend-inner-icon="mdi-text"
                          item-value="id"
                          item-title="name"
                          :items="parent_list"
                          :label="$t('school.parent.select_parent_name')"
                          density="compact"
                          :disabled="!selected"
                          @update:modelValue="showSelectedParent(fk_parent)"
                          :rules="selected ? [$required] : ''"
                        >
                        </VAutocomplete>
                        <VBtn
                          variant="elevated"
                          size="small"
                          color="grey-lighten-2"
                          class="my-2 mx-3"
                          @click.prevent="newParent(selected)"
                        >
                          <VIcon
                            :icon="
                              selected ? 'mdi-plus-thick ' : 'mdi-minus-thick'
                            "
                            :color="selected ? 'success ' : 'error'"
                          ></VIcon>
                        </VBtn>
                      </VRow>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('global.personal_information.name_ar')"
                        :placeholder="
                          $t('school.parent.parent_name_placeholder')
                        "
                        density="compact"
                        clearable
                        :rules="
                          !selected
                            ? [
                                $required,
                                $ar_letters_only,
                                $max_length(100),
                                $min_length(2),
                              ]
                            : ''
                        "
                        :disabled="selected"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="student.parent.identity_type"
                        prepend-inner-icon="mdi-card-account-details"
                        item-value="id"
                        item-title="name"
                        :items="identify_type"
                        :label="$t('global.personal_information.id_type')"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="!selected ? [] : ''"
                        :disabled="selected"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.identity_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('global.personal_information.id_number')"
                        :placeholder="
                          $t(
                            'global.personal_information.id_number_placeholder'
                          )
                        "
                        persistent-hint
                        density="compact"
                        clearable
                        type="number"
                        :rules="
                          !selected
                            ? [
                                $max_length(14),
                                $min_length(3),
                                $numeric,
                              ]
                            : ''
                        "
                        :disabled="selected"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.job"
                        prepend-inner-icon="mdi-account-hard-hat"
                        :label="$t('school.parent.job')"
                        :placeholder="$t('school.parent.job_placeholder')"
                        density="compact"
                        clearable
                        :rules="
                          !selected
                            ? [ $max_length(100), $min_length(3)]
                            : ''
                        "
                        :disabled="selected"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        :placeholder="
                          $t(
                            'global.personal_information.phone_number_placeholder'
                          )
                        "
                        v-model="student.parent.phone_number"
                        :onkeypress="$only_phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('global.personal_information.phone_number')"
                        density="compact"
                        clearable
                        :disabled="selected"
                        :rules="
                          !selected
                            ? [
                                $required,
                                $numeric,
                                $max_length(14),
                                $min_length(9),
                              ]
                            : ''
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        :placeholder="
                          $t(
                            'global.personal_information.home_number_placeholder'
                          )
                        "
                        v-model="student.parent.home_number"
                        :onkeypress="$only_phone_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('global.personal_information.home_number')"
                        density="compact"
                        clearable
                        :rules="
                          !selected
                            ? [$numeric, $max_length(14), $min_length(6)]
                            : ''
                        "
                        :disabled="selected"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.address"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('global.personal_information.address')"
                        :placeholder="
                          $t('global.personal_information.address_placeholder')
                        "
                        density="compact"
                        clearable
                        :disabled="selected"
                        :rules="
                          !selected
                            ? [ $max_length(100), $min_length(5)]
                            : ''
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    class="pa-0 ma-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <VCardItem class="px-3">
                      <VTextarea
                        v-model="student.parent.note"
                        prepend-inner-icon="mdi-note-outline"
                        clearable
                        :label="$t('global.notes')"
                        :placeholder="$t('global.notes_placeholder')"
                        counter="250"
                        no-resize
                        :disabled="selected"
                        density="compact"
                        rows="3"
                        :rules="student.parent.note ? [$max_length(250)] : ''"
                      ></VTextarea>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="3" lg="3">
                <ImageInput
                  :reset="image_reset"
                  :image="student.parent.identity_image"
                  :text="$t('school.parent.parent_id_image')"
                  @ImageSelected="saveIdImage"
                  :disabled="selected"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="3">
            <VRow v-for="(doc, index ,length) in student.documents" :key="doc">
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    v-model="doc.document"
                    prepend-inner-icon="mdi-passport"
                    item-value="id"
                    item-title="name"
                    :items="documents"
                    :label="$t('school.document.name')"
                    persistent-hint
                    density="compact"
                    :rules="[
                      doc.document || doc.files?.length ? $required : true,
                    ]"
                    clearable
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VFileInput
                    v-model="doc.files"
                    :label="$t('school.document.add')"
                    density="compact"
                    type="file"
                    :messages="
                      $t('school.document.current_file') +
                      ':' +
                      decodeURI(doc.files_text).slice(25)
                    "
                    :rules="[
                      doc.document || doc.files?.length
                        ? is_update
                          ? true
                          : $required
                        : true,
                    ]"
                  >
                  </VFileInput>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="doc.issuer"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('school.document.place_issued')"
                    :placeholder="
                      $t('school.document.place_issued_placehloder')
                    "
                    density="compact"
                    clearable
                    :rules="[
                      doc.document || doc.files?.length ? $required : true,
                      $max_length(100),
                      $min_length(2),
                    ]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field
                  v-model="doc.released_date"
                  density="compact"
                  class="cursor-pointer"
                  :placeholder="$t('school.document.release_date')"
                  clearable
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar-clock-outline"
                  :id="`date${index}`"
                  :rules="[
                    doc.document || doc.files?.length ? $required : true,
                  ]"
                >
                  <!-- :error-messages="
                      v$.filter_data.day_date.$errors.map((e) => e.$message)
                    " -->
                </v-text-field>
                <Datetime
                  style="cursor-pointer"
                  type="date"
                  :element="`date${index}`"
                  density="compact"
                  :color="'#0747a6'"
                  @input="(e) => getPermissionTime(e, index)"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('school.student_attendance.permission_time')"
                >
                </Datetime>
                <!-- <VCardItem class="pa-0">
                  <VTextField
                    v-model="doc.released_date"
                    :label="$t('school.document.release_date')"
                    density="compact"
                    type="date"
                    clearable
                  >
                  </VTextField>
                </VCardItem> -->
              </VCol>
              <v-divider class="mt-4" v-if="length" />
            </VRow>
            <VRow
              class=" mx-10 d-flex flex-wrap"
              style="gap: 1.5rem"
              elevation="4"
            >
              <VBtn
                variant="text"
                size="x-small"
                class="text-success"
                icon="mdi-plus-thick"
                @click.prevent="addDocument()"
              >
                <!-- icon="mdi-plus-thick" -->
                <v-icon color="success"></v-icon>
              </VBtn>
              <VBtn
                v-if="student.documents?.length > 0"
                variant="text"
                size="x-small"
                class="text-error"
                icon="mdi-minus-thick"
                @click.prevent="removeDocument()"
              >
              </VBtn>
            </VRow>
          </v-window-item>
        </v-window>
      </VCardText>

      <VRow class="mt-4 px-8 d-flex flex-wrap" elevation="4">
        <VCardActions
          v-if="
            (is_update && CheckGetPermission('school.change_student')) ||
            CheckGetPermission('school.add_student')
          "
        >
          <VBtn
            v-if="!is_update && CheckGetPermission('school.add_student')"
            :loading="is_loading"
            class="bg-indigo"
            @click="saveStudent"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="is_update && CheckGetPermission('school.change_student')"
            class="bg-success"
            :loading="is_loading"
            @click="updateStudent"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ !id ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
              :color="!id ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
        </VCardActions>
      </VRow>
    </VForm>
  </VCard>
  <v-dialog v-model="dialog" transition="dialog-top-transition" width="auto">
    <v-card>
      <v-card-title>
        <h3 class="text-warning">
          {{ $t("global.warning") }}
        </h3>
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.student_count_exceeded") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="dialog = false">{{
          $t("global.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="info_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        <h3 class="text-info">{{ $t("global.note") }}</h3>
      </v-card-title>
      <v-card-text>
        <p>{{ $t("global.alert.documents_per_class") }}</p>
        <p v-for="item in required_docs" :key="item">
          {{ item }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="info_dialog = false">{{
          $t("global.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";
import Datetime from "vue3-datetime-js";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      // v$: useValidate(),
      parent_list: [],
      max_date: undefined,
      date_now: undefined,
      selected: true,
      dialog: false,
      info_dialog: false,
      required_docs: [],
      documents: [],
      image_reset: 1,
      fk_parent: undefined,
      is_loading: false,
      student: {
        fk_parent: 0,
        // fk_user: 0,
        fk_country: undefined,
        fk_governorate: undefined,
        fk_branch: undefined,
        name_ar: undefined,
        name_en: undefined,
        gender: undefined,
        nationality: undefined,
        birthdate: undefined,
        birth_place: undefined,
        parent_relation: undefined,
        address: undefined,
        phone_number: undefined,
        home_number: undefined,
        student_image: undefined,
        registration_form: undefined,
        note: undefined,
        files: undefined,
        parent: {
          fk_user: 0,
          name: "",
          identity_type: undefined,
          identity_number: undefined,
          identity_image: undefined,
          job: undefined,
          address: undefined,
          phone_number: undefined,
          home_number: undefined,
          note: undefined,
        },
        class: {
          fk_student: 0,
          //   fk_year: undefined,
          fk_class: undefined,
          fk_division: undefined,
          // note:undefined,
        },
        documents: [
          {
            files: undefined,
            document: undefined,
            released_date: undefined,
            issuer: undefined,
          },
        ],
      },
      tab: undefined,
      is_update: false,
      max_date: null,
      tabs: [
        this.$t("school.student.student_info"),
        this.$t("school.student.student_academic_info"),
        this.$t("school.student.parent_info"),
        this.$t("school.student.student_documents"),
      ],
    };
  },
  components: {
    ImageInput,
    Datetime,
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      let date = new Date();
      this.max_date = new Date(
        date.getFullYear() - 4,
        date.getMonth(),
        date.getDay()
      )
        .toISOString()
        .split("T")[0];
      this.date_now = date.toISOString().split("T")[0];
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      //   if (!this.branch.length) await this.getBrenches({});
      if (!this.gender.length) await this.getGender();
      if (!this.identify_type.length) await this.getIdentifyType();
      if (!this.countries.length) await this.getCountries(true);
      if (!this.classes.length) await this.getClasses();
      if (!this.governorate.length) await this.getGovernorates({});
      this.getParents();

      if (this.id != undefined) {
        this.is_update = true;
        let student = [];
        let is_obj = false;
        await this.axios
          .get(`${this.base_url}/student/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setTimeout(() => {
              this.tab = 3;
            }, 1);
            setTimeout(() => {
              this.tab = 2;
            }, 2);
            setTimeout(() => {
              this.tab = 1;
            }, 3);
            setTimeout(() => {
              this.tab = 0;
            }, 4);
            student = response.data;
            for (const key in this.student) {
              for (const key2 in this.student[key]) {
                this.student[key][key2] = student[key2];
                is_obj = true;
              }
              if (is_obj == true) is_obj = false;
              else this.student[key] = student[key];
            }
            this.student.documents = student.documents;
            this.student.student_image =
              this.student.student_image.search(this.base_url) != -1
                ? this.student.student_image
                : this.base_url + this.student.student_image;
            this.student.registration_form =
              this.student.registration_form.search(this.base_url) != -1
                ? this.student.registration_form
                : this.base_url + this.student.registration_form;
            this.showSelectedParent(student.fk_parent);
            this.getDivisionsByClass(this.student.class.fk_class);
            this.fk_parent = student.fk_parent;
            this.checkClassDocument();
          });
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      gender: (state) => state.globals.gender,
      identify_type: (state) => state.globals.identify_type,
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      countries: (state) => state.school.countries,
      //   branch: (state) => state.school.branch,
      user: (state) => state.User,
      governorate: (state) => state.school.governorates,
      // nationalities: (state) => state.school.nationalities,
      // academic_years: (state) => state.school.academic_years,
      // documents: (state) => state.school.documents,
    }),
    ...mapGetters({
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDivisionsByClass: "school/getClassDivisions",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getGender: "globals/getGender",
      getIdentifyType: "globals/getIdentifyType",
      //   getAcademicYears: "school/getAcademicYears",
      getClasses: "school/getClasses",
      getDivisions: "school/getDivisions",
      getClassAssignHall: "school/getClassAssignHall",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      //   getBrenches: "school/getBrenches",
      // getNationalities: "school/getNationalities",
      // getDocuments: "school/getDocuments",
    }),
    addDocument() {
      this.student.documents.push({
        files: undefined,
        document: undefined,
        released_date: undefined,
        issuer: undefined,
      });
    },
    getPermissionTime(e, index = -1) {
      let date = this.toEnglishString(e);
      if (index >= 0) {
        this.student.documents[index].released_date = date;
      } else {
        this.student.birthdate = date;
      }
    },
    removeDocument() {
      this.student.documents.pop();
    },
    saveIdImage(event) {
      if (!this.selected) this.student.parent.identity_image = event;
    },
    newParent() {
      if (this.selected) {
        this.student.parent = {};
        this.selected = false;
      } else {
        this.selected = true;
      }
      this.fk_parent = undefined;
      this.student.fk_parent = 0;
    },
    getParents() {
      this.axios(`${this.base_url}parent/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          page_size: 10000,
        },
      }).then((response) => {
        this.parent_list = response.data?.results;
      });
    },
    showSelectedParent(id) {
      let parent = this.parent_list.find((item) => item.id == id);
      if (parent != undefined) this.setParent(parent);
      // this.student.parent=parent
    },
    setParent(parent) {
      for (const key in parent) {
        this.student.parent[key] = parent[key];
      }
    },
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "studentList" });
      } else {
        this.$refs.form.reset();
        this.image_reset++;
        this.student.registration_form = undefined;
        this.student.student_image = undefined;
        this.student.parent.identity_image = undefined;
        this.selected = true;
        this.student?.documents?.forEach((item) => {
          item.files_text = null;
        });
        if (this.is_update) this.$router.push({ name: "studentAdd" });
        this.is_update = false;
      }
    },
    saveRegistrationForm(event) {
      this.student.registration_form = event;
    },
    saveStudentImage(event) {
      this.student.student_image = event;
    },
    async checkStudentCountInHall() {
      if (this.student.class.fk_class && this.student.class.fk_division) {
        await this.axios
          .get(
            `${this.base_url}check-hall-count/?class=${this.student.class.fk_class}&&division=${this.student.class.fk_division}`
          )
          .then((responce) => {
            if (responce.data == false) this.dialog = true;
          });
      }
    },
    async checkClassDocument() {
      let docs = [];
      if (this.student.class.fk_class)
        await this.axios
          .get(
            `${this.base_url}check-class-document/?class=${this.student.class.fk_class}`
          )
          .then((responce) => {
            this.documents = responce.data;
            responce.data.forEach((element) => {
              docs.push(element.name);
            });
            if (docs.length) {
              this.required_docs = docs;
            }
          });
    },
    checkFiles() {
      let boolean = true;
      this.student.documents.forEach((element) => {
        if (element.files == undefined && element.files_text == undefined)
          boolean = false;
      });
      return boolean;
    },
    async saveStudent() {
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.student.parent.name &&
        this.student.name_ar &&
        this.student.class.fk_class
      ) {
        this.is_loading = true;
        // this.student.data_entry = this.user;
        if (this.selected) this.student.fk_parent = this.fk_parent;
        let result = await this.axios
          .post(this.base_url + "student/", this.student, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.getParents();
            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.process_alert = true;
        // this.resetForm()
        this.is_loading = false;
      } else {
        if (valid && !this.student.parent.name) {
          this.tab = 0;
        } else if (valid && !this.student.name_ar) {
          this.tab = 1;
        } else if (valid && !this.student.class.fk_class) {
          this.tab = 2;
        }
      }
    },
    async updateStudent() {
      const { valid } = await this.$refs.form.validate();
      if (this.checkFiles() && valid && this.id != undefined) {
        this.is_loading = true;
        // if (this.selected) this.student.fk_parent = this.fk_parent;
        // if (typeof this.student.identity_image == "string")
        //   this.student.identity_image = undefined;
        // if (typeof this.student.student_image == "string")
        //   this.student.student_image = undefined;
        // if (typeof this.student.registration_form == "string")
        //   this.student.registration_form = undefined;
        // this.student.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/student/${this.id}/`, this.student, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            // this.$router.push({ name: "studentList" });
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      } else {
        this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>

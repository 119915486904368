import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store/index";
import Vuex from "vuex";
import "@/assets/style.css";
import useValidate from "@vuelidate/core";
import Alert from "./components/Globals/Alert";
import AlertProtectedRecords from "@/components/Globals/AlertProtectedRecords.vue";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import FilterMenu from "@/components/Globals/FilterMenu.vue";
import SimpleReportTableTest from "@/components/Globals/SimpleReportTableTest";
import Pagination from "@/components/Globals/Pagination.vue";
import DiagonalHeader from "@/components/Globals/DiagonalHeader";
import Cliche from "./components/Globals/Cliche";
import ImageInput from "@/components/Globals/ImageInput.vue";
import ConfirmationDialog from "@/components/Globals/ConfirmationDialog";
// import { helpers, numeric } from "@vuelidate/validators";
import * as echart from "echarts";
import echarts from "vue-echarts";
import validation from "@/plugins/validation-plugin";
import moment from "moment-hijri";
import Datetime from "vue3-datetime-js";
import "vue3-datetime-js/assets/css/style.css";

import { reactive } from "vue";

let app = createApp(App);
const v$ = useValidate();
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

// export const api_url = "http://127.0.0.1:3300/";
// export const api_url = "http://127.0.0.1:6060/";
export const api_url = "https://schooltest.opensoftye.com/";
// export const api_url = "https://schoolmange.opensoftye.com/";
// export const api_url = "http:schoolb.mesrye.com/";

app.config.globalProperties.base_url = api_url;

function host() {
  return app.config.globalProperties.base_url;
}

app.config.globalProperties.$v = v$;
app.config.globalProperties.toHijriDate = function (date) {
  let dateVal = date.toLocaleDateString("en-US", { calendar: "islamic" });
  let dateFormat = dateVal.slice(0, 10).split("/").reverse();
  let day = dateFormat[1];
  let month = dateFormat[2];
  dateFormat[1] = month;
  dateFormat[2] = day;

  return dateFormat.join("-");
};
app.config.globalProperties.toEnglishString = function (string) {
  const arabic_numbers = {
    "\u0660": 0,
    "\u0661": 1,
    "\u0662": 2,
    "\u0663": 3,
    "\u0664": 4,
    "\u0665": 5,
    "\u0666": 6,
    "\u0667": 7,
    "\u0668": 8,
    "\u0669": 9,
    "/": "-",
  };

  var english_string = "";
  if (typeof string == "string") {
    for (let index = 0; index < string.length; index++) {
      if (Object.keys(arabic_numbers).includes(string[index])) {
        english_string += arabic_numbers[string[index]];
      } else {
        english_string += string[index];
      }
    }
    return english_string;
  } else {
    return string;
  }
};
app.config.globalProperties.toGregorianDate = function (date) {
  // console.log(date);
  const datetime = new Date(date);
  const gregorianString = datetime.toLocaleString("en-US", {
    calendar: "islamic",
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric",
  });
  return gregorianString;
};

// app.config.globalProperties.validators = {
//   uniqueValidator: (list) => {
//     return (value) => {
//       return !list.includes(value.trim());
//     };
//   },
//   lessDate: (date) => {
//     return (value) => {
//       return date < value;
//     };
//   },
//   moreDate: (date) => {
//     return (value) => {
//       return date > value;
//     };
//   },
//   timeValidator: (startTime) => {
//     return (value) => {
//       return x < value;
//     };
//   },
//   unique: (list, val) => {
//     return !list.includes(val) ? true : i18n.global.t("errors.unique");
//   },
//   englishOnly: helpers.regex(/^[a-zA-Z\-'\s]+$/),
//   arabicOnly: helpers.regex(
//     /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
//   ),
// };
const custom_axios = axios.create({
  timeout: 100000,
});
custom_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
custom_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Register the validation plugin
// function getLocalStorage(role = "role") {
//   console.log(role);
//   const roles = localStorage.getItem(role);
//   return roles;
// }
// app.config.globalProperties.$role = null
// app.config.globalProperties.$branch_ar = getLocalStorage("branch_ar");
// app.config.globalProperties.$branch_en = getLocalStorage("branch_en");
// app.config.globalProperties.$username = getLocalStorage("username");
app.config.globalProperties.$date = new Date().toDateString();
// app.config.globalProperties.CheckGetPermission = (prem) => {
//   return store.getters.checkpermission(prem);
// };

// app.config.globalProperties.$locale = {
//   name: "ar-sa",
//   weekdays: ["we", "wd", "xc", "fhg", "fg", "sa", "sfd"],
//   months: [
//     "y",
//     "f",
//     "m",
//     "u",
//     "u",
//     "sd",
//     "sds",
//     "gfg",
//     "hgh",
//     "hgj",
//     "ku",
//     "ui",
//   ],
//   pickers: {
//     select: "اختر",
//     cancel: "إلغاء",
//     now: "الآن",
//   },
//   placeholders: {
//     date: "ليب",
//     time: "يبل",
//     datetime: "يبل",
//   },
// };

app.use(validation);
app.use(store);
app.use(i18n);
app.use(vuetify);
app.use(router);
app.use(moment);
app.component("Datetime", Datetime);
app.use(VueAxios, custom_axios);
app.component("Alert", Alert);
app.component("AlertProtectedRecords", AlertProtectedRecords);
app.component("ReportHeader", ReportHeader);
app.component("FilterMenu", FilterMenu);
app.component("SimpleReportTableTest", SimpleReportTableTest);
app.component("Pagination", Pagination);
app.component("DiagonalHeader", DiagonalHeader);
app.component("Cliche", Cliche);
app.component("ImageInput", ImageInput);
app.component("ConfirmationDialog", ConfirmationDialog);
app.component("chart", echarts);
app.mount("#app");

export default host;

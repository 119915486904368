<template>
  <VNavigationDrawer
    v-model="collapse"
    :temporary="breakPointXS || breakPointSM"
    :permanent="breakPointMD"
    :rail="(breakPointMD || breakPointLG || breakPointXL) && expand"
    width="250"
    @input="printMessage"
    ref="drawer"
  >
    <div class="w-100 logo-box d-flex text-center">
      <v-img
        v-if="logo && (!expand || breakPointXS || breakPointSM)"
        :src="logo"
        style="flex: 2"
        class="me-2"
      ></v-img>
      <v-img
        v-else-if="!logo && (!expand || breakPointXS || breakPointSM)"
        src="../../assets/opensoft.png"
        style="flex: 2"
        class="me-2"
      ></v-img>
      <h3
        v-if="!expand || breakPointXS || breakPointSM"
        class="align-self-center"
        style="flex: 4"
      >
        School System
      </h3>
      <div style="flex: 1">
        <v-btn
          v-if="
            (breakPointMD || breakPointLG || breakPointXL) &&
            $i18n.locale == 'ar'
          "
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="
            expand ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'
          "
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else-if="
            (breakPointMD || breakPointLG || breakPointXL) &&
            $i18n.locale == 'en'
          "
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="
            expand ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
          "
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else
          @click="changeDrawer(), Expand()"
          density="compact"
          variant="text"
          icon="mdi-chevron-double-right"
          class="mt-1"
        ></v-btn>
      </div>
    </div>
    <div class="sidebar-item mt-14">
      <!-- <VDivider /> -->
      <VList
        v-model:opened="open"
        class="sidebar-item"
        active-color="indigo"
        open-strategy="single"
        v-model:selected="selectedList"
        @click="(expand = false), (show = false)"
        @click:select="tempOpenLists = open"
      >
        <!-- <VListItem
            value="Dashboard"
            @click="open = clean"
             min-height="40"
            :to="{ name: 'school_dashboard' }"
            :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
          >
            <template v-slot:title>
              <span class="v-select-title">{{ $t("school.side_bar.dashboard") }}</span>
            </template>

            <template v-slot:prepend>
              <v-tooltip  :text="$t('school.side_bar.dashboard')">
                <template v-slot:activator="{ props }">
                  <v-icon v-show="expand==true" v-bind="props"> mdi-home </v-icon>
                  <v-icon v-show="expand==false"> mdi-home </v-icon>
                </template>
              </v-tooltip>
            </template>
          </VListItem> -->
        <v-divider />
        <VListItem
          :value="$t('school.side_bar.dashboard')"
          :to="{ name: 'dashboard' }"
          min-height="40"
          :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
          @click="open = []"
        >
          <!-- v-if="CheckGetPermission('globaltable.change_settingsystem')" -->
          <template v-slot:title>
            <span class="v-select-title"
              >{{ $t("school.side_bar.dashboard") }}
            </span>
          </template>
          <template v-slot:prepend>
            <v-tooltip :text="$t('school.side_bar.dashboard')">
              <template v-slot:activator="{ props }">
                <v-icon v-show="expand == true" v-bind="props">
                  mdi-cog-outline
                </v-icon>
                <v-icon v-show="expand == false">mdi-view-dashboard </v-icon>
              </template>
            </v-tooltip>
          </template>
        </VListItem>
        <!-- <v-divider />
        <VListItem
          :value="$t('school.side_bar.fast_register')"
          :to="{ name: 'new-student' }"
          min-height="40"
          :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
          @click="open = []"
        >
          <template v-slot:title>
            <span class="v-select-title"
              >{{ $t("school.side_bar.fast_register") }}
            </span>
          </template>
          <template v-slot:prepend>
            <v-tooltip :text="$t('school.side_bar.fast_register')">
              <template v-slot:activator="{ props }">
                <v-icon v-show="expand == true" v-bind="props">
                  mdi-cog-outline
                </v-icon>
                <v-icon v-show="expand == false">mdi-cog-outline </v-icon>
              </template>
            </v-tooltip>
          </template>
        </VListItem> -->
        <v-divider />
        <VListGroup
          :value="$t('school.side_bar.system_initialize')"
          :fluid="false"
          v-if="
            CheckGetPermission('school.view_school') ||
            CheckGetPermission('school.view_branch') ||
            CheckGetPermission('school.view_building') ||
            CheckGetPermission('globaltable.view_yearofstudy') ||
            CheckGetPermission('school.view_semester') ||
            CheckGetPermission('school.add_month') ||
            CheckGetPermission('school.view_day') ||
            CheckGetPermission('school.view_class') ||
            CheckGetPermission('school.view_division') ||
            CheckGetPermission('school.view_classdocument') ||
            CheckGetPermission('school.view_subject') ||
            CheckGetPermission('school.add_hall') ||
            CheckGetPermission('school.view_hall') ||
            CheckGetPermission('school.view_classdivisionhall') ||
            CheckGetPermission('school.add_classdivisionhall') ||
            CheckGetPermission('school.view_estimate') ||
            CheckGetPermission('school.view_document') ||
            CheckGetPermission('globaltable.view_country') ||
            CheckGetPermission('globaltable.view_governorate') ||
            CheckGetPermission('school.view_schedule') ||
            CheckGetPermission('globaltable.view_directorate')
          "
        >
          <template v-slot:activator="{ props }">
            <VListItem
              v-bind="props"
              min-height="40"
              :title="$t('school.side_bar.system_initialize')"
            >
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.system_initialize")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.system_initialize')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props"
                      >mdi-cog-refresh-outline
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-cog-refresh-outline
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>

          <VListGroup
            v-if="
              CheckGetPermission('school.view_school') ||
              CheckGetPermission('school.view_branch') ||
              CheckGetPermission('school.view_building')
            "
            :value="$t('school.side_bar.school')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :title="$t('school.side_bar.school')"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-cog-outline</v-icon>
                </template>
              </VListItem>
            </template>

            <VListItem
              v-if="getUserRole < 2 && CheckGetPermission('school.view_school')"
              :value="$t('school.school_data.school_data')"
              :to="{ name: 'schools' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.school_data.school_data")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_branch')"
              :value="$t('school.side_bar.branch')"
              :to="{ name: 'branch' }"
              :title="$t('school.side_bar.branch')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <!-- <VListItem
              v-if="CheckGetPermission('globaltable.view_yearofstudy')"
              :value="$t('school.side_bar.study-years')"
              :to="{ name: 'academicYear' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.study-years")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem> -->
            <VListItem
              v-if="CheckGetPermission('school.view_building')"
              :value="$t('school.side_bar.building')"
              :to="{ name: 'buildings' }"
              :title="$t('school.side_bar.building')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('globaltable.view_yearofstudy') ||
              CheckGetPermission('school.view_semester') ||
              CheckGetPermission('school.add_month') ||
              CheckGetPermission('school.view_day')
            "
            :value="$t('school.side_bar.school_calendar')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :value="$t('school.side_bar.school_calendar')"
                :title="$t('school.side_bar.school_calendar')"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-calendar-sync</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                checkRole(['programmer', 'big_admin']) &&
                CheckGetPermission('globaltable.view_yearofstudy')
              "
              :value="$t('school.side_bar.year')"
              :to="{ name: 'academicYear' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.year")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.semesters')"
              :to="{ name: 'semesters' }"
              v-if="CheckGetPermission('school.view_semester')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.semesters")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_month')"
              :value="$t('school.month.months')"
              :title="$t('school.month.months')"
              :to="{ name: 'months' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.month.months")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_day')"
              :value="$t('school.side_bar.days_gaid')"
              :to="{ name: 'day' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.days_gaid")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            :value="$t('school.side_bar.classes_inti')"
            :fluid="false"
            v-if="
              CheckGetPermission('school.view_class') ||
              CheckGetPermission('school.view_division') ||
              CheckGetPermission('school.view_classdocument') ||
              CheckGetPermission('school.view_subject')
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :title="$t('school.side_bar.classes_inti')"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-google-classroom</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.classes')"
              :title="$t('school.side_bar.classes')"
              v-if="CheckGetPermission('school.view_class')"
              :to="{ name: 'classes' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.classes")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.divisions')"
              :to="{ name: 'divisions' }"
              v-if="CheckGetPermission('school.view_division')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.divisions")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="this.$t('school.side_bar.documents')"
              :to="{ name: 'class-document' }"
              v-if="CheckGetPermission('school.view_classdocument')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.documents")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :title="$t('school.side_bar.subjects')"
              :value="$t('school.side_bar.subjects')"
              v-if="CheckGetPermission('school.view_subject')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'subjects' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.subjects")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :title="$t('school.side_bar.library')"
              :value="$t('school.side_bar.library')"
              v-if="CheckGetPermission('school.view_library')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'library-view' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.library")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            :value="$t('school.side_bar.halls')"
            :fluid="false"
            v-if="
              CheckGetPermission('school.add_hall') ||
              CheckGetPermission('school.view_hall') ||
              CheckGetPermission('school.view_classdivisionhall') ||
              CheckGetPermission('school.add_classdivisionhall')
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :title="$t('school.side_bar.halls')"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-book-cog</v-icon>
                </template>
              </VListItem>
            </template>

            <VListItem
              v-if="CheckGetPermission('school.add_hall')"
              :value="$t('school.hall_data.add_hall')"
              :to="{ name: 'hallsadd' }"
              :title="$t('school.side_bar.add_halls')"
              :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_hall')"
              :value="$t('school.side_bar.view_halls')"
              :to="{ name: 'hallslist' }"
              :title="$t('school.side_bar.view_halls')"
              :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.add_classdivisionhall')"
              :value="$t('school.side_bar.add_assign_hall')"
              :to="{ name: 'assign-halladd' }"
              :title="$t('school.side_bar.add_assign_hall')"
              :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>

            <VListItem
              v-if="CheckGetPermission('school.view_classdivisionhall')"
              :value="$t('school.side_bar.assign_hall')"
              :to="{ name: 'assign-halllist' }"
              :title="$t('school.side_bar.assign_hall')"
              :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            :value="$t('school.side_bar.general_initialize')"
            :fluid="false"
            v-if="
              CheckGetPermission('school.view_estimate') ||
              CheckGetPermission('school.view_document') ||
              CheckGetPermission('globaltable.view_country') ||
              CheckGetPermission('globaltable.view_governorate') ||
              CheckGetPermission('school.view_schedule') ||
              CheckGetPermission('globaltable.view_directorate')
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :title="$t('school.side_bar.general_initialize')"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-book-cog</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.view_estimate')"
              :value="$t('school.side_bar.estimate')"
              :title="$t('school.side_bar.estimate')"
              :to="{ name: 'estimate' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.document_types')"
              v-if="CheckGetPermission('school.view_document')"
              :title="$t('school.side_bar.document_types')"
              :to="{ name: 'document' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('globaltable.view_country')"
              :value="$t('school.side_bar.country')"
              :to="{ name: 'countries' }"
              :title="$t('school.side_bar.country')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('globaltable.view_governorate')"
              :value="$t('school.side_bar.governorates')"
              :to="{ name: 'governorate' }"
              :title="$t('school.side_bar.governorates')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('globaltable.view_directorate')"
              :value="$t('school.side_bar.directorates')"
              :to="{ name: 'directorate' }"
              :title="$t('school.side_bar.directorates')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_schedule')"
              :value="$t('school.side_bar.schedule')"
              :to="{ name: 'schedule' }"
              :title="$t('school.side_bar.schedule')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <v-divider />
        <VListGroup
          :value="$t('school.side_bar.news')"
          :fluid="false"
          v-if="
            CheckGetPermission('school.view_notifications') ||
            CheckGetPermission('school.add_news') ||
            CheckGetPermission('school.change_news') ||
            CheckGetPermission('school.view_news')
          "
        >
          <template v-slot:activator="{ props }">
            <VListItem
              v-bind="props"
              min-height="40"
              :title="$t('school.side_bar.news')"
            >
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.news")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.news')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props"
                      >mdi-newspaper-variant-multiple
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-newspaper-variant-multiple
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListItem
            :value="$t('school.side_bar.notifications')"
            :title="$t('school.side_bar.notifications')"
            v-if="CheckGetPermission('school.view_notifications')"
            :to="{ name: 'Notifications' }"
            :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
          >
            <template v-slot:title>
              <span class="v-select-subtitle">{{
                $t("school.side_bar.notifications")
              }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="medium" class="ma-0">mdi-minus</v-icon>
            </template>
          </VListItem>
          <VListItem
            :value="$t('school.side_bar.add_news')"
            :title="$t('school.side_bar.add_news')"
            v-if="
              CheckGetPermission('school.add_news') ||
              CheckGetPermission('school.change_news')
            "
            :to="{ name: 'NewsAdd' }"
            :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
          >
            <template v-slot:title>
              <span class="v-select-subtitle">{{
                $t("school.side_bar.add_news")
              }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="medium">mdi-minus</v-icon>
            </template>
          </VListItem>
          <VListItem
            :value="$t('school.side_bar.news_list')"
            :title="$t('school.side_bar.news_list')"
            v-if="CheckGetPermission('school.view_news')"
            :to="{ name: 'NewsList' }"
            :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
          >
            <template v-slot:title>
              <span class="v-select-subtitle">{{
                $t("school.side_bar.news_list")
              }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="medium">mdi-minus</v-icon>
            </template>
          </VListItem>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.add_student') ||
            CheckGetPermission('school.view_student') ||
            CheckGetPermission('school.add_parent') ||
            CheckGetPermission('school.view_parent') ||
            CheckGetPermission('school.view_classstudentyear') ||
            CheckGetPermission('school.add_classstudentyear') ||
            CheckGetPermission('school.view_studentdocument')
          "
          :value="$t('school.side_bar.student_affairs')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.student_affairs")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.student_affairs')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-school
                    </v-icon>
                    <v-icon v-show="expand == false"> mdi-school </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListGroup
            v-if="
              CheckGetPermission('school.add_student') ||
              CheckGetPermission('school.view_student') ||
              CheckGetPermission('school.add_parent') ||
              CheckGetPermission('school.view_parent') ||
              CheckGetPermission('school.view_studentdocument')
            "
            :value="$t('school.side_bar.registration_addmission')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.registration_addmission')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-account-check</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.add_student')"
              :to="{ name: 'studentAdd' }"
              :value="$t('school.side_bar.new_registration')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.new_registration")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>

            <VListItem
              v-if="CheckGetPermission('school.view_student')"
              :value="$t('school.side_bar.show_registered_students')"
              :to="{ name: 'studentList' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.show_registered_students")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.add_parent')"
              :value="$t('school.side_bar.add_parent')"
              :to="{ name: 'parentadd' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.add_parent")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_parent')"
              :value="$t('school.side_bar.view_parent')"
              :to="{ name: 'parentlist' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.view_parent")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_studentdocument')"
              :value="$t('school.side_bar.archive')"
              :to="{ name: 'archive' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.archive")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListItem
            v-if="CheckGetPermission('school.change_classstudentyear')"
            :title="$t('school.side_bar.student_status')"
            :to="{ name: 'studentstatus' }"
            :value="$t('school.side_bar.student_status')"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-account</v-icon>
            </template>
          </VListItem>
          <!-- ترفيع الطلاب -->
          <!-- <VListItem
            v-if="CheckGetPermission('school.add_classstudentyear')"
            :value="$t('school.side_bar.student_elevate')"
            :title="$t('school.side_bar.student_elevate')"
            :to="{ name: 'student-elevate' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-school</v-icon>
            </template>
          </VListItem> -->
          <VListGroup
            :value="$t('school.side_bar.reports') + ' '"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListGroup
              :value="$t('school.side_bar.students_reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.students_reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.Students_by_Year_Class')"
                :to="{ name: 'report_StudentsByClassAndYear' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_by_Year_Class")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_by_Governorate')"
                :to="{ name: 'report_StudentByGovernorate' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_by_Governorate")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_by_Nationality')"
                :to="{ name: 'report_StudentByNationality' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_by_Nationality")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_by_Parent')"
                :to="{ name: 'student_with_parent' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_by_Parent")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_Age')"
                :to="{ name: 'students_ages' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_Age")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_Users_Name')"
                :to="{ name: 'report_StudentsAccounts' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_Users_Name")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_Images')"
                :to="{ name: 'report_StudentImages' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_Images")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.New_Enrolled_Students')"
                :to="{ name: 'new_rnrolles_student' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.New_Enrolled_Students")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Walk_out_Students')"
                :to="{ name: 'student_walkout' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Walk_out_Students")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('school.side_bar.parents_reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.parents_reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.parent_by_student')"
                :to="{ name: 'ParentwithStudent' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.parent_by_student")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Rest_Fees') + ' '"
                :to="{ name: 'StudentWithParentFee' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Rest_Fees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.personal_information')"
                :to="{ name: 'ParentsInfo' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.personal_information")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.parents_usernames')"
                :to="{ name: 'ParentsWithUser' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.parents_usernames")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <!-- <VListItem
                :value="$t('school.side_bar.parents_contacts')"
                :to="{ name: 'ParentsWithContact' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t('school.side_bar.parents_contacts')
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem> -->
            </VListGroup>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.add_lecture') ||
            CheckGetPermission('school.view_lecture') ||
            CheckGetPermission('school.homework_add') ||
            CheckGetPermission('school.view_homework') ||
            CheckGetPermission('school.homeworks_correction') ||
            CheckGetPermission('school.add_studentattendance')
          "
          :value="$t('school.side_bar.daily_follow_up')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.daily_follow_up")
                }}</span>
              </template>

              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.daily_follow_up')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-tablet-dashboard
                    </v-icon>
                    <v-icon v-show="expand == false">
                      mdi-tablet-dashboard
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListGroup
            v-if="
              CheckGetPermission('school.add_lecture') ||
              CheckGetPermission('school.view_lecture')
            "
            :value="$t('school.side_bar.lectures')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.lectures')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-book-multiple</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.add_lecture')"
              :to="{ name: 'lecture_attendance_add' }"
              :value="$t('school.side_bar.lecture_add')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.lecture_add")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_lecture')"
              :to="{ name: 'lecture_attendance_list' }"
              :value="$t('school.side_bar.lectures_list')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.lectures_list")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('school.homework_add') ||
              CheckGetPermission('school.view_homework') ||
              CheckGetPermission('school.homeworks_correction')
            "
            :value="$t('school.side_bar.preparing_homeworks')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.preparing_homeworks')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-file-edit-outline</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.homework_add')"
              v-if="CheckGetPermission('school.add_homework')"
              :to="{ name: 'homework_add' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.homework_add")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.show_homeworks')"
              v-if="CheckGetPermission('school.view_homework')"
              :to="{ name: 'homework_list' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.show_homeworks")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.homeworks_correction')"
              v-if="CheckGetPermission('school.view_studenthomework')"
              :to="{ name: 'homework_correction' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.homeworks_correction")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListItem
            :value="$t('school.side_bar.daily_attendance')"
            v-if="CheckGetPermission('school.add_studentattendance')"
            :to="{ name: 'student_attendance' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:title>
              <span class="v-select-subtitle">{{
                $t("school.side_bar.daily_attendance")
              }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="medium">mdi-clipboard-list-outline</v-icon>
            </template>
          </VListItem>
          <!-- <VListItem
                        value="lecture-preparing"
                        
                        :to="{ name: 'lecture_attendance_add' }"
                        :title="$t('lecture.preparing')"
                        v-if="CheckGetPermission('school.add_lectureattendance')||CheckGetPermission('school.change_lectureattendance')"
                        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
                        >
                        <template v-slot:prepend>
                            <v-icon size="small">mdi-book-cog</v-icon>
                        </template>
                    </VListItem> -->
          <!-- <VListItem 
                        value="lecture-view" 
                        
                        :to="{ name: 'lecture_attendance_list' }" 
                        :title="$t('lecture.view')" 
                        v-if="CheckGetPermission('school.view_lectureattendance')"
                        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'">
                        <template v-slot:prepend>
                        <v-icon size="small">mdi-note-multiple-outline</v-icon>
                        </template>
                    </VListItem> -->
          <VListGroup
            :value="' ' + ' ' + $t('school.side_bar.reports')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.Daily_Absence_Statement')"
              :to="{ name: 'days_student_attendance' }"
              :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
            >
              <template v-slot:title>
                <span class="v-select-mini-subtitle">{{
                  $t("school.side_bar.Daily_Absence_Statement")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.view_activity') ||
            CheckGetPermission('school.view_studentactivity')
          "
          :value="$t('school.side_bar.activities')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.activities")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.activities')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-account-multiple
                    </v-icon>
                    <v-icon v-show="expand == false">
                      mdi-account-multiple
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListItem
            v-if="CheckGetPermission('school.view_activity')"
            :value="$t('school.side_bar.add_new_activity')"
            :title="$t('school.side_bar.add_new_activity')"
            :to="{ name: 'activities' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-plus</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('school.view_studentactivity')"
            :value="$t('school.side_bar.student_activities')"
            :title="$t('school.side_bar.student_activities')"
            :to="{ name: 'studetn_activity' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-link</v-icon>
            </template>
          </VListItem>
          <VListGroup
            :value="$t('school.side_bar.reports') + ' ' + ' '"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.subscribers_activities')"
              :to="{ name: 'StudentsActivities' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.subscribers_activities")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.view_marks') ||
            CheckGetPermission('school.view_studentsubject') ||
            CheckGetPermission('school.view_examschedule') ||
            CheckGetPermission('school.add_examschedule') ||
            CheckGetPermission('school.view_examsperiod') ||
            CheckGetPermission('school.add_committees') ||
            CheckGetPermission('school.view_committees') ||
            CheckGetPermission('school.add_settingsstudentcommittees') ||
            CheckGetPermission('school.view_settingsstudentcommittees') ||
            CheckGetPermission('school.view_seatnumbers') ||
            CheckGetPermission('school.add_schoolweeklyschedule') ||
            CheckGetPermission('school.view_schoolweeklyschedule')
          "
          :value="$t('school.side_bar.control')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.control")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.control')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-content-save-cog-outline
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-content-save-cog-outline
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>

          <VListGroup
            v-if="
              CheckGetPermission('school.view_marks') ||
              CheckGetPermission('school.view_studentsubject')
            "
            :value="$t('school.side_bar.score_recording')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.score_recording')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-counter</v-icon>
                </template>
              </VListItem>
            </template>
            <VListGroup
              v-if="CheckGetPermission('school.view_marks')"
              :value="' ' + $t('school.side_bar.monthly_scores')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      " " + $t("school.side_bar.monthly_scores")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="CheckGetPermission('school.view_marks')"
                :value="$t('school.side_bar.monthly_scores')"
                :to="{ name: 'monthly-marks-add' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("school.side_bar.monthly_scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_marks')"
                :value="$t('school.side_bar.monthly_oral_scores')"
                :to="{ name: 'monthly-oral-marks' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("school.side_bar.monthly_oral_scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_marks')"
                :value="$t('school.side_bar.monthly_assignments_scores')"
                :to="{ name: 'monthly-assignments-marks' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("school.side_bar.monthly_assignments_scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_marks')"
                :value="$t('school.side_bar.monthly_attendance_scores')"
                :to="{ name: 'monthly-attendance-marks' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("school.side_bar.monthly_attendance_scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_marks')"
                :value="$t('school.side_bar.monthly_exam_scores')"
                :to="{ name: 'monthly-exam-marks' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("school.side_bar.monthly_exam_scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
            <VListItem
              :value="$t('school.side_bar.semester_grades')"
              v-if="CheckGetPermission('school.view_studentsubject')"
              :to="{ name: 'semester_marks' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.semester_grades")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('school.view_examschedule') ||
              CheckGetPermission('school.add_examschedule') ||
              CheckGetPermission('school.view_examsperiod') ||
              CheckGetPermission('school.add_committees') ||
              CheckGetPermission('school.view_committees') ||
              CheckGetPermission('school.add_settingsstudentcommittees') ||
              CheckGetPermission('school.view_settingsstudentcommittees') ||
              CheckGetPermission('school.view_seatnumbers')
            "
            :value="$t('school.side_bar.exams')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.exams')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-clipboard-clock</v-icon>
                </template>
              </VListItem>
            </template>
            <VListGroup
              v-if="
                CheckGetPermission('school.view_examschedule') ||
                CheckGetPermission('school.add_examschedule') ||
                CheckGetPermission('school.view_examsperiod')
              "
              :value="$t('school.side_bar.examination_table')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.examination_table")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.exam_schedule')"
                v-if="CheckGetPermission('school.view_examschedule')"
                :to="{ name: 'exam-schedule-view' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.exam_schedule")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.add_exam_schedule')"
                v-if="CheckGetPermission('school.add_examschedule')"
                :to="{ name: 'exam-schedule-add' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.add_exam_schedule")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_examsperiod')"
                :value="$t('school.side_bar.examination_periods')"
                :to="{ name: 'examPeriod' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.examination_periods")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              v-if="
                CheckGetPermission('school.add_committees') ||
                CheckGetPermission('school.view_committees') ||
                CheckGetPermission('school.add_settingsstudentcommittees') ||
                CheckGetPermission('school.view_settingsstudentcommittees') ||
                CheckGetPermission('school.view_seatnumbers')
              "
              :value="$t('school.side_bar.title_committee')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :value="$t('school.side_bar.title_committee')"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.title_committee")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="CheckGetPermission('school.add_committees')"
                :value="$t('school.side_bar.add_committee')"
                :to="{ name: 'committeesadd' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.add_committee")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_committees')"
                :value="$t('school.side_bar.committeesList')"
                :to="{ name: 'committeeslist' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.committeesList")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('school.add_settingsstudentcommittees')
                "
                :value="$t('school.side_bar.add_committee_plan')"
                :to="{ name: 'committeesPlanadd' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.add_committee_plan")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('school.view_settingsstudentcommittees')
                "
                :value="$t('school.side_bar.committee_plan_list')"
                :to="{ name: 'committeesPlanlist' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.committee_plan_list")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-minus</v-icon>
                </template>
              </VListItem>

              <VListItem
                v-if="CheckGetPermission('school.add_seatnumbers')"
                :value="$t('school.side_bar.distribute_students_on_committees')"
                :to="{ name: 'committeestudentsadd' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.distribute_students_on_committees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.add_teachercommittee')"
                :value="$t('school.side_bar.distribute_teacher_on_committees')"
                :to="{ name: 'teacher-committeeadd' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.distribute_teacher_on_committees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('school.view_teachercommittee')"
                :value="$t('school.side_bar.view_teacher_on_committees')"
                :to="{ name: 'teacher-committeelist' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.view_teacher_on_committees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              v-if="CheckGetPermission('school.view_seatnumbers')"
              :value="$t('school.side_bar.seat_number')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :title="$t('school.side_bar.seat_numbers')"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="CheckGetPermission('school.view_seatnumbers')"
                :value="$t('school.side_bar.add_seat_numbers')"
                :to="{ name: 'seat_numbers' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.add_seat_numbers")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- <VListItem
                v-if="CheckGetPermission('school.view_seatnumbers')"
                :value="$t('school.side_bar.secret_numbers')"
                :to="{ name: 'secret_numbers' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.secret_numbers")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem> -->
            </VListGroup>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('school.add_schoolweeklyschedule') ||
              CheckGetPermission('school.view_schoolweeklyschedule')
            "
            :value="$t('school.side_bar.weekly_schedule')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.weekly_schedule')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-timetable</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.add_schoolweeklyschedule')"
              :value="$t('school.side_bar.add_weekly_schedule')"
              :to="{ name: 'weekly_schedule_add' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.add_weekly_schedule")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-table-plus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_schoolweeklyschedule')"
              :value="$t('school.side_bar.view_weekly_schedule')"
              :to="{ name: 'weekly_schedule_update' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.view_weekly_schedule")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-table-multiple</v-icon>
              </template>
            </VListItem>
          </VListGroup>

          <VListGroup
            :value="' ' + $t('school.side_bar.reports')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListGroup
              :value="$t('school.side_bar.Monthly_Reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.Monthly_Reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <!-- value="without-monthly-scores" -->
              <VListItem
                :value="$t('school.side_bar.Monthly_Deficiencies_Marks')"
                :to="{ name: 'MonthlyDeficiencies' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Monthly_Deficiencies_Marks")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Monthly_Marks_statement')"
                :to="{ name: 'CollectorMarkStudentMonth' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Monthly_Marks_statement")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Specific_Month_Marks')"
                :to="{ name: 'month_exam_result_all' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Specific_Month_Marks")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Month_Certificate')"
                :to="{ name: 'month_exam_result' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subttitle">{{
                    $t("school.side_bar.Month_Certificate")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Month_Certificate_En')"
                :to="{ name: 'month_exam_result_en' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Month_Certificate_En")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.First_Class_Students')"
                :to="{ name: 'first_studetns_for_month' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.First_Class_Students")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Students_Estimation_by_Month')"
                :to="{ name: 'spicific_estimate_in_spicific_month' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Students_Estimation_by_Month")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('school.side_bar.Final_Reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  value="examination-table"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.Final_Reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.Specific_Years_Certificate')"
                :to="{ name: 'certificate_year' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Specific_Years_Certificate")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="
                  $t('school.side_bar.Specific_Years_English_Certificate')
                "
                :to="{ name: 'certificate_year_en' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Specific_Years_English_Certificate")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Years_Firsts')"
                :to="{ name: 'fristsbyyear' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">
                    {{ $t("school.side_bar.Years_Firsts") }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Year_Marks_Transcript')"
                :to="{ name: 'marksreportnyyear' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">
                    {{ $t("school.side_bar.Year_Marks_Transcript") }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('school.side_bar.Semester_Reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.Semester_Reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.Subjects_not_Assign')"
                :to="{ name: 'subject_not_assigned' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Subjects_not_Assign")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Marks_not_Assign')"
                :to="{ name: 'mark_not_assigned' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Marks_not_Assign")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Semester_Optional_Subjects')"
                :to="{ name: 'optional_subject' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">
                    {{ $t("school.side_bar.Semester_Optional_Subjects") }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Specific_Semester_Certificate')"
                :to="{ name: 'monthly_certificate' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle"
                    >{{ $t("school.side_bar.Specific_Semester_Certificate") }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Specific_Semester_Certificate_En')"
                :to="{ name: 'certificate_en' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Specific_Semester_Certificate_En")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Semester_Marks_Transcript')"
                :to="{ name: 'grade_semester_report' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Semester_Marks_Transcript")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Final_Exams_Marks')"
                :to="{ name: 'finalexammarksreport' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Final_Exams_Marks")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Total_Marks_Transcript')"
                :to="{ name: 'collectormarksreport' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Total_Marks_Transcript")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Subjects_Firsts')"
                :to="{ name: 'FirstStudentMonthforSubject' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Subjects_Firsts")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('school.side_bar.Exams_Reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.Exams_Reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.Student_Statement_Seat_Secret_No')"
                :to="{ name: 'student_with_seat_and_secret_number' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle"
                    >{{
                      $t("school.side_bar.Student_Statement_Seat_Secret_No")
                    }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Student_Without_Seat_secret_No')"
                :to="{ name: 'student_withot_seatnumber_or_secretnumber' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle"
                    >{{ $t("school.side_bar.Student_Without_Seat_secret_No") }}
                  </span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Committees_Distribution_Plan')"
                :to="{ name: 'commission_distr_plan' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Committees_Distribution_Plan")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Secret_No_Statement')"
                :to="{ name: 'student_with_secret_number' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Secret_No_Statement")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Student_Seat_No')"
                :to="{ name: 'steudent_with_seatnumber' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Student_Seat_No")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Exams_Schedule')"
                :to="{ name: 'exam_schedule_report' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Exams_Schedule")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <!-- <VListItem
                :value="$t('school.side_bar.Correction_by_secret_No')"
                :to="{ name: 'correct_with_secretNumber' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t('school.side_bar.Correction_by_secret_No')
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem> -->
              <VListItem
                :value="$t('school.side_bar.Committees_Statement')"
                :to="{ name: 'committee_with_student' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Committees_Statement")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
            <!-- value="Study_Schedule_Rrports" -->
            <VListGroup
              :value="$t('school.side_bar.Schedule_Reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <!-- value="examination_reports" -->
                  <template v-slot:title>
                    <span class="v-select-subtitle">{{
                      $t("school.side_bar.Schedule_Reports")
                    }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon>mdi-circle-medium</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('school.side_bar.Study_Schedule')"
                :to="{ name: 'weekly_schedule_report' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Study_Schedule")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.side_bar.Study_Schedule_by_Teacher')"
                :to="{ name: 'teacher_schedual' }"
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
              >
                <template v-slot:title>
                  <span class="v-select-mini-subtitle">{{
                    $t("school.side_bar.Study_Schedule_by_Teacher")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <span class="px-2"> - </span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.view_feetype') ||
            CheckGetPermission('school.view_fees') ||
            CheckGetPermission('school.view_discountcategories') ||
            CheckGetPermission('school.view_studentfee') ||
            CheckGetPermission('school.add_studentfee') ||
            CheckGetPermission('school.view_installmentspayments')
          "
          :value="$t('school.side_bar.financial_affairs')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.financial_affairs")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.financial_affairs')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-currency-usd
                    </v-icon>
                    <v-icon v-show="expand == false">mdi-currency-usd </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>

          <VListGroup
            v-if="
              CheckGetPermission('school.view_feetype') ||
              CheckGetPermission('school.view_fees') ||
              CheckGetPermission('school.view_discountcategories')
            "
            :value="$t('school.side_bar.fee')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.fee')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-cash-100</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.view_feetype')"
              :value="$t('school.side_bar.fee_types')"
              :to="{ name: 'feeType' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.fee_types")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_fees')"
              :value="$t('school.side_bar.fees_items')"
              :to="{ name: 'fees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.fees_items")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_discountcategories')"
              :value="$t('school.side_bar.discount_categories')"
              :to="{ name: 'discount' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.discount_categories")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_installmentsettings')"
              :value="$t('school.side_bar.installment_settings')"
              :to="{ name: 'installment-settings' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.installment_settings")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('school.view_studentfee') ||
              CheckGetPermission('school.add_studentfee') ||
              CheckGetPermission('school.view_installmentspayments')
            "
            :value="$t('school.side_bar.students_accounts')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.students_accounts')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-account-cash</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('school.add_studentfee')"
              :value="$t('school.side_bar.add_fees')"
              :to="{ name: 'studentFeeAdd' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.add_fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_studentfee')"
              :value="$t('school.side_bar.edit_fees')"
              :to="{ name: 'StudentFeeUpdate' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.edit_fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('school.view_installmentspayments')"
              :value="$t('school.side_bar.add_payment')"
              :to="{ name: 'PayInstallment' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.add_payment")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup :value="$t('school.side_bar.reports')" :fluid="false">
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.Rest_Fees')"
              :to="{ name: 'StudentWithParentFees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Rest_Fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Student_Account_Statement')"
              :to="{ name: 'StudentWithFeesWithoutRole' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Student_Account_Statement")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Student_Installments_Statement')"
              :to="{ name: 'student_fee_installment' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Student_Installments_Statement")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Students_without_Study_Fees')"
              :to="{ name: 'student_without_fees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Students_without_Study_Fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <!-- <VListItem
              :value="$t('school.side_bar.Students_without_Buses_Fees')"
              :to="{ name: 'student_without_fee_bus' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Students_without_Buses_Fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Students_Subscribed_Buses_Statement')"
              :to="{ name: 'Studentsubscribeinbus' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Students_Subscribed_Buses_Statement")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem> -->
            <VListItem
              :value="$t('school.side_bar.Student_Statement_Fee_Clause')"
              :to="{ name: 'student_with_fee' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Student_Statement_Fee_Clause")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Student_Statement_Clause')"
              :to="{ name: 'ReportBYFees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Student_Statement_Clause")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Detailed_Statement_study_Fees')"
              :to="{ name: 'detailed_statement_of_fees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Detailed_Statement_study_Fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Statement_Paid_Fees')"
              :to="{ name: 'student_payed_report' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Statement_Paid_Fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          v-if="
            CheckGetPermission('school.add_teacher') ||
            CheckGetPermission('school.view_teacher') ||
            CheckGetPermission('school.add_teachersubjectclass') ||
            CheckGetPermission('school.view_teachersubjectclass')
          "
          :value="$t('school.side_bar.faculty')"
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.faculty")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.faculty')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-account-group-outline
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-account-group-outline
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>

          <VListItem
            v-if="CheckGetPermission('school.add_teacher')"
            :value="$t('school.side_bar.add_teacher')"
            :title="$t('school.side_bar.add_teacher')"
            :to="{ name: 'teacheradd' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-circle-medium</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('school.view_teacher')"
            :value="$t('school.side_bar.teacher_information')"
            :title="$t('school.side_bar.teacher_information')"
            :to="{ name: 'teacherview' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-circle-medium</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('school.add_teachersubjectclass')"
            :value="$t('school.assign_teacher_data.add_assign_teacher')"
            :title="$t('school.assign_teacher_data.add_assign_teacher')"
            :to="{ name: 'assignteacheradd' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-circle-medium</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('school.view_teachersubjectclass')"
            :value="$t('school.assign_teacher_data.assign_teacher_plan')"
            :title="$t('school.assign_teacher_data.assign_teacher_plan')"
            :to="{ name: 'assignteacherview' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-circle-medium</v-icon>
            </template>
          </VListItem>
          <VListGroup
            :value="' ' + ' ' + $t('school.side_bar.reports') + ' '"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="medium">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.Teachers_Information')"
              :to="{ name: 'teachers_info' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Teachers_Information")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          :value="$t('school.side_bar.bus_system')"
          :fluid="false"
          v-if="
            CheckGetPermission('bus_system.add_driver') ||
            CheckGetPermission('bus_system.view_driver') ||
            CheckGetPermission('bus_system.add_bus') ||
            CheckGetPermission('bus_system.view_bus') ||
            CheckGetPermission('bus_system.view_studentbus') ||
            CheckGetPermission('bus_system.view_studentbusfee')
          "
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.bus_system")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.bus_system')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-bus-school
                    </v-icon>
                    <v-icon v-show="expand == false">mdi-bus-school </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListGroup
            :value="$t('school.driver_data.title')"
            :fluid="false"
            v-if="
              CheckGetPermission('bus_system.add_driver') ||
              CheckGetPermission('bus_system.view_driver')
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.driver_data.title')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-card-account-details-outline</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('bus_system.add_driver')"
              :value="$t('school.driver_data.add_driver')"
              :to="{ name: 'driveradd' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.driver_data.add_driver")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('bus_system.view_driver')"
              :value="$t('school.driver_data.driversList')"
              :to="{ name: 'driverlist' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.driver_data.driversList")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            :value="$t('school.bus_data.title')"
            :fluid="false"
            v-if="
              CheckGetPermission('bus_system.add_bus') ||
              CheckGetPermission('bus_system.view_bus') ||
              CheckGetPermission('bus_system.view_studentbus') ||
              CheckGetPermission('bus_system.view_studentbusfee')
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.bus_data.title')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-bus-multiple</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="CheckGetPermission('bus_system.add_bus')"
              :value="$t('school.bus_data.add_bus')"
              :to="{ name: 'busadd' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.bus_data.add_bus")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('bus_system.view_bus')"
              :value="$t('school.bus_data.bus_list')"
              :to="{ name: 'buslist' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.bus_data.bus_list")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('bus_system.view_studentbus')"
              :value="' ' + $t('school.side_bar.student_bus')"
              :to="{ name: 'studentBus' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.student_bus")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('bus_system.view_studentbusfee')"
              :value="' ' + $t('school.side_bar.bus_fees')"
              :to="{ name: 'bus-fees' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.bus_fees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>

          <VListGroup
            :value="' ' + ' ' + $t('school.side_bar.reports') + ' ' + ' '"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <VListItem
              :value="$t('school.side_bar.Students_Subscribed_Buses')"
              :to="{ name: 'StudentsBus' }"
              :class="direction === 'rtl' ? 'prtl-45' : 'pltr-45'"
            >
              <template v-slot:title>
                <span class="v-select-mini-subtitle">{{
                  $t("school.side_bar.Students_Subscribed_Buses")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
            <VListItem
              :value="$t('school.side_bar.Buses_Information')"
              :to="{ name: 'busInfo' }"
              :class="direction === 'rtl' ? 'prtl-45' : 'pltr-45'"
            >
              <template v-slot:title>
                <span class="v-select-mini-subtitle">{{
                  $t("school.side_bar.Buses_Information")
                }}</span>
              </template>
              <template v-slot:prepend>
                <span class="px-2"> - </span>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListGroup
          :value="$t('school.side_bar.users_adminstration')"
          class="title"
          v-if="
            CheckGetPermission('usermanage.view_user') ||
            CheckGetPermission('usermanage.add_user') ||
            CheckGetPermission('auth.view_group')
          "
          :fluid="false"
        >
          <template v-slot:activator="{ props }">
            <VListItem v-bind="props" min-height="40">
              <template v-slot:title>
                <span class="v-select-title">{{
                  $t("school.side_bar.users_adminstration")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('school.side_bar.users_adminstration')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-account-multiple
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-account-multiple
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
          </template>
          <VListItem
            v-if="CheckGetPermission('usermanage.add_user')"
            :value="$t('school.side_bar.add_user')"
            :to="{ name: 'school_add_user' }"
            :title="$t('school.side_bar.add_user')"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-minus</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('usermanage.view_user')"
            :value="$t('school.side_bar.users')"
            :to="{ name: 'school_user_list' }"
            :title="$t('school.side_bar.users')"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-minus</v-icon>
            </template>
          </VListItem>
          <VListItem
            v-if="CheckGetPermission('auth.view_group')"
            :value="$t('school.side_bar.groups')"
            :title="$t('school.side_bar.groups')"
            :to="{ name: 'school_add_group' }"
            :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
          >
            <template v-slot:prepend>
              <v-icon size="small">mdi-minus</v-icon>
            </template>
          </VListItem>
          <VListGroup
            :value="' ' + $t('school.side_bar.reports') + ' ' + ' '"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem
                v-bind="props"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                :title="$t('school.side_bar.reports')"
              >
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-pie</v-icon>
                </template>
              </VListItem>
            </template>
            <!-- :title="$t('school.side_bar.view_halls')" -->
            <VListItem
              v-if="CheckGetPermission('usermanage.view_user')"
              :value="$t('school.side_bar.System_Users_Statement')"
              :to="{ name: 'Users' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.System_Users_Statement")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <!-- :title="$t('school.side_bar.view_halls')" -->
            <VListItem
              v-if="CheckGetPermission('auth.view_group')"
              :value="$t('school.side_bar.Specific_Group_Members')"
              :to="{ name: 'GroupWithMembers' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Specific_Group_Members")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
            <!-- :title="$t('school.side_bar.view_halls')" -->
            <VListItem
              v-if="CheckGetPermission('auth.view_group')"
              :value="$t('school.side_bar.Specific_Group_Permissions')"
              :to="{ name: 'GroupWithPermissions' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("school.side_bar.Specific_Group_Permissions")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="medium">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
        </VListGroup>
        <VDivider />
        <VListItem
          :value="$t('school.side_bar.settings')"
          :to="{ name: 'settings' }"
          min-height="40"
          :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
          v-if="checkRole(['programmer', 'big_admin'])"
          @click="open = []"
        >
          <!-- v-if="CheckGetPermission('globaltable.change_settingsystem')" -->
          <template v-slot:title>
            <span class="v-select-title"
              >{{ $t("school.side_bar.settings") }}
            </span>
          </template>
          <template v-slot:prepend>
            <v-tooltip :text="$t('school.settings.system_settings')">
              <template v-slot:activator="{ props }">
                <v-icon v-show="expand == true" v-bind="props">
                  mdi-cog-outline
                </v-icon>
                <v-icon v-show="expand == false">mdi-cog-outline </v-icon>
              </template>
            </v-tooltip>
          </template>
        </VListItem>
        <VDivider />
        <!-- <VListItem
          value="Backup"
          :to="{ name: 'backup' }"
          min-height="40"
          :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
        >
          <template v-slot:title>
            <span class="v-select-title">{{ $t("backup.backup") }} </span>
          </template>
          <template v-slot:prepend>
            <v-tooltip :text="$t('backup.backup')">
              <template v-slot:activator="{ props }">
                <v-icon v-show="expand == true" v-bind="props"> mdi-cloud-upload </v-icon>
                <v-icon v-show="expand == false">mdi-cloud-upload </v-icon>
              </template>
            </v-tooltip>
          </template>
        </VListItem> -->

        <VDivider />
      </VList>
    </div>
  </VNavigationDrawer>
  <!-- end side bar  -->
</template>

<script>
import { mapGetters } from "vuex";
// document.addEventListener('click',dr)
export default {
  props: {
    // drawer: Boolean,
    direction: String,
    lang: String,
  },
  data() {
    return {
      expand: false,
      clickOpen: false,
      selected: [],
      tempOpenLists: [],
      selectedList: [],
      school_sys: localStorage.getItem("school_sys"),
      summer_center: localStorage.getItem("summer_center"),
      collapse: this.$store.state.drawer,
      open: [],
      temp: [],
      clean: [0],
      isOpen: false,
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      logo: undefined,
    };
  },

  computed: {
    ...mapGetters(["getdrawer"]),
    drawerVal() {
      return this.getdrawer;
    },

    CheckGetPermission() {
      return (prem) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    breakPointXS() {
      return this.$vuetify.display.xs;
    },
    breakPointSM() {
      return this.$vuetify.display.sm;
    },
    breakPointMD() {
      return this.$vuetify.display.md;
    },
    breakPointLG() {
      return this.$vuetify.display.lg;
    },
    breakPointXL() {
      return this.$vuetify.display.xl;
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  watch: {
    selectedList() {
      this.setBreadcrumb();
    },
    getdrawer() {
      this.collapse = this.getdrawer;
    },
    collapse() {
      this.$store.commit("updateDrawer", this.collapse);
    },
  },
  mounted() {
    var active_elements = document.querySelectorAll(".v-list-item--active");
    if (active_elements.length > 0) {
      const active_list_item = active_elements[
        active_elements.length - 1
      ].querySelector(".v-list-item__content .v-list-item-title").innerText;
      this.setBreadcrumb(active_list_item);
    } else {
      this.setBreadcrumb();
    }
  },
  async created() {
    window.addEventListener("beforeunload", this.BeforeReload);

    if (this.$vuetify.display.xs || this.$vuetify.display.sm) {
      this.$store.commit("updateDrawer", false);
      this.collapse = false;
    }
    // this.collapse=this.$store.state.drawer
    this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
    await this.axios
      .get(`${this.base_url}school-data/logo/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data && response.data.logo) this.logo = response.data.logo;
      });

    // this.drawerVal=this.getdrawer
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.BeforeReload);
  },
  methods: {
    BeforeReload() {
      this.selectedList.forEach((item) => {
        localStorage.setItem("selected_list", item);
      });
    },
    setBreadcrumb(active = null) {
      let items = [];
      items = Object.values(this.open).reverse();
      if (active != null) {
        items.push(active);
        this.$store.commit("updateBreadcrumbValue", items);
      } else if (this.selectedList.length > 0) {
        // if (this.selectedList[0] == "لوحة التحكم" || this.selectedList[0] == "الإعدادات") {
        //   items = []
        // }
        this.selectedList.forEach((item) => {
          items.push(item);
        });
        this.$store.commit("updateBreadcrumbValue", items);
      }
    },
    Expand() {
      this.show = !this.show;
      if (this.expand == false) {
        this.tempOpenLists = this.open;
        this.open = [];
      } else {
        if (this.open[0] != 0) {
          this.open = this.tempOpenLists;
        }
      }
      this.expand = !this.expand;
    },
    changeDrawer() {
      this.$store.commit("updateDrawer", !this.drawerVal);
    },
  },
};
</script>
<style scoped>
.v-list-item .v-list-item__prepend i {
  margin-inline-end: 12px !important;
}
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 40px !important;
}
.prtl-60 {
  padding-right: 55px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 40px !important;
}
.pltr-60 {
  padding-left: 50px !important;
}
</style>

<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon>  -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <!-- <v-fade-transition leave-absolute> -->
        <VForm ref="form">
          <VRow class="">
            <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
              <v-text-field
                v-model="date"
                density="compact"
                :placeholder="$t('school.exams.exam_date')"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                id="exam-date"
                readonly
              >
              </v-text-field>
              <Datetime
                type="date"
                element="exam-date"
                :color="'#0747a6'"
                @input="(e) => getPermissionTime(e)"
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="$t('school.homework.deadline')"
                :calendar="date_type == 'h' ? 'hijri' : ''"
              ></Datetime>
            </VCol>
            <VCol class="mt-2" cols="auto">
              <v-btn
                @click="getCommitteeTeacher((page = 1))"
                class="bg-indigo"
                size="small"
                :loading="loading"
              >
                <span class="text-white">
                  {{ $t("global.filter") }}
                </span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </v-btn>
            </VCol>
          </VRow>
        </VForm>
        <!-- </v-fade-transition> -->
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="justify-center">
    <!-- ######## Header Of Data Table  ####### -->

    <v-card class="pa-2">
      <!-- <div > -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2">
                mdi-eye-outline
              </v-icon>
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="text_search"
          @keyup.enter="getCommitteeTeacher((page = 1))"
          @update:model-value="
            !text_search ? getCommitteeTeacher((page = 1)) : ''
          "
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
        <div>
          <VBtn
            v-if="CheckGetPermission('school.add_teachercommittee')"
            class="bg-success"
            @click="newCommitteeTeacher"
          >
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- </div> -->
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        density="compact"
        v-model:sort-by="sortBy"
        @update:options="getCommitteeTeacher((page = 1))"
        class="elevation-1"
        :loading="loading"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_teachercommittee')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editCommitteeTeacher(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_teachercommittee')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(teacher_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
        </template>
      </v-data-table-server>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (teacher_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteCommitteeTeacher">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      teacher_committee_list: [],
      del_dialog: false,
      teacher_del_id: undefined,
      date: undefined,
      date_type: localStorage.getItem("date"),
      perPage: 10,
      page: 1,
      sortBy: [{ key: "date", order: "asc" }],
      totalItems: null,
      loading: false,
      headers: [
        {
          title: this.$t("school.committee.committee_name"),
          key: "committee_name",
        },
        { title: this.$t("school.exams.schedule_period"), key: "exam_period" },
        { title: this.$t("school.teacher.name"), key: "teacher_name" },
        { title: this.$t("school.exams.exam_date"), key: "date" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "committee_name",
        "exam_period",
        "teacher_name",
        "date",
        "note",
        "actions",
      ],
      text_search: undefined,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getCommitteeTeacher();
      // if (!this.divisions.length) await this.getDivisions({ all: true });
      // if (!this.classes.length) await this.getClasses();
      // await this.getBuilding({ all: true });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // divisions: (state) => state.school.divisions,
      // building: (state) => state.school.building,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      // this.teacher_committee_list.forEach((element) => {
      //   let hall = this.halls.find((item) => item.id == element.fk_hall);
      //   let classe = this.classes.find((item) => item.id == element.fk_class);
      //   let division = this.divisions.find(
      //     (item) => item.id == element.fk_division
      //   );
      //   try {
      //     let building = this.building.find(
      //       (item) => item.id == hall.fk_building
      //     );
      //     list.push({
      //       fk_hall: hall.name,
      //       fk_class: classe.name_ar,
      //       fk_building: building.name,
      //       fk_division: division.name,
      //       note: element.note,
      //       id: element.id,
      //     });
      //   } catch (e) {}
      // });
      return this.teacher_committee_list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      // getDivisions: "school/getDivisions",
      // getBuilding: "school/getBuilding",
      // getClasses: "school/getClasses",
      // getAllHalls: "school/getAllHalls",
      // changeCurrentPage: "school/changeCurrentPage",
      // getClassAssignHall: "school/getClassAssignHall",
    }),
    getPermissionTime(e, type = "start") {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.date = date;
    },
    newCommitteeTeacher() {
      this.$router.push("add");
    },
    editCommitteeTeacher(id) {
      this.$router.push(`${id}/edit/`);
    },
    async deleteCommitteeTeacher() {
      if (this.teacher_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}teacher-committee/${this.teacher_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getCommitteeTeacher();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.teacher_del_id = undefined;
        this.getCommitteeTeacher();
      }
    },
    async getCommitteeTeacher(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}teacher-committee/`, {
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
          date: this.date,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.teacher_committee_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getCommitteeTeacher();
    },
    page() {
      this.getCommitteeTeacher(this.page);
    },
  },
};
</script>

<template>
  <VCard class="justify-center">
    <VCardText
      class="card-fields"
      v-if="CheckGetPermission('school.view_library')"
    >
      <VForm ref="form">
        <VRow>
          <v-col cols="12" md="4" sm="12">
            <v-autocomplete
              color="indigo"
              v-model="fields_data.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              :label="$t('school.class.select')"
              @update:model-value="
                fields_data.fk_semester ? subjectsByClass() : ''
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-autocomplete
              color="indigo"
              v-model="fields_data.fk_semester"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-book-education"
              :items="fields_data.fk_class ? semesters : []"
              :label="$t('school.reports.select_semester')"
              @update:model-value="subjectsByClass()"
            />
          </v-col>
          <!-- <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="fields_data.fk_subject"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-book-open-variant"
                  clearable
                  :items="class_subjects"
                  :label="$t('school.subject.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col> -->
        </VRow>
      </VForm>
    </VCardText>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-progress-linear
      v-if="loading"
      color="indigo"
      indeterminate
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div v-show="show">
        <v-divider
          v-if="show"
          style="border-width: -1px; opacity: 0.25"
          class="my-1"
        />
        <v-card-text>
          <v-sheet
            class="pa-5 d-flex flex-wrap justify-center"
            style="gap: 2rem"
          >
            <v-card
              width="200"
              min-width="200"
              v-for="(subject, index) in class_subjects"
              :key="index"
            >
              <v-card-title>{{
                subject?.name_ar ?? subject?.subject_name
              }}</v-card-title>
              <v-card-text>
                <!-- @click="triggerImageInput(index)" -->
                <v-sheet
                  class="image-card overflow-hidden rounded-lg d-flex justify-center align-center"
                  rounded="0"
                  height="20rem"
                >
                  <!-- v-ripple -->
                  <v-img
                    v-if="subject.uploaded_img"
                    :src="
                      subject.uploaded_img
                        ? subject.uploaded_img
                        : require('@/assets/img/default_7.jpg')
                    "
                    class="image align-end text-white"
                    cover
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey-lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon
                    v-else
                    size="48"
                    color="grey"
                    class="d-flex justify-center align-center"
                    >mdi-camera</v-icon
                  >
                  <!-- <v-btn
                    v-if="subject.uploaded_img"
                    icon="mdi-close"
                    small
                    color="white"
                    class="close-btn d-none"
                    @click="subject.uploaded_img = null"
                  ></v-btn>
                  <v-file-input
                    ref="imageInput"
                    v-model="subject.book_image"
                    accept="image/*"
                    type="file"
                    class="d-none"
                    @change="(event) => handleImageUpload(event, index)"
                  >
                  </v-file-input> -->
                </v-sheet>
              </v-card-text>
              <!-- <v-card-title>اللغة العربية</v-card-title> -->
              <v-card-actions>
                <v-btn
                  :color="subject?.file_name ? '#47e5bc' : 'pink'"
                  size="large"
                  block
                  variant="flat"
                  :prepend-icon="
                    subject.file_name
                      ? 'mdi-file-check-outline'
                      : 'mdi-file-upload-outline'
                  "
                  @click="
                    CheckGetPermission('school.change_library') ||
                    CheckGetPermission('school.delete_library') ||
                    CheckGetPermission('school.add_library')
                      ? openDialog(index)
                      : subject?.file_name
                      ? openFile(subject?.file_name)
                      : ''
                  "
                >
                  <!-- @click="triggerFileInput(index)" -->
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-text
                        v-bind="subject.file_name ? props : ''"
                        class="truncate-text text-nowrap"
                      >
                        {{
                          subject.file_name
                            ? decodeURIComponent(
                                subject.file_name?.split("/").pop()
                              )
                            : $t("school.library.upload_file")
                        }}
                      </v-text>
                    </template>
                    <template v-slot:default>
                      <h4
                        class="text-wrap text-center tooltip-text"
                        :style="
                          $i18n.locale == 'en'
                            ? 'direction: ltr'
                            : 'direction:rtl'
                        "
                      >
                        {{
                          decodeURIComponent(
                            subject.file_name?.split("/").pop()
                          )
                        }}
                      </h4>
                    </template>
                  </v-tooltip>
                  <!-- <v-icon v-if="!subject.file_name || true" size="mini">{{
                    subject.file_name
                      ? "mdi-file-check-outline"
                      : "mdi-file-upload-outline"
                  }}</v-icon> -->
                </v-btn>
                <v-file-input
                  ref="fileInput"
                  v-model="subject.book_file"
                  class="d-none"
                  @change="(event) => handleFileUpload(event, index)"
                ></v-file-input>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-card-text>
      </div>
    </v-expand-transition>
    <!-- <VCardActions
      v-if="CheckGetPermission('school.add_library')"
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="CheckGetPermission('school.add_library')"
        class="bg-indigo"
        @click="saveSubjectsImages()"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="removeImageData()">
        {{ $t("global.clear") }}
        <VIcon :icon="'mdi-broom'" :color="'golden'" end></VIcon>
      </VBtn>
    </VCardActions> -->
  </VCard>
  <v-dialog
    v-model="show_dialog"
    width="600"
    class="text-right"
    :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card>
      <v-card-title class="text-center"
        ><h3>{{ $t("school.library.upload_file") }}</h3></v-card-title
      >
      <v-form ref="form">
        <v-card-text>
          <!-- <v-file-input
            density="compact"
            label="غلاف الكتاب"
            v-model="dialog_data.book_image"
            accept="image/*"
          >
          </v-file-input> -->
          <v-row>
            <v-col cols="12" md="12" class="pa-6">
              <ImageInput
                :reset="image_reset"
                :image="dialog_data?.book_image ?? dialog_data?.uploaded_img"
                :text="$t('school.library.book_cover')"
                @ImageSelected="handleImageUpload"
                :disabled="
                  !CheckGetPermission('school.change_library') &&
                  dialog_data?.id
                "
              ></ImageInput>
            </v-col>
            <v-col cols="12" md="12" class="px-4 pe-6">
              <v-file-input
                density="compact"
                :label="$t('school.library.book_file')"
                v-model="dialog_data.book_file"
                class="text-justify"
                @change="handleFileUpload"
                type="file"
                :messages="
                  dialog_data.file_name
                    ? $t('school.document.current_file') +
                      ':' +
                      decodeURIComponent(
                        dialog_data.file_name?.split('/').pop()
                      )
                    : ''
                "
                :disabled="
                  !CheckGetPermission('school.change_library') &&
                  dialog_data?.id
                "
                :rules="[dialog_data?.id ? true : $required, file_length]"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="dialog_data?.id && CheckGetPermission('school.change_library')"
          :class="'bg-success'"
          @click="saveSubjectsImages(dialog_data?.id)"
          :loading="is_loading"
          ><span class="text-white">
            {{ $t("global.edit") }}
          </span>
        </v-btn>
        <v-btn
          v-else-if="
            !dialog_data?.id && CheckGetPermission('school.add_library')
          "
          :class="'bg-indigo'"
          @click="saveSubjectsImages()"
          :loading="is_loading"
          ><span class="text-white">
            {{ $t("global.add") }}
          </span>
        </v-btn>
        <v-btn
          v-if="dialog_data?.id && CheckGetPermission('school.delete_library')"
          @click="del_dialog = true"
          class="bg-error"
          >{{ $t("global.delete") }}</v-btn
        >
        <v-btn @click="show_dialog = false">{{ $t("global.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="del_dialog = false">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteImage(dialog_data?.id)">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- ############### end Dialog Delete  ################### -->
</template>
<script>
import ImageInput from "@/components/Globals/ImageInput.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { ImageInput },
  data() {
    return {
      fields_data: {},
      is_loading: false,
      loading: false,
      show: false,
      class_subjects: [],
      show_dialog: false,
      dialog_data: {},
      image_reset: 1,
      del_dialog: false,
      file_length: (value) => {
        if (value[0]?.name?.length) {
          return value[0]?.name?.length > 100
            ? this.$t("global.error.validation.max_file_size")
            : true;
        }
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses({ all: true });
      if (!this.subjects.length) await this.getSubjects({});
      await this.getSemesters();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
      semesters: (state) => state.school.semesters,
    }),
    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getSemesters: "school/getSemesters",
    }),
    triggerFileInput(index) {
      this.$refs.fileInput[index].click();
    },
    triggerImageInput(index) {
      this.$refs.imageInput[index].click();
    },
    handleImageUpload(event) {
      // console.log(event);
      this.dialog_data.book_image = event;
      // const file = event.target.files[0];
      // if (file) {
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     this.class_subjects[index].uploaded_img = e.target.result;
      //   };
      //   reader.readAsDataURL(file);
      // }
    },
    handleFileUpload(event, index = 0) {
      // console.log(event.target?.files[0]);
      this.fields_data.book_file = event.target?.files[0];

      // const file = event.target.files[0];
      // if (file) {
      //   this.class_subjects[index].file_name = file.name;
      // }
    },
    openDialog(index) {
      this.dialog_data = this.class_subjects[index];
      // console.log(this.dialog_data);
      this.show_dialog = true;
    },
    openFile(file) {
      // console.log(file, "777");
      window.open(file, "_blank");
    },
    async subjectsByClass() {
      if (this.fields_data.fk_class) {
        this.removeImageData();
        this.loading = true;
        await this.axios
          .get(`${this.base_url}library/`, {
            params: {
              class_id: this.fields_data.fk_class,
              semester_id: this.fields_data.fk_semester,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data) {
              this.class_subjects = response.data?.results.map(
                ({ book_file, book_image, ...rest }) => ({
                  ...rest,
                  uploaded_img: book_image,
                  file_name: book_file,
                })
              );
              this.class_subjects = this.class_subjects.concat(
                this.getSubjectsByClass(this.fields_data.fk_class)
                  ?.filter(
                    (obj) =>
                      !this.class_subjects.some(
                        (item) => item.subject_name === obj.name_ar
                      )
                  )
                  .map(({ id, ...rest }) => ({
                    ...rest,
                    fk_class: this.fields_data.fk_class,
                    fk_subject: id,
                    fk_semester: this.fields_data.fk_semester,
                  }))
              );
              this.show = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            // if (error.response.data.branch) {
            //   this.$emit(
            //     "warningAlert",
            //     this.$t("global.error.failure.branch")
            //   );
            // } else if (error.response.data.semester) {
            //   this.$emit(
            //     "warningAlert",
            //     this.$t("global.error.failure.branch_semester")
            //   );
            // }
          });
      }
      this.loading = false;
      // setTimeout(() => {
      //     this.class_subjects = this.getSubjectsByClass(
      //       this.fields_data.fk_class
      //     );
      //     this.show = true;
      //   }, 500);
    },
    removeImageData() {
      this.show = false;
      this.class_subjects.forEach((item) => {
        item.uploaded_img = null;
        item.file_name = null;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.show = false;
      this.show_dialog = false;
      // this.is_update = false;
      this.is_loading = false;
      // this.updated_id = undefined;
    },
    // editMonth(data) {
    //   let months = this.months.find((item) => item.id == data.id);
    //   if (months != undefined) {
    //     for (const key in months) this.month[key] = months[key];
    //     this.show = true;
    //     this.is_update = true;
    //   }
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // },
    async saveSubjectsImages(method = null) {
      // console.log(this.class_subjects);
      // let new_class_subjects = this.class_subjects
      //   ?.filter((file) => file.book_file || file.book_file)
      //   .map(
      //     ({
      //       name_ar,
      //       name_en,
      //       uploaded_img,
      //       file_name,
      //       class_name,
      //       id,
      //       ...rest
      //     }) => ({
      //       ...rest,
      //       fk_subject: id,
      //       fk_class: this.fields_data.fk_class,
      //     })
      //   );
      // console.log(this.dialog_data);

      const { valid } = await this.$refs.form.validate();
      // console.log(valid);
      if (valid) {
        this.dialog_data.book_file = this.dialog_data.book_file
          ? this.dialog_data.book_file[0]
          : null;
        let exclude = ["semester_name", "class_name", "title", "note"];
        exclude.forEach((key) => {
          delete this.dialog_data[key];
        });
        this.is_loading = true;
        await this.axios[typeof method == "number" ? "put" : "post"](
          `${this.base_url}library/${method ? method + "/" : ""}`,
          this.dialog_data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              // class_id: this.fields_data?.fk_class,
            },
          }
        )
          .then(() => {
            this.$emit(
              "successAlert",
              this.$t(
                `global.success.${method ? "data_updated" : "data_saved"}`
              )
            );
            this.subjectsByClass();
            this.show_dialog = false;
            // this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.branch)
              this.$emit("errorAlert", this.$t("global.error.failure.branch"));
            else if (error.response.data.branch_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.branch_semester")
              );
            else if (error.response.data.month_not_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.month_not_semester")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false;
    },
    async deleteImage(image_id) {
      if (image_id) {
        await this.axios
          .delete(`${this.base_url}/library/${image_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.del_dialog = false;
            this.show_dialog = false;

            this.subjectsByClass();
          })
          .catch((error) => {
            if (error.response?.status == 418) {
              this.$emit("protectedAlert", error.response?.data?.data);
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.del_dialog = false;
    },
    // async updateMonth() {
    //   const { valid } = await this.$refs.form.validate();
    //   if (valid && this.month.is_current) {
    //     this.is_loading = true;
    //     await this.axios
    //       .put(`${this.base_url}/months/${this.month.id}/`, this.month, {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then(() => {
    //         this.$emit("successAlert", this.$t("global.success.data_updated"));
    //         this.getAllMonths();
    //         this.$emit("updateDate", {
    //           month_h: this.month.name_h,
    //           month_m: this.month.name_m,
    //         });
    //         this.resetForm();
    //       })
    //       .catch((error) => {
    //         if (error.response.data.branch)
    //           this.$emit("errorAlert", this.$t("global.error.failure.branch"));
    //         else if (error.response.data.branch_semester)
    //           this.$emit(
    //             "errorAlert",
    //             this.$t("global.error.failure.branch_semester")
    //           );
    //         else if (error.response.data.month_not_semester)
    //           this.$emit(
    //             "errorAlert",
    //             this.$t("global.error.failure.month_not_semester")
    //           );
    //         else
    //           this.$emit(
    //             "errorAlert",
    //             this.$t("global.error.failure.error_in_data")
    //           );
    //       });
    //   } else
    //     this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
    //   this.is_loading = false;
    // },
    // checkIfOnlyOneMain() {
    //   let month = this.months.find((item) => item.is_current == true);
    //   if (
    //     month &&
    //     this.month.id == month.id &&
    //     this.month.is_current == false
    //   ) {
    //     this.$emit(
    //       "errorAlert",
    //       this.$t("global.error.failure.cureent_month_error")
    //     );
    //   }
    // },
  },
};
</script>
<style scoped>
.image-card {
  position: relative;
  /* cursor: pointer; */
}
/* .image-card:hover {
  transform: scale(1.02);
} */
.image-card:hover .close-btn {
  display: block !important;
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  /* transition: transform 0.3s ease; */
}
.image:hover {
  /* transform: scale(1.05); */
}
.close-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(0, 0, 0, 0.6);
  /* backdrop-filter: blur(5px); */
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding-bottom: 3px;
}
.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}
.truncate-text {
  /* max-width: 160px; */
  max-width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: right;
}
.tooltip-text {
  max-width: 300px !important;
  min-width: fit-content;
  word-break: break-all;
}
</style>

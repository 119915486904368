<template>
  <v-card class="justify-center mt-2">
    <v-card-text class="card-view">
      <v-form
        v-if="CheckGetPermission('school.view_studentdocument')"
        ref="form"
        @submit="getStudentsDocuments"
      >
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              prepend-inner-icon="mdi-google-classroom"
              @update:modelValue="
                (filter_data.fk_division = null),
                  (filter_data.fk_subject = null)
              "
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.fk_division"
              density="compact"
              item-title="name"
              item-value="id"
              clearable
              :items="class_divisions"
              :no-data-text="$t('school.division.not_found')"
              prepend-inner-icon="mdi-format-list-bulleted"
              :label="$t('school.division.select')"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.academic_year"
              density="compact"
              :item-title="date_type == 'h' ? 'year' : 'year_m'"
              item-value="id"
              clearable
              :items="academic_years"
              prepend-inner-icon="mdi-calendar-account-outline"
              :label="$t('school.academic_year.name')"
            />
          </v-col>
          <v-col cols="4" md="2">
            <v-btn
              @click="checkClassDocument()"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-expand-transition>
        <div
          v-show="(main_data?.length && !loading) || search_student"
          class="card-table"
        >
          <v-card class="card-table">
            <v-table dense density="compact">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <v-text-field
                      color="indigo"
                      v-model="search_student"
                      @input="!search_student ? filter_students() : ''"
                      @keyup.enter="filter_students()"
                      density="compact"
                      variant="solo-filled"
                      hide-details
                      clearable
                      single-line
                      append-inner-icon="mdi-magnify"
                      :label="$t('school.student.name')"
                    />
                  </th>
                  <th v-for="document in documents" :key="document">
                    {{ document.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, index) in main_data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ student.student_name }}</td>
                  <template v-for="{ id } in documents" :key="id">
                    <td
                      v-for="document in student.docs?.filter(
                        (item, index, self) =>
                          index === self.findIndex((t) => t.fk_document == id)
                      )"
                      :key="document"
                    >
                      <div class="d-flex">
                        <v-checkbox
                          style="max-width: fit-content"
                          v-model="document.acquired"
                          color="indigo"
                          density="compact"
                          hide-details
                          :disabled="
                            !(
                              (CheckGetPermission(
                                'school.add_studentdocument'
                              ) &&
                                !document.acquired) ||
                              (CheckGetPermission(
                                'school.delete_studentdocument'
                              ) &&
                                document.acquired)
                            )
                          "
                          @change="
                            (document_data = document),
                              showConfirmDialog(document.acquired)
                          "
                        >
                        </v-checkbox>
                        <v-btn
                          v-if="
                            document.acquired &&
                            document?.Image &&
                            (CheckGetPermission(
                              'school.change_studentdocument'
                            ) ||
                              CheckGetPermission('school.view_studentdocument'))
                          "
                          icon
                          variant="text"
                          density="compact"
                          class="align-self-center"
                        >
                          <v-icon color="green">{{
                            document?.Image ? "mdi-dots-horizontal" : "mdi-plus"
                          }}</v-icon>
                          <v-menu activator="parent">
                            <v-list>
                              <!-- v-if="document?.Image" -->
                              <v-list-item
                                :href="
                                  document?.Image != null
                                    ? document?.Image.search(base_url) != -1
                                      ? document?.Image
                                      : base_url + document?.Image
                                    : false
                                "
                                target="_blank"
                                >{{ $t("school.archive.view_document") }}
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  CheckGetPermission(
                                    'school.change_studentdocument'
                                  )
                                "
                                @click="
                                  (is_update = !!document?.Image),
                                    (document_data = document),
                                    (document_dialog = true)
                                "
                              >
                                {{ $t("school.archive.edit_document") }}
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-btn>
                        <v-btn
                          v-else-if="
                            document.acquired &&
                            CheckGetPermission('school.change_studentdocument')
                          "
                          icon
                          variant="text"
                          density="compact"
                          class="align-self-center"
                          @click="
                            (is_update = false),
                              (document_data = document),
                              (document_dialog = true)
                          "
                        >
                          <v-icon color="indigo"> mdi-plus </v-icon>
                          <v-tooltip activator="parent" location="top">
                            {{ $t("school.archive.upload_document") }}
                          </v-tooltip>
                        </v-btn>
                      </div>
                    </td>
                  </template>
                </tr>
                <tr v-show="!main_data.length && search_student">
                  <td colspan="5" class="text-center">
                    {{ $t("global.data_table.student_not_found") }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
  <v-dialog
    v-model="document_dialog"
    width="500"
    :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }"
    persistent
  >
    <v-card>
      <v-card-title>
        <h3 class="d-inline" style="padding: 0 4px">
          {{ $t("school.student.student_documents") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="uploadDocument(true)" ref="dialog_form">
          <VRow style="row-gap: 0.352rem">
            <VCol cols="12" md="12">
              <VFileInput
                :label="$t('school.document.add')"
                density="compact"
                @change="
                  (event) => (document_data.Image = event.target.files[0])
                "
                :messages="
                  $t('school.document.current_file') +
                  ':' +
                  ((typeof document_data.Image == 'string'
                    ? decodeURI(document_data.Image).split('/').pop()
                    : document_data?.Image?.name) ?? '')
                "
                :rules="[is_update ? true : $required]"
              >
              </VFileInput>
            </VCol>
            <VCol cols="12" xs="12" sm="12" md="6">
              <VTextField
                v-model="document_data.issuer"
                prepend-inner-icon="mdi-map-marker-outline"
                :label="$t('school.document.place_issued')"
                :placeholder="$t('school.document.place_issued_placehloder')"
                density="compact"
                clearable
                :rules="[$required, $max_length(100), $min_length(2)]"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="12" md="6">
              <v-text-field
                v-model="document_data.released_date"
                density="compact"
                class="cursor-pointer"
                :placeholder="$t('school.document.release_date')"
                clearable
                hide-details="auto"
                prepend-inner-icon="mdi-calendar-clock-outline"
                :id="`released_date`"
                :rules="[$required]"
              >
              </v-text-field>
              <Datetime
                style="cursor-pointer"
                type="date"
                :element="`released_date`"
                density="compact"
                :color="'#0747a6'"
                @input="(e) => getReleaseDate(e)"
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="$t('school.student_attendance.permission_time')"
              >
              </Datetime>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          :class="`${is_update ? 'bg-success' : 'bg-indigo'}`"
          :loading="uploading"
          @click="uploadDocument(true, !!document_data?.id, true)"
          size="small"
        >
          <template v-slot:append>
            <v-icon color="white">
              {{
                is_update ? " mdi-content-save" : " mdi-cloud-upload-outline"
              }}
            </v-icon>
          </template>
          <span class="text-white">{{
            is_update ? $t("global.edit") : $t("global.upload")
          }}</span></v-btn
        >
        <!-- <v-btn v-if="show_permission" @click="document_dialog = false">
          <span class="text-indigo">
            {{ $t("global.exit") }}
          </span>
        </v-btn> -->
        <v-btn
          @click="
            is_update ? '' : deleteDocumentData(false),
              (document_dialog = false)
          "
        >
          <span>
            {{ $t("global.cancel") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialog
    v-model="confirmation_dialog"
    :message="$t('school.archive.do_you_want_to_upload_file')"
    :subject="
      documents.find((doc) => doc.id == document_data.fk_document)?.name
    "
    @confirmed="uploadDocument"
    @canceled="cancelDialog"
  >
    <template v-slot:buttons>
      <v-btn color="indigo" @click="cancelDialog(false)">{{
        $t("global.cancel")
      }}</v-btn>
      <v-btn
        size="small"
        variant="tonal"
        append-icon="mdi-check"
        color="indigo"
        @click="uploadDocument(true)"
        >{{ $t("school.archive.select_only") }}</v-btn
      >
      <v-btn
        size="small"
        color="indigo"
        variant="elevated"
        @click="(document_dialog = true), (confirmation_dialog = false)"
        append-icon="mdi-upload-outline"
        >{{ $t("school.archive.upload_document") }}</v-btn
      >
    </template>
  </ConfirmationDialog>
  <ConfirmationDialog
    type="delete"
    v-model="delete_dialog"
    :message="$t('school.archive.delete_file')"
    :subject="
      documents.find((doc) => doc.id == document_data.fk_document)?.name
    "
    :confirmed_text="$t('school.archive.delete')"
    confirmed_color="red"
    @confirmed="deleteDocument"
    @canceled="cancelDialog"
  >
  </ConfirmationDialog>
  <!-- :subject="document_data?.name" -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "StudentAttendance",
  props: {
    id: String,
  },
  //   components: { Datetime, ConfirmationDialog },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.academic_years.length) await this.getAcademicYears();
      if (!this.class_assign_hall.length) await this.getClassAssignHall(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      duplicate_data: [],
      main_data: [],
      documents: [],
      document_data: {},
      confirmation_dialog: false,
      delete_dialog: false,
      document_dialog: false,
      image_url: false,
      is_update: false,
      date_type: localStorage.getItem("date"),
      filter_data: {},
      loading: false,
      uploading: false,

      // after_break: false,
      // permission_dialog: false,
      // show_permission: false,
      // schedules_data: [],
      //   v$: useValidate(),
      // selected_student: null,
      // showAlert: false,
      // update: false,
      // txt_search: null,
      // search_student: "",
      // user: null,
      // exist: false,
      // valid: false,
      // date: null,
      // user: null,
      // from_date: undefined,
      // to_date: undefined,
      // permission_data: {
      // permission_type: null,
      // permission_name: null,
      // permission_reason: null,
      // permission_mobile: null,
      // permission_time: null,
      // },
      // students: [],
      // is_loading: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      academic_years: (state) => state.school.academic_years,
      class_assign_hall: (state) => state.school.class_assign_hall,
    }),

    // attendanceTwice() {
    //   return localStorage.getItem("attendance") == 2;
    // },
    ...mapGetters({
      // getDivisionName: "school/getDivisionName",
      // getSubjectName: "school/getSubjectName",
      getClassDivisions: "school/getClassDivisions",
    }),
    // division_teacher() {
    //   if (this.filter_data.fk_division && this.filter_data.fk_class) {
    //     var teacher_id = this.teacher_class.results
    //       .filter((item) => {
    //         if (
    //           item.fk_class == this.filter_data.fk_class &&
    //           item.fk_division == this.filter_data.fk_division
    //         ) {
    //           return item;
    //         }
    //       })
    //       .map((item) => item.fk_teacher);
    //     var teacher_unique = [...new Set(teacher_id)];
    //     var teacher_data = [];
    //     teacher_unique.forEach((el) => {
    //       teacher_data.push({ id: el, name: this.getTeacherName(el) });
    //     });
    //   }
    //   return teacher_data;
    // },
    class_divisions() {
      if (this.filter_data.fk_class) {
        return this.getClassDivisions(this.filter_data.fk_class);
      }
    },
    //   teacher_subjects() {
    //     if (this.filter_data.fk_teacher) {
    //       var subject_id = this.teacher_class.results
    //         .filter(
    //           (item) =>
    //             item.fk_teacher === this.filter_data.fk_teacher &&
    //             item.fk_class == this.filter_data.fk_class
    //         )
    //         .map((item) => item.fk_subject);
    //       var subjects_uniqe = [...new Set(subject_id)];
    //       var subject_data = [];
    //       subjects_uniqe.forEach((el) => {
    //         subject_data.push({ id: el, name: this.getSubjectName(el) });
    //       });
    //     }
    //     return subject_data;
    //   },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    // previewFile(event) {
    //   console.log(this.document_data.Image[0]);
    //   if (this.document_data.Image[0]) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.document_data.image_url = e.target.result;
    //       console.log(this.document_data.image_url,'llllllllllllllllllllllllllllllll');

    //     };
    //     reader.readAsDataURL(this.document_data.Image[0]);
    //   }
    // },
    ...mapActions({
      getDivisions: "school/getDivisions",
      // getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      // getSubjects: "school/getSubjects",
      // getAllTeachers: "school/getAllTeachers",
      // getTeacherClass: "school/getTeacherClass",
      getAcademicYears: "school/getAcademicYears",
      // getPermissionType: "globals/getPermissionType",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    filter_students() {
      if (this.search_student != "") {
        this.main_data = this.main_data.filter((student) => {
          return student.student_name.includes(this.search_student);
        });
      } else this.main_data = this.duplicate_data;
    },
    showConfirmDialog(checkBox) {
      if (checkBox) {
        this.document_data.acquired = !checkBox;
        this.confirmation_dialog = true;
      } else if (this.document_data?.Image) {
        this.document_data.acquired = !checkBox;
        this.delete_dialog = true;
        // this.deleteDocument();
      } else {
        this.deleteDocument();
      }
      this.is_update = false;
    },
    async uploadDocument(results, update = false, validate) {
      const { valid } = validate
        ? await this.$refs?.dialog_form?.validate()
        : { valid: true };
      if (results && valid) {
        this.uploading = true;
        let data = { ...this.document_data };
        data.acquired = true;
        await this.axios[update ? "put" : "post"](
          `${this.base_url}student-document/${
            update ? this.document_data?.id + "/" : ""
          }`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((response) => {
            if (this.document_data?.Image) {
              this.$emit(
                "successAlert",
                this.$t(
                  `global.success.${
                    this.is_update ? "data_updated" : "data_uploaded"
                  }`
                )
              );
            }
            this.document_data.acquired = true;
            this.document_data.id = response.data?.id;
            this.document_data.Image = response.data?.Image;
            this.confirmation_dialog = false;
            this.document_dialog = false;
            this.uploading = false;
          })
          .catch((error) => {
            this.document_data.acquired = false;
            this.uploading = false;
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
            // this.getLecturesAttendance();
            this.confirmation_dialog = false;
          });
        this.uploading = false;
      }
    },
    cancelDialog(results) {
      if (!results) {
        this.document_data.acquired = this.document_data.acquired;
        this.confirmation_dialog = results;
        this.delete_dialog = results;
      }
    },
    async deleteDocument() {
      await this.axios
        .delete(`${this.base_url}student-document/1/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            obj: this.document_data?.id,
            fk_document: this.document_data?.fk_document,
            fk_student: this.document_data?.fk_student,
          },
        })
        .then((response) => {
          this.deleteDocumentData();
          this.document_data.acquired = false;
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.document_data.acquired = true;
          // this.getLecturesAttendance();
          this.delete_dialog = false;
        });
    },
    // getCurrentDate() {
    //   const today = new Date();
    //   this.filter_data.attendance_date =
    //     this.date_type == "h"
    //       ? moment().locale("en").format("iYYYY-iMM-iDD")
    //       : this.toEnglishString(today.toLocaleDateString("ar-EG"));
    // },
    getReleaseDate(e) {
      let date = this.toEnglishString(e);
      this.document_data.released_date = date;
    },
    // async savePermissionData() {
    //   const { valid } = await this.$refs.permission.validate();
    //   if (this.selected_student != null && valid) {
    //     this.selected_student.permission_type =
    //       this.permission_data.permission_type;
    //     this.selected_student.permission_name =
    //       this.permission_data.permission_name;
    //     this.selected_student.permission_reason =
    //       this.permission_data.permission_reason;
    //     this.selected_student.permission_time =
    //       this.permission_data.permission_time;
    //     this.selected_student.permission_mobile =
    //       this.permission_data.permission_mobile;
    //     this.permission_dialog = false;
    //     if (!this.attendanceTwice) this.selected_student.is_attendance = false;
    //     if (
    //       this.selected_student.is_attendance &
    //       this.selected_student.is_permitted &
    //       this.selected_student.after_brake
    //     ) {
    //       this.selected_student.is_permitted = false;
    //       this.selected_student.permission_type = null;
    //       this.selected_student.permission_name = null;
    //       this.selected_student.permission_reason = null;
    //       this.selected_student.permission_time = null;
    //       this.selected_student.permission_mobile = null;
    //       this.$emit(
    //         "errorAlert",
    //         this.$t("global.error.failure.three_choices_cannot_be_selected")
    //       );
    //     }
    //   }
    // },
    // setPermissionData(index) {
    //   this.selected_student = this.students[index];
    //   if (this.selected_student.is_permitted) {
    //     let student = this.students[index];
    //     this.permission_data.permission_type = student.permission_type;
    //     this.permission_data.permission_name = student.permission_name;
    //     this.permission_data.permission_time = student.permission_time;
    //     this.permission_data.permission_reason = student.permission_reason;
    //     this.permission_data.permission_mobile = student.permission_mobile;
    //   }
    // },
    // getCheckThreeData(index) {
    //   let item = this.students[index];
    //   if (
    //     item.is_attendance & item.is_permitted & item.after_brake ||
    //     item.is_attendance & item.is_permitted & !this.attendanceTwice
    //   ) {
    //     this.students[index].is_permitted = false;
    //     this.students[index].permission_type = null;
    //     this.students[index].permission_name = null;
    //     this.students[index].permission_reason = null;
    //     this.students[index].permission_time = null;
    //     this.students[index].permission_mobile = null;
    //     if (this.attendanceTwice)
    //       this.$emit(
    //         "errorAlert",
    //         this.$t("global.error.failure.three_choices_cannot_be_selected")
    //       );
    //   }
    // },

    // selectStudentData(index) {
    //   try {
    //     this.selected_student = this.students[index];
    //   } catch (error) {
    //     return 0;
    //   }
    // },
    async getStudentsDocuments() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}student-document/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              //   fk_attendance: this.filter_data.fk_attendance,
              txt_search: this.txt_search,
              fk_class: this.filter_data.fk_class,
              fk_division: this.filter_data.fk_division,
              fk_year: this.filter_data.academic_year,
              //   date: this.filter_data.attendance_date,
            },
          })
          .then((response) => {
            if (response?.data?.length > 0) {
              const docMap = new Map(this.documents?.map((d) => [d.id, d]));
              // { fk_document: d.id, name: d.name, acquired: false },
              this.main_data = response.data;
              this.main_data.forEach((item) => {
                const existingDocIds = new Set(
                  item.docs.map((d) => d.fk_document)
                );
                item.docs = [
                  ...item.docs,
                  ...Array.from(docMap.values())
                    .filter((d) => !existingDocIds.has(d.id))
                    .map((doc) => ({
                      fk_document: doc.id,
                      name: doc.name,
                      fk_student: item.student_id,
                    })),
                ];
              });
              this.duplicate_data = structuredClone(this.main_data);
              this.exist = true;
            } else {
              this.main_data = response.data;
              this.$emit("infoAlert", this.$t("global.error.failure.no_data"));
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.students = [];
      }
      this.loading = false;
    },
    async checkClassDocument() {
      let docs = [];
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios
          .get(
            `${this.base_url}check-class-document/?class=${this.filter_data.fk_class}`
          )
          .then((response) => {
            this.getStudentsDocuments();
            this.documents = response.data;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // cleanPermissionData() {
    //   if (this.selected_student != null) {
    //     this.selected_student.is_permitted = false;
    //     this.selected_student.permitted_after_break = false;
    //     this.selected_student.permission_type = null;
    //     this.selected_student.permission_mobile = null;
    //     this.selected_student.permission_time = null;
    //     this.selected_student.permission_name = null;
    //     this.selected_student.permission_reason = null;
    //   }
    // },
    // async saveStudentAttendance() {
    //   const { valid } = await this.$refs.form.validate();
    //   if (valid) {
    //     this.is_loading = true;
    //     await this.axios
    //       .post(
    //         `${this.base_url}api/student-attendance/multiple-update/`,
    //         this.students,
    //         {
    //           headers: {
    //             Authorization: "Bearer " + localStorage.getItem("token"),
    //           },
    //           //   params: {
    //           //     date: this.filter_data?.attendance_date,
    //           //   },
    //         }
    //       )
    //       .then((response) => {
    //         this.$emit("successAlert", this.$t("global.success.insert"));
    //       })
    //       .catch((error) => {
    //         this.$emit("errorAlert", this.$t("global.error.failure.insert"));
    //       });
    //   }
    //   this.is_loading = false;
    // },
    // cleanStudentAttendanceForm() {
    //   this.$refs.form.reset();
    //   this.exist = false;
    // },
    deleteDocumentData(id = true) {
      if (id) {
        delete this.document_data?.id;
      }
      delete this.document_data?.Image;
      delete this.document_data?.issuer;
      delete this.document_data?.released_date;
    },
  },
  watch: {
    // permission_dialog() {
    //   if (this.permission_dialog == false) {
    //     this.show_permission = false;
    //     this.permission_data = {};
    //   }
    // },
  },
};
</script>
<style scoped>
.v-list-item--density-compact.v-list-item--one-line {
  min-height: 32px;
}
a:is(.v-list-item) {
  margin-left: 0;
}
</style>

<template>
  <v-layout class="mainbg">
    <v-container class="ma-auto">
      <v-locale-provider :locale="locale">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="6" md="8" sm="12">
            <v-card elevation="6" class="mt-10 rounded-xl">
              <!-- <v-window v-model="step">
              <v-window-item :value="1"> -->
              <v-row class="row-nm" style="height: 30rem">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  class="d-flex flex-column my-auto"
                >
                  <v-form
                    v-if="!is_reset"
                    transition="scroll-x-transition"
                    ref="form"
                    @keydown.enter="loginAction(email, password, year)"
                  >
                    <v-card-text :class="{ 'mt-6 mb-6': breakPointXS }">
                      <h2
                        class="text-center text-h4"
                        style="font-family: 'Almarai' !important"
                      >
                        {{ $t("global.login") }}
                      </h2>
                      <br />
                      <h4 class="text-center grey--text">
                        {{ $t("global.login_account_school") }}
                      </h4>
                      <v-row align="center" justify="center" class="mb-4 pb-2">
                        <v-col cols="12" sm="10">
                          <v-text-field
                            autofocus
                            v-model="email"
                            :label="$t('users.user.email')"
                            placeholder="abc@example.com"
                            dense
                            variant="outlined"
                            class="mt-10"
                            aria-autocomplete="off"
                            clearable
                            @input="error ? (error = null) : null"
                            :rules="[$required]"
                            density="compact"
                          >
                            <!-- :error-messages="
                            v$.username.$errors.map((e) => e.$message)
                          " -->
                            <template v-slot:prepend-inner>
                              <!-- color="indigo" -->
                              <v-icon>mdi-account</v-icon>
                            </template>
                          </v-text-field>
                          <v-text-field
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :disabled="timerCount > 0"
                            variant="outlined"
                            color="blue"
                            clearable
                            name="password"
                            id="password"
                            @input="error ? (error = null) : null"
                            autocomplete="new-password"
                            :placeholder="$t('users.user.placeholder_password')"
                            dense
                            :label="$t('users.user.password')"
                            :rules="[$required]"
                            density="compact"
                          >
                            <!-- :error-messages="
                            v$.password.$errors.map((e) => e.$message)
                          " -->
                            <template v-slot:prepend-inner>
                              <!-- color="indigo" -->
                              <v-icon @click="showPassword = !showPassword">{{
                                showPassword ? "mdi-eye" : "mdi-eye-off"
                              }}</v-icon>
                            </template>
                          </v-text-field>
                          <!-- <v-row dense>
                            <v-col cols="12" sm="7">
                              <v-checkbox
                                :label="$t('global.remember_me')"
                                class="mt-n1"
                                density="compact"
                                hide-details=""
                              ></v-checkbox>
                            </v-col>
                          </v-row> -->
                          <!-- <small
                            v-if="error"
                            class="text-error text-center my-2 d-block"
                          >
                            {{ error }}
                            <span v-if="timerCount > 0">{{ timer }}</span>
                          </small> -->
                          <v-btn
                            :loading="loading"
                            tile
                            :disabled="timerCount > 0"
                            color="blue"
                            dark
                            block
                            @click.prevent="
                              error ? (error = null) : null,
                                loginAction(email, password, year)
                            "
                          >
                            {{ $t("global.login") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <h4 v-if="error" class="text-center text-error">
                        {{ error }}
                      </h4>
                    </v-card-text>
                  </v-form>
                  <v-form v-if="is_reset" ref="form">
                    <v-card-text
                      class="d-flex flex-column gap-3"
                      :class="{ 'mt-6 mb-6': breakPointXS }"
                    >
                      <div>
                        <h2
                          class="text-center text-h4"
                          style="font-family: 'Almarai' !important"
                        >
                          {{ $t("users.user.change_password") }}
                        </h2>
                        <br />
                        <h4 class="text-center grey--text">
                          {{ $t("users.user.please_change_password") }}
                        </h4>
                      </div>
                      <v-row align="center" justify="center" class="mb-4 pb-2">
                        <v-col cols="12" sm="10" class="d-flex flex-column">
                          <v-text-field
                            color="primary"
                            v-model="new_password"
                            variant="outlined"
                            :type="showPassword2 ? 'text' : 'password'"
                            append-inner-icon="mdi-lock"
                            :prepend-inner-icon="
                              !showPassword2 ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:prepend-inner="
                              showPassword2 = !showPassword2
                            "
                            density="compact"
                            :rules="[
                              $required,
                              $min_length(8),
                              $max_length(20),
                              $contain_letter,
                              $contain_number,
                              notSameAs,
                            ]"
                            :label="$t('users.user.new_password')"
                          >
                            <!-- <template v-slot:prepend-inner>
                              <v-icon
                                @click="showPassword2 = !showPassword2"
                                :icon="
                                  !showPassword2 ? 'mdi-eye' : 'mdi-eye-off'
                                "
                              />
                            </template> -->
                          </v-text-field>
                          <v-text-field
                            ref
                            color="primary"
                            v-model="confirm_password"
                            variant="outlined"
                            :type="showPassword2 ? 'text' : 'password'"
                            append-inner-icon="mdi-lock"
                            :prepend-inner-icon="
                              !showPassword2 ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:prepend-inner="
                              showPassword2 = !showPassword2
                            "
                            density="compact"
                            :rules="[$required, validate_field ? sameAs : true]"
                            @input="validate_field = false"
                            :label="$t('users.user.confirm_password')"
                          >
                          </v-text-field>
                          <v-btn
                            :loading="saveProgress"
                            tile
                            color="blue"
                            dark
                            block
                            append-icon="mdi-form-textbox-password"
                            @click.prevent="changePassword()"
                          >
                            {{ $t("global.save") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-form>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="align-center justify-center bg-blue h-100 d-none d-sm-flex"
                  :style="
                    locale == 'en'
                      ? 'border-radius: 24px 0 0 24px;'
                      : 'border-radius: 0 24px 24px 0;'
                  "
                >
                  <div style="width: 100%; height: 100%">
                    <v-img
                      class="login-img h-100 w-100"
                      :src="require('@/assets/avatars/option_3.svg')"
                    ></v-img>
                  </div>
                  <!-- :style="backgroundStyle" -->
                  <!-- <v-card  width="100%" height="100%" :style="backgroundStyle">
                      <v-img  :src="require('@/assets/summer_centers_logo.png')">

                      </v-img>
                    </v-card> -->
                  <!-- <v-icon  size="250" color="blue">mdi-account-circle</v-icon> -->
                </v-col>
              </v-row>
              <!-- </v-window-item>
            </v-window> -->
            </v-card>
          </v-col>
        </v-row>
      </v-locale-provider>
    </v-container>
  </v-layout>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import axios from "axios";
import host from "./../../main";
// import store from './store'
// import axios from "axios";
export default {
  data() {
    return {
      // v$: useValidate(),
      years: [],
      year: null,
      email: undefined,
      token: undefined,
      loading: false,
      saveProgress: false,
      password: undefined,
      // backgroundImage: require("@/assets/1.png"),
      error: null,
      lang: localStorage.getItem("user-locale")
        ? localStorage.getItem("user-locale")
        : "ar",
      showPassword: false,
      showPassword2: false,
      new_password: null,
      confirm_password: null,
      validate_field: false,
      is_reset: false,
      sameAs: (value) => {
        if (this.new_password) {
          return (
            this.new_password == value ||
            this.$t("global.error.validation.same_password")
          );
        }
      },
    };
  },
  created() {
    // this.axios(`${this.base_url}api/all-years`)
    //   .then((response) => {
    //     this.years = response.data;
    //   })
    //   .catch((error) => {
    //     if (error.message.includes("Network Error")) {
    //       this.error = this.$t("alert.failure.connect");
    //     } else {
    //       if (error.response.status == 403) {
    //         this.error = this.$t("errors.user-logged-in");
    //       }
    //       if (error.response.status == 401) {
    //         this.error = this.$t("user.login_error");
    //       }
    //     }
    //   });
    const dir = localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
    this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    this.$store.dispatch("updatedirection", dir);
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
      year: {
        // required: helpers.withMessage(this.$t("errors.required"), required),
      },
    };
  },
  computed: {
    // backgroundStyle() {
    //   return {
    //     "background-image": `url(${this.backgroundImage})`,
    //     "background-size": "cover",
    //   };
    // },
    // ...mapGetters(["getdir"]),
    ...mapState({
      login_error: (state) => state.login_error,
    }),
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    resetForm() {
      // this.v$.$reset();
      this.$refs.form.reset();
      this.login_error = undefined;
    },
    // systemType(){
    //   this.axios(`${this.base_url}api/settings`).then((res) => {
    //     localStorage.setItem("school_sys", res.data.school);
    //     localStorage.setItem("summer_center", res.data.summer_center);
    //     this.settings = res.data;
    //   });
    // },

    async loginAction(email, password) {
      const { valid } = await this.$refs.form.validate();
      // this.v$.$validate();
      if (valid) {
        try {
          this.loading = true;
          await axios
            .post(host() + "/api/token/", { email, password })
            .then((res) => {
              if (
                res.data.status &&
                (res.data.status == 4000 || res.data.status == 3000)
              )
                this.error = this.$t("global.error.failure.login_error");
              else {
                if (this.password == "12345678") {
                  this.token = res.data.access;
                  // this.$emit(
                  //   "infoAlert",
                  //   this.$t("global.success.reset_password")
                  // );
                  this.is_reset = true;
                } else {
                  res.data.email = email;
                  this.$store.dispatch("setAuthenticated", res);
                }
              }

              this.loading = false;
              // this.systemType()
              //  if(localStorage.getItem('token') )
              //     this.$router.push({path:"/",replace:true})
            });
        } catch (error) {
          this.loading = false;
          if (error.message.includes("Network Error")) {
            this.error = this.$t("global.error.failure.connect");
          } else {
            if (error.response.status == 403) {
              this.error = this.$t("global.error.failure.user-logged-in");
            }
            if (error.response.status == 401) {
              this.error = this.$t("global.error.failure.login_error");
            }
          }

          return 0;
        }
      }
    },
    async changePassword() {
      // this.v$.$validate();
      this.validate_field = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveProgress = true;
        this.axios
          .post(
            `${this.base_url}api/user/change-password`,
            {
              old_password: "12345678",
              password: this.new_password,
              password2: this.confirm_password,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )

          .then((responce) => {
            this.$emit(
              "successAlert",
              this.$t("global.success.change_password")
            );
            this.new_password = null;
            this.confirm_password = null;
            this.email = null;
            this.password = null;
            this.saveProgress = false;
            this.is_reset = false;
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.change_password")
            );
            this.saveProgress = false;
          });
      }
    },
  },
};
</script>
<style scoped>
body {
  background-color: #90caf9;
}
i {
  color: #176ee1 !important;
}
.v-application__wrap {
  background-color: #999;
}
.login-img > img {
  object-position: 50% 50px !important;
}
/* .bg-blue {
  background-color: #547189 !important;
  color: #ffffff !important;
} */
</style>

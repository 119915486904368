import BusesView from "../views/BusSystem/buses/BusesView.vue";
import BusesList from "../views/BusSystem/buses/BusesList.vue";

import DriversView from "../views/BusSystem/drivers/DriversView.vue";
import DriversList from "../views/BusSystem/drivers/DriversList.vue";

import StudentBusView from "../views/BusSystem/StudentBus/StudentBusView.vue";

import BusFees from "@/views/School/student/BusFees";

import store from "@/store/index";
const hasPermission = (prem) => {
  store.dispatch("updatePermission", localStorage.getItem("userinfo"));
  return store.getters.checkpermission(prem);
};
const requirePermission = (perm) => {
  return (to, from, next) => {
    if (hasPermission(perm)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
export default [
  {
    path: "bus/add",
    name: "busadd",
    component: BusesView,
    beforeEnter: requirePermission("bus_system.add_bus"),
  },
  {
    path: "bus/:id/edit",
    name: "busedit",
    component: BusesView,
    props: true,
    beforeEnter: requirePermission("bus_system.change_bus"),
  },
  {
    path: "bus/list",
    name: "buslist",
    component: BusesList,
    beforeEnter: requirePermission("bus_system.view_bus"),
  },

  {
    path: "driver/add",
    name: "driveradd",
    component: DriversView,
    beforeEnter: requirePermission("bus_system.add_driver"),
  },
  {
    path: "driver/:id/edit",
    name: "driveredit",
    component: DriversView,
    props: true,
    beforeEnter: requirePermission("bus_system.change_driver"),
  },
  {
    path: "driver/list",
    name: "driverlist",
    component: DriversList,
    beforeEnter: requirePermission("bus_system.view_driver"),
  },

  {
    path: "studentBus",
    name: "studentBus",
    component: StudentBusView,
    beforeEnter: requirePermission("bus_system.view_studentbus"),
  },
  {
    path: "bus-fees",
    name: "bus-fees",
    component: BusFees,
    beforeEnter: requirePermission("bus_system.view_studentbusfee"),
  },
];

<template>
  <v-card class="justify-center">
    <v-card-text
      v-if="
        checkRole(['programmer', 'big_admin']) &&
        (CheckGetPermission('globaltable.add_yearofstudy') ||
          (update && CheckGetPermission('globaltable.change_yearofstudy')))
      "
      class="card-fields"
    >
      <v-row class="justify-center">
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="saveAcademicYear">
            <v-row>
              <v-col cols="6" md="3" sm="12">
                <v-text-field
                  color="indigo"
                  v-model="academicYear.year"
                  :placeholder="$t('school.academic_year.placeholder-year-h')"
                  clearable
                  counter="10"
                  density="compact"
                  prepend-inner-icon="mdi-calendar-range"
                  :label="$t('school.academic_year.name-h')"
                  :rules="[$required, $max_length(10)]"
                />
              </v-col>
              <v-col cols="6" md="3" sm="12">
                <v-text-field
                  color="indigo"
                  v-model="academicYear.year_m"
                  clearable
                  counter="10"
                  :placeholder="$t('school.academic_year.placeholder-year-m')"
                  density="compact"
                  prepend-inner-icon="mdi-calendar-range"
                  :label="$t('school.academic_year.name-m')"
                  :rules="[$required, $max_length(10)]"
                />
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-checkbox
                  :disabled="checkCurrentYear()"
                  v-model="academicYear.current_year"
                  :label="$t('school.academic_year.current-year')"
                  color="indigo"
                  density="compact"
                  @change="checkMustBeTrue"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea
                  color="indigo"
                  v-model="academicYear.note"
                  density="compact"
                  counter="250"
                  clearable
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      v-if="
        checkRole(['programmer', 'big_admin']) &&
        (CheckGetPermission('globaltable.add_yearofstudy') ||
          (update && CheckGetPermission('globaltable.change_yearofstudy')))
      "
      class="px-8"
    >
      <v-btn
        v-if="!update && CheckGetPermission('globaltable.add_yearofstudy')"
        @click="saveAcademicYear"
        class="bg-indigo"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="update && CheckGetPermission('globaltable.change_yearofstudy')"
        @click="updateAcademicYear(selectedAcademicYear)"
        class="bg-success"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <VBtn class="mx-3" size="small" @click="cleanacAdemicYearForm">
        {{ !update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <v-btn @click="cleanacAdemicYearForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn> -->
    </v-card-actions>
    <!-- ##########    data Table Server ########### -->
    <v-divider class="mt-5"></v-divider>

    <v-card
      class="pa-2"
      v-if="CheckGetPermission('globaltable.view_yearofstudy')"
    >
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        :items-per-page="perPage"
        :page="page"
        :headers="selectedHeaders"
        :items="academicYears"
        item-value="name_en"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key == 'current_year'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('globaltable.change_yearofstudy')
                  "
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="selectAcademicYear(item.raw)"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('globaltable.delete_yearofstudy')
                  "
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="(year_del_id = item.raw.id), (del_dialog = true)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="academicYears.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(academicYears.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ academicYears.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->

    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <v-row justify="center">
    <v-form ref="delete">
      <v-dialog
        color="indigo"
        v-model="dialog"
        transition="dialog-top-transition"
        width="auto"
        dir="rtl"
      >
        <v-card>
          <v-card-title>
            {{ $t("global.will-be-deleted") }}
          </v-card-title>
          <v-divider />
          <v-card-text style="height: 250">
            <v-list-item prepend-icon="mdi-circle-small">
              <span style="font-size: 0.85rem">{{
                $t("school.academic_year.teacher-subject-class")
              }}</span>
            </v-list-item>
            <v-list-item prepend-icon="mdi-circle-small">
              <span style="font-size: 0.85rem">{{
                $t("school.academic_year.class-student-year")
              }}</span>
            </v-list-item>
            <v-list-item prepend-icon="mdi-circle-small">
              <span style="font-size: 0.85rem">{{
                $t("school.academic_year.class-activity")
              }}</span>
            </v-list-item>
            <v-list-item prepend-icon="mdi-circle-small">
              <span style="font-size: 0.85rem">{{
                $t("school.academic_year.homework")
              }}</span>
            </v-list-item>
            <v-list-item prepend-icon="mdi-circle-small">
              <span style="font-size: 0.85rem">{{
                $t("school.academic_year.student-fee")
              }}</span>
            </v-list-item>
            <br />
            <v-checkbox
              class="error"
              v-model="ok"
              :rules="must_be_true"
              :label="$t('global.ok')"
              color="indigo"
              density="compact"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn class="bg-error" size="small" @click="submitDelete">{{
              $t("global.delete")
            }}</v-btn>
            <v-btn
              class="bg-ccc"
              size="small"
              @click="(dialog = false), (selectedAcademicYear = '')"
              >{{ $t("global.cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-row>
  <div></div>
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(del_dialog = false), (year_del_id = undefined)"
          >{{ $t("global.cancel") }}</v-btn
        >
        <v-btn color="red" @click="deleteacademicYear">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AcademicYear",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      // this.$store.dispatch(
      //   "updatePermission",
      //   localStorage.getItem("userinfo")
      // );
      await this.getAcademicYears(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      text_search: null,
      search: null,
      ok: false,
      dialog: false,
      del_dialog: false,
      year_del_id: undefined,
      addDialog: false,
      overlay: false,
      update: false,
      user: null,
      current_year: null,
      selectedHead: ["note", "year", "year_m", "current_year", "actions"],
      selectedAcademicYear: null,
      academicYear: {
        year: "",
        year_m: "",
        current_year: false,
        note: null,
        data_entry: null,
        updated_by: null,
      },

      must_be_true: [(value) => value == true || this.$t("errors.must-be-ok")],
      headers: [
        { title: this.$t("school.academic_year.name-h"), key: "year" },
        { title: this.$t("school.academic_year.name-m"), key: "year_m" },
        {
          title: this.$t("school.academic_year.current-year"),
          key: "current_year",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      page: 1,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      is_loading: false,
    };
  },
  computed: {
    years() {
      return this.academicYears.map((item) => item.year.split("/")[0]);
    },
    years_m() {
      return this.academicYears.map((item) => item.year_m.split("/")[0]);
    },

    ...mapState({
      academicYears: (state) => state.school.academic_years,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
      getAcademicYears: "school/getAcademicYears",
    }),
    async getCurrentYear() {
      await this.axios
        .get(`${this.base_url}api/current-year`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          localStorage.setItem("current_year", res.data.id);
        });
    },
    checkCurrentYear() {
      if (this.update) {
        let current_year = this.academicYears.find(
          (item) => item.current_year == true
        );
        if (this.selectedAcademicYear == current_year.id) return true;
      }
      return false;
    },
    checkMustBeTrue() {
      let year = this.academicYears.find((item) => item.current_year == true);
      if (year) {
        if (
          this.update &&
          this.selectedAcademicYear == year.id &&
          this.academicYear.current_year == false
        ) {
          this.$emit("errorAlert", this.$t("global.current-year"));
          this.showAlert = true;
          this.academicYear.current_year = true;
        }
      }
    },
    async submitDelete() {
      const { valid } = await this.$refs.delete.validate();
      if (valid) {
        this.deleteacademicYear(this.selectedAcademicYear);
      }
    },
    selectAcademicYear(item) {
      this.selectedAcademicYear = item.id;
      this.academicYear = Object.assign({}, item);
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveAcademicYear() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.academicYear.data_entry = this.user;
        await this.axios
          .post(`${this.base_url}api/academic-years/`, this.academicYear, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.insert"));
            this.cleanacAdemicYearForm();
            this.getAcademicYears(true);
            this.getCurrentYear();
          })
          .catch((error) => {
            if (error.message.includes("Network Error")) {
              this.$emit("errorAlert", this.$t("global.error.failure.connect"));
            } else if (error.message.includes("400")) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.already_exists")
              );
            } else {
              this.$emit("errorAlert", this.$t("global.error.failure.insert"));
            }
          });
      }
      this.is_loading = false;
    },
    cleanacAdemicYearForm() {
      this.update = false;
      this.academicYear.current_year = false;
      this.$refs.form.reset();
    },
    async updateAcademicYear(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.academicYear.updated_by = this.user;
        delete this.academicYear.id;
        await this.axios
          .put(`${this.base_url}api/academic-years/${id}/`, this.academicYear, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.getAcademicYears(true);
            if (this.academicYear.current_year) {
              // localStorage.setItem("semester_ar", undefined);
              // localStorage.setItem("semester_en", undefined);
              // localStorage.setItem("month_h", undefined);
              // localStorage.setItem("month_m", undefined);
              this.$emit("updateDate", {
                year_h: this.academicYear.year,
                year_m: this.academicYear.year_m,
              });
            }
            this.cleanacAdemicYearForm();
            this.getCurrentYear();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("global.error.failure.update"));
          });
      }
      this.is_loading = false;
    },
    deleteacademicYear() {
      if (this.year_del_id)
        this.axios
          .delete(`${this.base_url}api/academic-years/${this.year_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.delete"));
            // setTimeout(()=>{
            //     this.showAlert=false
            // },1500)
            this.del_dialog = false;
            this.year_del_id = undefined;
            this.getAcademicYears(true);
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit("errorAlert", this.$t("global.error.failure.delete"));
          });
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

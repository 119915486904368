import axios from "axios";
import host from "./../../main";
import { integer } from "@vuelidate/validators";

export default {
  namespaced: true,
  state: {
    countries: [],
    governorates: [],
    directorate: [],
    // host:'http://127.0.0.1:5000',
    school_data: undefined,
    building: [],
    hall: [],
    committee: [],
    current_page: 1,
    items_count: 10,
    // host:'http://127.0.0.1:7000/',
    // shehab:'http://127.0.0.1:5000/',
    // abod:'http://127.0.0.1:3000/',
    // abood:'http://127.0.0.1:7700/',
    branch: [],
    contact: [],
    subjects: [],
    drivers: [],
    exam_period: [],
    classes: [],
    classes_by_branch: [],
    semesters: [],
    divisions: [],
    teachers: [],
    schedules: [],
    nationalities: [],
    discounts: [],
    assigned_teachers: [],
    academic_years: [],
    current_year: undefined,
    fees: [],
    fee_types: [],
    exam_period: [],
    yearsOfStudy: [],
    classDocuments: [],
    documents: [],
    activities: [],
    lectures_attendance: [],
    days_choices: [],
    schedules: [],
    homeworks: [],
    teacher_class: [],
    months: [],
    class_assign_hall: [],
    branch_assign_hall: [],
    lectures: [],

    estimates: [],
    documents: [],
    buses: [],
    groups: [],
  },
  getters: {
    getGovernoratesByCountry: (state) => (country) => {
      let gov_list = [];
      state.governorates.forEach((element) => {
        if (element.fk_country == country) gov_list.push(element);
      });
      return gov_list;
    },
    getDirectorateByGovernorates: (state) => (gov) => {
      let dir_list = [];
      state.directorate.forEach((element) => {
        if (element.fk_governorate == gov) dir_list.push(element);
      });
      return dir_list;
    },
    getMonthBySemester: (state) => (semester) => {
      let month_list = [];
      state.months.forEach((element) => {
        if (element.fk_semester != undefined && element.fk_semester == semester)
          month_list.push(element);
      });
      return month_list;
    },
    getSubjectName: (state) => (id) => {
      let subject = state.subjects.find((item) => item.id == id);
      if (subject) {
        return subject.name_ar;
      }
    },

    getTeacherName: (state) => (id) => {
      let teacher = state.teachers.find((item) => item.id == id);
      if (teacher) {
        return teacher.name_ar;
      }
    },
    getClassName: (state) => (id) => {
      let class_data = state.classes.find((item) => item.id == id);
      if (class_data) {
        return class_data.name_ar;
      }
    },
    getSemesterName: (state) => (id) => {
      let semester = state.semesters.find((item) => item.id == id);
      if (semester) {
        return semester.name_ar;
      }
    },
    getMonthName: (state) => (id) => {
      let month = state.months.find((item) => item.id == id);
      if (month) {
        return month.name;
      }
    },
    getDivisionName: (state) => (id) => {
      let division = state.divisions.find((item) => item.id == id);
      if (division) {
        return division.name;
      }
    },
    getYearName: (state) => (id) => {
      let year = state.academic_years.find((item) => item.id == id);
      if (year) {
        return `${year.year} - ${year.year_m}`;
      }
    },
    getClassDivisions:
      (state, getters) =>
      (fk_class, branch = false) => {
        let assigned_hall = branch
          ? state.branch_assign_hall
          : state.class_assign_hall;
        if (assigned_hall) {
          let divisions = assigned_hall.filter(
            (item) => item.fk_class == fk_class
          );
          if (divisions) {
            let divisions_data = [];
            divisions.forEach((element) => {
              divisions_data.push({
                id: element.fk_division,
                name: getters.getDivisionName(element.fk_division),
              });
            });
            return divisions_data;
          }
        }
      },
    getSubjectsByClass:
      (state) =>
      (fk_class, data = null) => {
        if (fk_class) {
          let classes = data ? data : state.classes;
          let subjects_class = classes.find(
            (item) => item.id == fk_class
            )?.subjects;

          return subjects_class.map((item) =>
            state.subjects.find((sub) => sub.id === item)
          );
        }
      },
    getBuildingsHalls: (state, getters) => (fk_building) => {
      let list = state.hall.filter((item) => item.fk_building == fk_building);
      return list;
    },
    current_month: (state, actions) => {
      actions.getMonths;
      let current_month = state.months.find(
        (item) => item.current_month == true
      );
      if (current_month) {
        return current_month.id;
      }
      return state.months[0].id;
    },

    getCurrentSemesterMonths: (state) => {
      let current_semester = state.semesters?.find(
        (semester) => semester.name_ar == localStorage.getItem("semester_ar")
      )?.id;
      return state.months?.filter(
        (month) => month.fk_semester == current_semester
      );
    },
  },
  actions: {
    async getMonths({ state, commit }) {
      let months = (
        await axios.get(host() + "api/month/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getMonths", months);
    },
    async getClassAssignHall({ state, commit }, all = false) {
      let class_assign_hall = (
        await axios.get(
          `${host()}/assign-hall/${
            typeof all == "boolean" && all == true ? "" : "select/"
          }`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              page_size: 1000,
              fk_branch: typeof all == "number" ? all : null,
            },
          }
        )
      ).data;
      return commit(
        `${
          typeof all == "number" ? "getBranchAssignHall" : "getClassAssignHall"
        }`,
        typeof all == "boolean" && all == true
          ? class_assign_hall.results
          : class_assign_hall
      );
    },
    async getTeacherClass({ state, commit }) {
      let teacher_class = (
        await axios.get(host() + "/assign-teacher/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page_size: 1000,
          },
        })
      ).data.results;
      return commit("getTeacherClass", teacher_class);
    },
    async getLecturesAttendance({ state, commit }) {
      let lectures_attendance = (
        await axios.get(host() + "/lecture-attendance/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page_size: 1000,
          },
        })
      ).data;
      return commit("getLecturesAttendance", lectures_attendance);
    },
    // async getLectures({commit}){
    //   let lectures = (await.axios.get())
    // },
    async getHomeworks({ state, commit }, all = false) {
      let homeworks = (
        await axios.get(`${host()}/api/homework/${all ? "" : "select/"}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page_size: 1000,
          },
        })
      ).data;
      return commit("getHomeworks", homeworks);
    },
    async getExamPeriods({ state, commit }, all = false) {
      let exam_period = (
        await axios.get(`${host()}examsPeriod/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getExamPeriods", exam_period);
    },
    async getAcademicYears({ state, commit }, all = false) {
      let academic_years = (
        await axios.get(`${host()}api/academic-years/${all ? "" : "select/"}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getAcademicYears", academic_years);
    },
    async getDivisions({ state, commit }, { all = false, current_class = 0 }) {
      let divisions = (
        await axios.get(
          `${host()}api/divisions/${
            all ? "?class=" + current_class : "select/"
          }`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getDivisions", divisions);
    },
    async getSemesters({ state, commit }, all = false) {
      let semesters = (
        await axios.get(`${host()}api/semesters/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getSemesters", semesters);
    },
    // async getClasses({state,commit}){
    //     let classes=(await axios.get(host()+'api/classes/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
    //     return commit('getClasses',classes)
    // },
    async getSubjects({ state, commit }, { all = false, fk_class = 0 }) {
      let subjects = (
        await axios.get(
          `${host()}api/subjects/${all ? "?class=" + fk_class : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getSubjects", subjects);
    },
    // async getExamPeriods({state,commit}){
    //     let exam_period=(await axios.get(host()+'examsPeriod/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
    //     return commit('getExamPeriods',exam_period)
    // },
    async getAllMonths({ state, commit }) {
      let months = (
        await axios.get(host() + "months/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getAllMonths", months);
    },
    /////this is repated
    // async getSubjects({state,commit}){
    //     let subjects=(await axios.get(host()+'api/subjects/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
    // },

    // async getSubjects({state,commit}){
    //     let subjects=(await axios.get(host()+'api/subjects/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
    // },
    async getLecturesAttendance(
      { state, commit, fk_class, fk_subject },
      all = false
    ) {
      let lectures_attendance = (
        await axios.get(
          `${host()}api/lecture-attendance/${all ? "" : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              class_id: fk_class,
              subject: fk_subject,
            },
          }
        )
      ).data;
      return commit("getLecturesAttendance", lectures_attendance);
    },

    // async getSchedule({state,commit}){
    //     let schedule=(await axios.get(state.abod+'/schedule/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
    //     return commit('getSchedule',schedule)
    // },
    async getDaysChoices({ state, commit }) {
      let days = (
        await axios.get(host() + "days-choices/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getDaysChoices", days);
    },
    async getAllTeachers({ state, commit }, all = false) {
      let teachers = (
        await axios.get(`${host()}/api/teachers/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 1000,
          },
        })
      ).data;
      return commit("getAllTeachers", all ? teachers.results : teachers);
    },
    async getAllDrivers({ state, commit }, all = false) {
      let drivers = (
        await axios.get(`${host()}driver/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 1000,
          },
        })
      ).data;
      return commit("getAllDrivers", all ? drivers.results : drivers);
    },
    async getActivities({ state, commit }, all = false) {
      let activities = (
        await axios.get(
          `${host()}api/activities/${
            typeof all == "boolean" && all ? "" : "select/"
          }`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_branch: typeof all == "number" ? all : null,
            },
          }
        )
      ).data;
      return commit("getActivities", activities);
    },

    async getClassDocuments({ state, commit }, all = false) {
      let classDocuments = (
        await axios.get(
          `${host()}/api/class-documents/${all ? "" : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getClassDocuments", classDocuments);
    },
    async getDocuments({ state, commit }, all = false) {
      let documents = (
        await axios.get(`${host()}api/documents/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getDocuments", documents);
    },
    async getYearsOfStudy({ state, commit }, all = false) {
      let yearsOfStudy = (
        await axios.get(`${host()}api/academic-years/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getYearsOfStudy", yearsOfStudy);
    },

    // async getSemesters({state,commit}){
    //     let semesters=(await axios.get(host()+'api/semesters/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }})).data
    //     return commit('getSemesters',semesters)
    // },
    async getClasses({ state, commit }, all = false) {
      let classes = (
        await axios.get(`${host()}api/classes/${all ? "" : "select/"}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getClasses", classes);
    },
    async getBranchClasses({ state, commit }, fk_branch = null) {
      let classes_by_branch = (
        await axios.get(`${host()}api/classes/report-select/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_branch: fk_branch,
          },
        })
      ).data;
      return commit("getClassesByBranch", classes_by_branch);
    },
    // async getSubjects({ state, commit }) {
    //   let subjects = (
    //     await axios.get(host() + "api/subjects/", {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //   ).data;
    //   return commit("getSubjects", subjects);
    // },
    async getCountries({ state, commit }, all = false) {
      let countries = (
        await axios.get(`${host()}api/country/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getCountries", countries);
    },
    async getGovernorates({ state, commit }, { all = false, country = 0 }) {
      let governorates = (
        await axios.get(
          `${host()}api/governorate/${all ? "?country=" + country : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getGovernorates", governorates);
    },
    async getDirectorate({ state, commit }, { all = false, governorate = 0 }) {
      let directorate = (
        await axios.get(
          `${host()}api/directorate/${all ? "?gov=" + governorate : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getDirectorate", directorate);
    },
    async getSchoolData({ state, commit }, all = false) {
      let school_data = (
        await axios.get(`${host()}school-data/${all ? "" : "select/"}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getSchoolData", school_data);
    },
    async getBrenches({ state, commit }, { all = false, is_main = 0 }) {
      let branch = (
        await axios.get(
          `${host()}/branches/${all ? "?is_main=" + is_main : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getBrenches", branch);
    },
    async getContact({ state, commit }, branch = 0) {
      let contact = (
        await axios.get(`${host()}/contacts/?branch=${branch}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getContact", contact);
    },
    async getBuilding({ state, commit }, { all = false, branch = 0 }) {
      let building = (
        await axios.get(
          `${host()}/building/${all ? "?branch=" + branch : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
      ).data;
      return commit("getBuilding", building);
    },
    async getAllHalls({ state, commit }, building = 0) {
      let hall = (
        await axios.get(host() + "/hall/?building=" + building, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 1000,
          },
        })
      ).data.results;
      return commit("getAllHalls", hall);
    },
    async getAllCommittees(
      { state, commit },
      { all = false, hall = 0, fk_branch = null }
    ) {
      let committee = (
        await axios.get(
          `${host()}/committees/${all ? "?hall=" + hall : "select/"}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              page: 1,
              page_size: 1000,
              fk_branch: fk_branch,
            },
          }
        )
      ).data;
      return commit("getAllCommittees", all ? committee.results : committee);
    },
    async getCommittee({ state, commit }, hall = 0) {
      let committee = (
        await axios.get(host() + "/committees/?hall=" + hall, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getCommittee", committee);
    },
    changeCurrentPage({ commit }, payload) {
      return commit("changeCurrentPage", payload);
    },
    async getSchedule({ state, commit }, all = false) {
      let schedule = (
        await axios.get(`${host()}/schedule/${all ? "" : "select/"}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getSchedule", schedule);
    },
    // async getAllTeachers({state,commit}){
    //     let teachers=(await axios.get(host()+'api/teachers/',
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')},params: {
    //         page:1,
    //         page_size:1000
    //     }})).data.results
    //     return commit('getAllTeachers',teachers)
    // },
    async getNationalities({ state, commit }) {
      let nationalities = (
        await axios.get(`${host()}/country/nationalities/select/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getNationalities", nationalities);
    },
    async getDiscount({ state, commit }) {
      let discounts = (
        await axios.get(host() + "/discount/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getDiscount", discounts);
    },
    async getAssignedTeachers({ commit }) {
      let assigned_teachers = (
        await axios.get(host() + "assign-teacher/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getAssignedTeachers", assigned_teachers);
    },
    async getFees({ commit }) {
      let fees = (
        await axios.get(host() + "fees/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getFees", fees);
    },
    async getFeeTypes({ commit }, fk_branch = null) {
      let fee_types = (
        await axios.get(host() + "fee-types/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_branch: fk_branch,
          },
        })
      ).data;
      return commit("getFeeTypes", fee_types);
    },

    async getAllEstimations({ commit }) {
      let estimates = (
        await axios.get(host() + "estimate/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getAllEstimations", estimates);
    },

    async getAllDocuments({ commit }) {
      let documents = (
        await axios.get(host() + "document/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getAllDocuments", documents);
    },
    async getAllBuses({ state, commit }) {
      let buses = (
        await axios.get(host() + "/bus/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 1000,
          },
        })
      ).data.results;
      return commit("getAllBuses", buses);
    },
    async getAllGroups({ state, commit }, fk_branch = null) {
      let groups = (
        await axios.get(host() + "groups/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_branch: fk_branch,
          },
        })
      ).data;
      return commit("getAllGroups", groups);
    },
  },
  mutations: {
    getClassAssignHall(state, class_assign_hall) {
      state.class_assign_hall = class_assign_hall;
    },
    getBranchAssignHall(state, branch_assign_hall) {
      state.branch_assign_hall = branch_assign_hall;
    },
    getMonths(state, months) {
      state.months = months;
    },
    getLecturesAttendance(state, lectures_attendance) {
      state.lectures_attendance = lectures_attendance;
    },
    getTeacherClass(state, teacher_class) {
      state.teacher_class = teacher_class;
    },
    getHomeworks(state, homeworks) {
      state.homeworks = homeworks;
    },
    getAcademicYears(state, academic_years) {
      state.academic_years = academic_years;
      let year = academic_years.find((item) => item.curentyear == true);
      if (year != undefined) state.current_year = year.id;
    },
    getSchedule(state, schedule) {
      state.schedules = schedule;
    },
    getDaysChoices(state, days) {
      state.days_choices = days;
    },
    getAllTeachers(state, teachers) {
      state.teachers = teachers;
    },
    getAllDrivers(state, drivers) {
      state.drivers = drivers;
    },
    getActivities(state, activities) {
      state.activities = activities;
    },

    getClassDocuments(state, classDocuments) {
      state.classDocuments = classDocuments;
    },
    getDocuments(state, documents) {
      state.documents = documents;
    },
    getYearsOfStudy(state, yearsOfStudy) {
      state.yearsOfStudy = yearsOfStudy;
    },
    getDivisions(state, divisions) {
      state.divisions = divisions;
    },
    getSemesters(state, semesters) {
      state.semesters = semesters;
    },
    getClasses(state, classes) {
      state.classes = classes;
    },
    getClassesByBranch(state, classes_by_branch) {
      state.classes_by_branch = classes_by_branch;
    },
    getSubjects(state, subjects) {
      state.subjects = subjects;
    },
    getCountries(state, countries) {
      state.countries = countries;
    },
    getGovernorates(state, governorates) {
      state.governorates = governorates;
    },
    getDirectorate(state, directorate) {
      state.directorate = directorate;
    },
    getSchoolData(state, school_data) {
      state.school_data = school_data;
    },
    getBrenches(state, branch) {
      state.branch = branch;
    },
    getContact(state, contact) {
      state.contact = contact;
    },
    getBuilding(state, building) {
      state.building = building;
    },
    getAllHalls(state, hall) {
      state.hall = hall;
    },
    getAllCommittees(state, committee) {
      state.committee = committee;
    },
    getCommittee(state, committee) {
      state.committee = committee;
    },
    changeCurrentPage(state, payload) {
      state.current_page = payload.page;
      state.items_count = payload.count;
    },
    getSchedule(state, schedule) {
      state.schedules = schedule;
    },

    getNationalities(state, nationalities) {
      state.nationalities = nationalities;
    },
    getDiscount(state, discounts) {
      state.discounts = discounts;
    },
    getAssignedTeachers(state, assigned_teachers) {
      state.assigned_teachers = assigned_teachers;
    },
    getFees(state, fees) {
      state.fees = fees;
    },
    getFeeTypes(state, fee_types) {
      state.fee_types = fee_types;
    },
    getExamPeriods(state, exam_period) {
      state.exam_period = exam_period;
    },
    getAllMonths(state, months) {
      state.months = months;
    },
    getAllEstimations(state, estimates) {
      state.estimates = estimates;
    },
    getAllDocuments(state, documents) {
      state.documents = documents;
    },
    getAllBuses(state, buses) {
      state.buses = buses;
    },
    getAllGroups(state, groups) {
      state.groups = groups;
    },
  },
};

<template>
  <div class="text-justify">
    <v-label class="my-sm-2 mt-md-0">{{ text }}</v-label>
    <div class="image-card">
      <v-card
        class="w-100 border"
        style="height: 200px"
        :disabled="disabled"
        @click="choseImage"
        link
      >
        <v-label
          v-if="image == this.base_url || !image || imageRender == 'no_img'"
          class="w-100 h-100 d-flex justify-center align-center bg-ccc"
          >{{ $t("school.school_data.select_image") }}
        </v-label>
        <input
          type="file"
          ref="imageInput"
          class="d-none"
          @input="selectImage"
          accept="image/*"
        />
        <img
          v-if="imageRender != 'no_img' && imageRender"
          :src="imageRender"
          class="image"
        />
        <img
          v-if="
            image != this.base_url && !(imageRender != 'no_img' && imageRender)
          "
          class="image"
          :src="image"
        />
      </v-card>
      <span v-show="imageError" class="text-red pa-2"
        >{{ $t("global.error.validation.imageError") }} 2mb</span
      >
      <!-- <span
        class="image-icon"
        v-if="!((image == this.base_url || !image) || imageRender == 'no_img' || disabled)"
      > -->
      <!-- <v-icon size="large" color="error" @click="removeLogo"
          >mdi-alpha-x-circle</v-icon
        > -->
      <button
        class="close-btn"
        @click="removeLogo"
        v-if="
          !(
            image == this.base_url ||
            !image ||
            imageRender == 'no_img' ||
            disabled
          )
        "
      >
        &times;
      </button>
      <!-- </span> -->
    </div>
  </div>
</template>
<script>
// import { file } from "@babel/types";
// import { watch } from "@vue/runtime-core";
export default {
  name: "ImageInput",
  props: ["image", "text", "reset", "disabled"],
  data() {
    return {
      imageRender: null,
      imageError: false,
    };
  },
  methods: {
    choseImage() {
      this.$refs.imageInput.click();
    },
    selectImage(e) {
      const input = this.$refs.imageInput;
      const files = input.files;
      if (files && files[0]) {
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (files[0].size > maxSizeInBytes) {
          this.imageError = true;
        } else {
          this.imageError = false;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageRender = e.target.result;
            this.$emit("ImageSelected", files[0]);
          };
          reader.readAsDataURL(files[0]);
          this.$emit("resetRequest", 0);
        }
      }
    },
    removeLogo() {
      this.imageRender = "no_img";
      this.$refs.imageInput.value = null;
      // if (this.reset != "no_remove") {
      this.$emit("ImageSelected", "no_img");
      // }
      this.$emit("resetRequest", 1);
    },
  },
  watch: {
    reset() {
      this.imageRender = null;
    },
  },
};
</script>
<style scoped>
.image-card {
  position: relative;
}
.image-card .v-card:hover {
  cursor: pointer;
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  transition: transform 0.3s ease;
}
.image:hover {
  transform: scale(1.05);
}
.close-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-bottom: 3px;
}
.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

/* .image-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  border-bottom-left-radius: 23px;
  padding: 5px;
} */
</style>

<template>
  <v-card class="justify-center">
    <v-card-subtitle>
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-banner v-bind="props" class="error-info" density="compact">
            <template v-slot:prepend>
              <v-icon color="error" v-bind="props">
                mdi-information-outline</v-icon
              >
            </template>
            <h4 class="ps-1">مجموع الاقساط يساوي 100</h4>
          </v-banner>
        </template>
        <template v-slot:default>
          <h4
            class="tool text-wrap text-center"
            style="width: fit-content; white-space: break-spaces"
          >
            يجب ان يكون مجموع نسب الاقساط مساوي للمئة
          </h4>
        </template>
      </v-tooltip>
    </v-card-subtitle>
    <v-card-text class="card-fields">
      <v-form
        ref="form"
        v-if="
          (update && CheckGetPermission('school.change_installmentsettings')) ||
          CheckGetPermission('school.add_installmentsettings')
        "
      >
        <v-row v-for="(installment, index) in installments" :key="index">
          <v-col cols="12" md="3">
            <v-text-field
              ref="installment_field"
              v-model="installment.percentage"
              type="number"
              density="comfortable"
              :tabindex="index + 1"
              :label="
                $t('school.student_fees.ond_installment') +
                ' ' +
                $t(`global.order.${installment?.index ?? index + 1}`)
              "
              append-inner-icon="mdi-percent-outline"
              :rules="[$required, $max_value(100), sumToHundred]"
              @update:model-value="validate()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="9" lg="6" class="d-none d-md-block">
            <v-textarea
              v-model="installment.note"
              :label="$t('global.note')"
              :tabindex="installments?.length + 1"
              prepend-inner-icon="mdi-note-outline"
              rows="1"
              clearable
              counter="250"
              no-resize
              density="comfortable"
            ></v-textarea>
          </v-col>
        </v-row>
        <VRow class="mx-10 d-flex flex-wrap" style="gap: 1.5rem" elevation="4">
          <VBtn
            variant="text"
            size="x-small"
            class="text-success"
            icon="mdi-plus-thick"
            :tabindex="installments?.length + 2"
            :disabled="installments?.length == 10"
            @click.prevent="pushObj()"
          >
            <!-- icon="mdi-plus-thick" -->
            <v-icon color="success"></v-icon>
          </VBtn>
          <VBtn
            v-if="installments?.length > 1"
            variant="text"
            size="x-small"
            class="text-error"
            icon="mdi-minus-thick"
            :tabindex="installments?.length + 3"
            @click.prevent="installments.pop(), validate()"
          >
          </VBtn>
        </VRow>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        (update && CheckGetPermission('school.change_installmentsettings')) ||
        CheckGetPermission('school.add_installmentsettings')
      "
      class="px-8"
    >
      <v-btn
        v-if="!update && CheckGetPermission('school.add_installmentsettings')"
        @click="saveInstallment"
        class="bg-indigo"
        size="small"
        :loading="is_loading"
      >
        <!-- :disabled="
          !(
            installments.reduce(
              (total, item) => total + parseInt(item.percentage),
              0
            ) == 100
          )
        " -->
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="resetForm" class="mx-3" size="small">
        <span>
          {{ $t("global.undo") }}
        </span>
        <v-icon icon="mdi-undo-variant mdi-flip-h" color="indigo" end></v-icon>
      </v-btn>
    </v-card-actions>
    <!-- ############### Dialog Delete  ################### -->
    <!-- <v-dialog
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (selected_fee_type = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteFeeType(selected_fee_type)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "FeeType",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getInstallments();
      if (!this.fee_types_list.length) await this.getFeeTypes();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      installments: [{ index: 1 }],
      init_installments: [{}],
      first_time: false,
      is_loading: false,
      sumToHundred: (value) => {
        const total = this.installments?.reduce(
          (total, item) => total + parseInt(item?.percentage ?? 0),
          0
        );
        return (
          total <= 100 || this.$t("global.error.validation.reached_hundred")
        );
      },
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    async getInstallments() {
      await this.axios
        .get(`${this.base_url}installment-settings/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data?.length > 0) {
            this.first_time = false;
            this.installments = response.data?.map((installment, index) => {
              return { ...installment, index: installment?.index ?? index + 1 };
            });
            this.init_installments = structuredClone(this.installments);
          } else {
            this.first_time = true;
          }
        })
        .catch((error) => {});
    },
    async pushObj() {
      await this.installments.push({ index: this.installments?.length + 1 });
      //   await this.$refs.installment_field[
      //     this.installments?.length - 1
      //   ].validate();
    },
    async validate() {
      if (!this.installments.find((item) => item.percentage == null)) {
        await this.$refs.form.validate();
      }
    },
    async saveInstallment() {
      const { valid } = await this.$refs.form.validate();
      const total = this.installments?.reduce(
        (total, item) => total + parseInt(item?.percentage ?? 0),
        0
      );
      if (valid) {
        if (total == 100) {
          this.is_loading = true;
          await this.axios[this.first_time ? "post" : "put"](
            `${this.base_url}installment-settings/${
              this.first_time ? "" : "1/"
            }`,
            this.installments,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then((response) => {
              this.$emit("successAlert", this.$t("global.success.data_saved"));
              // this.resetForm();
              this.getInstallments();
            })
            .catch((error) => {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            });
        } else {
          this.$emit(
            "errorAlert",
            this.$t("global.error.validation.reached_hundred")
          );
        }
      }
      this.is_loading = false;
    },
    async resetForm() {
      this.installments = structuredClone(this.init_installments);
      await this.$refs.form.validate();
    },
  },
};
</script>

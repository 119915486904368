<template>
  <v-card class="justify-center mt-2">
    <v-card-text class="card-view">
      <v-form
        v-if="CheckGetPermission('bus_system.view_studentbusfee')"
        ref="form"
        @submit="getStudentsDocuments"
      >
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-autocomplete
              color="indigo"
              :items="buses"
              v-model="filter_data.fk_bus"
              density="compact"
              item-title="itinerary"
              item-value="id"
              prepend-inner-icon="mdi-bus-school"
              :label="$t('school.student_bus_data.chose_bus')"
              :rules="[$required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.fk_month"
              density="compact"
              :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
              item-value="id"
              prepend-inner-icon="mdi-calendar"
              :items="months"
              :label="$t('school.reports.select_month')"
              @update:model-value="filter_data.day_date = null"
              hide-details="auto"
              :rules="[$required]"
            />
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.academic_year"
              density="compact"
              :item-title="date_type == 'h' ? 'year' : 'year_m'"
              item-value="id"
              clearable
              :items="academic_years"
              prepend-inner-icon="mdi-calendar-account-outline"
              :label="$t('school.academic_year.name')"
            />
          </v-col> -->
          <v-col cols="4" md="2">
            <v-btn
              @click="getStudentsBusFee()"
              class="bg-indigo"
              size="small"
              :loading="loading.get"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-expand-transition>
        <div
          v-show="(!!student_bus_fee?.length && !loading.get) || search_student"
          class="card-table"
        >
          <v-card class="card-table">
            <v-form ref="table_form">
              <v-table dense density="compact">
                <thead>
                  <tr>
                    <th>#</th>
                    <th
                      v-for="header in headers"
                      :key="header"
                      :class="header.key == 'name' ? '' : 'text-center'"
                    >
                      <v-text-field
                        v-if="header.key == 'name'"
                        color="indigo"
                        v-model="search_student"
                        @update:model-value="
                          !search_student ? filter_students() : ''
                        "
                        @keyup.enter="filter_students()"
                        density="compact"
                        variant="solo-filled"
                        hide-details
                        clearable
                        single-line
                        append-inner-icon="mdi-magnify"
                        :label="$t('school.student.name')"
                      />
                      <template v-else>{{ header.title }}</template>
                    </th>
                    <th class="text-center">{{ $t("global.actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in student_bus_fee" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td
                      v-for="header in headers"
                      :key="header.key"
                      :class="header.key == 'name' ? '' : 'text-center'"
                    >
                      <template v-if="header.key == 'fee'">
                        <v-text-field
                          ref="bus_fee"
                          color="indigo"
                          class="text-center py-1"
                          v-model="student[header.key]"
                          :onkeypress="$only_numbers"
                          @update:model-value="
                            !student[header.key]
                              ? (student[header.key] = undefined)
                              : ''
                          "
                          :disabled="
                            !CheckGetPermission('bus_system.add_studentbusfee')
                          "
                          :rules="[$max_length(5), $min_value(student.paid)]"
                          density="compact"
                          hide-details
                          :placeholder="$t('school.bus_fees.enter_bus_fees')"
                        />
                      </template>
                      <template v-else-if="header.key == 'total'">
                        {{
                          student[header.key]
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                      </template>
                      <template v-else>
                        {{
                          student[header.key] != null
                            ? student[header.key]
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : "-"
                        }}
                      </template>
                    </td>
                    <td class="text-center">
                      <div
                        class="d-flex gap-space"
                        v-if="
                          CheckGetPermission('bus_system.add_payreciept') ||
                          CheckGetPermission('bus_system.view_payreciept')
                        "
                      >
                        <v-btn
                          v-if="
                            student.id &&
                            CheckGetPermission('bus_system.add_payreciept')
                          "
                          density="comfortable"
                          variant="flat"
                          color="indigo"
                          @click="openDialog(student)"
                          ><span class=""
                            >{{ $t("school.bus_fees.pay") }}
                          </span>
                          <v-icon icon="mdi-plus" color="white" end></v-icon>
                        </v-btn>
                        <v-btn
                          v-if="student.id && student.paid"
                          @click="
                            getPayments(student.fk_student, index),
                              (row_data = student),
                              resetMasterCheck()
                          "
                          density="comfortable"
                          variant="flat"
                          class="bg-success"
                          :loading="loading[`get_payments${index}`]"
                        >
                          <span class="text-white">
                            {{ $t("school.student_fees.payments") }}
                          </span>
                          <v-icon icon="mdi-pencil" color="white" end></v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                  <tr v-show="!student_bus_fee.length && search_student">
                    <td colspan="5" class="text-center">
                      {{ $t("global.data_table.student_not_found") }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-form>
            <v-card-actions
              v-if="
                CheckGetPermission('bus_system.add_studentbusfee') ||
                CheckGetPermission('bus_system.view_studentbusfee')
              "
            >
              <v-btn
                v-if="CheckGetPermission('bus_system.add_studentbusfee')"
                @click="save_bus_fee()"
                class="bg-indigo"
                size="small"
                :loading="loading.fee"
              >
                <span class="text-white">
                  {{ $t("global.save") }}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
              </v-btn>
              <v-btn @click="cleanStudentBusForm" class="mx-3" size="small">
                <span>
                  {{ $t("global.clear") }}
                </span>
                <v-icon icon="mdi-broom" color="golden" end></v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
  <v-dialog
    v-model="show_pay_dialog"
    :dir="this.$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
    width="600"
    class="text-right"
  >
    <v-card>
      <v-card-title class="text-right">
        <v-icon icon="mdi-text-box-plus" />
        <h3 class="d-inline" style="padding: 0 4px">
          {{ $t("school.bus_fees.add_bus_payment") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <v-card-text>
        <VForm ref="form2">
          <VRow class="">
            <VCol cols="12" md="6">
              <VTextField
                v-model="pay_fee.paid_amount"
                type="number"
                density="compact"
                clearable
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.student_fees.payment_amount')"
                :rules="[
                  $required,
                  paid_still !== null
                    ? $max_value(
                        max_value ? max_value : row_data.total,
                        $t('school.student_fees.installment_still')
                      )
                    : '',
                  $min_value(1),
                ]"
              />
            </VCol>
            <!-- <VCol cols="12" md="6">
                <VAutocomplete
                  color="indigo"
                  v-model="student_installments.fk_fee_type"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  :items="fee_type_list"
                  clearable
                  @update:modelValue="getInstallments()"
                  prepend-inner-icon="mdi-cash"
                  :label="$t('school.fees.fee_type')"
                  :rules="[$required, $numeric]"
                ></VAutocomplete>
              </VCol> -->
            <VCol cols="12">
              <VTextField
                v-model="pay_fee.paid_by"
                density="compact"
                clearable
                prepend-inner-icon="mdi-file"
                :label="$t('school.student_fees.who_pay')"
                :rules="[$required, $max_length(100)]"
              />
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="!is_update"
          class="bg-indigo"
          @click="savePayment()"
          size="small"
          :loading="loading.payment"
          ><span class="text-white">{{ $t("global.save") }}</span></v-btn
        >
        <v-btn @click="closeDialog">{{ $t("global.cancel") }}</v-btn>
        <v-btn
          v-if="is_update"
          class="bg-success"
          @click="savePayment(pay_fee?.id)"
          size="small"
          :loading="loading.save"
          ><span class="text-white">{{ $t("global.edit") }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="show_payment_dialog"
    width="600"
    scrollable
    :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
  >
    <v-card>
      <v-card-title class="d-flex">
        <div>{{ $t("school.bus_fees.bus_payments") }}</div>
        <v-spacer></v-spacer>
        <v-icon icon="mdi-close" @click="show_payment_dialog = false"></v-icon>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 60rem">
        <VTable density="compact" class="rounded">
          <thead>
            <tr>
              <th class="pe-0">
                <v-checkbox
                  v-model="masterChecked.is_checked"
                  :indeterminate="isIndeterminate.is_checked"
                  density="compact"
                  hide-details
                  label=""
                  @change="
                    toggleAll(masterChecked.is_checked, 'is_checked'),
                      pushPopObj('all', masterChecked.is_checked)
                  "
                  :disabled="!payments_list.length"
                >
                </v-checkbox>
              </th>
              <!-- <th class="ps-0">{{ $t("school.fees.fee_type") }}</th> -->
              <th class="ps-0">
                {{ $t("school.student_fees.payment_amount") }}
              </th>
              <th>{{ $t("school.student_fees.who_pay") }}</th>
              <th>{{ $t("global.actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in payments_list" :key="payment">
              <td class="pe-0">
                <v-checkbox
                  hide-details
                  density="compact"
                  v-model="payment.is_checked"
                  @change="
                    updateMaster('is_checked'),
                      pushPopObj(payment, payment.is_checked)
                  "
                >
                </v-checkbox>
              </td>
              <!-- <td class="ps-0">{{ payment.fee_type }}</td> -->
              <td class="ps-0">{{ payment.paid_amount }}</td>
              <td>{{ payment.paid_by }}</td>
              <td>
                <span>
                  <span
                    v-if="CheckGetPermission('bus_system.change_payreciept')"
                    class="me-1"
                    style="color: #e5b254; border-radius: 4px"
                  >
                    <v-icon
                      @click="editPayment(payment)"
                      size="small"
                      style="cursor: pointer"
                      icon="mdi-pencil-outline"
                    />
                  </span>
                  <span
                    v-if="CheckGetPermission('bus_system.delete_payreciept')"
                    style="color: #d05251; border-radius: 4px"
                  >
                    <v-icon
                      @click="(pay_fee.id = payment.id), (del_dialog = true)"
                      size="small"
                      style="cursor: pointer"
                      icon="mdi-trash-can-outline"
                    />
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </VTable>
      </v-card-text>
      <v-card-actions>
        <!-- @click="showBill()" -->
        <v-btn
          v-if="payments_list?.length"
          @click="showBill()"
          variant="tonal"
          block
          >{{ $t("global.print") }}
          <template v-slot:append>
            <!-- <v-fade-transition> -->
            <!-- class="ms-3 mb-2"
                  style="line-height: 0.8" -->
            <v-badge
              :class="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
              v-if="payments_list?.find((item) => item.is_checked == true)"
              floating
              color="indigo"
              :content="
                payment_bill?.length
                  ? payments_list?.filter((item) => item.is_checked == true)
                      ?.length
                  : ''
              "
            >
              <v-icon>mdi-printer</v-icon>
            </v-badge>
            <v-icon v-else>mdi-printer</v-icon>
            <!-- </v-fade-transition> -->
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="del_dialog = false">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deletePayment(pay_fee?.id)">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-sheet id="bill" style="gap: 2.5rem" width="100%">
    <!-- color="#cfe5e2" -->
    <div class="header">
      <v-container>
        <v-row class="justify-space-between">
          <v-col cols="10">
            <span class="title">{{ school_data?.name_ar }}</span>
            <h5>
              <span>{{ $t("school.student_fees.bill") }} :</span
              >{{ Math?.max(...payment_bill.map((bill) => bill.id)) }} b#
            </h5>
            <h5>
              <span>{{ $t("school.student_fees.bill_date") }} :</span
              >{{ $date }}
            </h5>
            <!-- <h3>{{ $branch_ar }}</h3>
            <h3>قطع بواسطة : {{ $username }}</h3> -->
          </v-col>

          <v-col cols="2">
            <div class="logo">
              <img
                class="w-100 h-100"
                style="object-fit: cover"
                :src="school_data?.logo"
                alt=""
              />
              <!-- <h5>
                رقم {{ bill.payments.find((item) => item.id)?.id || "Nan" }}
              </h5> -->
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-container>
        <v-row>
          <v-col cols="4" class="d-flex flex-column" style="gap: 0.3rem">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
        </v-row>
      </v-container> -->
    </div>
    <div class="body">
      <v-container>
        <v-table
          class="bill-info"
          density="compact"
          style="border-bottom: 1px solid #959595"
        >
          <!-- <caption>
            <h3 class="text-center mb-2">ضريب تسديد قسط</h3>
          </caption> -->
          <thead style="border-top: 1px solid #959595">
            <tr>
              <th>{{ $t("school.student_fees.student_name") }}</th>
              <!-- <th>{{ $t("school.student_fees.class") }}</th> -->
              <th>{{ $t("school.student_fees.fee_type") }}</th>
              <th>{{ $t("school.student_fees.who_pay") }}</th>
              <th>{{ $t("school.student_fees.installment_paid") }}</th>
              <!-- <th>المبلغ المتبقي</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(bill, index) in payment_bill"
              :key="bill"
              :class="index == 16 ? 'break' : ''"
            >
              <td v-if="index == 0" :rowspan="payment_bill?.length">
                {{ row_data?.name || NAN }}
              </td>
              <td v-if="index == 0" :rowspan="payment_bill?.length">
                رسوم باص
              </td>
              <!-- student_list.find(
                    (item) => item.id === student_installments.fk_student
                  )?.name_ar || $t("school.student_fees.no_name") -->
              <!-- <td>{{}} add class to the list that come from the backend</td> -->
              <!-- classes.find(
                    (item) => item.id === student_installments.fk_class
                  )?.name_ar || $t("school.student_fees.no_name") -->
              <td>{{ bill.paid_by }}</td>
              <td>
                {{
                  bill.paid_amount
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <!-- <td>{{ installments_list[0]?.still || "لايوجد" }}</td> -->
            </tr>
          </tbody>
        </v-table>
      </v-container>
      <v-container class="d-flex justify-end pt-0">
        <div class="text-start">
          <!-- <div
            class="d-flex justify-space-between my-1"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> القسط: </span
            ><span>{{ "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <div
            class="d-flex justify-space-between my-1 align-content-center"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> : </span
            ><span class="text-center">{{
               "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <v-divider></v-divider> -->
          <div
            class="d-flex justify-space-between pa-3 bg-header"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px">
              {{ $t("school.bus_fees.total") }}:
            </span>
            <span class="mx-auto">{{
              payment_bill?.length
                ? payment_bill?.reduce((total, item) => {
                    return total + parseInt(item.paid_amount);
                  }, 0)
                : "-"
            }}</span>
          </div>
        </div>
      </v-container>
    </div>
    <div class="footer">
      <v-container>
        <div v-if="branch_information?.phone_number">
          <v-icon
            icon="mdi-phone-in-talk-outline mdi-flip-h"
            class="pb-1"
          ></v-icon>
          <span class="ps-1" style="word-spacing: 6px"
            >{{ branch_information?.phone_number }}
          </span>
        </div>
        <div v-if="branch_information?.whatsApp">
          <v-icon icon="mdi-whatsapp" class="pb-1"></v-icon>
          <span class="ps-1">{{ branch_information?.whatsApp }} </span>
        </div>
        <div v-if="branch_information?.email">
          <v-icon icon="mdi-at" class="pb-1"></v-icon>
          <span class="ps-1">{{ branch_information?.email }} </span>
        </div>
        <div>
          <v-icon icon="mdi-map-marker-radius-outline" class="pb-1"></v-icon>
          <span class="ps-1">{{
            branch_information.place +
            " - " +
            branch_information.directorate_name_ar +
            " - " +
            branch_information.governorate_name_ar +
            " - " +
            branch_information.country_name_ar
          }}</span>
        </div>
        <!-- <div>
          <v-icon icon="mdi-map-marker-radius" class="mb-1"></v-icon>
          <span class="ps-1">{{
            branch_information.place +
            "، " +
            branch_information.directorate_name_ar +
            "، " +
            branch_information.governorate_name_ar +
            "، " +
            branch_information.country_name_ar
          }}</span>
        </div>
        <div>
          <v-icon icon="mdi-phone-in-talk"></v-icon>
          <span>{{ 773584109 }}</span>
        </div>
        <div>
          <v-icon icon="mdi-map-marker-radius"></v-icon>
          <span>{{ 0 }}</span>
        </div> -->
      </v-container>
    </div>
  </v-sheet>
  <!-- <ConfirmationDialog
    v-model="confirmation_dialog"
    :message="$t('school.archive.do_you_want_to_upload_file')"
    :subject="
      documents.find((doc) => doc.id == document_data.fk_document)?.name
    "
    @confirmed="uploadDocument"
    @canceled="cancelDialog"
  >
    <template v-slot:buttons>
      <v-btn color="indigo" @click="cancelDialog(false)">{{
        $t("global.cancel")
      }}</v-btn>
      <v-btn
        size="small"
        variant="tonal"
        append-icon="mdi-check"
        color="indigo"
        @click="uploadDocument(true)"
        >{{ $t("school.archive.select_only") }}</v-btn
      >
      <v-btn
        size="small"
        color="indigo"
        variant="elevated"
        @click="(document_dialog = true), (confirmation_dialog = false)"
        append-icon="mdi-upload-outline"
        >{{ $t("school.archive.upload_document") }}</v-btn
      >
    </template>
  </ConfirmationDialog>
  <ConfirmationDialog
    type="delete"
    v-model="delete_dialog"
    :message="$t('school.archive.delete_file')"
    :subject="
      documents.find((doc) => doc.id == document_data.fk_document)?.name
    "
    :confirmed_text="$t('school.archive.delete')"
    confirmed_color="red"
    @confirmed="deleteDocument"
    @canceled="cancelDialog"
  >
  </ConfirmationDialog> -->
  <!-- :subject="document_data?.name" -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "StudentAttendance",
  props: {
    id: String,
  },
  //   components: { Datetime, ConfirmationDialog },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );

      await this.getAllBuses();
      await this.getAllMonths();
      if (!this.contact_type_list.length) await this.getContactTypes();
      if (!this.classes.length) await this.getClasses();

      await this.getSemesters();
      this.getSchoolData();
      this.getBranchData();

      //   if (!this.classes.length) await this.getClasses();
      //   if (!this.divisions.length) await this.getDivisions({});
      //   if (!this.academic_years.length) await this.getAcademicYears();
      //   if (!this.class_assign_hall.length) await this.getClassAssignHall(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      buses: [],
      filter_data: {},
      student_bus_fee: [],
      show_pay_dialog: false,
      show_payment_dialog: false,
      pay_fee: {},
      payments_list: {},
      payment_bill: [],
      branch_information: {},
      school_data: {},
      row_data: {},
      masterChecked: {},
      isIndeterminate: {},
      is_update: false,
      del_dialog: false,
      loading: {},
      duplicate_data: [],
      max_value: null,
      headers: [
        { title: this.$t("school.student.name"), key: "name" },
        { title: this.$t("school.fees.fee"), key: "fee" },
        { title: this.$t("school.reports.installment_paid"), key: "paid" },
        { title: this.$t("school.reports.installment_remain"), key: "total" },
      ],

      //   main_data: [],
      //   documents: [],
      //   document_data: {},
      //   confirmation_dialog: false,
      //   delete_dialog: false,
      //   document_dialog: false,
      //   image_url: false,
      //   is_update: false,
      //   date_type: localStorage.getItem("date"),
      //   uploading: false,
    };
  },
  computed: {
    ...mapState({
      months: (state) => state.school.months,
      contact_type_list: (state) => state.globals.contacts_type,
      classes: (state) => state.school.classes,

      //   divisions: (state) => state.school.divisions,
      //   academic_years: (state) => state.school.academic_years,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
    }),

    // ...mapGetters({
    //   getClassDivisions: "school/getClassDivisions",
    //   getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
    // }),
    // class_divisions() {
    //   if (this.filter_data.fk_class) {
    //     return this.getClassDivisions(this.filter_data.fk_class);
    //   }
    // },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllMonths: "school/getAllMonths",
      getSemesters: "school/getSemesters",
      getContactTypes: "globals/getContactTypes",
      getClasses: "school/getClasses",

      //   getDivisions: "school/getDivisions",
      //   getAcademicYears: "school/getAcademicYears",
      //   getClassAssignHall: "school/getClassAssignHall",
    }),
    async getAllBuses() {
      await this.axios
        .get(`${this.base_url}/bus/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: 1,
            page_size: 10000000,
          },
        })
        .then((response) => {
          this.buses = response.data.results;
        });
    },
    filter_students() {
      if (this.search_student != "") {
        this.student_bus_fee = this.student_bus_fee.filter((student) => {
          return student.name.includes(this.search_student);
        });
      } else this.student_bus_fee = this.duplicate_data;
    },
    editPayment(payments_data) {
      this.pay_fee = { ...payments_data };
      this.pay_fee.paid_amount = parseInt(this.pay_fee?.paid_amount);
      this.max_value = this.row_data.total + this.pay_fee.paid_amount;
      this.is_update = true;
      this.show_pay_dialog = true;
      //   this.show_dialog = false;
    },
    openDialog(data) {
      this.pay_fee = {};
      this.is_update = false;
      this.row_data = data;
      this.max_value = null;
      this.pay_fee.fk_student_fee = data.id;
      this.show_pay_dialog = true;
    },
    closeDialog() {
      this.pay_fee = {};
      this.show_pay_dialog = false;
    },
    // async uploadDocument(results, update = false, validate) {
    //   const { valid } = validate
    //     ? await this.$refs?.dialog_form?.validate()
    //     : { valid: true };
    //   if (results && valid) {
    //     this.uploading = true;
    //     let data = { ...this.document_data };
    //     data.acquired = true;
    //     await this.axios[update ? "put" : "post"](
    //       `${this.base_url}student-document/${
    //         update ? this.document_data?.id + "/" : ""
    //       }`,
    //       data,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       }
    //     )
    //       .then((response) => {
    //         if (this.document_data?.Image) {
    //           this.$emit(
    //             "successAlert",
    //             this.$t("global.success.data_uploaded")
    //           );
    //         }
    //         this.document_data.acquired = true;
    //         this.document_data.id = response.data?.id;
    //         this.document_data.Image = response.data?.Image;
    //         this.confirmation_dialog = false;
    //         this.document_dialog = false;
    //         this.uploading = false;
    //       })
    //       .catch((error) => {
    //         this.document_data.acquired = false;
    //         this.uploading = false;
    //         this.$emit(
    //           "errorAlert",
    //           this.$t("global.error.failure.error_in_data")
    //         );
    //         // this.getLecturesAttendance();
    //         this.confirmation_dialog = false;
    //       });
    //     this.uploading = false;
    //   }
    // },
    // cancelDialog(results) {
    //   if (!results) {
    //     this.document_data.acquired = this.document_data.acquired;
    //     this.confirmation_dialog = results;
    //     this.delete_dialog = results;
    //   }
    // },
    // async deleteDocument() {
    //   await this.axios
    //     .delete(`${this.base_url}student-document/1/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //       params: {
    //         obj: this.document_data?.id,
    //         fk_document: this.document_data?.fk_document,
    //         fk_student: this.document_data?.fk_student,
    //       },
    //     })
    //     .then((response) => {
    //       this.deleteDocumentData();
    //       this.document_data.acquired = false;
    //       this.delete_dialog = false;
    //     })
    //     .catch((error) => {
    //       this.document_data.acquired = true;
    //       // this.getLecturesAttendance();
    //       this.delete_dialog = false;
    //     });
    // },
    // getReleaseDate(e) {
    //   let date = this.toEnglishString(e);
    //   this.document_data.released_date = date;
    // },
    async getStudentsBusFee() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading.get = true;
        await this.axios
          .get(`${this.base_url}student-bus-fee/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              //   fk_attendance: this.filter_data.fk_attendance,
              txt_search: this.txt_search,
              fk_bus: this.filter_data.fk_bus,
              fk_month: this.filter_data.fk_month,
              //   date: this.filter_data.attendance_date,
            },
          })
          .then((response) => {
            this.student_bus_fee = response.data.map((item) => ({
              ...item,
              total: item?.fee - item?.paid,
            }));
            this.duplicate_data = structuredClone(this.student_bus_fee);
            this.loading.get = false;
          })
          .catch((error) => {
            this.loading.get = false;
          });
      } else {
        this.student_bus_fee = [];
      }
      this.loading.get = false;
    },
    async savePayment(update = false) {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        this.loading.payment = true;
        await this.axios[update ? "put" : "post"](
          `${this.base_url}student-bus-fee/${
            update ? "update" : "make"
          }-payment/`,
          this.pay_fee,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((response) => {
            this.$emit(
              "successAlert",
              this.$t(
                `global.success.${update ? "data_updated" : "data_saved"}`
              )
            );
            this.row_data.paid = response.data?.paid;
            this.row_data.fee = response.data?.fee;
            this.row_data.total = this.row_data.fee - this.row_data.paid;
            this.show_pay_dialog = false;
            this.loading.payment = false;
            if (update) {
              this.getPayments(this.row_data?.fk_student);
              this.resetMasterCheck();
            }
          })
          .catch((error) => {
            this.loading.payment = false;
            if (error.response.data?.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data?.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.loading.payment = false;
    },
    async deletePayment(id) {
      await this.axios
        .delete(
          `${this.base_url}student-bus-fee/delete-payment/`,

          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: { id: id },
          }
        )
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.row_data.paid = response.data?.paid;
          this.row_data.fee = response.data?.fee;
          this.row_data.total = this.row_data.fee - this.row_data.paid;
          this.payments_list = this.payments_list?.filter(
            (item) => item?.id != id
          );
          this.del_dialog = false;
        })
        .catch((error) => {
          if (error.response.data.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response.data.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });
    },
    async getPayments(fk_student, index) {
      this.loading[`get_payments${index}`] = true;
      await this.axios
        .get(`${this.base_url}student-bus-fee/show-payment/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_bus: this.filter_data?.fk_bus,
            fk_month: this.filter_data?.fk_month,
            fk_student: fk_student,
          },
        })
        .then((response) => {
          this.payments_list = response.data;
          this.show_payment_dialog = true;
          this.payment_bill = [];
          this.loading[`get_payments${index}`] = false;
        })
        .catch((error) => {
          this.loading[`get_payments${index}`] = false;
          if (error.response?.data?.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response?.data?.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });
      this.loading[`get_payments${index}`] = false;
    },
    async save_bus_fee() {
      const { valid } = await this.$refs.form.validate();
      const valid2 = await this.$refs.table_form.validate();
      if (valid && valid2.valid) {
        this.loading.fee = true;
        await this.axios
          .post(`${this.base_url}student-bus-fee/`, this.student_bus_fee, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_bus: this.filter_data?.fk_bus,
              fk_month: this.filter_data?.fk_month,
            },
          })
          .then((response) => {
            this.getStudentsBusFee();
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            this.loading.fee = false;
          })
          .catch((error) => {
            this.loading.fee = false;
            if (error.response.data?.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data?.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.loading.fee = false;
      }
    },
    // async checkClassDocument() {
    //   let docs = [];
    //   const { valid } = await this.$refs.form.validate();
    //   if (valid) {
    //     this.loading = true;
    //     await this.axios
    //       .get(
    //         `${this.base_url}check-class-document/?class=${this.filter_data.fk_class}`
    //       )
    //       .then((response) => {
    //         this.getStudentsDocuments();
    //         this.documents = response.data;
    //       })
    //       .catch(() => {
    //         this.loading = false;
    //       });
    //   }
    // },
    async getBranchData() {
      await this.axios
        .get(`${this.base_url}branches/school-with-branch-data/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.branch_information = response.data;
        })
        .catch((error) => {
          alert(error);
        });
      await this.axios
        .get(`${this.base_url}contacts/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.branch_information.phone_number = response.data
            ?.filter(
              (data) =>
                data.type ==
                  this.contact_type_list?.find(
                    (contact) => contact?.name == "هاتف"
                  )?.id ||
                data.type ==
                  this.contact_type_list?.find(
                    (contact) => contact?.name == "موبايل"
                  )?.id
            )
            ?.map((item) => item.branch_contact)
            ?.join(" - ");
          this.branch_information.email = response.data
            ?.filter(
              (data) =>
                data.type ==
                this.contact_type_list?.find(
                  (contact) => contact?.name == "ايميل"
                )?.id
            )
            ?.map((item) => item.branch_contact)
            ?.join(" - ");
          this.branch_information.whatsApp = response.data
            ?.filter(
              (data) =>
                data.type ==
                this.contact_type_list?.find(
                  (contact) => contact?.name == "واتساب"
                )?.id
            )
            ?.map((item) => item.branch_contact)
            ?.join(" - ");
          // this.branch_information.faceBook = response.data?.filter(
          //   (data) => data.type == 5
          // )?.map((item) => item.branch_contact)
          //   ?.join(" - ");
        })
        .catch((error) => {
          alert(error);
        });
    },
    async getSchoolData() {
      await this.axios
        .get(`${this.base_url}school-data/logo/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.school_data = response.data;
        });
    },
    pushPopObj(obj, method) {
      if (obj == "all") {
        this.payment_bill = method ? structuredClone(this.payments_list) : [];
      } else {
        if (method) {
          if (
            this.payment_bill.find(
              (pay) => pay.is_checked == false || pay.is_checked == undefined
            )
          ) {
            this.payment_bill = this.payment_bill?.filter(
              (pay) => pay.is_checked == true
            );
          }
          if (!this.payment_bill?.find((item) => item.id === obj.id)) {
            this.payment_bill?.push(obj);
          }
        } else {
          const index = this.payment_bill?.findIndex(
            (pay) => pay.id === obj.id
          );
          if (index !== -1) this.payment_bill?.splice(index, 1);
        }
      }
    },
    toggleAll(All, checkedField) {
      this.payments_list.forEach((item) => {
        item[checkedField] = All;
      });
      this.updateMaster(checkedField);
    },
    updateMaster(checkedField) {
      const data = this.payments_list;

      const totalChecked = data.reduce((total, item) => {
        return total + (item[checkedField] ? 1 : 0);
      }, 0);
      const totalItems = data.length;
      this.masterChecked[checkedField] = totalChecked === totalItems;
      this.isIndeterminate[checkedField] =
        totalChecked > 0 && totalChecked < totalItems;
    },
    cleanStudentBusForm() {
      this.$refs.form.reset();
      this.student_bus_fee = [];
    },
    resetMasterCheck() {
      this.masterChecked = {};
      this.isIndeterminate = {};
    },
    showBill() {
      if (!this.payment_bill?.length) {
        this.payment_bill = [this.payments_list?.slice(-1)[0]];
      }
      this.print();
      //   this.show_bill = true;
    },
    print() {
      if (this.payment_bill?.length) {
        setTimeout(() => {
          window.print();
        }, 0);
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
#bill {
  /* padding: 5rem; */
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  display: none;
}

.bill_dialog {
  padding: 2rem;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 3em;
  color: #3f85c6;
}

.v-col h5:has(span) {
  width: 15rem;
  display: flex !important;
  justify-content: space-between;
  margin-block: 0.5px;
  padding-inline: 2px;
  align-items: center;
}

.bill-info thead th {
  border-inline: 3px solid white;
  background-color: #009bdd;
  color: white !important;
}
.bg-header {
  background-color: #009bdd;
}
.bg-header span {
  color: white;
}
/* 
.body th:not(:first-child),
.body td:not(tr:first-child :first-child) {
  text-align: center;
} */
.body th,
.body td {
  text-align: center !important;
}
</style>

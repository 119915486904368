<template>
  <VCard class="justify-center">
    <VCardText
      class="card-fields"
      v-if="
        CheckGetPermission('school.add_teachercommittee') ||
        (id && CheckGetPermission('school.change_teachercommittee'))
      "
    >
      <VForm ref="form">
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="committee"
                v-model="teacher_committee.fk_committee"
                prepend-inner-icon="mdi-group"
                item-title="name"
                item-value="id"
                :label="$t('school.committee.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required, committee_duplicate]"
                @update:model-value="validate()"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="teachers"
                v-model="teacher_committee.fk_teacher"
                prepend-inner-icon="mdi-account-tie"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.teacher.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required, teacher_duplicate]"
                @update:model-value="validate()"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="period_list"
                v-model="teacher_committee.fk_exam_period"
                prepend-inner-icon="mdi-timetable"
                item-title="name"
                item-value="id"
                :label="$t('school.exams.schedule_period')"
                persistent-hint
                density="compact"
                :rules="[$required]"
                @update:model-value="validate()"
                clearable
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <v-text-field
                v-model="teacher_committee.date"
                density="compact"
                :placeholder="$t('school.exams.exam_date')"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                id="'teacher_exam_date'"
                readonly
                :rules="[$required]"
              >
              </v-text-field>
              <Datetime
                type="date"
                element="'teacher_exam_date'"
                :color="'#0747a6'"
                @input="
                  (e) => {
                    getExamDate(e);
                    validate();
                  }
                "
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="$t('school.homework.deadline')"
                :calendar="dateType"
              >
              </Datetime>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" md="8" lg="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="teacher_committee.note"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_teachercommittee') ||
        (id && CheckGetPermission('school.change_teachercommittee'))
      "
    >
      <VBtn
        v-if="!id && CheckGetPermission('school.add_teachercommittee')"
        class="bg-indigo"
        @click="saveAssignedHall"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('school.change_teachercommittee')"
        class="bg-success"
        @click="updateAssignedHall"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
  </VCard>
</template>
<script>
import { tsImportEqualsDeclaration } from "@babel/types";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      teacher_committee: {
        fk_teacher: undefined,
        fk_committee: undefined,
        fk_exam_period: undefined,
        date: undefined,
        note: undefined,
        id: undefined,
      },
      teacher_committee_list: [],
      is_loading: false,
      teacher_duplicate: (value) => {
        if (
          !value ||
          !this.teacher_committee.date ||
          !this.teacher_committee.fk_exam_period
        )
          return true;
        let duplicate = this.teacher_committee_list.find(
          (item) =>
            item.fk_teacher == value &&
            item.date == this.teacher_committee.date &&
            item.fk_exam_period == this.teacher_committee.fk_exam_period &&
            item.id != this.id
        );
        if (duplicate)
          return this.$t("global.error.failure.teacher_committee_schedule");
        else return true;
      },
      committee_duplicate: (value) => {
        if (
          !value ||
          !this.teacher_committee.date ||
          !this.teacher_committee.fk_exam_period
        )
          return true;
        let duplicate = this.teacher_committee_list.find(
          (item) =>
            item.fk_committee == value &&
            item.date == this.teacher_committee.date &&
            item.fk_exam_period == this.teacher_committee.fk_exam_period &&
            item.id != this.id
        );
        if (duplicate)
          return this.$t("global.error.failure.committee_teacher_schedule");
        else true;
      },
      // uniqueDivision: (value) => {
      //   var assign_hall = this.assigned_halls.find((item) =>
      //     this.id
      //       ? (this.assign.fk_division != this.original.fk_division ||
      //           this.assign.fk_class != this.original.fk_class) &&
      //         item.fk_class == this.assign.fk_class &&
      //         item.fk_division == value
      //       : item.fk_class == this.assign.fk_class && item.fk_division == value
      //   );
      //   return (
      //     !assign_hall || this.$t("global.error.validation.division_exists")
      //   );
      // },
      // classes: [],
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      await this.getAllCommittees({});
      if (!this.teachers.length) await this.getAllTeachers({});
      if (!this.exam_period.length) await this.getExamPeriods();
      if (!this.period.length) await this.getPeriod();
      await this.getCommitteeTeacher();
      if (this.id) {
        await this.axios(`${this.base_url}teacher-committee/${this.id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }).then((response) => {
          this.teacher_committee = response.data;
        });
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      committee: (state) => state.school.committee,
      teachers: (state) => state.school.teachers,
      exam_period: (state) => state.school.exam_period,
      period: (state) => state.globals.period,
    }),
    ...mapGetters({
      // getBuildingsHalls: "school/getBuildingsHalls",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    dateType() {
      return localStorage.getItem("date") == "h" ? "hijri" : "";
    },
    period_list() {
      let list = [];
      this.exam_period.forEach((element) => {
        let period = this.period.find((item) => item.id == element.period);
        if (period != undefined) period = period.name;
        list.push({
          id: element.id,
          name: period,
        });
      });
      return list;
    },
  },
  methods: {
    ...mapActions({
      getAllCommittees: "school/getAllCommittees",
      getAllTeachers: "school/getAllTeachers",
      getExamPeriods: "school/getExamPeriods",
      getPeriod: "globals/getPeriod",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "teacher-committeelist" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    getExamDate(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.teacher_committee.date = date;
    },
    async saveAssignedHall() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "teacher-committee/", this.teacher_committee, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.teacher_committee_list.push(this.teacher_committee);
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.includes("Duplicate"))
              if (error.response.data.includes("teacher_schedule"))
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.teacher_committee_schedule")
                );
              else
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.committee_teacher_schedule")
                );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false;
    },
    async updateAssignedHall() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.teacher_committee.id != undefined) {
        this.is_loading = true;
        await this.axios
          .put(
            `${this.base_url}teacher-committee/${this.teacher_committee.id}/`,
            this.teacher_committee,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async getCommitteeTeacher(page = 1) {
      return await this.axios(`${this.base_url}teacher-committee/`, {
        params: {
          page: 1,
          page_size: 1000,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }).then((response) => {
        this.teacher_committee_list = response.data.results;
      });
    },
    async validate() {
      if (
        this.teacher_committee.fk_teacher &&
        this.teacher_committee.date &&
        this.teacher_committee.fk_exam_period &&
        this.teacher_committee.fk_committee
      ) {
        await this.$refs.form.validate();
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>

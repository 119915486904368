// import Dashboard from '../views/Examples/Dashboard'
import NewStudent from "@/views/School/student/NewStudent.vue";

import Countries from "../views/School/init/Countries.vue";
import Governorate from "../views/School/init/Governorate";
import Directorate from "../views/School/init/Directorate";
import Buildings from "../views/School/init/Buildings.vue";
import School from "../views/School/init/School.vue";
import Day from "../views/School/init/Day.vue";
import store from "@/store/index";
import teacher from "../views/School/AddTeacher.vue";
import Branch from "../views/School/init/Branch.vue";
import HallView from "../views/School/init/halls/HallView.vue";
import HallList from "../views/School/init/halls/HallList.vue";
import AssignHallView from "../views/School/init/halls/AssignHallView.vue";
import AssignHallList from "../views/School/init/halls/AssignHallList.vue";
import Schedule from "../views/School/init/Schedule.vue";
import TeacherView from "../views/School/teacher/TeacherView.vue";
import TeachersList from "../views/School/teacher/TeachersList.vue";
import AssignTeacherView from "../views/School/teacher/AssignTeacherView.vue";
import AssignTeacherList from "../views/School/teacher/AssignTeacherList.vue";
import ExamScheduleView from "../views/School/exam/ExamScheduleView.vue";
import ExamScheduleList from "../views/School/exam/ExamScheduleList.vue";
import ClassDocument from "../views/School/init/ClassDocument.vue";
import Fees from "../views/School/init/Fees.vue";
import FeeType from "../views/School/init/FeeType.vue";
import InstallmentSettings from "@/views/School/init/InstallmentSettings.vue";
import HomeworkList from "../views/School/HomeworkList.vue";
import Division from "../views/School/init/Division.vue";
import Discount from "../views/School/init/Discount.vue";
import SchoolWeeklyScheduleUpdate from "../views/School/init/SchoolWeeklyScheduleUpdate.vue";
import CorrectionHomework from "../views/School/CorrectionHomework.vue";
import ParentView from "../views/School/process/parent/ParentView.vue";
import ParentList from "../views/School/process/parent/ParentList";
import ExamPeriod from "../views/School/init/ExamPeriod.vue";
import CommitteesView from "../views/School/process/committees/CommitteesView";
import CommitteesList from "../views/School/process/committees/CommitteesList";
import CommitteeStudentsView from "../views/School/process/committeeStudents/CommitteeStudentsView";
import CommitteesPlanView from "../views/School/process/committeesPlan/CommitteesPlanView";
import CommitteesPlanList from "../views/School/process/committeesPlan/CommitteesPlanList";

import StudentAttendance from "../views/School/StudentAttendance.vue";
import Subject from "../views/School/init/Subjects.vue";
import Classes from "../views/School/init/Classes.vue";
import Semester from "../views/School/init/Semester.vue";
import HomeWorkAdd from "../views/School/HomeWorkAdd.vue";
import AcademicYear from "../views/School/init/AcademicYear.vue";
import Activity from "../views/School/init/ِActivity.vue";
import LectureAttendance from "../views/School/LectureAttendance.vue";
import WeeklyScheduleAdd from "../views/School/init/WeeklyScheduleAdd.vue";
import LecturesList from "../views/School/LecturesList.vue";
import StudentView from "../views/School/student/StudentView.vue";
import StudentList from "../views/School/student/StudentList.vue";
import StudentElevate from "../views/School/student/StudentElevate.vue";
import StudentFeeAdd from "../views/School/student/StudentFeeAdd.vue";
import StudentFeeUpdate from "../views/School/student/StudentFeeUpdate";
import PayInstallment from "../views/School/student/PayInstallment.vue";
import SeatNumbers from "../views/School/SeatNumbers.vue";
import SecretNumbers from "../views/School/SecretNumbers.vue";
import StudentStatus from "../views/School/student/StudentStatus";

import MonthlyMarksAdd from "../views/School/exam/MonthlyMarksAdd.vue";
import MonthlyOralMarks from "@/views/School/exam/MonthlyOralMarks";
import MonthlyAssignmentsMarks from "@/views/School/exam/MonthlyAssignmentsMarks";
import MonthlyAttendanceMarks from "@/views/School/exam/MonthlyAttendanceMarks";
import MonthlyExamMarks from "@/views/School/exam/MonthlyExamMarks";
import Home from "../views/Globals/HomeView";
import MonthsView from "../views/School/init/months/MonthsView.vue";
import Estimate from "../views/School/init/Estimate.vue";
import Document from "../views/School/init/Document.vue";
import example from "../views/Examples/Dashboard.vue";
import SemesterMarks from "../views/School/SemesterMarks";
import AddStudentActivity from "../views/School/AddStudentActivity";
import ReportExample from "../views/School/ReportExample";
import BackUp from "../views/School/backup/BackUp";
import Settings from "../views/Globals/Settings.vue";

// features
import LibraryView from "@/views/School/features/LibraryView";
import Notifications from "@/views/School/news/Notifications.vue";
import NewsList from "@/views/School/news/NewsList.vue";
import NewsView from "@/views/School/news/NewsView.vue";
import SchoolDashboard from "@/views/School/SchoolDashboard.vue";

import TeacherCommitteeList from "../views/School/exam/TeacherCommitteeList.vue";
import TeacherCommitteeView from "../views/School/exam/TeacherCommitteeView.vue";
import Archive from "@/views/School/student/Archive";

// import testMapAction from '@/views/School/testMapAction'

const hasPermission = (prem) => {
  // store.dispatch("updateAuthenticated", localStorage.getItem("token"));
  store.dispatch("updatePermission", localStorage.getItem("userinfo"));
  return store.getters.checkpermission(prem);
};

const requirePermission = (perm) => {
  return (to, from, next) => {
    if (hasPermission(perm)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
    if (store.getters.checkrole(role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};

export default [
  {
    path: "dashboard",
    name: "dashboard",
    alias: "/",
    component: SchoolDashboard,
    beforeEnter: requirePermission("globaltable.view_country"),
    // beforeEnter:CheckSchoolSystem
  },
  // {
  //   path: "new-student",
  //   name: "new-student",
  //   component: NewStudent,
  //   // beforeEnter: requirePermission("globaltable.view_country"),
  //   // beforeEnter:CheckSchoolSystem
  // },
  {
    path: "countries",
    name: "countries",
    component: Countries,
    beforeEnter: requirePermission("globaltable.view_country"),
    // beforeEnter:CheckSchoolSystem
  },
  {
    path: "governorate",
    name: "governorate",
    component: Governorate,
    beforeEnter: requirePermission("globaltable.view_governorate"),
  },
  {
    path: "directorate",
    name: "directorate",
    component: Directorate,
    beforeEnter: requirePermission("globaltable.view_directorate"),
  },
  {
    path: "school",
    name: "schools",
    component: School,
    // beforeEnter: requirePermission("school.view_school"),
    beforeEnter: requireRole(["programmer", "big_admin"]),
  },
  {
    path: "buildings",
    name: "buildings",
    component: Buildings,
    beforeEnter: requirePermission("school.view_building"),
  },
  {
    path: "day",
    name: "day",
    component: Day,
    beforeEnter: requirePermission("school.view_day"),
  },

  {
    path: "teacher/add",
    name: "teacheradd",
    component: TeacherView,
    beforeEnter: requirePermission("school.add_teacher"),
  },
  {
    path: "teacher/list",
    name: "teacherview",
    component: TeachersList,
    beforeEnter: requirePermission("school.view_teacher"),
  },
  {
    path: "teacher/:id/edit",
    name: "teacheredit",
    component: TeacherView,
    props: true,
    beforeEnter: requirePermission("school.change_teacher"),
  },
  {
    path: "assign-teacher/add",
    name: "assignteacheradd",
    component: AssignTeacherView,
    beforeEnter: requirePermission("school.add_teachersubjectclass"),
  },
  {
    path: "assign-teacher/list",
    name: "assignteacherview",
    component: AssignTeacherList,
    beforeEnter: requirePermission("school.view_teachersubjectclass"),
  },
  {
    path: "assign-teacher/:id/edit",
    name: "assignteacheredit",
    component: AssignTeacherView,
    props: true,
    beforeEnter: requirePermission("school.change_teachersubjectclass"),
  },
  {
    path: "exam-schedule/add",
    name: "exam-schedule-add",
    component: ExamScheduleView,
    beforeEnter: requirePermission("school.add_examschedule"),
  },
  {
    path: "exam-schedule/list",
    name: "exam-schedule-view",
    component: ExamScheduleList,
    beforeEnter: requirePermission("school.view_examschedule"),
  },
  {
    path: "exam-schedule/:id/edit",
    name: "exam-schedule-edit",
    component: ExamScheduleView,
    props: true,
    beforeEnter: requirePermission("school.change_examschedule"),
  },
  {
    path: "monthly-marks/add",
    name: "monthly-marks-add",
    component: MonthlyMarksAdd,
    beforeEnter: requirePermission("school.view_marks"),
  },
  {
    path: "monthly-oral-marks",
    name: "monthly-oral-marks",
    component: MonthlyOralMarks,
    beforeEnter: requirePermission("school.view_marks"),
  },
  {
    path: "monthly-assignments-marks",
    name: "monthly-assignments-marks",
    component: MonthlyAssignmentsMarks,
    beforeEnter: requirePermission("school.view_marks"),
  },
  {
    path: "monthly-attendance-marks",
    name: "monthly-attendance-marks",
    component: MonthlyAttendanceMarks,
    beforeEnter: requirePermission("school.view_marks"),
  },
  {
    path: "monthly-exam-marks",
    name: "monthly-exam-marks",
    component: MonthlyExamMarks,
    beforeEnter: requirePermission("school.view_marks"),
  },
  //   {
  //     path:'monthly-marks/list',
  //     name:"monthly-marks-view",
  //     component:MonthlyMarksList,
  //     beforeEnter:requirePermission("school.view_marks"),
  //   },
  // {
  //     path:'monthly-marks/:id/edit',
  //     name:"monthly-marks-edit",
  //     component:MonthlyMarksAdd,
  //     props:true,
  //     beforeEnter:requirePermission("school.change_marks"),

  // },
  {
    path: "branch",
    name: "branch",
    component: Branch,
    beforeEnter: requirePermission("school.view_branch"),
  },
  {
    path: "halls/add",
    name: "hallsadd",
    component: HallView,
    beforeEnter: requirePermission("school.add_hall"),
  },
  {
    path: "halls/list",
    name: "hallslist",
    component: HallList,
    props: true,
    beforeEnter: requirePermission("school.view_hall"),
  },
  {
    path: "halls/:id/edit",
    name: "hallsedit",
    component: HallView,
    props: true,
    beforeEnter: requirePermission("school.change_hall"),
  },
  {
    path: "assign-hall/add",
    name: "assign-halladd",
    component: AssignHallView,
    beforeEnter: requirePermission("school.add_classdivisionhall"),
  },
  {
    path: "assign-hall/list",
    name: "assign-halllist",
    component: AssignHallList,
    props: true,
    beforeEnter: requirePermission("school.view_classdivisionhall"),
  },
  {
    path: "assign-hall/:id/edit",
    name: "assign-halledit",
    component: AssignHallView,
    props: true,
    beforeEnter: requirePermission("school.change_classdivisionhall"),
  },

  {
    path: "committees/add",
    name: "committeesadd",
    component: CommitteesView,
    props: true,
    beforeEnter: requirePermission("school.add_committees"),
  },
  {
    path: "committees/list",
    name: "committeeslist",
    component: CommitteesList,
    props: true,
    beforeEnter: requirePermission("school.view_committees"),
  },
  {
    path: "committees/:id/edit",
    name: "committeesedit",
    component: CommitteesView,
    props: true,
    beforeEnter: requirePermission("school.change_committees"),
  },
  {
    path: "committee-students/add",
    name: "committeestudentsadd",
    component: CommitteeStudentsView,
    props: false,
    beforeEnter: requirePermission("school.view_seatnumbers"),
  },
  {
    path: "committeesPlan/add",
    name: "committeesPlanadd",
    component: CommitteesPlanView,
    props: true,
    beforeEnter: requirePermission("school.add_settingsstudentcommittees"),
  },
  {
    path: "committeesPlan/list",
    name: "committeesPlanlist",
    component: CommitteesPlanList,
    props: true,
    beforeEnter: requirePermission("school.view_settingsstudentcommittees"),
  },
  {
    path: "committeesPlan/:id/edit",
    name: "committeesPlan",
    component: CommitteesPlanView,
    props: true,
    beforeEnter: requirePermission("school.change_settingsstudentcommittees"),
  },
  {
    path: "schedule",
    name: "schedule",
    component: Schedule,
    beforeEnter: requirePermission("school.view_schedule"),
  },
  {
    path: "discount",
    name: "discount",
    component: Discount,
    beforeEnter: requirePermission("school.view_discountcategories"),
  },
  {
    path: "fees",
    name: "fees",
    component: Fees,
    beforeEnter: requirePermission("school.view_fees"),
  },

  // {
  //   path: "class-document",
  //   name: "class-document",
  //   component: ClassDocument,
  //   beforeEnter: requirePermission("school.view_classdocument"),
  // },
  // {
  //   path: "assign-teacher/add",
  //   name: "assignteacheradd",
  //   component: AssignTeacherView,
  //   beforeEnter: requirePermission("school.add_teachersubjectclass"),
  // },
  // {
  //   path: "assign-teacher/list",
  //   name: "assignteacherview",
  //   component: AssignTeacherList,
  //   beforeEnter: requirePermission("school.view_teachersubjectclass"),
  // },
  // {
  //   path: "assign-teacher/:id/edit",
  //   name: "assignteacheredit",
  //   component: AssignTeacherView,
  //   props: true,
  //   beforeEnter: requirePermission("school.change_teachersubjectclass"),
  // },
  // {
  //   path: "exam-schedule/add",
  //   name: "exam-schedule-add",
  //   component: ExamScheduleView,
  //   beforeEnter: requirePermission("school.add_examschedule"),
  // },
  // {
  //   path: "exam-schedule/list",
  //   name: "exam-schedule-view",
  //   component: ExamScheduleList,
  //   beforeEnter: requirePermission("school.view_examschedule"),
  // },
  // {
  //   path: "exam-schedule/:id/edit",
  //   name: "exam-schedule-edit",
  //   component: ExamScheduleView,
  //   props: true,
  //   beforeEnter: requirePermission("school.change_examschedule"),
  // },
  // {
  //   path: "monthly-marks/add",
  //   name: "monthly-marks-add",
  //   component: MonthlyMarksAdd,
  //   beforeEnter: requirePermission("school.add_marks"),
  // },
  //   {
  //     path:'monthly-marks/list',
  //     name:"monthly-marks-view",
  //     component:MonthlyMarksList,
  //     beforeEnter:requirePermission("school.view_marks"),
  //   },
  // {
  //     path:'monthly-marks/:id/edit',
  //     name:"monthly-marks-edit",
  //     component:MonthlyMarksAdd,
  //     props:true,
  //     beforeEnter:requirePermission("school.change_marks"),

  // },

  // {
  //   path: "halls/add",
  //   name: "hallsadd",
  //   component: HallView,
  //   beforeEnter: requirePermission("school.add_hall"),
  // },
  // {
  //   path: "halls/list",
  //   name: "hallslist",
  //   component: HallList,
  //   props: true,
  //   beforeEnter: requirePermission("school.view_hall"),
  // },
  // {
  //   path: "halls/:id/edit",
  //   name: "hallsedit",
  //   component: HallView,
  //   props: true,
  //   beforeEnter: requirePermission("school.change_hall"),
  // },
  // {
  //   path: "assign-hall/add",
  //   name: "assign-halladd",
  //   component: AssignHallView,
  //   beforeEnter: requirePermission("school.add_classdivisionhall"),
  // },
  // {
  //   path: "assign-hall/list",
  //   name: "assign-halllist",
  //   component: AssignHallList,
  //   props: true,
  //   beforeEnter: requirePermission("school.view_classdivisionhall"),
  // },
  // {
  //   path: "assign-hall/:id/edit",
  //   name: "assign-halledit",
  //   component: AssignHallView,
  //   props: true,
  //   beforeEnter: requirePermission("school.change_classdivisionhall"),
  // },

  // {
  //   path: "committees/add",
  //   name: "committeesadd",
  //   component: CommitteesView,
  //   props: true,
  // },
  // {
  //   path: "committees/list",
  //   name: "committeeslist",
  //   component: CommitteesList,
  //   props: true,
  // },
  {
    path: "committees/:id/edit",
    name: "committeesedit",
    component: CommitteesView,
    props: true,
  },
  // {
  //   path: "committeesPlan/add",
  //   name: "committeesPlanadd",
  //   component: CommitteesPlanView,
  //   props: true,
  // },
  // {
  //   path: "committeesPlan/list",
  //   name: "committeesPlanlist",
  //   component: CommitteesPlanList,
  //   props: true,
  // },
  {
    path: "committeesPlan/:id/edit",
    name: "committeesPlan",
    component: CommitteesPlanView,
    props: true,
  },
  // {
  //   path: "schedule",
  //   name: "schedule",
  //   component: Schedule,
  //   beforeEnter: requirePermission("school.view_schedule"),
  // },
  // {
  //   path: "discount",
  //   name: "discount",
  //   component: Discount,
  //   beforeEnter: requirePermission("school.view_discountcategories"),
  // },
  // {
  //   path: "fees",
  //   name: "fees",
  //   component: Fees,
  //   beforeEnter: requirePermission("school.view_fees"),
  // },

  {
    path: "class-document",
    name: "class-document",
    component: ClassDocument,
    beforeEnter: requirePermission("school.view_classdocument"),
  },
  {
    path: "subjects",
    name: "subjects",
    component: Subject,
    beforeEnter: requirePermission("school.view_subject"),
  },
  {
    path: "library-view",
    name: "library-view",
    component: LibraryView,
    beforeEnter: requirePermission("school.view_library"),
  },
  {
    path: "classes",
    name: "classes",
    component: Classes,
    beforeEnter: requirePermission("school.view_class"),
  },
  {
    path: "semesters",
    name: "semesters",
    component: Semester,
    beforeEnter: requirePermission("school.view_semester"),
  },
  {
    path: "divisions",
    name: "divisions",
    component: Division,
    beforeEnter: requirePermission("school.view_division"),
  },
  {
    path: "academic-years",
    name: "academicYear",
    component: AcademicYear,
    beforeEnter: requirePermission("globaltable.view_yearofstudy"),
  },
  {
    path: "activities",
    name: "activities",
    component: Activity,
    beforeEnter: requirePermission("school.view_activity"),
  },
  {
    path: "lecture-attendance/add",
    name: "lecture_attendance_add",
    component: LectureAttendance,
    beforeEnter: requirePermission("school.add_lecture"),
  },
  {
    path: "lecture-attendance/:id/edit",
    name: "lecture_attendance_edit",
    component: LectureAttendance,
    props: true,
    beforeEnter: requirePermission("school.change_lecture"),
  },
  {
    path: "lecture-attendance/list",
    name: "lecture_attendance_list",
    component: LecturesList,
    beforeEnter: requirePermission("school.view_lecture"),
  },
  {
    path: "weekly-schedule/add",
    name: "weekly_schedule_add",
    component: WeeklyScheduleAdd,
    beforeEnter: requirePermission("school.add_schoolweeklyschedule"),
  },
  {
    path: "weekly-schedule/update",
    name: "weekly_schedule_update",
    component: SchoolWeeklyScheduleUpdate,
    beforeEnter: requirePermission("school.view_schoolweeklyschedule"),
  },
  {
    path: "feeType",
    name: "feeType",
    component: FeeType,
    beforeEnter: requirePermission("school.view_feetype"),
  },
  {
    path: "installment-settings",
    name: "installment-settings",
    component: InstallmentSettings,
    beforeEnter: requirePermission("school.view_installmentsettings"),
  },

  // {
  //   path: "examPeriod",
  //   name: "examPeriod",
  //   component: ExamPeriod,
  // },

  {
    path: "homework/add",
    name: "homework_add",
    component: HomeWorkAdd,
    beforeEnter: requirePermission("school.add_homework"),
  },
  {
    path: "homework/:id/edit/",
    name: "homework_edit",
    component: HomeWorkAdd,
    props: true,
    beforeEnter: requirePermission("school.change_homework"),
  },
  {
    path: "homework/list",
    name: "homework_list",
    component: HomeworkList,
    beforeEnter: requirePermission("school.view_homework"),
  },
  {
    path: "homework/correction",
    name: "homework_correction",
    component: CorrectionHomework,
    beforeEnter: requirePermission("school.view_studenthomework"),
  },
  // {
  //   path: "lecture-attendance/:id/edit",
  //   name: "lecture_attendance_edit",
  //   component: LectureAttendance,
  //   props: true,
  //   beforeEnter: requirePermission("school.view_lectureattendance"),
  // },
  // {
  //   path: "lecture-attendance/list",
  //   name: "lecture_attendance_list",
  //   component: LecturesList,
  //   beforeEnter: requirePermission("school.view_lectureattendance"),
  // },
  // {
  //   path: "weekly-schedule/add",
  //   name: "weekly_schedule_add",
  //   component: WeeklyScheduleAdd,
  //   beforeEnter: requirePermission("school.add_schoolweeklyschedule"),
  // },
  // {
  //   path: "weekly-schedule/update",
  //   name: "weekly_schedule_update",
  //   component: SchoolWeeklyScheduleUpdate,
  //   beforeEnter: requirePermission("school.view_schoolweeklyschedule"),
  // },
  {
    path: "months",
    name: "months",
    component: MonthsView,
    beforeEnter: requirePermission("school.view_month"),
  },
  // {
  //   path: "feeType",
  //   name: "feeType",
  //   component: FeeType,
  //   beforeEnter: requirePermission("school.view_feetype"),
  // },

  {
    path: "parent/add",
    name: "parentadd",
    component: ParentView,
    beforeEnter: requirePermission("school.add_parent"),
  },
  {
    path: "parent/list",
    name: "parentlist",
    component: ParentList,
    beforeEnter: requirePermission("school.view_parent"),
  },
  {
    path: "parent/:id/edit",
    component: ParentView,
    props: true,
    beforeEnter: requirePermission("school.change_parent"),
  },

  {
    path: "examPeriod",
    name: "examPeriod",
    component: ExamPeriod,
    beforeEnter: requirePermission("school.view_examsperiod"),
  },

  {
    path: "homework/add",
    name: "homework_add",
    component: HomeWorkAdd,
    beforeEnter: requirePermission("school.add_homework"),
  },
  {
    path: "homework/:id/edit/",
    name: "homework_edit",
    component: HomeWorkAdd,
    props: true,
    beforeEnter: requirePermission("school.change_homework"),
  },
  // {
  //   path: "homework/list",
  //   name: "homework_list",
  //   component: HomeworkList,
  //   beforeEnter: requirePermission("school.view_homework"),
  // },
  // {
  //   path: "homework/correction",
  //   name: "homework_correction",
  //   component: CorrectionHomework,
  //   beforeEnter: requirePermission("school.view_studenthomework"),
  // },

  {
    path: "student/add",
    name: "studentAdd",
    component: StudentView,
    beforeEnter: requirePermission("school.add_student"),
  },
  {
    path: "student/:id/edit",
    name: "studentEdit",
    component: StudentView,
    props: true,
    beforeEnter: requirePermission("school.change_student"),
  },
  {
    path: "student/list",
    name: "studentList",
    component: StudentList,
    beforeEnter: requirePermission("school.view_student"),
  },
  {
    path: "student-elevate",
    name: "student-elevate",
    component: StudentElevate,
    beforeEnter: requirePermission("school.view_classstudentyear"),
  },
  {
    path: "student-status",
    name: "studentstatus",
    component: StudentStatus,
    beforeEnter: requirePermission("school.change_classstudentyear"),
  },
  {
    path: "student-fee/add",
    name: "studentFeeAdd",
    component: StudentFeeAdd,
    beforeEnter: requirePermission("school.add_studentfee"),
  },
  {
    path: "student-attendance",
    name: "student_attendance",
    component: StudentAttendance,
    beforeEnter: requirePermission("school.add_studentattendance"),
  },
  {
    path: "semester-marks",
    name: "semester_marks",
    component: SemesterMarks,
    beforeEnter: requirePermission("school.view_studentsubject"),
  },
  // {
  //   path: "student-fee/add",
  //   name: "studentFeeAdd",
  //   component: StudentFeeAdd,
  //   beforeEnter: requirePermission("school.add_studentfee"),
  // },
  {
    path: "student-fee/edit",
    name: "StudentFeeUpdate",
    component: StudentFeeUpdate,
    beforeEnter: requirePermission("school.view_studentfee"),
  },
  // beforeEnter: requirePermission("school.change_studentfee"),
  {
    path: "estimate",
    name: "estimate",
    component: Estimate,
    beforeEnter: requirePermission("school.view_estimate"),
  },
  {
    path: "document",
    name: "document",
    component: Document,
    beforeEnter: requirePermission("school.view_document"),
  },
  {
    path: "pay-installment",
    name: "PayInstallment",
    component: PayInstallment,
    beforeEnter: requirePermission("school.view_installmentspayments"),
  },
  {
    path: "student-activity/",
    name: "studetn_activity",
    component: AddStudentActivity,
    beforeEnter: requirePermission("school.view_studentactivity"),
  },
  {
    path: "report/example",
    name: "report",
    component: ReportExample,
  },

  {
    path: "seat-numbers",
    name: "seat_numbers",
    component: SeatNumbers,
    beforeEnter: requirePermission("school.view_seatnumbers"),
  },
  {
    path: "secret-numbers",
    name: "secret_numbers",
    component: SecretNumbers,
    beforeEnter: requirePermission("school.view_seatnumbers"),
  },
  {
    path: "backup",
    name: "backup",
    component: BackUp,
  },
  {
    path: "settings",
    name: "settings",
    component: Settings,
    // beforeEnter: requirePermission("globaltable.change_settingsystem"),
    beforeEnter: requireRole(["programmer", "big_admin"]),
  },
  {
    path: "notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: requirePermission("school.view_notifications"),
  },
  {
    path: "news/add",
    name: "NewsAdd",
    component: NewsView,
    beforeEnter: requirePermission("school.add_news"),
  },
  {
    path: "news/:id/edit",
    name: "NewsEdit",
    component: NewsView,
    props: true,
    beforeEnter: requirePermission("school.change_news"),
  },
  {
    path: "news/list",
    name: "NewsList",
    component: NewsList,
    beforeEnter: requirePermission("school.view_news"),
  },
  {
    path: "archive",
    name: "archive",
    component: Archive,
    beforeEnter: requirePermission("school.view_studentdocument"),
  },

  // {
  //     path: "",
  //     name: "Dashboard",
  //     component: Dashboard,
  //   },
  // {
  //   path: "report/spicific-estimate-in-spicific-month",
  //   name: "spicific_estimate_in_spicific_month",
  //   component: SpicificEstimateInSpicificMonth,
  // },
  // {
  //   path: "report/spicific-estimate-in-spicific-semester",
  //   name: "spicific_estimate_in_spicific_semester",
  //   component: SpicificEstimateInSpicificSemester,
  // },
  // {
  //   path: "report/commission-distr-plan",
  //   name: "commission_distr_plan",
  //   component: CommissionDistrPlan,
  // },
  // {
  //   path: "report/optional-subject",
  //   name: "optional_subject",
  //   component: OptionalSubject,
  // },
  // {
  //   path: "report/optional-subject-student-degree",
  //   name: "optional_subject_student_degree",
  //   component: OptionalSubjectStudentDegree,
  // },
  // {
  //   path: "report/certificate",
  //   name: "certificate",
  //   component: Certificate,
  // },
  // {
  //   path: "report/grade-report",
  //   name: "grade_report",
  //   component: GradeReport,
  // },
  // {
  //   path: "report/certificate-year",
  //   name: "certificate_year",
  //   component: CertificateYear,
  // },
  // {
  //   path: "report/grade-semester-report",
  //   name: "grade_semester_report",
  //   component: GradeSemesterReport,
  // },
  // {
  //   path: "report/month-exam-result",
  //   name: "month_exam_result",
  //   component: MonthExamResult,
  // },
  // {
  //   path: "report/month-exam-result-all",
  //   name: "month_exam_result_all",
  //   component: MonthExamResultAll,
  // },
  // {
  //   path: "report/certificate-en",
  //   name: "certificate_en",
  //   component: CertificateEn,
  // },
  // {
  //   path: "report/certificate-year-en",
  //   name: "certificate_year_en",
  //   component: CertificateYearEn,
  // },
  // {
  //   path: "report/month-exam-result-en",
  //   name: "month_exam_result_en",
  //   component: MonthExamResultEn,
  // },
  // {
  //   path: "test",
  //   name: "test",
  //   component: testMapAction,
  // },
  {
    path: "teacher-committee/add",
    name: "teacher-committeeadd",
    component: TeacherCommitteeView,
    beforeEnter: requirePermission("school.add_teachercommittee"),
  },
  {
    path: "teacher-committee/list",
    name: "teacher-committeelist",
    component: TeacherCommitteeList,
    props: true,
    beforeEnter: requirePermission("school.view_teachercommittee"),
  },
  {
    path: "teacher-committee/:id/edit",
    name: "teacher-committeeedit",
    component: TeacherCommitteeView,
    props: true,
    beforeEnter: requirePermission("school.change_teachercommittee"),
  },
];

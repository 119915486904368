<template>
  <VCard class="justify-center">
    <VForm ref="form">
      <v-card-text class="card-fields" v-if="CheckGetPermission('bus_system.add_bus') || (id && CheckGetPermission('bus_system.change_bus'))">
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" md="5">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="teachers"
                v-model="bus.fk_teacher"
                prepend-inner-icon="mdi-account-tie"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.bus_data.teacher_name')"
                persistent-hint
                density="compact"
                clearable
                :rules="[...teacher_rule, $required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3 d-flex" cols="12" md="4" style="gap: 1rem">
            <VCardItem class="pa-0 flex-grow-1">
              <VAutocomplete
                :items="drivers"
                v-model="bus.fk_driver"
                prepend-inner-icon="mdi-card-account-details"
                item-title="driver_name"
                item-value="id"
                :label="$t('school.bus_data.driver_name')"
                persistent-hint
                density="compact"
                clearable
                :rules="[...driver_rule, $required]"
              ></VAutocomplete>
            </VCardItem>
            <v-btn
              v-if="!id && CheckGetPermission('bus_system.add_driver')"
              @click="add_dialog = !add_dialog"
              class="mt-1 bg-indigo"
              density="default"
              variant="tonal"
            >
              <span class="combined-icons">
                <v-icon class="plus" icon="mdi-plus" size="x-small"></v-icon>
                <v-icon class="card-details" color="white"
                  >mdi-card-account-details</v-icon
                >
              </span>
            </v-btn>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.itinerary"
                prepend-inner-icon="mdi-road-variant"
                :label="$t('school.bus_data.driver_road')"
                :placeholder="$t('school.bus_data.driver_road_placeholder')"
                density="compact"
                type="text"
                clearable
                counter="100"
                :rules="[...alpha_num_rule, $required, $max_length()]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.bus_type"
                prepend-inner-icon="mdi-bus-school"
                :label="$t('school.bus_data.type')"
                :placeholder="$t('school.bus_data.type_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[...alpha_num_rule, $required, $max_length()]"
                counter="100"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.bus_data.number')"
                :placeholder="$t('school.bus_data.number_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[
                  $required,
                  $max_value(100),
                  $min_value(1),
                  ...number_rule,
                ]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.seat_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.bus_data.seat_number')"
                :placeholder="$t('school.bus_data.seat_number_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $max_value(50), $min_value(1)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.color"
                prepend-inner-icon="mdi-palette"
                :label="$t('school.bus_data.color')"
                :placeholder="$t('school.bus_data.color_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[...alpha_num_rule, $required, $max_length(20)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.car_card"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.bus_data.car_card')"
                :placeholder="$t('school.bus_data.car_card_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $max_length(10)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.card_issuance"
                prepend-inner-icon="mdi-map-marker"
                :label="$t('school.driver_data.card_issuance')"
                :placeholder="
                  $t('school.driver_data.card_issuance_placeholder')
                "
                density="compact"
                type="text"
                clearable
                :rules="[$required, $max_length()]"
                counter="100"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="bus.card_expiration"
                :label="$t('school.driver_data.card_expiration')"
                density="compact"
                prepend-inner-icon="mdi-calendar"
                clearable
                :rules="[
                  bus_expiration_date_rule(id ? bus.created_at : date_now),
                  $required,
                  $max_length(),
                ]"
                id="car-ex-date"
                readonly
              >
                <!-- (value) =>
                    this.driver.identity_issuer == undefined ||
                    value > this.driver.identity_issuer ||
                    $t('global.error.validation.expiration_date'), -->
              </VTextField>
              <Datetime
                style="cursor-pointer"
                type="date"
                element="car-ex-date"
                density="compact"
                :color="'#0747a6'"
                @input="(e) => getDate(e, 'car-expiration')"
                :locale="'ar-sa'"
              ></Datetime>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol cols="12" class="pa-0 ma-0" md="9" lg="9">
            <VCardItem class="px-3">
              <VTextarea
                v-model="bus.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </v-card-text>
    </VForm>
    <VCardActions class="mx-5 d-flex flex-wrap" v-if="CheckGetPermission('bus_system.add_bus') || (id && CheckGetPermission('bus_system.change_bus'))">
      <VBtn
        v-if="!id && CheckGetPermission('bus_system.add_bus')"
        class="bg-indigo"
        @click="saveBus"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('bus_system.change_bus')"
        class="bg-success"
        @click="updateBus"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
  </VCard>
  <v-dialog v-model="add_dialog" width="800" class="text-right">
    <v-card>
      <v-card-title
        :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }"
        class="text-right"
      >
        <v-icon style="padding: 0 15px"
          ><span class="combined-icons">
            <v-icon
              class="plus"
              color="white"
              icon="mdi-plus"
              size="x-small"
            ></v-icon>
            <v-icon class="card-details">mdi-card-account-details</v-icon>
          </span></v-icon
        >
        <h3 class="d-inline">{{ $t("school.driver_data.add_driver") }}</h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <v-card-text
        :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }"
      >
        <v-form ref="form2">
          <VRow class="">
            <VCol class="pt-0 px-3" cols="12">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.driver_name"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.driver_data.driver_name')"
                  :placeholder="
                    $t('school.driver_data.driver_name_placeholder')
                  "
                  density="compact"
                  type="text"
                  clearable
                  :rules="[$required, $max_length(100)]"
                  counter="100"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow class="">
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.phone_number"
                  prepend-inner-icon="mdi-cellphone-text"
                  :label="$t('school.driver_data.driver_phone')"
                  :placeholder="
                    $t('school.driver_data.driver_phone_placeholder')
                  "
                  density="compact"
                  clearable
                  :rules="[
                    $required,
                    $numeric,
                    $max_length(14),
                    $min_length(9),
                  ]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.home_number"
                  prepend-inner-icon="mdi-phone-classic"
                  :label="$t('school.driver_data.driver_home_no')"
                  :placeholder="
                    $t('school.driver_data.driver_home_no_placeholder')
                  "
                  density="compact"
                  type="number"
                  clearable
                  :rules="[
                    $required,
                    $numeric,
                    $max_length(14),
                    $min_length(6),
                  ]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.age"
                  prepend-inner-icon="mdi-account-alert"
                  :label="$t('school.driver_data.driver_age')"
                  :placeholder="$t('school.driver_data.driver_age_placeholder')"
                  density="compact"
                  type="number"
                  clearable
                  :rules="[$required, $max_value(100), $min_value(18)]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="maritalStatus"
                  v-model="driver.martial_status"
                  prepend-inner-icon="mdi-account-alert"
                  item-title="name"
                  item-value="id"
                  :label="$t('school.driver_data.driver_martial_status')"
                  persistent-hint
                  density="compact"
                  clearable
                  :rules="[$required]"
                  counter="100"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.email"
                  prepend-inner-icon="mdi-cellphone-text"
                  :label="$t('school.driver_data.driver_email')"
                  :placeholder="
                    $t('school.driver_data.driver_email_placeholder')
                  "
                  density="compact"
                  type="text"
                  clearable
                  :rules="[$email, $max_length(64)]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.address"
                  prepend-inner-icon="mdi-map-marker"
                  :label="$t('school.driver_data.address')"
                  :placeholder="$t('school.driver_data.address_placeholder')"
                  density="compact"
                  type="text"
                  clearable
                  :rules="[$required, $max_length(100), $min_length(6)]"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <!-- <v-divider style="border-width: -1px; opacity: 0.25" class="my-6" /> -->
          <VRow class="">
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="identify_type"
                  v-model="driver.identity_type"
                  prepend-inner-icon="mdi-card-account-details"
                  item-title="name"
                  item-value="id"
                  :label="$t('school.driver_data.card_type')"
                  persistent-hint
                  density="compact"
                  clearable
                  :rules="[$required]"
                  counter="100"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.identity_number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('school.driver_data.card_no')"
                  :placeholder="$t('school.driver_data.card_no_placeholder')"
                  density="compact"
                  type="number"
                  clearable
                  :rules="[$required, $max_length(14)]"
                  counter="14"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="driver.identity_issuance"
                  prepend-inner-icon="mdi-map-marker"
                  :label="$t('school.driver_data.card_issuance')"
                  :placeholder="
                    $t('school.driver_data.card_issuance_placeholder')
                  "
                  density="compact"
                  type="text"
                  clearable
                  :rules="[$required, $max_length(100)]"
                  counter="100"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow class="">
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  ref="date1"
                  v-model="driver.identity_issuer"
                  :label="$t('school.driver_data.card_issuer')"
                  density="compact"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  :rules="[...issuer_date_rule, $required, $max_length(100)]"
                  id="iss-date"
                >
                </VTextField>
                <Datetime
                  style="cursor-pointer"
                  type="date"
                  element="iss-date"
                  density="compact"
                  :color="'#0747a6'"
                  @input="(e) => getDate(e, 'issuer')"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('school.driver_data.card_issuer')"
                ></Datetime>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  ref="date"
                  v-model="driver.identity_expiration"
                  :label="$t('school.driver_data.card_expiration')"
                  density="compact"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  :rules="[...expiration_date_rule, $required]"
                  id="ex-date"
                >
                </VTextField>
                <Datetime
                  style="cursor-pointer"
                  type="date"
                  element="ex-date"
                  density="compact"
                  :color="'#0747a6'"
                  @input="(e) => getDate(e, 'expiration')"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('school.driver_data.card_expiration')"
                >
                </Datetime>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow class="">
            <VCol cols="12" class="pa-0 ma-0" md="12" lg="12">
              <VCardItem class="px-3">
                <VTextarea
                  v-model="driver.note"
                  prepend-inner-icon="mdi-note-outline"
                  clearable
                  :label="$t('global.note')"
                  counter="250"
                  no-resize
                  density="compact"
                  rows="3"
                  :rules="[$max_length(250)]"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <VBtn class="mx-3" size="small" @click="resetForm2">
          {{ $t("global.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn
          v-if="!id && CheckGetPermission('bus_system.add_driver')"
          class="bg-indigo"
          @click="saveDriver"
          size="small"
          :loading="is_loading"
        >
          <span class="text-white">
            {{ $t("global.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  // components: { Datetime },
  data() {
    return {
      index: "",
      buses_records: [],
      add_dialog: false,
      overlay: false,
      update: false,
      alert: false,
      alertTitle: "",
      alertMessage: "",
      alertIcon: "",
      showAlert: false,
      date_now: undefined,
      bus: {
        fk_teacher: undefined,
        fk_driver: undefined,
        itinerary: undefined,
        bus_type: undefined,
        number: undefined,
        seat_number: undefined,
        color: undefined,
        car_card: undefined,
        card_issuance: undefined,
        card_expiration: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      driver: {
        driver_name: undefined,
        phone_number: undefined,
        home_number: undefined,
        email: undefined,
        age: undefined,
        martial_status: undefined,
        identity_type: undefined,
        identity_number: undefined,
        identity_issuance: undefined,
        identity_issuer: undefined,
        identity_expiration: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_loading: false,
      // locale: {
      //   name: "ar-sa",
      //   weekdays: ["we", "wd", "xc", "fhg", "fg", "sa", "sfd"],
      //   months: [
      //     "y",
      //     "f",
      //     "m",
      //     "u",
      //     "u",
      //     "sd",
      //     "sds",
      //     "gfg",
      //     "hgh",
      //     "hgj",
      //     "ku",
      //     "ui",
      //   ],
      //   pickers: {
      //     select: "اختر",
      //     cancel: "إلغاء",
      //     now: "الآن",
      //   },
      //   placeholders: {
      //     date: "ليب",
      //     time: "يبل",
      //     datetime: "يبل",
      //   },
      // },
      alpha_num_rule: [
        (value) =>
          isNaN(value) ||
          this.$t("global.error.validation.numbers_and_letters"),
      ],
      teacher_rule: [
        (value) =>
          this.checkTeacher(value) || this.$t("school.bus_data.teacher_exist"),
      ],
      driver_rule: [
        (value) =>
          this.checkDriver(value) || this.$t("school.bus_data.driver_exist"),
      ],
      number_rule: [
        (value) =>
          this.checkNumber(value) || this.$t("school.bus_data.number_exist"),
      ],
      bus_expiration_date_rule: (date) => (value) =>
        value > date ||
        this.$t("global.error.validation.min_date") +
          (this.id ? date.split("T")[0] : date),
      expiration_date_rule: [
        (value) =>
          this.driver.identity_issuer == undefined ||
          value > this.driver.identity_issuer ||
          this.$t("global.error.validation.expiration_date"),
        // (value) =>
        //   value > this.date_now ||
        //   this.$t("global.error.validation.min_date") + this.date_now,
      ],
      issuer_date_rule: [
        // (value) =>
        //   value <= this.date_now ||
        //   this.$t("global.error.validation.max_date") + this.date_now,
        (value) =>
          this.driver.identity_expiration == undefined ||
          value < this.driver.identity_expiration ||
          this.$t("global.error.validation.issuer_date"),
      ],
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllTeachers();
      await this.getAllDrivers();
      await this.getIdentifyType();
      await this.getMaritalStatus();
      this.getBus();
      let date = new Date();
      this.date_now = date.toISOString().split("T")[0];

      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}bus/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.bus = response.data;
            this.original_bus = { ...response.data };
            this.bus.card_expiration = new Date(response.data.card_expiration)
              .toISOString()
              .split("T")[0];
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      teachers: (state) => state.school.teachers,
      drivers: (state) => state.school.drivers,
      identify_type: (state) => state.globals.identify_type,
      maritalStatus: (state) => state.globals.maritalStatus,
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllTeachers: "school/getAllTeachers",
      getAllDrivers: "school/getAllDrivers",
      getIdentifyType: "globals/getIdentifyType",
      getMaritalStatus: "globals/getMaritalStatus",
    }),
    checkTeacher(value) {
      let validated = true;
      this.buses_records.find((element) => {
        if (
          (this.original_bus == undefined ||
            this.original_bus.fk_teacher != value) &&
          element.fk_teacher == value
        )
          validated = false;
      });
      return validated;
    },

    checkNumber(value) {
      let validated = true;
      this.buses_records.find((element) => {
        if (
          (this.original_bus == undefined ||
            this.original_bus.number != value) &&
          element.number == value
        )
          validated = false;
      });
      return validated;
    },
    getDate(e, target) {
      let date = this.toEnglishString(e);
      switch (target) {
        case "issuer":
          this.driver.identity_issuer = date;
          break;
        case "expiration":
          this.driver.identity_expiration = date;
          break;
        case "car-expiration":
          this.bus.card_expiration = date;
          break;
      }
      // target = date;
      if (this.driver.identity_issuer && this.driver.identity_expiration) {
        this.$refs.date.validate();
        this.$refs.date1.validate();
      }
    },
    checkDriver(value) {
      let validated = true;
      this.buses_records.find((element) => {
        if (
          (this.original_bus == undefined ||
            this.original_bus.fk_driver != value) &&
          element.fk_driver == value
        )
          validated = false;
      });
      return validated;
    },
    resetForm2() {
      this.$refs.form2.reset();
      this.is_update = false;
    },
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "buslist" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
        this.$router.push({ name: "busadd" });
        this.original_bus = undefined;
      }
    },
    async saveDriver() {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "driver/", this.driver, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm2();
            this.getAllDrivers();
            this.add_dialog = false;
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async saveBus() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "bus/", this.bus, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm();
          })
          .catch((error) => {
            try {
              if (error.response.data.fk_teacher) {
                this.$emit(
                  "errorAlert",
                  this.$t("school.bus_data.teacher_exist")
                );
              } else if (error.response.data.fk_driver) {
                this.$emit(
                  "errorAlert",
                  this.$t("school.bus_data.driver_exist")
                );
              } else
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
            } catch {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateBus() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.bus.id != undefined) {
        this.is_loading = true;
        this.bus.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}/bus/${this.bus.id}/`, this.bus, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.resetForm();
            this.$emit("successAlert", this.$t("global.success.save"));
            setTimeout(() => {
              this.$router.push({ name: "buslist" });
            }, 1500);
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async getBus(page = 1) {
      return await this.axios(`${this.base_url}/bus/`, {
        params: {
          page: page,
          page_size: 10000,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }).then((response) => {
        this.buses_records = response.data.results;
      });
    },
    formatDate(date) {
      return date.split("T")[0];
    },
    // changeText() {
    //   var action = document.querySelectorAll(".vpd-actions");
    //   console.log(action);
    //   action[0].innerHTML = "<h3>kdfdkf</h3>";
    // },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>

<template>
  <VCard class="justify-center">
    <VCardText class="card-fields">
      <VRow>
        <VCol cols="12" md="8">
          <VForm ref="form">
            <VRow justify="center" class="mt-4">
              <VCol cols="12" md="6">
                <VAutocomplete
                  v-model="group.id"
                  color="indigo"
                  density="compact"
                  item-title="name"
                  item-value="pk"
                  prepend-inner-icon="mdi-account-group"
                  clearable
                  :items="group_list"
                  @update:modelValue="isUpdate"
                  :label="$t('users.user.group')"
                />
              </VCol>
              <VCol cols="12" md="6">
                <VTextField
                  v-model="group.name"
                  color="indigo"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('users.user.group_name')"
                  :rules="[$required, $max_length(100)]"
                />
              </VCol>
            </VRow>
          </VForm>
          <VCardActions
            v-if="
              CheckGetPermission('auth.add_group') ||
              (is_update && CheckGetPermission('auth.change_group')) ||
              (is_update && CheckGetPermission('auth.delete_group'))
            "
          >
            <VBtn
              v-if="!is_update && CheckGetPermission('auth.add_group')"
              @click="saveGroup"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.add") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn
              v-if="is_update && CheckGetPermission('auth.change_group')"
              @click="updateGroup"
              class="bg-success"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.edit") }}
              </span>
              <VIcon icon="mdi-pencil" color="white" end></VIcon>
            </VBtn>
            <VBtn
              v-if="is_update && CheckGetPermission('auth.delete_group')"
              @click="del_dialog = true"
              class="bg-error"
              size="small"
            >
              <span class="text-white">
                {{ $t("global.delete") }}
              </span>
              <VIcon icon="mdi-trash-can" color="white" end></VIcon>
            </VBtn>
            <VBtn class="mx-3" size="small" @click="resetForm">
              {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
              <VIcon
                :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
                :color="!is_update ? 'golden' : 'gray'"
                end
              ></VIcon>
            </VBtn>
            <!-- <VBtn @click="resetForm" class="mx-3" size="small">
              {{ $t("global.clear") }}
              <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn> -->
          </VCardActions>
        </VCol>
      </VRow>
    </VCardText>
    <!-- ##########    data Table Server  ########### -->

    <VDivider class="my-2" />
    <!-- <v-container>
      <v-data-table-virtual
        :headers="headers"
        :items="permission_list"
        height="400"
        item-value="table"
      >
        
        <template v-slot:item="{ item }">
            <tr>
          <td>
            <VCheckbox
              color="indigo"
              hide-details="true"
              v-model="item.is_all"
              density="compact"
              @update:modelValue="checkRow(item)"
              :label="$t('users.permission.' + item.value)"
            >
            </VCheckbox>
          </td>
          <td class="text-center border">
            <VCheckbox
              color="indigo"
              hide-details="true"
              v-model="item.is_add"
              density="compact"
              @update:modelValue="addPermission(item.add)"
            ></VCheckbox>
          </td>
          <td class="text-center border">
            <VCheckbox
              color="indigo"
              hide-details="true"
              v-model="item.is_change"
              density="compact"
              @update:modelValue="addPermission(item.change)"
            ></VCheckbox>
          </td>
          <td class="text-center border">
            <VCheckbox
              color="indigo"
              hide-details="true"
              v-model="item.is_delete"
              density="compact"
              @update:modelValue="addPermission(item.delete)"
            ></VCheckbox>
          </td>
          <td class="text-center border">
            <VCheckbox
              color="indigo"
              hide-details="true"
              v-model="item.is_view"
              density="compact"
              @update:modelValue="addPermission(item.view)"
            ></VCheckbox>
          </td>

            </tr>
        </template>
      </v-data-table-virtual>
    </v-container> -->
    <v-container>
      <!-- height="500px" -->
      <v-table height="60vh" fixed-header>
        <thead>
          <tr>
            <th>
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="check_all"
                density="compact"
                @update:modelValue="checkAll()"
                :label="$t('users.permission.permissions')"
              >
              </VCheckbox>
              <!-- <input v-model="check_all" type="checkbox" @change="checkAll()"/> -->
              <!-- الصلاحيات -->
            </th>
            <th>{{ $t("users.permission.add") }}</th>
            <th>{{ $t("users.permission.change") }}</th>
            <th>{{ $t("users.permission.delete") }}</th>
            <th>{{ $t("users.permission.view") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!permission_list.length">
            <td colspan="5" class="text-center">{{ $t("global.loading") }}</td>
          </tr>
          <tr v-for="item in permission_list" :key="item" class="">
            <td class="text-center" style="max-width: 100px">
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="item.is_all"
                density="compact"
                @update:modelValue="checkRow(item)"
                :label="$t('users.permission.' + item.table)"
              >
              </VCheckbox>
              <!-- <input v-model="item.is_all" type="checkbox" @change="checkRow(item)"/> -->
              <!-- {{$t("permission."+item.table)}} -->
            </td>
            <td class="text-center">
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="item.is_add"
                density="compact"
                @update:modelValue="addPermission(item.add, item)"
              ></VCheckbox>
            </td>
            <td class="text-center">
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="item.is_change"
                density="compact"
                @update:modelValue="addPermission(item.change, item)"
              ></VCheckbox>
            </td>
            <td class="text-center">
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="item.is_delete"
                density="compact"
                @update:modelValue="addPermission(item.delete, item)"
              ></VCheckbox>
            </td>
            <td class="text-center">
              <VCheckbox
                color="indigo"
                hide-details="true"
                v-model="item.is_view"
                :disabled="item.is_view"
                density="compact"
                @update:modelValue="addPermission(item.view, item)"
              ></VCheckbox>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-container>

    <!-- ############### Dialog Delete  ################### -->
    <VDialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <VCard>
        <VCardTitle>
          {{ $t("global.confirmation") }}
        </VCardTitle>
        <VCardText>
          {{ $t("global.alert.confirm_delete") }}
        </VCardText>
        <VCardActions>
          <VBtn color="info" @click="del_dialog = false">{{
            $t("global.cancel")
          }}</VBtn>
          <VBtn color="red" @click="deleteGroup()">{{
            $t("global.delete")
          }}</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <!-- ############### end Dialog Delete ################### -->
  </VCard>
</template>

<script>
// import useValidate from "@vuelidate/core";
// import { mapState, mapActions } from "vuex";
// import {
//   email,
//   required,
//   helpers,
//   maxLength,
//   url,
//   alpha,
//   alphaNum,
//   numeric,
// } from "@vuelidate/validators";
// import ImageInput from "@/components/Libraries/ImageInput.vue";
import usersModule from "@/store/Users/users.module";

export default {
  name: "AddClasses",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGroups();
      await this.getPermissions();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      test: 300,
      //   v$: useValidate(),
      group_list: [],
      all_permission_list: undefined,
      // exclude:['session','contenttype','logentry','accounts','analystaccount','bank','box','costcenter','currency','dailyoperation','dailyoperationdetails','dailyoperationorder','dailyoperationorderdetails','deleteddailyoperationorder','deletedreceiptorder','doctype','financialperiods','mainaccounts','openingaccountbalance','openinganalystbalance','period','receipt','receiptorder','studentaccount','classsubject','yearofstudy','classstudentyear'],
      exclude: [
        "admin",
        "contenttype",
        "session",
        "financial",
        "sessionuser",
        "summer_center",
        "django_apscheduler",
        "rating",
        // "yearofstudy",
        // "classstudentyear",
        "settingsystem",
        "groupextra",
        "userauthority",
        "branchmonth",
        "branchsemester",
      ],
      permission_list: [],
      default_permission: [],
      check_all: false,
      is_update: false,
      del_dialog: false,
      group_del_id: undefined,
      group: {
        permissions: [],
        name: undefined,
        id: undefined,
      },
      is_loading: false,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    async getGroups() {
      let group = await this.axios.get(`${this.base_url}groups/`, {
        params: { system_type: 1 },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (group) this.group_list = group.data;
    },
    getPermissions() {
      this.axios
        .get(`${this.base_url}permission/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((responce) => {
          this.all_permission_list = responce.data;
          if (this.all_permission_list != undefined) {
            this.all_permission_list.forEach((element) => {
              if (
                !(
                  this.exclude.includes(element.content) ||
                  this.exclude.includes(element.content_label)
                )
              ) {
                if (element.codename.search("view") != -1) {
                  this.default_permission.push(element.id);
                }
              }
            });
            this.permission_list = this.orderPermissions(
              this.all_permission_list,
              this.exclude,
              this.default_permission
            );
            this.group.permissions = [...this.default_permission];
          }
        })
        .catch((error) => {});
    },
    orderPermissions(permissions, exclude = [], selected = []) {
      let list = [];
      let current = 0;
      let obj = {};
      permissions.forEach((element) => {
        if (
          exclude.includes(element.content) ||
          exclude.includes(element.content_label)
        )
          return 0;
        if (element.content_type != current && current != 0) {
          if (obj.is_add && obj.is_change && obj.is_delete && obj.is_view)
            obj.is_all = true;
          else obj.is_all = false;
          list.push(obj);
          obj = {};
          current = element.content_type;
        }
        if (!obj.add) {
          obj.add = element.id;
          obj.table = element.content;
          obj.is_add = selected.includes(element.id);
          obj.is_all;
          current = element.content_type;
        } else if (!obj.change) {
          obj.change = element.id;
          obj.is_change = selected.includes(element.id);
        } else if (!obj.delete) {
          obj.delete = element.id;
          obj.is_delete = selected.includes(element.id);
        } else {
          obj.view = element.id;
          obj.is_view = selected.includes(element.id);
        }
      });
      if (obj.is_add && obj.is_change && obj.is_delete && obj.is_view)
        obj.is_all = true;
      else obj.is_all = false;
      list.push(obj);
      return list;
    },
    addPermission(id, item = 0) {
      if (this.group.permissions.includes(id))
        this.group.permissions.splice(this.group.permissions.indexOf(id), 1);
      else this.group.permissions.push(id);
      if (item != 0) {
        if (item.is_add && item.is_change && item.is_delete && item.is_view)
          item.is_all = true;
        else item.is_all = false;
      }
      if (this.group.permissions.length / 4 == this.permission_list.length)
        this.check_all = true;
      else this.check_all = false;
    },
    checkRow(item) {
      if (item.is_all) {
        if (!item.is_add) this.addPermission(item.add);
        if (!item.is_change) this.addPermission(item.change);
        if (!item.is_delete) this.addPermission(item.delete);
        // if (!item.is_view) this.addPermission(item.view);
      } else {
        if (item.is_add) this.addPermission(item.add);
        if (item.is_change) this.addPermission(item.change);
        if (item.is_delete) this.addPermission(item.delete);
        // if (item.is_view) this.addPermission(item.view);
      }
      item.is_change = item.is_all;
      item.is_delete = item.is_all;
      item.is_add = item.is_all;
      // item.is_view = item.is_all;
    },
    checkAll() {
      this.group.permissions = [...this.default_permission];
      if (this.check_all)
        this.permission_list.forEach((element) => {
          this.group.permissions.push(element.add);
          this.group.permissions.push(element.delete);
          this.group.permissions.push(element.change);
          // this.group.permissions.push(element.view);
          element.is_change = true;
          element.is_delete = true;
          element.is_add = true;
          // element.is_view = true;
          element.is_all = true;
        });
      else
        this.permission_list.forEach((element) => {
          element.is_change = false;
          element.is_delete = false;
          element.is_add = false;
          // element.is_view = false;
          element.is_all = false;
        });
    },
    isUpdate() {
      if (this.group.id == undefined) {
        this.is_update = false;
        this.group.name = undefined;
        this.group.permissions = [];
      } else {
        this.is_update = true;
        let group = this.group_list.find((item) => item.pk == this.group.id);
        if (group) {
          this.group.name = group.name;
          this.permission_list = this.orderPermissions(
            this.all_permission_list,
            this.exclude,
            group.permissions
          );
          this.group.permissions = group.permissions;
          if (this.group.permissions.length / 4 == this.permission_list.length)
            this.check_all = true;
          else this.check_all = false;
        }
      }
    },
    async saveGroup() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.group.system_type = 1;
        await this.axios
          .post(`${this.base_url}groups/`, this.group, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.resetForm();
            this.getGroups();
          })
          .catch((error) => {
            if (
              error.response.data["name"][0].includes(
                "group with this name already exists"
              )
            ) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.group_exists")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateGroup() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.group.id != undefined) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}groups/${this.group.id}/`, this.group, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            // this.resetForm();
            // this.getGroups();
          })
          .catch((error) => {
            if (
              error.response.data["name"][0].includes(
                "group with this name already exists"
              )
            ) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.group_exists")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        // this.process_alert = true;
        // setTimeout(() => {
        //   this.process_alert = false;
        // }, 2000);
      }
      this.is_loading = false;
    },
    async deleteGroup() {
      if (this.group.id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}groups/${this.group.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));

            this.resetForm();
            this.getGroups();
            this.del_dialog = false;
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.process_alert = true;
        // setTimeout(() => {
        //   this.process_alert = false;
        // }, 2000);
      }
    },
    resetForm() {
      this.group.permissions = [];
      this.check_all = false;
      this.is_update = false;
      this.group.id = undefined;
      this.group.name = undefined;
      this.$refs.form.reset();
      //   this.v$.group.$reset();
      this.permission_list = this.orderPermissions(
        this.all_permission_list,
        this.exclude,
        this.default_permission
      );
      this.group.permissions = [...this.default_permission];
    },
  },
};
</script>
<style scoped>
.v-table ::-webkit-scrollbar {
  width: 5px;
}
.v-table ::-webkit-scrollbar-track {
  margin-top: 55px;
}
.v-table ::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #c0c0c0;
}

.v-table * {
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0;
}

.v-table {
  border: 1px solid #c1c1c1;
  border-radius: 0.5rem;
}
/* td,
th {
  text-align: start;
} */
.row-hover:hover {
  background: #d4d3d3;
}
</style>

<template>
  <v-locale-provider :locale="locale">
    <v-row
      class="row-nm flex-row-reverse"
      justify="center"
      style="row-gap: 1rem"
    >
      <!-- <v-col v-if="false" cols="12" md="4">
        <v-card class="text-center pb-4 border">
          <v-img :src="require('@/assets/background.jpg')" cover height="100">
          </v-img>
          <v-avatar size="70" class="mt-n9 elevation-2">
            <img
              :src="require('@/assets/profile.jpg')"
              style="height: 100%; width: 100%; object-fit: cover"
            />
          </v-avatar>
          <h3>
            {{ user_data.username }}
          </h3>
          <v-card-subtitle>
            <h5>{{ user_data.role_name }}</h5>
          </v-card-subtitle>
        </v-card>
      </v-col> -->

      <v-col cols="12" md="12">
        <v-card class="border">
          <v-img :src="require('@/assets/background.jpg')" cover height="150">
          </v-img>
          <VTabs
            v-model="tab"
            color="primary"
            fixed-tabs
            align-tabs="center"
            show-arrows
            slider-color="primary"
          >
            <VTab
              v-for="(item, index) in tabs"
              :key="index"
              :value="index"
              max-width=""
            >
              <v-icon class="mx-3">{{ item.icon }}</v-icon>
              {{ item.title }}
            </VTab>
          </VTabs>
          <v-card-text class="my-4" :class="{ 'mt-6 mb-6': breakPointXS }">
            <v-window v-model="tab">
              <v-window-item :value="0">
                <!-- <br />
                <h4 class="text-center grey--text">
                  {{ $t("user.profile-data") }}
                </h4> -->
                <v-row align="center" justify="center" class="mb-2 pb-2">
                  <v-col cols="12" sm="10">
                    <h2 class="text-grey">
                      {{ $t("users.user.account_data") }}
                    </h2>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-text-field
                      readonly
                      v-model="user_data.branch"
                      :label="$t('users.user.branch')"
                      :placeholder="$t('users.user.branch')"
                      density="comfortable"
                      prepend-inner-icon="mdi-office-building"
                      variant="outlined"
                      class="mt-6"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="user_data.full_name"
                      :label="$t('users.user.full_name')"
                      :placeholder="$t('users.user.full_name')"
                      density="comfortable"
                      :disabled="!user_data.full_name"
                      prepend-inner-icon="mdi-abjad-arabic"
                      variant="outlined"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="user_data.username"
                      :label="$t('users.user.username')"
                      :placeholder="$t('users.user.username')"
                      density="comfortable"
                      prepend-inner-icon="mdi-account"
                      variant="outlined"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      color="primary"
                      v-model="user_data.email"
                      :placeholder="$t('users.user.email')"
                      density="comfortable"
                      variant="outlined"
                      :label="$t('global.email')"
                      prepend-inner-icon="mdi-at"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="user_data.role_name"
                      color="primary"
                      :placeholder="$t('users.user.role')"
                      density="comfortable"
                      variant="outlined"
                      :label="$t('users.user.role')"
                      prepend-inner-icon="mdi-shape-outline"
                    >
                    </v-text-field>
                    <!-- <v-text-field
                      readonly
                      v-model="user_data.governorate"
                      color="primary"
                      :placeholder="$t('global.governorate')"
                      density="comfortable"
                      variant="outlined"
                      :label="$t('global.governorate')"
                      prepend-inner-icon="mdi-flag"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="user_data.directorate"
                      color="primary"
                      :placeholder="$t('global.directorate')"
                      density="comfortable"
                      variant="outlined"
                      :label="$t('global.directorate')"
                      prepend-inner-icon="mdi-card-account-details"
                    >
                    </v-text-field> -->
                    <!-- <v-btn
                      color="primary"
                      density="comfortable"
                      dark
                      block
                      disabled
                    >
                      {{ $t("global.save") }}
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="1">
                <v-form ref="form">
                  <v-row align="center" justify="center" class="mt-1 mb-2 pb-2">
                    <!-- <v-col cols="12" md="10">
                      <v-alert
                        v-model="alert"
                        border="top"
                        color="white"
                        type="warning"
                        class="mb-2"
                        border-color="warning"
                        closable
                        elevation="1"
                      >
                        {{ $t("alert.warnning.change-password") }}
                      </v-alert>
                    </v-col> -->
                    <v-col cols="12" sm="10">
                      <h2 class="text-grey">
                        {{ $t("users.user.change_password") }}
                      </h2>
                      <!-- <br />
                      <h4 class="text-center grey--text">
                        {{ $t("user.profile-data") }}
                      </h4> -->
                    </v-col>
                    <v-col cols="12" sm="10" class="mt-6">
                      <v-text-field
                        color="primary"
                        v-model="user_data.old_password"
                        variant="outlined"
                        append-inner-icon="mdi-lock"
                        density="comfortable"
                        :rules="[$required, $max_length(20)]"
                        :error-messages="
                          !not_match ||
                          $t('global.error.validation.wrong_old_password')
                        "
                        :type="showOldPassword ? 'text' : 'password'"
                        :label="$t('users.user.old_password')"
                        @update:model-value="validate()"
                      >
                        <!-- :error-messages="
                          v$.user_data.old_password.$errors.map((e) => e.$message)
                        " -->
                        <template v-slot:prepend-inner>
                          <!-- <v-btn size="small" variant="text"  :disabled="!user_data.old_password" icon density="compact" > -->
                          <v-icon
                            :color="primary"
                            @click="showOldPassword = !showOldPassword"
                            :icon="!showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          />
                          <!-- </v-btn> -->
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field
                        ref="password"
                        color="primary"
                        v-model="user_data.password"
                        variant="outlined"
                        :type="showPassword ? 'text' : 'password'"
                        append-inner-icon="mdi-lock"
                        density="comfortable"
                        :rules="[
                          $required,
                          $min_length(8),
                          $max_length(20),
                          $contain_letter,
                          $contain_number,
                          notSameAs,
                        ]"
                        :label="$t('users.user.new_password')"
                      >
                        <!-- :error-messages="
                          v$.user_data.password.$errors.map((e) => e.$message)
                        " -->
                        <template v-slot:prepend-inner>
                          <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password" icon density="compact" > -->
                          <v-icon
                            @click="showPassword = !showPassword"
                            :icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          />
                          <!-- </v-btn> -->
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field
                        ref
                        color="primary"
                        v-model="user_data.password2"
                        variant="outlined"
                        :type="showPassword2 ? 'text' : 'password'"
                        append-inner-icon="mdi-lock"
                        density="comfortable"
                        :rules="[$required, validate_field ? sameAs : true]"
                        @input="validate_field = false"
                        :label="$t('users.user.confirm_password')"
                      >
                        <!-- :error-messages="
                          v$.user_data.password2.$errors.map((e) => e.$message)
                        " -->
                        <template v-slot:prepend-inner>
                          <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password2" icon density="compact" > -->
                          <v-icon
                            @click="showPassword2 = !showPassword2"
                            :icon="!showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                          />
                          <!-- </v-btn> -->
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-btn
                        :loading="saveProgress"
                        @click="changePassword"
                        block
                        class="bg-primary"
                        density="default"
                      >
                        <span class="text-white">
                          {{ $t("global.save") }}
                        </span>
                        <v-icon
                          icon="mdi-content-save"
                          color="white"
                          end
                        ></v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-locale-provider>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    const items = [this.$t("users.user.profile")];
    this.$store.commit("updateBreadcrumbValue", items);
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getUserProfile();
      // if (localStorage.getItem("must_change_password")) {
      //   this.alert = true;
      //   this.tab = 1;
      // }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      // alert: false,
      showPassword: false,
      showPassword2: false,
      showOldPassword: false,
      saveProgress: false,
      user_data: {
        password: null,
        password2: null,
        old_password: null,
      },
      step: 1,
      validate_field: false,
      tabs: [
        { title: this.$t("users.user.profile"), icon: "mdi-account-circle" },
        {
          title: this.$t("users.user.change_password"),
          icon: "mdi-form-textbox-password",
        },
      ],
      tab: 0,
      user_data: {},
      not_match: false,
      notSameAs: (value) => {
        if (this.user_data?.old_password && value) {
          return (
            this.user_data?.old_password != value ||
            this.$t("global.error.validation.not_same_old_password")
          );
        }
      },
      sameAs: (value) => {
        if (this.user_data.password) {
          return (
            this.user_data.password == value ||
            this.$t("global.error.validation.same_password")
          );
        }
      },
    };
  },
  computed: {
    ...mapActions(["logout"]),
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async validate() {
      if (this.user_data.password) {
        await this.$refs.password.validate();
      }
    },
    async getUserProfile() {
      await this.axios(this.base_url + "api/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.user_data = response.data;
      });
    },
    // ...mapActions(["logout"]),
    async changePassword() {
      // this.v$.$validate();
      this.validate_field = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveProgress = true;
        await this.axios
          .post(`${this.base_url}api/user/change-password`, this.user_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })

          .then((responce) => {
            this.logoutfromsys();
            this.$emit(
              "successAlert",
              this.$t("global.success.change_password")
            );
            this.user_data.password = null;
            this.user_data.old_password = null;
            this.user_data.password2 = null;
            this.saveProgress = false;
            // setTimeout(() => {
            //   this.logout();
            // }, 1500);
            localStorage.removeItem("must_change_password");
          })
          .catch((error) => {
            let error_message = error.response?.data?.non_field_errors;
            if (error_message[0]?.includes("Old password is not correct")) {
              this.not_match = true;
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.change_password")
              );
            }
            this.saveProgress = false;
          });
      }
    },
    async logoutfromsys() {
      // if (this.create_backup) {
      //     this.CreateBackUp();
      // }
      this.is_loading = true;
      await this.axios(`${this.base_url}api/delete/session/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res) => {
        this.logout;
        window.location.reload();
      });
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <v-form
        v-if="CheckGetPermission('school.view_studentsubject')"
        ref="form"
      >
        <v-row>
          <v-col cols="6" md="4">
            <v-autocomplete
              color="indigo"
              v-model="class_marks.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              :label="$t('school.class.select')"
              @update:modelValue="
                (class_marks.fk_division = null),
                  (class_marks.fk_subject = null),
                  getStudents()
              "
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-autocomplete
              color="indigo"
              v-model="class_marks.fk_division"
              density="compact"
              item-title="name"
              item-value="id"
              :items="class_divisions"
              :no-data-text="$t('school.division.not_found')"
              prepend-inner-icon="mdi-format-list-bulleted"
              :label="$t('school.division.select')"
              :rules="[$required]"
              @update:modelValue="getStudents()"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-autocomplete
              color="indigo"
              v-model="class_marks.fk_subject"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-book-open-variant"
              :items="
                class_marks.fk_class
                  ? getSubjectsByClass(class_marks.fk_class)
                  : []
              "
              :label="$t('school.subject.name')"
              :rules="[$required]"
              @update:modelValue="getStudents()"
            />
          </v-col>
          <!-- <v-col cols="6" md="1" class="mt-2">
            <v-btn class="bg-indigo" @click="getStudents()" :loading="loading" size="small">
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
            </v-btn>
          </v-col> -->
        </v-row>
        <!-- 
        <v-row class="mt-4" v-if="exist">
          <v-col cols="6">
            <v-text-field
              color="indigo"
              v-model="search_student"
              @input="searchStudentMarks"
              density="compact"
              append-inner-icon="mdi-magnify"
              prepend-inner-icon="mdi-school"
              :label="$t('global.data_table.search_here')"
            />
          </v-col>
        </v-row> -->
      </v-form>
      <!-- </v-card> -->
    </v-card-text>
    <v-progress-linear
      v-if="loading"
      color="indigo"
      indeterminate
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div
        v-show="!loading && class_marks.fk_subject && class_marks.fk_division"
        class="card-table"
      >
        <v-card>
          <!-- <template v-slot:subtitle>
          <h5  class="text-red">
            لا يمكن تعديل الدرجات الشهرية بعد إدخال الدرجات النصفية وفي حالة تم
            تعديل الدرجات الشهرية بعد إدخال الدرجات النصفية يجب إعادة إدخال
            الدرجات الفصلية
          </h5>
          </template> -->
          <v-card-subtitle>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-banner v-bind="props" class="error-info" density="compact">
                  <template v-slot:prepend>
                    <v-icon color="error" v-bind="props">
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <h4 class="ps-1">لا يمكن تعديل الدرجات الشهرية</h4>
                </v-banner>
              </template>
              <template v-slot:default>
                <h4
                  class="tool text-wrap text-center"
                  style="width: 300px; white-space: break-spaces"
                >
                  لا يمكن تعديل الدرجات الشهرية بعد إدخال الدرجات النصفية وفي
                  حالة تم تعديل الدرجات الشهرية بعد إدخال الدرجات النصفية يجب
                  إعادة إدخال الدرجات الفصلية
                </h4>
              </template>
            </v-tooltip>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-table density="compact">
            <thead>
              <tr>
                <th>#</th>
                <th class="px-0">
                  <v-text-field
                    color="indigo"
                    v-model="search_student"
                    @input="!search_student ? searchStudentMarks() : ''"
                    @keyup.enter="searchStudentMarks()"
                    density="compact"
                    variant="solo-filled"
                    hide-details
                    single-line
                    clearable
                    append-inner-icon="mdi-magnify"
                    prepend-inner-icon="mdi-account-search"
                    :label="$t('school.student.name')"
                  />
                </th>
                <th class="text-center">
                  {{ $t("school.semester_marks.collector") }}
                </th>
                <th class="text-center">
                  {{ $t("school.semester_marks.final_exam") }}
                </th>
                <th class="text-center">{{ $t("global.note") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="exist"
                v-for="(
                  student_subject_mark, index
                ) in class_marks.student_subject_mark"
                :key="student_subject_mark"
              >
                <td>{{ index + 1 }}</td>
                <td style="min-width: 300px">
                  {{ student_subject_mark.student_name }}
                </td>
                <td style="min-width: 100px">
                  <v-text-field
                    class="mt-1"
                    v-model="student_subject_mark.collector"
                    density="compact"
                    disabled
                    type="number"
                    :rules="[$required, $max_value(20), $min_value(0)]"
                    hide-details="auto"
                  >
                  </v-text-field>
                </td>
                <td style="min-width: 100px">
                  <v-text-field
                    class="mt-1"
                    v-model="student_subject_mark.final_exam"
                    density="compact"
                    type="number"
                    style="text-align: center"
                    :rules="[$required, $max_value(30), $min_value(0)]"
                    hide-details="auto"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="student_subject_mark.note"
                    style="min-width: 250px"
                    density="compact"
                    :placeholder="$t('global.note')"
                    hide-details="auto"
                    class="my-1"
                    :rules="[$max_length(250)]"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr v-if="!exist">
                <td colspan="4" class="text-center">
                  {{ this.$t("global.error.failure.message") }}
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions
            v-if="
              CheckGetPermission('school.add_studentsubject') ||
              CheckGetPermission('school.change_studentsubject')
            "
            class="px-5"
          >
            <v-btn
              v-if="
                !update &&
                (CheckGetPermission('school.add_studentsubject') ||
                  CheckGetPermission('school.change_studentsubject'))
              "
              @click="saveClassMarks"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.save") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <!-- <v-btn
                  v-if="update"
                  @click="updateMarks"
                  class="bg-success"
                  size="small"
                  :loading="is_loading"
                >
                  <span class="text-white">
                    {{ $t("global.edit") }}
                  </span>
                  <v-icon
                    icon="mdi-content-save"
                    color="white"
                    end
                  ></v-icon>
                </v-btn> -->
            <v-btn @click="cleanStudentSubjectMark" class="mx-3" size="small">
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.subjects.length) await this.getSubjects({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      class_marks: {
        fk_class: undefined,
        fk_division: undefined,
        fk_subject: undefined,
        fk_student: undefined,
        // fk_semester: undefined,
        // data_entry: undefined,
        student_subject_mark: [],
      },
      valid: false,
      loading: false,
      student_list: [],
      search_student: "",
      exist: false,
      is_loading: false,
      //   collector_rule: [
      //     // (value) => !!value || this.$t('globals.required_field'),
      //     (value) =>
      //       value == undefined ||
      //       /^[-0-9]+$/.test(value) ||
      //       this.$t("globals.must_be_numbers"),
      //     (value) =>
      //       value == undefined ||
      //       value <= 20 ||
      //       `${this.$t("globals.biggest_number_of_character")} 20`,
      //     (value) =>
      //       value == undefined ||
      //       value >= 0 ||
      //       `${this.$t("globals.lowest_number_of_character")} 0`,
      //   ],
      //   final_exam_rule: [
      //     (value) => !!value || this.$t("globals.required_field"),
      //     (value) =>
      //       value == undefined ||
      //       /^[-0-9]+$/.test(value) ||
      //       this.$t("globals.must_be_numbers"),
      //     (value) => !!value || this.$t("errors.required"),
      //     (value) =>
      //       value == undefined ||
      //       value <= 30 ||
      //       `${this.$t("globals.biggest_number_of_character")} 30`,
      //     (value) =>
      //       value == undefined ||
      //       value >= 0 ||
      //       `${this.$t("globals.lowest_number_of_character")} 0`,
      //   ],
      //   note_max_entry: [(value) => value == null || value.length < 250 || ""],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      //   semesters: (state) => state.school.semesters,
      //   months: (state) => state.school.months,
      //   academic_years: (state) => state.school.academic_years,
      //   user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
    }),

    class_divisions() {
      if (this.class_marks.fk_class) {
        return this.getClassDivisions(this.class_marks.fk_class);
      }
    },
    // class_subjects() {
    //   if (this.class_marks.fk_class) {
    //     var class_data = this.classes.find(
    //       (item) => item.id == this.class_marks.fk_class
    //     );
    //     var subjects_data = [];
    //     if (class_data)
    //       class_data.subjects.forEach((element) => {
    //         subjects_data.push({
    //           id: element,
    //           name: this.getSubjectName(element),
    //         });
    //       });
    //   }
    //   return subjects_data;
    // },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getClassAssignHall: "school/getClassAssignHall",
      //   getSemesters: "school/getSemesters",
      //   getMonths: "school/getMonths",
      //   getAcademicYears: "school/getAcademicYears",
    }),
    // checkClassMark() {
    //   this.v$.class_marks.$validate();
    //   if (!this.v$.class_marks.$error) {
    //     this.getStudents();
    //   }
    // },
    searchStudentMarks() {
      if (this.search_student != "") {
        // console.log(this.student_list);
        this.class_marks.student_subject_mark = this.student_list.filter(
          (student) => {
            return student.student_name.includes(this.search_student);
          }
        );
      } else {
        this.class_marks.student_subject_mark = this.student_list;
      }
    },
    async getStudents() {
      let item = this.class_marks;
      if (
        item.fk_class == undefined ||
        item.fk_division == undefined ||
        item.fk_subject == undefined
      )
        return 0;
      // const { valid } = await this.$refs.form.validate();
      // if (valid) {
      this.loading = true;
      this.exist = false;
      await this.axios
        .get(`${this.base_url}api/class-marks/`, {
          params: {
            fk_class: this.class_marks.fk_class,
            fk_division: this.class_marks.fk_division,
            fk_subject: this.class_marks.fk_subject,
            fk_student: this.class_marks.fk_student,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.exist = true;
            this.loading = false;
            this.student_list = response.data;
            this.class_marks.student_subject_mark = response.data;
            // this.student_list.forEach(el=>this.class_marks.student_subject_mark.push(el))
          } else {
            this.loading = false;
            // this.$emit("warningAlert", this.$t("global.error.failure.message"));
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response.data.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          }
          // else {
          //   this.$emit(
          //     "errorAlert",
          //     this.$t("global.error.failure.error_in_data")
          //   );
          // }
        });
      // }
    },
    getSubjectName(id) {
      var subject = this.subjects.find((item) => item.id == id);
      return subject.name_ar;
    },
    async saveClassMarks() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        // this.class_marks.data_entry = this.user;
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/class-marks/`, this.class_marks, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              year: localStorage.getItem("current_year"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.getStudents();
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    cleanStudentSubjectMark() {
      //   this.v$.class_marks.$reset();
      this.$refs.form.reset();
      this.class_marks.student_subject_mark = [];
      this.exist = false;
    },
  },
};
</script>

import i18n from "@/i18n";
import moment from "moment-hijri";

const ValueValidationPlugin = {
  install(Vue) {
    // required validation
    Vue.config.globalProperties.$required = (value) => {
      if (typeof value == "object" && value?.length == 0) value = null;
      return (
        !!value ||
        parseInt(value) == 0 ||
        typeof value === "boolean" ||
        i18n.global.t("global.error.validation.required_field")
      );
    };
    // numeric validation
    Vue.config.globalProperties.$numeric = (value) =>
      !value ||
      /^[0-9+]+$/.test(value) ||
      i18n.global.t("global.error.validation.must_be_numbers");
    // max_length validation
    Vue.config.globalProperties.$max_length =
      (length = 100) =>
      (value) => {
        if (value) {
          if (value && value.length > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? "global.error.validation.max_characters"
                  : "global.error.validation.max_numbers_value"
              ) +
              " " +
              length +
              " " +
              i18n.global.t(
                isNaN(parseInt(value))
                  ? "global.letter"
                  : "global.number_validation"
              )
            }`;
        }
        return true;
      };
    // min_length validation
    Vue.config.globalProperties.$min_length =
      (length = 10) =>
      (value) =>
        !value ||
        (value && value.length >= length) ||
        `${
          i18n.global.t("global.error.validation.lowest_number_of_character") +
          " " +
          length +
          " " +
          i18n.global.t(
            isNaN(parseInt(value)) ? "global.letters" : "global.numbers"
          )
        }`;

    // ar_letters_only validation
    Vue.config.globalProperties.$ar_letters_only = (value) =>
      !value ||
      /^[\u0621-\u064A ]+$/.test(value) ||
      i18n.global.t("global.error.validation.must_be_ar_letters");

    // en_letters_only validation
    Vue.config.globalProperties.$en_letters_only = (value) =>
      !value ||
      /^[a-zA-Z' ]+$/.test(value) ||
      i18n.global.t("global.error.validation.must_be_en_letters");

    // max_date validation
    Vue.config.globalProperties.$max_date =
      (
        { subtract = 0, type = "h" },
        year = type == "h"
          ? moment().locale("en").format("iYYYY-iMM-iDD")
          : new Date(
              new Date().getFullYear() - subtract,
              new Date().getMonth(),
              new Date().getDay()
            )
              .toISOString()
              .split("T")[0]
      ) =>
      (value) =>
        !value ||
        year >= value ||
        `${i18n.global.t("global.error.validation.max_date") + " " + year}`;

    // max_date validation
    Vue.config.globalProperties.$min_date = (year) => (value) =>
      !value ||
      year < value ||
      `${i18n.global.t("global.error.validation.min_date") + " " + year}`;

    // email validation
    Vue.config.globalProperties.$email = (value) =>
      !value ||
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
      i18n.global.t("global.error.validation.email");
    // // greater_than validation
    // Vue.config.globalProperties.$greater = (smaller) => (value) =>
    //   !value ||
    //   (value > smaller) ||
    //   i18n.global.t("global.error.validation.email");
    // max_value validation
    Vue.config.globalProperties.$max_value =
      (length = 10, message = false) =>
      (value) => {
        if (value) {
          if (value && value > length)
            return !message
              ? `${
                  i18n.global.t(
                    isNaN(parseInt(value))
                      ? "global.error.validation.max_characters"
                      : "global.error.validation.max_numbers_value"
                  ) +
                  " " +
                  length
                }`
              : message + " " + length;
        }
        return true;
      };
    // min_value validation
    Vue.config.globalProperties.$min_value =
      (length = 10) =>
      (value) => {
        if (value) {
          if (value && value < length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? "global.error.validation.min_characters"
                  : "global.error.validation.min_numbers_value"
              ) +
              " " +
              length
            }`;
        }
        return true;
      };

    // contain_letter validation
    Vue.config.globalProperties.$contain_letter = (value) =>
      /[A-Za-z]/.test(value) ||
      i18n.global.t("global.error.validation.contain_letter");

    // contain_number validation
    Vue.config.globalProperties.$contain_number = (value) =>
      /\d/.test(value) ||
      i18n.global.t("global.error.validation.contain_number");

    Vue.config.globalProperties.$only_phone_number =
      "return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 43";
    Vue.config.globalProperties.$only_numbers =
      "return event.charCode >= 48 && event.charCode <= 57";
  },
};
export default ValueValidationPlugin;

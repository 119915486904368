<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    width="400px"
    :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
  >
    <v-card>
      <!-- <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text> -->
      <v-card-title>
        <v-row class="row-nm align-center" style="gap: 0.5rem">
          <v-icon v-if="type == 'warning'" color="#e27c8b" size="x-large"
            >mdi-lock-alert-outline</v-icon
          >
          <h3>{{ title ?? $t("global.confirmation") }}</h3>
        </v-row>
        <!-- <v-row class="row-nm align-center" style="gap: 0.5rem"> -->
        <!-- <v-icon color="indigo" size="x-large">mdi-check</v-icon> -->
        <!-- <h3>{{ title }}</h3> -->
        <!-- </v-row> -->
      </v-card-title>
      <v-card-text class="px-5 py-4">
        <h3 v-if="type == 'warning'">
          {{ dialog_message }}
        </h3>
        <h3 v-else>
          {{ dialog_message + " ( " }}
          {{
            subject +
            " ) " +
            " " +
            $t("global.?")
          }}
            <!-- $t("school.lecture.been_taken") + -->
        </h3>
      </v-card-text>
      <v-card-actions class="justify-end">

        <slot name="buttons">
            <v-btn color="indigo" @click="closeDialog(false)">{{
              $t("global.cancel")
            }}</v-btn>
            <v-btn
              :loading="loading"
              variant="text"
              :color="confirmed_color"
              @click="closeDialog(true)"
              >{{ confirmed_text }}</v-btn
            >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- <v-dialog v-model="dialog" max-width="400px" dir="rtl">
    <v-card>
      <v-card-title>
        <v-row class="row-nm align-center" style="gap: 0.5rem">
          <v-icon color="indigo" size="x-large">mdi-check</v-icon>
          <h3>{{ title }}</h3>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="px-5 py-4">
        <h3>
          {{ message + " " }} <v-span>{{ subject }}</v-span>
        </h3>
      </v-card-subtitle>
      <v-card-actions class="px-3 py-2">
        <v-spacer></v-spacer>
        <v-btn
          @click="closeDialog(false)"
          variant="outlined"
          color="indigo"
          density="comfortable"
          rounded="0"
        >
          {{ $t("global.cancel") }}
        </v-btn>
        <v-btn
          @click="closeDialog(true)"
          variant="flat"
          color="indigo"
          density="comfortable"
          rounded="0"
          append-icon="mdi-check-all"
        >
          {{ $t("global.taken") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
</template>

<script>
export default {
  name: "Dialog",
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    subject: {
      type: String,
      default: "",
    },
    confirmed_text: {
      type: String,
      default: "نعم",
    },
    confirmed_color: {
      type: String,
      default: "indigo",
    },
    type: {
      type: String,
      default: "con",
    },
    // value:{
    //     type:Boolean,
    //     default:false
    // }
  },
  data() {
    return {
      dialog: false,
      dialog_message: this.message,
    };
  },
//   created() {
//     switch (this.type) {
//       case "delete":
//         this.dialog_message = "هل ترغب بالحذف";
//         break;

//       default:
//         break;
//     }
//   },
  methods: {
    closeDialog(result) {
      this.dialog = false;
      this.$emit("input", false);
      this.$emit(result ? "confirmed" : "canceled", result);
    },
  },
};
</script>
<style scoped>
/* .v-card-actions .v-btn {
  padding: 0 10px;
}
.v-card-title {
  background-color: #2196f31f;
} */
</style>

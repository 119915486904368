<template>
  <VCard class="justify-center">
    <v-expand-transition v-if="getUserRole < 2">
      <div v-show="(update && getUserRole < 2) || getUserRole < 2">
        <VCard-text
          class="card-fields"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            ((is_update && CheckGetPermission('school.change_estimate')) ||
              CheckGetPermission('school.add_estimate'))
          "
        >
          <VForm ref="form">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="estmation.name_ar"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('school.estimate.name_ar')"
                    :placeholder="$t('school.estimate.name_ar_placeholder')"
                    density="compact"
                    type="text"
                    clearable
                    :rules="[
                      $required,
                      $max_length(100),
                      $ar_letters_only,
                      uniqueEstimate,
                    ]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="estmation.name_en"
                    prepend-inner-icon="mdi-alpha-e"
                    :label="$t('school.estimate.name_en')"
                    :placeholder="$t('school.estimate.name_en_placeholder')"
                    density="compact"
                    type="text"
                    clearable
                    :rules="[
                      $required,
                      $max_length(100),
                      $en_letters_only,
                      uniqueEstimate,
                    ]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    ref="min"
                    v-model="estmation.degree_min"
                    prepend-inner-icon="mdi-chevron-double-down"
                    :label="$t('school.estimate.degree_min')"
                    :placeholder="$t('school.estimate.degree_min_placeholder')"
                    density="compact"
                    type="number"
                    clearable
                    :rules="[
                      $required,
                      $max_value(100),
                      $min_value(0),
                      max_score_rule,
                      notBetweenValidate(estmation.id),
                    ]"
                    @input="validate()"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    ref="max"
                    v-model="estmation.degree_max"
                    prepend-inner-icon="mdi-chevron-double-up"
                    :label="$t('school.estimate.degree_max')"
                    :placeholder="$t('school.estimate.degree_max_placeholder')"
                    density="compact"
                    type="number"
                    clearable
                    :rules="[
                      $required,
                      $max_value(100),
                      $min_value(0),
                      min_score_rule,
                      notBetweenValidate(estmation.id),
                    ]"
                    @input="validate()"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="8" lg="8">
                <VCardItem class="px-3">
                  <VTextarea
                    v-model="estmation.note"
                    clearable
                    :label="$t('global.note')"
                    prepend-inner-icon="mdi-note-outline"
                    counter="250"
                    no-resize
                    density="compact"
                    rows="3"
                    :rules="[$max_length(250)]"
                  >
                  </VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VForm>
        </VCard-text>
        <VCardActions
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            ((is_update && CheckGetPermission('school.change_estimate')) ||
              CheckGetPermission('school.add_estimate'))
          "
          class="px-8 d-flex flex-wrap"
          elevation="4"
        >
          <VBtn
            v-if="
              !is_update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.add_estimate')
            "
            class="bg-indigo"
            @click="saveEstimation"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.change_estimate')
            "
            class="bg-success"
            @click="updateEstimation"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!is_update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <VBtn class="mx-3" size="small" @click="resetForm">
            {{ $t("global.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn> -->
        </VCardActions>
        <v-divider class="mt-5" />
      </div>
    </v-expand-transition>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span class="me-1" style="color: #e5b254; border-radius: 4px">
                  <v-icon
                    v-if="
                      checkRole(['programmer', 'big_admin']) &&
                      CheckGetPermission('school.change_estimate')
                    "
                    @click="editEstimation(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span style="color: #d05251; border-radius: 4px">
                  <v-icon
                    v-if="
                      checkRole(['programmer', 'big_admin']) &&
                      CheckGetPermission('school.delete_estimate')
                    "
                    @click="
                      (estimate_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (estimate_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteEstimation">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      is_loading: false,
      estmation: {
        name_ar: undefined,
        name_en: undefined,
        degree_max: undefined,
        degree_min: undefined,

        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      del_dialog: false,
      estimate_del_id: undefined,
      original: null,
      headers: [
        { title: this.$t("school.estimate.name_ar"), key: "name_ar" },
        { title: this.$t("school.estimate.name_en"), key: "name_en" },
        { title: this.$t("school.estimate.degree_max"), key: "degree_max" },
        { title: this.$t("school.estimate.degree_min"), key: "degree_min" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "name_en",
        "degree_max",
        "degree_min",
        "actions",
      ],
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      txt_search: undefined,
      search: null,
      uniqueEstimate: (value) => {
        if (
          this.tableList.some(
            (item) =>
              (item.name_ar.trim() === value.trim() &&
                this.original?.name_ar?.trim() != value.trim()) ||
              (item.name_en.trim().toLowerCase() ===
                value.trim().toLowerCase() &&
                this.original?.name_en?.trim().toLowerCase() !=
                  value.trim().toLowerCase())
          )
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },
      min_score_rule: (value) =>
        !this.estmation.degree_min ||
        parseInt(value) > parseInt(this.estmation.degree_min) ||
        this.$t("global.error.validation.degree_error"),
      max_score_rule: (value) =>
        !this.estmation.degree_max ||
        parseInt(value) < parseInt(this.estmation.degree_max) ||
        this.$t("global.error.validation.degree_error"),
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      if (!this.estimates.length) await this.getAllEstimations();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      estimates: (state) => state.school.estimates,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.estimates.forEach((element) => {
        list.push({
          name_ar: element.name_ar,
          name_en: element.name_en,
          degree_max: element.degree_max,
          degree_min: element.degree_min,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getAllEstimations: "school/getAllEstimations",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.estmation.id = undefined;
      this.original = [];
      //   this.updated_id = undefined;
    },
    editEstimation(data) {
      let estimates = this.estimates.find((item) => item.id == data.id);
      this.original = { ...data };
      if (estimates != undefined) {
        for (const key in estimates) this.estmation[key] = estimates[key];
        this.estmation.data_entry = undefined;
        this.estmation.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveEstimation() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // if(!this.v$.estmation.$error){
        let result = await this.axios
          .post(this.base_url + "/estimate/", this.estmation, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.resetForm();
        this.getAllEstimations();
      }
      this.is_loading = false;
    },
    async updateEstimation() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid && this.estmation.id != undefined) {
        this.is_loading = true;
        // this.estmation.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/estimate/${this.estmation.id}/`,
            this.estmation,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.resetForm();
        this.getAllEstimations();
      }
      this.is_loading = false;
    },
    async deleteEstimation() {
      if (this.estimate_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/estimate/${this.estimate_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.estimate_del_id = undefined;
        this.getAllEstimations();
      }
    },
    notBetweenValidate(id) {
      // if (this.estmation.degree_max && this.estmation.degree_min) {
      const value1 = parseFloat(this.estmation.degree_max);
      const value2 = parseFloat(this.estmation.degree_min);
      let not_between = this.estimates.find((item) => {
        if (item.id != id) {
          const { degree_max, degree_min } = item;
          return (
            (value1 <= degree_max && value1 >= degree_min) ||
            (value2 <= degree_max && value2 >= degree_min)
          );
        }
      });
      return (
        !not_between ||
        this.$t("global.error.validation.interfering") +
          " " +
          (this.$i18n.locale == "ar"
            ? not_between.name_ar
            : not_between.name_en)
      );
      // }
    },
    validate() {
      if (this.estmation.degree_max >= 0 && this.estmation.degree_min >= 0) {
        setTimeout(() => {
          this.$refs.max.validate();
          this.$refs.min.validate();
        }, 100);
      }
    },
  },
};
</script>

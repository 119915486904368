<template>
  <VCard class="justify-center">
    <v-form ref="form">
      <v-card-text class="px-5 pb-5">
        <v-list
          select-strategy="leaf"
          class="d-flex flex-column"
          lines="one"
          style="overflow: hidden; gap: 1rem"
        >
          <!-- style="overflow: hidden;" -->
          <fieldset class="border">
            <legend class="ms-2 px-2">
              {{ this.$t("school.settings.date_settings") }}
            </legend>
            <v-row class="pa-8">
              <VCol cols="12" lg="6" md="6" sm="12">
                <VRow>
                  <VCol cols="12">
                    <VAutocomplete
                      v-model="settings.current_year"
                      :label="this.$t('school.settings.current_year')"
                      :placeholder="
                        this.$t('school.settings.current_year_placholder')
                      "
                      item-value="id"
                      :item-title="settings.date_type ? 'year' : 'year_m'"
                      :items="academicYears"
                      hide-details="auto"
                      :hint="this.$t('school.settings.current_year_hint')"
                      persistent-hint
                      prepend-inner-icon="mdi-calendar-cursor"
                      :rules="[$required]"
                    >
                      <template v-slot:append class="pa-0">
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-btn
                            @click="dialoge = !dialoge"
                            class="bg-indigo"
                            density="default"
                            variant="tonal"
                          >
                            <v-icon
                              size="large"
                              color="white"
                              icon="mdi-calendar-plus"
                            >
                            </v-icon>
                          </v-btn>
                        </v-slide-x-reverse-transition>
                      </template>
                    </VAutocomplete>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" lg="6" md="6" sm="12">
                <v-list-subheader>{{
                  $t("school.settings.date_type")
                }}</v-list-subheader>
                <v-radio-group
                  class="px-5"
                  hide-details
                  v-model="settings.date_type"
                  inline
                >
                  <v-radio
                    :label="$t('school.settings.hijri')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('school.settings.gregorian')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </VCol>
            </v-row>
            <!--</fieldset> 
          <fieldset>
            <legend class="mr-2 px-2">{{ this.$t('school.settings.current_year') }}</legend>-->
          </fieldset>
          <fieldset class="border">
            <legend class="mr-2 px-2">
              {{ this.$t("school.settings.general") }}
            </legend>
            <v-row class="pa-6">
              <VCol cols="12" lg="6" md="6" sm="12">
                <v-list-subheader>{{
                  $t("school.settings.attendance_times")
                }}</v-list-subheader>
                <v-radio-group
                  class="px-5"
                  hide-details
                  v-model="settings.attendance_twice"
                  inline
                >
                  <v-radio
                    :label="$t('school.settings.once')"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    :label="$t('school.settings.twice')"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </VCol>
            </v-row>
          </fieldset>
        </v-list>
        <!-- <VContainer> -->
        <VDialog v-model="dialoge" width="500" class="text-right" dir="rtl">
          <v-card class="justify-center">
            <v-card-title>
              <v-icon icon="mdi-calendar-plus" />
              <h3 class="d-inline" style="padding: 0 4px">
                {{ $t("school.academic_year.add") }}
              </h3>
              <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
            </v-card-title>
            <v-card-text class="mb-4">
              <v-form ref="form2" @submit.prevent="saveAcademicYear">
                <v-row class="mt-4">
                  <v-col cols="6">
                    <v-text-field
                      color="indigo"
                      v-model="academicYear.year"
                      :placeholder="
                        $t('school.academic_year.placeholder-year-h')
                      "
                      clearable
                      counter="10"
                      density="compact"
                      prepend-inner-icon="mdi-calendar-range"
                      :label="$t('school.academic_year.name-h')"
                      :rules="[$required, $max_length(10), uniqueYear]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      color="indigo"
                      v-model="academicYear.year_m"
                      clearable
                      counter="10"
                      :placeholder="
                        $t('school.academic_year.placeholder-year-m')
                      "
                      density="compact"
                      prepend-inner-icon="mdi-calendar-range"
                      :label="$t('school.academic_year.name-m')"
                      :rules="[$required, $max_length(10), uniqueYear]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      color="indigo"
                      v-model="academicYear.note"
                      hide-details="auto"
                      density="compact"
                      counter="250"
                      clearable
                      prepend-inner-icon="mdi-note-outline"
                      :label="$t('global.note')"
                      :rules="[$max_length(250)]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <!-- <v-row class="justify-center px-5">
              <v-col cols="12"> -->
            <!-- <v-card class="pa-2 ma-auto"> -->
            <v-card-actions class="mt-4 px-8">
              <v-btn
                v-if="
                  !update && CheckGetPermission('globaltable.add_yearofstudy')
                "
                @click="saveAcademicYear()"
                class="bg-indigo"
                size="small"
                :loading="loading"
              >
                <span class="text-white">
                  {{ $t("global.add") }}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
              </v-btn>
              <v-btn class="mx-3" size="small" @click="dialoge = false">
                {{ $t("global.cancel") }}
                <VIcon icon="mdi-cancel" color="gray" end></VIcon>
              </v-btn>
            </v-card-actions>
            <!-- </v-card> -->
            <!-- </v-col>
            </v-row> -->
            <!-- ##########    data Table Server ########### -->
          </v-card>
        </VDialog>
      </v-card-text>
    </v-form>
    <v-card-actions class="px-8">
      <VRow>
        <VCol class="" cols="12" xs="12" sm="12" md="2" lg="2">
          <VCardItem class="pa-0">
            <VBtn
              class="bg-indigo"
              @click="saveSetting"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.save") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
          </VCardItem>
        </VCol>
      </VRow>
    </v-card-actions>
  </VCard>
</template>
<script>
// import { mapState } from "vuex"
import { mapState, mapActions } from "vuex";

// import useValidate from '@vuelidate/core'
// import {email,required,helpers,maxLength,url,alpha,alphaNum,numeric} from '@vuelidate/validators'
export default {
  data() {
    return {
      settings: {
        current_year: undefined,
        date_type: false,
        attendance_twice: false,
      },
      dialoge: false,
      academicYear: {
        year: "",
        year_m: "",
        note: null,
      },
      is_loading: false,
      loading: false,
      uniqueYear: (value) =>
        !value ||
        !this.academicYears.some(
          (year) => year.year == value || year.year_m == value
        ) ||
        "هذا التاريخ موجود من قبل",
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      this.getSettings();
      if (!this.academicYears.length) await this.getAcademicYears();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      academicYears: (state) => state.school.academic_years,
      //   user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAcademicYears: "school/getAcademicYears",
    }),
    async saveSetting() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        if (this.settings.id == undefined)
          await this.axios
            .post(`${this.base_url}system-settings/`, this.settings, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              this.$emit("successAlert", this.$t("global.success.data_saved"));
              this.getSettings();
            })
            .catch((error) => {
              if (error.response.data.branch) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch")
                );
              } else if (error.response.data.semester) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch_semester")
                );
              } else {
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
              }
            });
        else
          await this.axios
            .put(
              `${this.base_url}system-settings/${this.settings.id}/`,
              this.settings,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              this.$emit("successAlert", this.$t("global.success.data_saved"));
              this.getSettings();
            })
            .catch((error) => {
              if (error.response.data.branch) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch")
                );
              } else if (error.response.data.semester) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch_semester")
                );
              } else {
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
              }
            });
      }
      this.is_loading = false;
    },

    async saveAcademicYear() {
      const { valid } = await this.$refs.form2.validate();

      if (valid) {
        this.loading = true;
        await this.axios
          .post(`${this.base_url}api/academic-years/`, this.academicYear, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.getAcademicYears();
            this.dialoge = false;
            if (this.settings.date_type)
              this.settings.current_year = this.academicYear.year;
            else this.settings.current_year = this.academicYear.year_m;
          })
          .catch((error) => {
            // console.log(error);
            if (error.response && error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response && error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.loading = false;
    },

    async getSettings() {
      await this.axios
        .get(`${this.base_url}system-settings/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data.length) {
            this.settings.id = response.data[0].id;
            this.settings.current_year = response.data[0].current_year;
            this.settings.date_type = response.data[0].use_hijri_calendar;
            this.settings.attendance_twice = response.data[0].attendance_twice;

            localStorage.setItem(
              "date",
              response.data[0].use_hijri_calendar ? "h" : "m"
            );
            localStorage.setItem(
              "attendance",
              response.data[0].attendance_twice ? "2" : "1"
            );
            this.$emit("updateDate", {
              year_m: response.data[0].year_m,
              year_h: response.data[0].year,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.v-list-item {
  max-width: 260px;
  padding: 0;
  padding-inline: 10px !important;
  opacity: 0.8;
}
.v-list-item .v-list-item-title {
  font-size: 0.9rem;
}
.v-list-item .v-list-item-subtitle {
  font-size: 0.79rem;
}

fieldset {
  border-radius: 0.4rem;
}

/* .v-list-item-action 
{
    margin-inline: 50px !important;
} */
</style>

<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-form
        v-if="
          CheckGetPermission('school.add_lecture') ||
          (id && CheckGetPermission('school.change_lecture'))
        "
        ref="form"
        @submit.prevent="saveLectureAttendance"
      >
        <v-row>
          <v-col cols="12" md="5">
            <v-text-field
              color="indigo"
              v-model="lecture_attendance.name"
              clearable
              counter="100"
              density="compact"
              prepend-inner-icon="mdi-format-text"
              :label="$t('school.lecture.name')"
              :rules="[$required, $max_length()]"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_class"
              density="compact"
              :item-title="classesName"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              :rules="[$required]"
              @update:modelValue="
                (lecture_attendance.fk_division = null),
                  (lecture_attendance.fk_teacher = null),
                  (lecture_attendance.fk_subject = null)
              "
            />
          </v-col>
          <div class="w-100"></div>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_division"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              clearable
              :no-data-text="$t('school.division.not_found')"
              :items="class_divisions"
              :label="$t('school.division.select')"
              @update:modelValue="
                (lecture_attendance.fk_teacher = null),
                  (lecture_attendance.fk_subject = null)
              "
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_teacher"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-account-tie"
              clearable
              :items="division_teacher"
              :label="$t('school.teacher.select')"
              :rules="[$required]"
              @update:model-value="lecture_attendance.fk_subject = null"
              :no-filter="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_subject"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-book-open-variant"
              clearable
              :items="teacher_subjects"
              :label="$t('school.subject.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              color="indigo"
              v-model="lecture_attendance.note"
              hide-details="auto"
              density="compact"
              counter="250"
              rows="3"
              clearable
              prepend-inner-icon="mdi-note-outline"
              :label="$t('global.note')"
              :rules="[$max_length(250)]"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        CheckGetPermission('school.add_lecture') ||
        (id && CheckGetPermission('school.change_lecture'))
      "
      class="px-8"
    >
      <v-btn
        v-if="!id && CheckGetPermission('school.add_lecture')"
        @click="saveLectureAttendance"
        :loading="is_loading"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="id && CheckGetPermission('school.change_lecture')"
        @click="updateLectureAttendance(selected_lecture_attendance)"
        :loading="is_loading"
        class="bg-success"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="cleanLectureAttendanceForm" class="mx-3" size="small">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <div></div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "LectureAttendance",
  props: {
    id: String,
  },
  async mounted() {
    if (this.id) {
      await this.axios
        .get(`${this.base_url}api/lecture-attendance/${this.id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.lecture_attendance = response.data;
          this.update = true;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("global.error.failure.not_found"));
        });
    }
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getClasses();
      await this.getSubjects({});
      await this.getDivisions({});
      await this.getAllTeachers();
      await this.getClassAssignHall();
      await this.getTeacherClass();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      // txt_search:null,
      // uniqeYearMessage:null,
      // uniqeYearMMessage:null,
      // dialog:false,
      // addDialog:false,
      // overlay:false,
      update: false,
      // user:null,
      selected_lecture_attendance: null,
      lecture_attendance: {},
      is_loading: false,
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      lectures_attendance: (state) => state.school.lectures_attendance,
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      teachers: (state) => state.school.teachers,
      subjects: (state) => state.school.subjects,
      teacher_class: (state) => state.school.teacher_class,
    }),
    ...mapGetters({
      getDivisionName: "school/getDivisionName",
      getClassDivisions: "school/getClassDivisions",
      // getSubjectsByClass: "school/getSubjectsByClass",
    }),

    division_teacher() {
      if (
        this.lecture_attendance.fk_division &&
        this.lecture_attendance.fk_class
      ) {
        // var teacher = this.teachers.find(teacher => teacher.id == this.teacher_class.find(item => item.))
        var division = this.lecture_attendance.fk_division;
        var class_id = this.lecture_attendance.fk_class;
        var teacher_id = this.teacher_class
          .filter((item) => {
            if (item.fk_class == class_id && item.fk_division == division) {
              return item;
            }
          })
          .map((item) => item.fk_teacher);
        var teacher_unique = [...new Set(teacher_id)];
        var teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
    class_divisions() {
      if (this.lecture_attendance.fk_class) {
        return this.getClassDivisions(this.lecture_attendance.fk_class);
      }
    },
    teacher_subjects() {
      if (this.lecture_attendance.fk_teacher) {
        var subject_id = this.teacher_class
          .filter(
            (item) =>
              item.fk_teacher === this.lecture_attendance.fk_teacher &&
              item.fk_class == this.lecture_attendance.fk_class
          )
          .map((item) => item.fk_subject);
        var subjects_uniqe = [...new Set(subject_id)];
        var subject_data = [];
        subjects_uniqe.forEach((el) => {
          subject_data.push({ id: el, name: this.getSubjectName(el) });
        });
      }
      // let subject_data = this.getSubjectsByClass(
      //   this.lecture_attendance.fk_class
      // );

      return subject_data;
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    subjectsName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    teachersName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getAllTeachers: "school/getAllTeachers",
      getTeacherClass: "school/getTeacherClass",
      getDivisions: "school/getDivisions",
      getClassAssignHall: "school/getClassAssignHall",
    }),

    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },

    // selectLectureAttendance(item){

    //     this.selected_lecture_attendance=item.id
    //     var temp_lecture=this.lectures_attendance.find(element=>element.id==item.id)
    //     delete temp_lecture.id
    //     this.lecture_attendance=Object.assign({},temp_lecture)
    //     this.update=true
    // },
    async saveLectureAttendance() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(
            `${this.base_url}api/lecture-attendance/`,
            this.lecture_attendance,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.cleanLectureAttendanceForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    cleanLectureAttendanceForm() {
      if (this.id) {
        this.$router.push({ name: "lecture_attendance_list" });
        // this.$router.go(-1);
      } else {
        this.$refs.form.reset();
      }
    },
    async updateLectureAttendance() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(
            `${this.base_url}api/lecture-attendance/${this.id}/`,
            this.lecture_attendance,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.cleanLectureAttendanceForm();
            // setTimeout(() => {
            //   this.$router.push({ name: "lecture_attendance_list" });
            // }, 500);
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

<template>
  <v-card>
    <v-card-text class="card-view">
      <VForm v-if="CheckGetPermission('school.view_marks')" ref="form">
        <!-- <VRow class="mt-4">
          <VCol cols="6" md="3">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_semester"
              density="compact"
              item-title="name_ar"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-calendar-month"
              :items="semesters"
              :label="$t('school.semester.select')"
              :rules="[$required]"
              @update:modelValue="students_marks.fk_month = undefined"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="5">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_month"
              density="compact"
              item-title="name"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-calendar-month"
              :items="getMonthBySemester(students_marks.fk_semester)"
              :label="$t('school.monthly_marks.month')"
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
        </VRow> -->
        <VRow class="mt-4">
          <VCol cols="12" md="3">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              :label="$t('school.class.select')"
              :rules="[$required]"
              @update:modelValue="
                (students_marks.fk_division = undefined),
                  (students_marks.fk_subject = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol cols="12" md="3">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_division"
              density="compact"
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-format-list-bulleted"
              :items="getClassByDivisions(students_marks.fk_class)"
              :label="$t('school.division.select')"
              @update:modelValue="getStudents()"
            ></VAutocomplete>
          </VCol>
          <VCol cols="12" md="3">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_subject"
              density="compact"
              :loading="
                getSubjectsByClass(students_marks.fk_class)?.length < 1 &&
                students_marks.fk_subject
              "
              item-title="name_ar"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-book-open-variant"
              :items="
                students_marks.fk_class
                  ? getSubjectsByClass(students_marks.fk_class)
                  : []
              "
              :label="$t('school.subject.name')"
              :rules="[$required]"
              @update:modelValue="getStudents()"
            ></VAutocomplete>
          </VCol>
          <v-col cols="12" md="3" sm="12">
            <v-autocomplete
              color="indigo"
              v-model="students_marks.fk_month"
              density="compact"
              :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
              item-value="id"
              prepend-inner-icon="mdi-calendar-month"
              :items="getCurrentSemesterMonths"
              :label="$t('school.reports.select_month')"
              hide-details="auto"
              clearable
              @update:modelValue="getStudents()"
            />
          </v-col>
          <!-- <VCol cols="auto" class="mt-auto pb-4 pt-3">
            <v-btn
              @click="getStudents()"
              :loading="this.is_loading"
              class="bg-indigo"
              size="small"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon> 
            </v-btn>
          </VCol> -->
        </VRow>
        <!-- <VRow class="mt-4">
          <VCol cols="12">
            <VAutocomplete
              color="indigo"
              v-model="students_marks.fk_student"
              density="compact"
              item-title="name"
              item-value="id"
              clearable
              :items="student_list"
              prepend-inner-icon="mdi-school"
              :label="$t('school.student.student_search')"
            />
          </VCol>
        </VRow> -->
      </VForm>
    </v-card-text>
    <v-progress-linear
      v-if="loading"
      color="indigo"
      indeterminate
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div v-show="!loading && students_marks.fk_subject" class="card-table">
        <VCard>
          <VTable density="compact">
            <thead>
              <tr>
                <th class="px-0">
                  <v-text-field
                    color="indigo"
                    v-model="search_student"
                    @input="!search_student ? searchStudentMarks() : ''"
                    @keyup.enter="searchStudentMarks()"
                    density="compact"
                    variant="solo-filled"
                    hide-details
                    single-line
                    clearable
                    append-inner-icon="mdi-magnify"
                    prepend-inner-icon="mdi-account-search"
                    :label="$t('school.student.name')"
                  />
                  <!-- <VAutocomplete
                        color="indigo"
                        v-model="students_marks.fk_student"
                        density="compact"
                        item-title="name"
                        item-value="id"
                        hide-details
                        clearable
                        :items="student_list"
                        append-inner-icon="mdi-magnify"
                        prepend-inner-icon="mdi-account-search"
                        @update:modelValue="getStudents"
                        :label="$t('school.student.name')"
                        variant="underlined"
                      /> -->
                </th>
                <th>{{ $t("school.monthly_marks.oral") }}</th>
                <th>{{ $t("school.monthly_marks.assigments") }}</th>
                <th>{{ $t("school.monthly_marks.attendance") }}</th>
                <th>{{ $t("school.monthly_marks.exam") }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr v-if="!loading" class="text-center"><td colspan="12" > جاري جلب البيانات...</td></tr> -->
              <tr v-if="exist" v-for="mark in students_marks.marks" :key="mark">
                <td style="min-width: 250px">
                  {{ mark.student_name }}
                </td>
                <td>
                  <VTextField
                    class="mt-1"
                    type="number"
                    v-model="mark.oral"
                    density="compact"
                    variant="underlined"
                    hide-details="auto"
                    :placeholder="$t('school.monthly_marks.oral')"
                    :rules="[$max_value(20), $min_value(0)]"
                  >
                  </VTextField>
                </td>
                <td>
                  <VTextField
                    class="mt-1"
                    type="number"
                    v-model="mark.assignments"
                    density="compact"
                    variant="underlined"
                    hide-details="auto"
                    :placeholder="$t('school.monthly_marks.assigments')"
                    :rules="[$max_value(20), $min_value(0)]"
                  >
                  </VTextField>
                </td>
                <td>
                  <VTextField
                    class="mt-1"
                    type="number"
                    v-model="mark.attendance"
                    density="compact"
                    variant="underlined"
                    hide-details="auto"
                    :placeholder="$t('school.monthly_marks.attendance')"
                    :rules="[$max_value(20), $min_value(0)]"
                  >
                  </VTextField>
                </td>
                <td>
                  <VTextField
                    class="mt-1"
                    type="number"
                    v-model="mark.exam"
                    density="compact"
                    variant="underlined"
                    hide-details="auto"
                    :placeholder="$t('school.monthly_marks.exam')"
                    :rules="[$max_value(40), $min_value(0)]"
                  >
                  </VTextField>
                </td>
                <!-- <td>
                      <v-text-field
                        style="min-width: 200px"
                        v-model="mark.note"
                        density="compact"
                        :placeholder="$t('global.note')"
                        hide-details
                        class="my-1"
                        :rules="max_entry"
                      >
                      </v-text-field>
                    </td> -->
              </tr>
              <tr v-if="!exist && !loading">
                <td colspan="4" class="text-center">
                  {{ this.$t("global.error.failure.message") }}
                </td>
              </tr>
            </tbody>
          </VTable>
          <v-card-actions
            v-if="
              CheckGetPermission('school.add_marks') ||
              CheckGetPermission('school.change_marks')
            "
            class="px-5"
          >
            <v-btn
              v-if="
                CheckGetPermission('school.add_marks') ||
                CheckGetPermission('school.change_marks')
              "
              @click="saveMarks"
              :loading="is_loading"
              class="bg-indigo"
              size="small"
              :disabled="!exist"
            >
              <span class="text-white">
                {{ $t("global.save") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <!-- <v-btn
                  v-if="update"
                  @click="updateMarks"
                  class="bg-success"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("global.edit") }}
                  </span>
                  <v-icon
                    icon="mdi-content-save"
                    color="white"
                    end
                  ></v-icon>
                </v-btn> -->
            <v-btn @click="cleanMarks" class="mx-3" size="small">
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn>
          </v-card-actions>
        </VCard>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import { load } from "webfontloader";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.months.length) await this.getAllMonths();
      if (!this.semesters.length) await this.getSemesters();
      this.students_marks.fk_month = this.months?.find((month) =>
        this.date_type == "h"
          ? month?.name_h == localStorage.getItem("month_h")
          : month?.name_m == localStorage.getItem("month_m")
      )?.id;
      // if (!this.months.length) await this.getMonths();
      // if (!this.semesters.length) await this.getSemesters();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      is_loading: false,
      loading: false,
      exist: false,
      students_marks: {
        fk_class: undefined,
        fk_division: undefined,
        fk_subject: undefined,
        fk_student: undefined,
        fk_month: {},
        // fk_semester: undefined,
        // fk_month: undefined,
        // data_entry: undefined,
        marks: [],
      },
      date_type: localStorage.getItem("date"),
      student_list: [],
      search_student: null,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      months: (state) => state.school.months,
      semesters: (state) => state.school.semesters,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",

      // getMonthBySemester: "school/getMonthBySemester",
    }),

    // class_subjects() {
    //   if (this.students_marks.fk_class) {
    //     var class_data = this.classes.find(
    //       (item) => item.id == this.students_marks.fk_class
    //     );
    //     var subjects_data = [];
    //     if (class_data)
    //       class_data.subjects.forEach((element) => {
    //         subjects_data.push({
    //           id: element.id,
    //           name: this.getSubjectName(element),
    //         });
    //       });
    //   }
    //   return subjects_data ? subjects_data : [];
    // },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getClassAssignHall: "school/getClassAssignHall",
      getSemesters: "school/getSemesters",
      getAllMonths: "school/getAllMonths",
      // getMonths: "school/getMonths",
    }),
    async getStudents() {
      //   this.v$.$validate();
      if (
        this.students_marks.fk_class == undefined ||
        this.students_marks.fk_subject == undefined
      )
        return 0;
      // const { valid } = await this.$refs.form.validate();
      // if (valid) {
      this.search_student = "";
      this.loading = true;
      this.exist = false;
      let list = await this.axios
        .get(`${this.base_url}/monthly-marks/`, {
          params: {
            fk_class: this.students_marks.fk_class,
            fk_division: this.students_marks.fk_division,
            fk_subject: this.students_marks.fk_subject,
            fk_student: this.students_marks.fk_student,
            fk_month: this.students_marks?.fk_month,
            // fk_year: localStorage.getItem("current_year"),
            // fk_semester: this.students_marks.fk_semester,
            // fk_month: this.students_marks.fk_month,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.exist = true;
            this.loading = false;
            this.students_marks.marks = response.data;
            this.student_list = response.data;
          }
          // if (!this.students_marks.fk_student) {
          //   this.student_list = [];
          //   this.students_marks.marks = [];
          //   response.data.forEach((element) => {
          //     this.student_list.push({
          //       id: element.fk_student,
          //       name: element.student_name,
          //     });
          //     this.students_marks.marks.push(element);
          //   });
          // } else {
          //   let student = this.student_list.find(
          //     (student) => student.id == this.students_marks.fk_student
          //   );
          //   this.students_marks.marks = [];
          //   this.students_marks.marks.push({
          //     student_name: student.name,
          //     fk_student: student.id,
          //     assignments: undefined,
          //     attendance: undefined,
          //     oral: undefined,
          //     exam: undefined,
          //   });
          // }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response.data.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });

      // else if (list.data.length) {
      //   this.students_marks.marks = [];
      //   list.data.forEach((element) => {
      //     this.students_marks.marks.push(element);
      //   });
      // }

      this.loading = false;
      // }
    },
    // getSubjectName(id) {
    //   var subject = this.subjects.find((item) => item.id == id);
    //   if (subject) return subject.name_ar;
    //   return "";
    // },

    async saveMarks() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.students_marks.data_entry = this.user;
        await this.axios
          .post(`${this.base_url}monthly-marks/`, this.students_marks, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            this.is_loading = false;
            this.getStudents();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    cleanMarks() {
      this.students_marks.marks = [];
      this.$refs.form.reset();
    },
    searchStudentMarks() {
      if (this.search_student != "") {
        this.students_marks.marks = this.student_list.filter((student) =>
          student.student_name.includes(this.search_student)
        );
      } else {
        this.students_marks.marks = this.student_list;
      }
    },
  },
};
</script>
<style scoped>
.v-table th,
td {
  text-align: center !important;
  padding-inline: 10px !important;
}
</style>

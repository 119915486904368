<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        checkRole(['programmer', 'big_admin']) &&
        CheckGetPermission('school.change_day')
      "
    >
      <!-- <VCard-title class="my-4">
        <VIcon icon="mdi-calendar-plus" />
        <h3 class="d-inline text-grey" style="padding: 0 4px">
          {{ $t("school.day.add_day") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </VCard-title> -->
      <v-expand-transition v-if="getUserRole < 2">
        <div v-show="is_update && getUserRole < 2">
          <VCard-text class="card-fields">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                <VCardItem class="pa-0">
                  <VTextField
                    readonly
                    v-model="day.name"
                    prepend-inner-icon="mdi-calendar-edit"
                    :label="$t('school.day.day')"
                    :placeholder="$t('school.day.day_placeholder')"
                    density="compact"
                    type="text"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VCheckbox
                    v-model="day.is_holiday"
                    :label="$t('school.day.day_off')"
                    color="blue"
                    density="compact"
                  >
                  </VCheckbox>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="8" lg="8">
                <VTextarea
                  v-model="day.note"
                  clearable
                  :label="$t('global.note')"
                  prepend-inner-icon="mdi-note-outline"
                  counter="250"
                  no-resize
                  density="compact"
                  rows="3"
                  :rules="[$max_length(250)]"
                >
                </VTextarea>
              </VCol>
            </VRow>
          </VCard-text>
        </div>
      </v-expand-transition>
    </VForm>
    <VCardActions
      v-if="
        is_update &&
        checkRole(['programmer', 'big_admin']) &&
        CheckGetPermission('school.change_day')
      "
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <!-- <VBtn
        v-if="!is_update && CheckGetPermission('school.add_day')"
        class="bg-indigo"
        @click="saveDay"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn> -->
      <VBtn
        v-if="
          is_update &&
          checkRole(['programmer', 'big_admin']) &&
          CheckGetPermission('school.change_day')
        "
        class="bg-success"
        @click="updateDay"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.cancel") }}
        <VIcon icon="mdi-cancel" color="red" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider
      v-if="is_update"
      style="border-width: -1px; opacity: 0.25"
      class="mt-5"
    />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-if="key == 'is_holiday'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.change_day')
                  "
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editDay(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <!-- <span style="color: #d05251; border-radius: 4px">
                  <v-icon
                    @click="(day_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span> -->
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (day_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteDay">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      //   v$: useValidate(),
      day: {
        name: undefined,
        is_holiday: false,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      days: [],
      is_update: false,
      del_dialog: false,
      day_del_id: undefined,
      headers: [
        { title: this.$t("school.day.day"), key: "name" },
        { title: this.$t("school.day.day_off"), key: "is_holiday" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["name", "is_holiday", "note", "actions"],
      txt_search: null,
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      is_loading: false,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getDays();
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.days.forEach((element) => {
        list.push({
          name: element.name,
          is_holiday: element.is_holiday,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      //   this.v$.day.$reset();
      this.is_update = false;
      this.day.id = undefined;
      this.updated_id = undefined;
      this.day.is_holiday = false;
    },
    editDay(data) {
      let day = this.days.find((item) => item.id == data.id);
      if (day != undefined) {
        for (const key in day) this.day[key] = day[key];
        this.day.data_entry = undefined;
        this.day.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveDay() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      //   if (this.days.length < 7) {
      if (valid) {
        //   this.day.data_entry = this.user;
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "/day/", this.day, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            //   9;
            this.resetForm();
            this.getDays();
          })
          .catch((error) => {
            if (error.response && error.response.data == "overload")
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.day_overload")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false;
      //   }
      //   else {
      //     this.$emit("errorAlert", this.$t("global.error.failure.day_overload"));
      //   }
    },
    async updateDay() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.day.id != undefined) {
        // this.day.updated_by = this.user;
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}/day/${this.day.id}/`, this.day, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getDays();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async deleteDay() {
      if (this.day_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/day/${this.day_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getDays();
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data == "current"
            )
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.errpr_current")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.day_del_id = undefined;
      }
    },
    async getDays() {
      await this.axios
        .get(this.base_url + "/day/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data) this.days = response.data;
        });
    },
  },
};
</script>

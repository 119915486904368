<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon>  -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <v-row>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              @update:modelValue="(fk_subject = null), (fk_teacher = null)"
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_teacher"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-account-tie"
              clearable
              :items="class_teachers"
              :label="$t('school.teacher.select')"
              @update:modelValue="fk_subject = null"
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_subject"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-book-open-variant"
              clearable
              :items="teacher_subjects"
              :label="$t('school.subject.select')"
            />
          </v-col>
          <v-col class="mt-2" cols="12" xs="12" sm="12" md="2" lg="2">
            <v-btn
              @click="getLecturesAttendance((page = 1))"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.filter") }}
              </span>
              <VIcon icon="mdi-filter" color="white" end></VIcon>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card class="card-table">
    <!-- ##########    data Table Server ########### -->
    <!-- ######## Header Of Data Table Server ####### -->
    <div v-if="CheckGetPermission('school.view_lecture')">
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="txt_search"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        @input="!txt_search ? getLecturesAttendance((page = 1)) : ''"
        @keyup.enter="getLecturesAttendance((page = 1))"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div class="d-flex justify-end" style="flex: 1">
        <VBtn
          v-if="CheckGetPermission('school.add_lecture')"
          class="bg-success"
          @click="addLectureAttendane"
        >
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <v-data-table-server
      :headers="selectedHeaders"
      :items="tableList ? items : []"
      item-key="id"
      fixed-footer
      density="compact"
      class="elevation-1"
      id="vDataTable"
      v-model:sort-by="sortBy"
      :loading="loading"
      @update:options="getLecturesAttendance((page = 1))"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <!-- <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details density="compact"> </v-checkbox>
      </template> -->
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <v-checkbox
              v-if="key == 'is_taken'"
              v-model="item.raw.is_checked"
              hide-details
              density="compact"
              @change="
                (lecture_data = item.raw),
                  showConfirmDialog(item.raw.is_checked)
              "
            >
            </v-checkbox>
            <span v-else> {{ val }}</span>
            <span v-if="key === 'actions'">
              <v-btn
                variant="text"
                v-if="CheckGetPermission('school.change_lecture')"
                class="me-1 v-btn-icon"
                size="small"
                @click="editLectureAttendance(item.raw)"
              >
                <v-icon color="warnning">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                variant="text"
                v-if="CheckGetPermission('school.delete_lecture')"
                size="small"
                class="v-btn-icon"
                @click="
                  (selected_lecture_attendance = item.raw.id), (dialog = true)
                "
              >
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="tableList.length"
          :newItem="[perPage]"
        ></pagination>
        <!-- <div class="d-flex mt-2">
                  <v-pagination  v-model="page" :length="length" density="compact" ></v-pagination>
                  <div class="d-flex align-center">
                      <span>{{$t('globals.per-page')}} </span>
                      <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" density="compact"  ></v-select>
                  </div>
              </div> -->
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>

            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }}
              {{ $t("global.from") }} [ {{ totalItems }} ]
              {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table-server>
    <ConfirmationDialog
      v-model="confirmation_dialog"
      :message="$t('school.lecture.has_taken')"
      :subject="lecture_data?.name"
      :confirmed_text="$t('global.taken')"
      @confirmed="takenLecture"
      @canceled="cancelDialog"
    >
    </ConfirmationDialog>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selected_lecture_attendance = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn
            :loading="loading"
            color="red"
            @click="deleteLectureAttendance(selected_lecture_attendance)"
            >{{ $t("global.delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getClasses();
      await this.getDivisions({});
      await this.getSubjects({ all: true });
      await this.getAllTeachers();
      await this.getTeacherClass();
      await this.getLecturesAttendance();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      items: [],
      selected: [],
      lecture_data: null,
      confirmation_dialog: false,
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      is_checked: false,
      loading: false,
      user: null,
      sortBy: [{ key: "name", order: "asc" }],
      selectedHead: [
        "name",
        "fk_class",
        "fk_division",
        "fk_subject",
        "fk_teacher",
        "is_taken",
        "actions",
      ],
      selected_lecture_attendance: null,
      fk_class: null,
      fk_subject: null,
      fk_teacher: null,
      from_date: null,
      lectures_attendance: [],
      to_date: null,
      headers: [
        { title: this.$t("school.lecture.name"), key: "name" },
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.division.name"), key: "fk_division" },
        { title: this.$t("school.subject.name"), key: "fk_subject" },
        { title: this.$t("school.teacher.name"), key: "fk_teacher" },
        { title: this.$t("school.lecture.is_taken"), key: "is_taken" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
        { value: "all", text: this.$t("global.data_table.all") },
      ],
      page: 1,
      totalItems: null,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      teachers: (state) => state.school.teachers,
      subjects: (state) => state.school.subjects,
      teacher_class: (state) => state.school.teacher_class,
    }),
    ...mapGetters({
      getDivisionName: "school/getDivisionName",
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      list.forEach((element) => {
        let fk_class = element.fk_class;
        let fk_teacher = element.fk_teacher;
        let fk_subject = element.fk_subject;
        let fk_division = element.fk_division;
        element.fk_class = this.getClassName(fk_class);
        element.fk_subject = this.getSubjectName(fk_subject);
        element.fk_teacher = this.getTeacherName(fk_teacher);
        element.fk_division = this.getDivisionName(fk_division);
      });
      this.items = list;
      return list;
    },
    class_subjects() {
      if (this.fk_class) {
        var class_data = this.classes.find((item) => item.id == this.fk_class);
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name_ar: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    class_teachers() {
      var teacher_data = this.teachers;
      if (this.fk_class) {
        var teacher_id = this.teacher_class
          .filter((item) => item.fk_class == this.fk_class)
          .map((item) => item.fk_teacher);
        var teacher_unique = [...new Set(teacher_id)];
        teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name_ar: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
    teacher_subjects() {
      var subject_data = this.subjects;
      if (this.fk_teacher && this.fk_class) {
        var subject_id = this.teacher_class
          .filter(
            (item) =>
              item.fk_teacher === this.fk_teacher &&
              item.fk_class == this.fk_class
          )
          .map((item) => item.fk_subject);
        var subjects_uniqe = [...new Set(subject_id)];
        subject_data = [];
        subjects_uniqe.forEach((el) => {
          subject_data.push({ id: el, name_ar: this.getSubjectName(el) });
        });
      } else if (!this.fk_teacher && this.fk_class) {
        subject_data = this.class_subjects;
      }
      return subject_data;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },

  // validations() {
  //   return {
  //     lecture_attendance: {
  //       name: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //         maxLength: helpers.withMessage(
  //           this.$t("errors.max-entry"),
  //           maxLength(100)
  //         ),
  //       },
  //       lecture_date: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       note: {
  //         maxLength: helpers.withMessage(
  //           this.$t("errors.max-entry"),
  //           maxLength(250)
  //         ),
  //       },
  //       fk_class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       fk_subject: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       fk_teacher: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       data_entry: {},
  //       updated_by: {},
  //     },
  //   };
  // },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getAllTeachers: "school/getAllTeachers",
      getTeacherClass: "school/getTeacherClass",
      getDivisions: "school/getDivisions",
    }),
    addLectureAttendane() {
      this.$router.push({ name: "lecture_attendance_add" });
    },
    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },

    editLectureAttendance(item) {
      //   this.$router.push(`${item.id}/edit/`);
      this.$router.push({
        name: "lecture_attendance_edit",
        params: { id: item.id },
      });
    },

    async deleteLectureAttendance(id) {
      this.loading = true;
      await this.axios
        .delete(`${this.base_url}api/lecture-attendance/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.getLecturesAttendance();
          this.loading = false;
        })
        .catch(() => {
          if (error.response?.status == 418)
            this.$emit("protectedAlert", error.response?.data?.data);
          else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          this.loading = false;
        });
      this.dialog = false;
    },
    async getLecturesAttendance(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/lecture-attendance/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          class_id: this.fk_class,
          subject: this.fk_subject,
          teacher: this.fk_teacher,
          from_date: this.from_date,
          to_date: this.to_date,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.lectures_attendance = response.data.results?.map((lecture) => ({
          ...lecture,
          is_checked: lecture?.take_date ? true : false,
        }));
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.totalItems = response.data.pagination.count;
      });
      this.loading = false;
    },
    async takenLecture(results) {
      if (results) {
        await this.axios(`${this.base_url}api/lecture-attendance/taken/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            lecture: this.lecture_data?.id,
          },
        })
          .then((response) => {
            this.lecture_data.is_checked = true;
            this.confirmation_dialog = false;
          })
          .catch((error) => {
            this.lecture_data.is_checked = false;
            this.getLecturesAttendance();
            this.confirmation_dialog = false;
          });
      }
    },
    cancelDialog(results) {
      if (!results) {
        this.lecture_data.is_checked = false;
        this.confirmation_dialog = false;
      }
    },
    async unTakenLecture() {
      await this.axios(`${this.base_url}api/lecture-attendance/untake/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          lecture: this.lecture_data?.id,
        },
      })
        .then((response) => {
          this.lecture_data.is_checked = false;
        })
        .catch((error) => {
          this.lecture_data.is_checked = this.lecture_data.is_checked;
        });
    },
    showConfirmDialog(checkBox) {
      if (checkBox) {
        this.lecture_data.is_checked = false;
        this.confirmation_dialog = true;
      } else {
        this.unTakenLecture();
      }
    },
  },
  watch: {
    perPage() {
      this.getLecturesAttendance();
    },
    page() {
      this.getLecturesAttendance(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>

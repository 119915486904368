<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <v-form
        v-if="CheckGetPermission('school.add_schoolweeklyschedule')"
        ref="form"
        @submitSchoolWeeklySchedule.prevent="saveSchoolWeeklySchedule"
      >
        <v-row>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="school_weekly_schedule.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              @update:modelValue="school_weekly_schedule.fk_division = null"
              :rules="[$required]"
            />
            <!-- getSubjectsWithTeacher(), -->
          </v-col>
          <!-- hide-details="auto" -->
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="school_weekly_schedule.fk_division"
              density="compact"
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              clearable
              :items="getClassByDivisions(school_weekly_schedule.fk_class)"
              :label="$t('school.division.select')"
              :rules="[$required]"
            ></v-autocomplete>
            <!-- hide-details="auto" -->
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="school_weekly_schedule.period_am"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-timeline-clock-outline"
              clearable
              :items="[
                { id: true, name: $t('school.weekly_schedule.am') },
                { id: false, name: $t('school.weekly_schedule.pm') },
              ]"
              :label="$t('school.schedule_data.schedule_period')"
              :rules="[$required]"
            ></v-autocomplete>
            <!-- @update:modelValue="createTable" -->
          </v-col>
          <v-col cols="6" md="1" sm="1" class="mt-2">
            <v-btn
              class="bg-indigo"
              @click="createTable()"
              :loading="loading"
              size="small"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- </v-card> -->
    </v-card-text>
    <v-progress-linear
      v-if="loading"
      color="indigo"
      indeterminate
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div
        v-show="
          school_weekly_schedule.days.length > 0 &&
          timetable_schedules.length > 0
        "
        class="card-table"
      >
        <!-- <VDivider></VDivider> -->
        <v-form
          v-if="CheckGetPermission('school.add_schoolweeklyschedule')"
          ref="table"
        >
          <v-card class="mt-4">
            <div>
              <v-table density="compact">
                <thead>
                  <tr>
                    <th>
                      <span>
                        {{ $t("school.day.day") }}/{{
                          $t("school.lecture.lecture")
                        }}
                      </span>
                    </th>
                    <th v-for="schedule in timetable_schedules" :key="schedule">
                      {{ schedule.quotas }}
                      <!-- <small>
                    {{ schedule.quotas_start_time }}
                    -
                    {{ schedule.quotas_end_time }}
                  </small> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- {{addTimeTable(7)}} -->
                  <tr
                    v-for="(day, index) in school_weekly_schedule.days"
                    :key="day"
                  >
                    <td>
                      <span>{{ days_choices[index].name }}</span>
                    </td>
                    <td
                      v-for="subject in day.subjects"
                      :key="subject"
                      class="justify-center"
                    >
                      <v-autocomplete
                        v-model="subject.fk_subject"
                        density="compact"
                        clearable
                        variant="underlined"
                        item-value="id"
                        item-title="name_ar"
                        :no-data-text="$t('school.subject.not_found')"
                        :items="
                          school_weekly_schedule.fk_class
                            ? this.getSubjectsByClass(
                                this.school_weekly_schedule.fk_class
                              )
                            : []
                        "
                        hide-details
                        :placeholder="$t('school.subject.select')"
                      >
                      </v-autocomplete>
                      <!-- <div class="d-none" v-if="subject.fk_subject !== null">
                        {{
                          (subject.fk_teacher = determineTeacher(
                            subject.fk_subject
                          ))
                        }}
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
            <v-card-actions
              v-if="CheckGetPermission('school.add_schoolweeklyschedule')"
            >
              <v-btn
                v-if="
                  !update &&
                  CheckGetPermission('school.add_schoolweeklyschedule')
                "
                @click="saveSchoolWeeklySchedule"
                class="bg-indigo"
                size="small"
                :loading="is_loading"
              >
                <span class="text-white">
                  {{ $t("global.save") }}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
              </v-btn>
              <!-- <v-btn
                v-if="update"
                @click="updateWeeklySchedule"
                class="bg-success"
                size="small"
              >
                <span class="text-white">
                  {{ $t("global.edit") }}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
              </v-btn> -->
              <v-btn
                @click="cleanSchoolWeeklySchedule"
                class="mx-3"
                size="small"
              >
                <span>
                  {{ $t("global.clear") }}
                </span>
                <v-icon icon="mdi-broom" color="golden" end></v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <div
          v-if="timetable_schedules.length < 1 && !loading"
          class="text-center mt-2 mb-1 text-grey-darken-2"
        >
          <span v-if="school_weekly_schedule.period_am == true">
            {{ $t("school.weekly_schedule.no_periods_am") }}
          </span>
          <span v-if="school_weekly_schedule.period_am == false">
            {{ $t("school.weekly_schedule.no_periods_pm") }}
          </span>
        </div>
      </div>
    </v-expand-transition>
  </v-card>
  <div></div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "WeeklyScheduleAdd",

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getSchedule(true);
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.divisions.length) await this.getDivisions({ all: true });
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.days_choices.length) await this.getDaysChoices();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      // console.log(error);
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      uniqueData: [null, null, null],
      schedules_data: [],
      showAlert: false,
      update: false,
      user: null,
      subjects: [],
      loading: false,
      is_loading: false,
      data_excist: false,
      //   user: null,
      school_weekly_schedule: {
        fk_class: null,
        fk_division: null,
        // fk_semester: null,
        period_am: null,
        // fk_year: localStorage.getItem("current_year"),
        days: [],
        note: "",
        // data_entry: undefined,
        // updated_by: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      //   semesters: (state) => state.school.semesters,
      days_choices: (state) => state.school.days_choices,
      schedules: (state) => state.school.schedules,
      subjects: (state) => state.school.subjects,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    timetable_schedules() {
      return this.schedules.filter(
        (el) =>
          el.add_to_table == true &&
          el.period == this.school_weekly_schedule.period_am
      );
    },
    checkSchedules() {
      var list = [];
      this.schedules_data.forEach((element) => {
        var schedule = Object.values(element);
        schedule.pop();
        list.push(schedule);
      });
      var found = list.some((array) => {
        return array.every((el, index) => {
          return el === this.uniqueSchedule[index];
        });
      });

      return found;
    },
    uniqueSchedule() {
      return [
        this.school_weekly_schedule.fk_class,
        this.school_weekly_schedule.fk_division,
        this.school_weekly_schedule.fk_semester,
      ];
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  beforeUnmount() {
    this.$store.commit("updateLoadingValue", true);
  },
  methods: {
    ...mapActions({
      //   getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getDaysChoices: "school/getDaysChoices",
      getSchedule: "school/getSchedule",
      getDivisions: "school/getDivisions",
      getSubjects: "school/getSubjects",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    // determineTeacher(id_subject) {
    //   const teacher = this.subjects.find((el) => el.id_subject == id_subject);
    //   return teacher ? teacher.id_teacher : null;
    // },
    async createTable() {
      const { valid } = await this.$refs.form.validate();

      // this.v$.school_weekly_schedule.$validate()
      // if (this.school_weekly_schedule.period_am !== null) {
      // this.checkClassSubject()
      if (valid) {
        if (this.timetable_schedules.length) {
          this.loading = true;
          await this.getSchedules();
          if (this.data_excist) {
            this.loading = false;
            return this.$emit(
              "warningAlert",
              this.$t("global.error.validation.unique_schedule")
            );
          }

          this.school_weekly_schedule.days = [];
          setTimeout(() => {
            this.addDays(this.days_choices.length);
          }, 1000);
          setTimeout(() => {
            this.addSubjects();
          }, 1000);
        } else {
          this.school_weekly_schedule.days = [];
          this.$emit("warningAlert", this.$t("global.warning.no_period_data"));
        }
      }
    },
    cleanTable() {
      this.$refs.table.reset();
    },
    async getSchedules() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        await this.axios
          .get(`${this.base_url}api/weekly-schedule/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              class: this.school_weekly_schedule.fk_class,
              division: this.school_weekly_schedule.fk_division,
              period_am: this.school_weekly_schedule.period_am,
              //   semester: fk_semester,
            },
          })
          .then((response) => {
            // this.school_weekly_schedule = response.data;
            // this.update = true;
            this.data_excist = true;
          })
          .catch(() => {
            this.data_excist = false;
          });
      }
    },
    addDays(number) {
      this.school_weekly_schedule.days = [];
      for (let index = 0; index < number; index++) {
        this.school_weekly_schedule.days.push({
          day: this.days_choices[index].id,
          subjects: [],
        });
      }
    },
    addSubjects() {
      this.school_weekly_schedule.days.forEach((element) => {
        this.timetable_schedules.forEach((schedule) => {
          element.subjects.push({
            fk_subject: null,
            fk_schedule: schedule.id,
            // fk_teacher: null,
          });
        });
        this.loading = false;
      });
    },
    // async getSubjectsWithTeacher() {
    //   await this.axios
    //     .get(`${this.base_url}assign-teacher/teachers-subjects/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //       params: {
    //         class: this.school_weekly_schedule.fk_class,
    //         division: this.school_weekly_schedule.fk_division,
    //         // semester: this.school_weekly_schedule.fk_semester,
    //         // year: localStorage.getItem("current_year"),
    //       },
    //     })
    //     .then((res) => {
    //       this.subjects = res.data;
    //       this.checkClassSubject();
    //     });
    // },
    // checkClassSubject() {
    //   if (this.school_weekly_schedule.days.length > 0) {
    //     if (this.subjects.length < 1) {
    //       this.school_weekly_schedule.days.forEach((el) => {
    //         if (el.subjects.length > 0) {
    //           el.subjects.forEach((subject) => {
    //             subject.fk_subject = null;
    //             // subject.fk_teacher = null;
    //           });
    //         }
    //       });
    //     } else {
    //       this.school_weekly_schedule.days.forEach((el) => {
    //         if (el.subjects.length > 0) {
    //           el.subjects.forEach((subject) => {
    //             if (
    //               !this.subjects
    //                 .map((el) => el.id_subject)
    //                 .includes(subject.fk_subject)
    //             ) {
    //               subject.fk_subject = null;
    //               // subject.fk_teacher = null;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   } else {
    //   }
    // },
    // submitSchoolWeeklySchedule() {
    //   this.v$.$validate();
    // },
    async saveSchoolWeeklySchedule() {
      //   const { valid } = await this.$refs.form.validate();

      //   if (valid) {
      // this.school_weekly_schedule.data_entry = this.user;
      this.is_loading = true;
      await this.axios
        .post(
          `${this.base_url}api/weekly-schedule/`,
          this.school_weekly_schedule,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              class: this.school_weekly_schedule.fk_class,
              division: this.school_weekly_schedule.fk_division,
              // semester: this.school_weekly_schedule.fk_semester,
            },
          }
        )
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_saved"));

          this.cleanSchoolWeeklySchedule();
          this.is_loading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            if (error.response.data.find((item) => item.non_field_errors)) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique_schedule")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          }
          this.is_loading = false;
        });
      //   }
      this.is_loading = false;
    },
    cleanSchoolWeeklySchedule() {
      this.$refs.form.reset();
      this.school_weekly_schedule.days = [];
    },
  },
};
</script>
<style scoped>
.v-table th,
td {
  width: 150px !important;
  text-align: center !important;
  padding-inline: 10px !important;
}
</style>

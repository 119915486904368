<template>
  <v-card class="justify-center">
    <v-expand-transition>
      <div>
        <v-card-text
          class="card-fields"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_subject') ||
              (update && CheckGetPermission('school.change_subject')))
          "
        >
          <v-form ref="form" @submit.prevent="saveSubject">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="subject.name_ar"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.subject.name_ar')"
                  :rules="[
                    $required,
                    $max_length(100),
                    $ar_letters_only,
                    uniqueSubject,
                  ]"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="subject.name_en"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.subject.name_en')"
                  :rules="[
                    $required,
                    $max_length(100),
                    $en_letters_only,
                    uniqueSubject,
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" sm="6">
                <v-text-field
                  ref="max"
                  color="indigo"
                  v-model="subject.max_score"
                  density="compact"
                  type="number"
                  clearable
                  prepend-inner-icon="mdi-chevron-double-up"
                  append-inner-icon="mdi-numeric"
                  :label="$t('school.subject.max_score')"
                  :rules="[
                    $required,
                    max_score_rule,
                    $max_value(100),
                    $min_value(1),
                  ]"
                  @input="validate()"
                />
              </v-col>
              <v-col cols="12" md="2" sm="6">
                <v-text-field
                  ref="min"
                  color="indigo"
                  v-model="subject.min_score"
                  density="compact"
                  type="number"
                  clearable
                  prepend-inner-icon="mdi-chevron-double-down"
                  append-inner-icon="mdi-numeric"
                  :label="$t('school.subject.min_score')"
                  :rules="[
                    $required,
                    min_score_rule,
                    $max_value(99),
                    $min_value(0),
                  ]"
                  @input="validate()"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  color="indigo"
                  v-model="subject.optional"
                  density="compact"
                  item-title="val"
                  item-value="id"
                  prepend-inner-icon="mdi-text-box-multiple-outline"
                  clearable
                  :items="[
                    { id: true, val: $t('school.subject.optional') },
                    { id: false, val: $t('school.subject.obligatory') },
                  ]"
                  :label="$t('school.subject.type')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  :label="$t('school.subject.add_to_total')"
                  color="indigo"
                  v-model="subject.added_to_total"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea
                  color="indigo"
                  v-model="subject.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  clearable
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>
          <!-- </v-card>
        </v-col>
      </v-row> -->
        </v-card-text>

        <v-card-actions
          class="px-8"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_subject') ||
              (update && CheckGetPermission('school.change_subject')))
          "
        >
          <v-btn
            v-if="
              !update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.add_subject')
            "
            @click="saveSubject"
            class="bg-indigo"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <v-btn
            v-if="
              update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.change_subject')
            "
            @click="updateSubject(selectedSubject)"
            class="bg-success"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <VBtn class="mx-3" size="small" @click="cleanSubjectForm">
            {{ !update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <v-btn @click="cleanSubjectForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn> -->
        </v-card-actions>

        <v-divider class="mt-5"></v-divider>
      </div>
    </v-expand-transition>
    <!-- ##########    data Table Server ########### -->
    <div class="pa-2" v-if="CheckGetPermission('school.view_subject')">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="subjects"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template> -->
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-else-if="key == 'added_to_total'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else-if="key == 'optional'">
                <v-chip
                  size="default"
                  density="compact"
                  v-if="val == 1"
                  class="bg-success"
                  label=""
                  variant="flat"
                >
                  <span class="text-white">{{
                    $t("school.subject.optional")
                  }}</span>
                </v-chip>
                <v-chip
                  size="default"
                  density="compact"
                  v-if="val == 0"
                  class="bg-error"
                  label=""
                  variant="flat"
                >
                  <span class="text-white">{{
                    $t("school.subject.obligatory")
                  }}</span>
                </v-chip>
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="selectSubject(item.raw)"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.change_subject')
                  "
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(selectedSubject = item.raw.id), (dialog = true)"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.delete_subject')
                  "
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="subjects.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(subjects.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ subjects.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </div>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selectedSubject = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteSubject(selectedSubject)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Subject",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSubjects({ all: true });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      is_loading: false,
      name: "",
      name_en: "",
      txt_search: null,
      search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      classes: [],
      selectedHead: [
        "name_ar",
        "name_en",
        "max_score",
        "min_score",
        "optional",
        "actions",
      ],
      selectedSubject: null,
      subject: {
        optional: undefined,
        name_ar: "",
        name_en: "",
        min_score: null,
        max_score: null,
        added_to_total: false,
        note: "",
      },
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      uniqueSubject: (value) => {
        if (
          !this.update &&
          this.subjects.some(
            (item) =>
              item.name_ar.trim() === value.trim() ||
              item.name_en.trim() === value.trim()
          )
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },

      min_score_rule: (value) =>
        !this.subject.max_score ||
        parseInt(value) < parseInt(this.subject.max_score) ||
        this.$t(this.$t("global.error.validation.degree_error")),
      max_score_rule: (value) =>
        !this.subject.min_score ||
        parseInt(value) > parseInt(this.subject.min_score) ||
        this.$t(this.$t("global.error.validation.degree_error")),
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.school.subjects,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    headers() {
      let headers = [
        { title: this.$t("school.subject.name_ar"), key: "name_ar" },
        { title: this.$t("school.subject.name_en"), key: "name_en" },
        { title: this.$t("school.subject.max_score"), key: "max_score" },
        { title: this.$t("school.subject.min_score"), key: "min_score" },
        { title: this.$t("school.subject.type"), key: "optional" },
        {
          title: this.$t("school.subject.add_to_total"),
          key: "added_to_total",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ];

      if (this.getUserRole >= 2) {
        headers.pop();
      }
      return headers;
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getNationalities: "school/getCountries",
      getSubjects: "school/getSubjects",
    }),

    selectSubject(item) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.selectedSubject = item.id;
      //   this.name = item.name_ar.trim();
      //   this.name_en = item.name_en.trim().toLowerCase();
      //   delete item.id;
      this.subject = Object.assign({}, item);
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    // submitSubjectForm() {
    //   this.v$.$validate();
    // },
    async saveSubject() {
      //   this.submitSubjectForm();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/subjects/`, this.subject, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.cleanSubjectForm();
            this.getSubjects({ all: true });
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    cleanSubjectForm() {
      this.update = false;
      this.$refs.form.reset();
      this.subject.optional = undefined;
      this.subject.added_to_total = false;
      //   this.$nextTick(() => {
      //     this.subject = Object.assign({}, this.defaultSubject);
      //   });
    },

    async updateSubject(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}api/subjects/${id}/`, this.subject, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.getSubjects({ all: true });
            this.cleanSubjectForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    deleteSubject(id) {
      this.axios
        .delete(`${this.base_url}api/subjects/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.dialog = false;
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          this.getSubjects({ all: true });
          this.cleanSubjectForm();
        })
        .catch((error) => {
          if (error.response?.status == 418)
            this.$emit("protectedAlert", error.response?.data?.data);
          else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
      this.dialog = false;
    },
    validate() {
      if (this.subject.max_score && this.subject.min_score) {
        this.$refs.max.validate();
        this.$refs.min.validate();
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

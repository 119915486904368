<template>
  <v-row class="mt-2">
    <v-col cols="12" md="12" v-if="CheckGetPermission('school.view_student')">
      <v-card class="pt-3 px-1" id="card" style="height: 300px">
        <!-- :style="{height:$vuetify.display.xs?'600px':'300px'}" -->
        <!-- :option="$vuetify.display.xs?option_3:option_2" -->
        <div id="studentStatistics"></div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as echarts from "echarts";

export default {
  async mounted() {
    await this.axios
      .get(`${this.base_url}school/statistics/studentcount_by_class`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        const dimensions = response.data?.map((item) => item.class);
        const source = response.data?.map((item) => item.count);
        // dimensions?.unshift("الاسم")
        this.statistics.dimensions = dimensions;
        this.statistics.source = source;
      });
    this.option_2 = {
      color: ["#536dfe"],
      title: {
        text: this.$t("school.dashboard.students_stat_by_class"),
        // subtext:this.$t('dashboard.walkout-constant'),
        left: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 16,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      // dataset:[
      //     {
      //         dimensions:['الاسم',...statistics.dimensions,''],
      //         source:this.statistics.classes_statistics.map(el=>{
      //             return [el.class_name,el.walkout,el.constant]
      //         })
      //     },
      //     {
      //         transform:{
      //             type:'sort',
      //             config:{dimension:'constant',order:'desc'}
      //         }
      //     }
      // ],
      toolbox: {
        feature: {
          saveAsImage: { title: this.$t("school.dashboard.save-as-png") },
        },
      },
      legend: {
        orient: "vertical",
        show: true,
        left: "left",
        textStyle: {
          fontFamily: "Almarai",
        },
      },
      tooltip: {},
      xAxis: {
        type: "category",
        data: this.statistics.dimensions,
        axisLabel: {
          interval: 0,
          rotate: 30,
          textStyle: {
            fontFamily: "Almarai",
            fontSize: "10px",
          },
        },
      },
      grid: {
        top: "40%",
      },
      yAxis: {},
      series: [
        {
          type: "bar",
          data: this.statistics.source,
        },
      ],
    };

    this.printChart();
    const appIconMenu = document.getElementById("appIconMenu");
    appIconMenu.addEventListener("click", () => {
      // this.printChart()
      const chartElement = document.getElementById("studentStatistics");
      const chart = echarts.getInstanceByDom(chartElement);
      setTimeout(() => {
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }, 10);
    });
    window.addEventListener("resize", this.printChart);
  },
  methods: {
    printChart() {
      if (this.CheckGetPermission("school.view_student")) {
        const chartElement = document.getElementById("studentStatistics");
        const chart = echarts.init(chartElement);
        chart.setOption(this.option_2);
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth - 12,
          height: element.offsetHeight,
        });
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      statistics: [],
      option_2: {},
      option_3: {},
      size: null,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
};
</script>

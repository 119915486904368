<template>
  <v-card>
      <v-form
        v-if="
          CheckGetPermission('school.add_teacher') ||
          (update && CheckGetPermission('school.change_teacher'))
        "
        ref="form"
        @submit.prevent="saveTeacher"
      >
        <v-tabs v-model="tab" color="indigo">
          <v-tab v-for="item in items" :key="item.key" :value="item.key">
            {{ item.value }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item :value="items[0].key">
              <v-row>
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        prepend-inner-icon="mdi-abjad-arabic"
                        counter="100"
                        type="input"
                        v-model="teacher.name_ar"
                        density="compact"
                        clearable
                        :label="$t('school.teacher_data.name_ar')"
                        :rules="[$required, $max_length(), $ar_letters_only]"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        counter="100"
                        v-model="teacher.name_en"
                        hide-details="auto"
                        prepend-inner-icon="mdi-alpha-e"
                        density="compact"
                        clearable
                        :label="$t('school.teacher_data.name_en')"
                        :rules="[$required, $max_length(), $en_letters_only]"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mb-4">
                    <v-col cols="6" md="4" sm="4">
                      <v-autocomplete
                        v-model="teacher.nationality"
                        density="compact"
                        item-title="nati_name_ar"
                        item-value="id"
                        clearable
                        prepend-inner-icon="mdi-passport"
                        :items="nationalities"
                        :label="$t('school.teacher_data.nationality')"
                        :rules="[$required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="4" sm="4">
                      <v-autocomplete
                        v-model="teacher.marital_status"
                        density="compact"
                        item-title="name"
                        item-value="id"
                        clearable
                        prepend-inner-icon="mdi-human-male-female"
                        :items="maritalStatus"
                        :label="$t('school.teacher_data.marital_status')"
                        :rules="[$required]"
                        @update:model-value="teacher.marital_status == 1 ? teacher.children= null:null"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="4" sm="4">
                      <v-autocomplete
                        v-model="teacher.gender"
                        density="compact"
                        item-title="val"
                        item-value="id"
                        clearable
                        prepend-inner-icon="mdi-gender-male-female"
                        :items="[
                          { id: 1, val: $t('school.teacher_data.male') },
                          { id: 2, val: $t('school.teacher_data.female') },
                        ]"
                        :label="$t('school.teacher_data.gender')"
                        :rules="[$required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="4" sm="4">
                      <v-text-field
                        v-model="teacher.birthdate"
                        density="compact"
                        :placeholder="$t('school.teacher_data.birth_date')"
                        clearable
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        id="birthdate"
                        readonly
                        :rules="[
                          $required,
                          $max_date({ subtract: 18, type: 'en' }),
                        ]"
                      >
                      </v-text-field>
                      <Datetime
                        type="date"
                        element="birthdate"
                        :color="'#0747a6'"
                        @input="(e) => getPermissionTimeBirthDate(e)"
                        :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                        :placeholder="$t('school.teacher_data.birth_date')"
                      ></Datetime>
                      <!-- <v-text-field
                        v-model="teacher.birthdate"
                        density="compact"
                        type="date"
                        :label="$t('school.teacher_data.birth_date')"
                        :rules="[$required]"
                      >
                      </v-text-field> -->
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <v-text-field
                        counter="100"
                        v-model="teacher.place"
                        hide-details="auto"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-store"
                        :label="$t('school.teacher_data.place')"
                        :rules="[$required, $max_length()]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                      sm="12"
                      v-if="teacher.marital_status > 1"
                    >
                      <v-text-field
                        v-model="teacher.children"
                        hide-details="auto"
                        density="compact"
                        clearable
                        counter="2"
                        prepend-inner-icon="mdi-human-male-child"
                        class="mb-4"
                        :label="$t('school.teacher_data.children')"
                        :rules="[$numeric, $max_value(99)]"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="4" sm="0"> </v-col>
                <h1>{{ $vuetify.breakpoint }}</h1>
              </v-row>
            </v-window-item>
            <v-window-item :value="items[1].key">
              <v-row>
                <v-col cols="12" md="8" sm="12">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        counter="100"
                        type="input"
                        v-model="teacher.job_title"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-account-hard-hat"
                        :label="$t('school.teacher_data.job_title')"
                        :rules="[$required, $max_length()]"
                      />
                    </v-col>

                    <v-col cols="6" md="4" sm="6">
                      <v-autocomplete
                        v-model="teacher.work_period"
                        density="compact"
                        item-title="val"
                        item-value="id"
                        prepend-inner-icon="mdi-clock-time-nine"
                        :items="[
                          { id: false, val: $t('global.pm') },
                          { id: true, val: $t('global.am') },
                        ]"
                        :label="$t('school.teacher_data.work_period')"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="6" md="4">
                      <v-text-field
                        counter="14"
                        v-model="teacher.job_id"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.teacher_data.job_id')"
                        :rules="[$max_length(14)]"
                      />
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="teacher.date_of_employment"
                        density="compact"
                        :placeholder="
                          $t('school.teacher_data.date_of_employment')
                        "
                        clearable
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        id="date_of_employment"
                        readonly
                        :rules="[$required]"
                      >
                      </v-text-field>
                      <Datetime
                        type="date"
                        element="date_of_employment"
                        :color="'#0747a6'"
                        @input="(e) => getPermissionTime(e)"
                        :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                        :placeholder="
                          $t('school.teacher_data.date_of_employment')
                        "
                        :calendar="date_type == 'h' ? 'hijri' : ''"
                      ></Datetime>
                    </v-col>
                    <!-- <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="teacher.date_of_employment"
                        density="compact"
                        type="date"
                        :label="$t('school.teacher_data.date_of_employment')"
                        :rules="[$required]"
                      >
                      </v-text-field>
                    </v-col> -->
                    <v-col cols="6" md="6" sm="6">
                      <v-checkbox
                        v-model="teacher.type"
                        color="indigo"
                        :label="$t('school.teacher_data.basic_teacher')"
                      />
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <v-radio-group
                        color="indigo"
                        inline
                        v-model="teacher.service_status"
                        :label="$t('school.teacher_data.service_status')"
                        density="compact"
                      >
                        <v-radio
                          :label="$t('school.teacher_data.stopped')"
                          :value="2"
                        ></v-radio>
                        <v-radio
                          :label="$t('school.teacher_data.continus')"
                          :value="1"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="4" sm="0"> </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="items[2].key">
              <v-row>
                <v-col cols="12" md="8" sm="12">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        counter="150"
                        type="input"
                        v-model="teacher.address"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('school.teacher_data.address')"
                        :rules="[$required, $max_length(150)]"
                      />
                    </v-col>

                    <v-col cols="6" md="6" sm="6">
                      <v-text-field
                        counter="14"
                        v-model="teacher.phone_number"
                        hide-details="auto"
                        density="compact"
                        clearable
                        type="Number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('school.teacher_data.mobile')"
                        :rules="[$max_length(14), $min_length(9), $numeric]"
                      />
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <v-text-field
                        counter="12"
                        v-model="teacher.home_number"
                        hide-details="auto"
                        type="Number"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('school.teacher_data.phone')"
                        :rules="[$max_length(8), $min_length(6), $numeric]"
                      />
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="mb-3">
                      <v-textarea
                        counter="250"
                        v-model="teacher.note"
                        hide-details="auto"
                        density="compact"
                        rows="3"
                        clearable
                        :auto-grow="false"
                        prepend-inner-icon="mdi-note-outline"
                        :label="$t('global.note')"
                        :rules="[$max_length(250)]"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="4" sm="0"> </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="items[3].key">
              <v-row>
                <v-col cols="12" md="8" sm="12">
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="teacher.user_data.first_name"
                        clearable
                        counter="100"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('users.user.fname')"
                        :rules="[$max_length()]"
                      />
                      <!-- :error-messages="message.first_name" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="teacher.user_data.last_name"
                        clearable
                        counter="100"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('users.user.lname')"
                        :rules="[$max_length()]"
                      />
                      <!-- :error-messages="message.last_name" -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="teacher.user_data.username"
                        clearable
                        counter="100"
                        autocomplete="off"
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('users.user.username')"
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="teacher.user_data.email"
                        autocomplete="off"
                        clearable
                        type="email"
                        counter="100"
                        name="email"
                        id="email"
                        density="compact"
                        prepend-inner-icon="mdi-alpha-e"
                        :label="$t('users.user.email')"
                        :rules="[$required, $email, $max_length(64)]"
                      />
                      <!-- :error-messages="message.email" -->
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="mt-4">
                    <v-col cols="12" md="6">
                      <v-text-field
                        color="indigo"
                        v-model="teacher.user_data.password"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        id="password"
                        clearable
                        counter="20"
                        density="compact"
                        autocomplete="new-password"
                        :label="$t('users.user.password')"
                        :rules="[update ? true : $required, $max_length(20), $min_length(8)]"
                      >
                        <template v-slot:prepend-inner>
                          <v-icon @click="showPassword = !showPassword">{{
                            showPassword ? "mdi-eye" : "mdi-eye-off"
                          }}</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        color="indigo"
                        multiple
                        v-model="teacher.user_data.groups"
                        density="compact"
                        item-title="name"
                        item-value="pk"
                        prepend-inner-icon="mdi-account-group"
                        clearable
                        :items="groups_list"
                        :label="$t('users.user.group')"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2 mb-2">
                    <v-col cols="3" md="3">
                      <v-checkbox
                        :label="$t('users.user.active')"
                        color="indigo"
                        v-model="teacher.user_data.is_active"
                        density="compact"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="4" sm="0"> </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-form>
      <v-card-actions
        v-if="
          CheckGetPermission('school.add_teacher') ||
          (update && CheckGetPermission('school.change_teacher'))
        "
        class="mt-4 px-8"
      >
        <v-btn
          v-if="!update && CheckGetPermission('school.add_teacher')"
          @click="saveTeacher()"
          class="bg-indigo"
          size="small"
          :loading="is_loading"
        >
          <span class="text-white">
            {{ $t("global.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn
          v-if="update && id && CheckGetPermission('school.change_teacher')"
          @click="updateTeacher(teacher.id)"
          class="bg-success"
          size="small"
          :loading="is_loading"
        >
          <span class="text-white">
            {{ $t("global.edit") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <VBtn class="mx-3" size="small" @click="clean()">
          {{ !id ? $t("global.clear") : $t("global.cancel") }}
          <VIcon
            :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
            :color="!id ? 'golden' : 'gray'"
            end
          ></VIcon>
        </VBtn>
        <!-- <v-btn @click="clean" class="mx-3" size="small">
          <span>
            {{ $t("global.clear") }}
          </span>
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn> -->
      </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Datetime from "vue3-datetime-js";
import moment from "moment-hijri";

export default {
  name: "TeacherView",

  props: {
    id: {
      type: Number,
    },
  },
  components: { Datetime },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.id) {
        this.getTeacher(this.id);
        this.update = true;
      }
      let groups = await this.axios.get(`${this.base_url}groups/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (groups) this.groups_list = groups.data;
      await this.axios.get(`${this.base_url}user-role/`);
      if (!this.nationalities.length)
        await this.getNationalities({ all: true });
      if (!this.maritalStatus.length) await this.getMaritalStatus();

      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      this.header = this.$store.getters.header;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      header: undefined,
      update: false,
      groups_list: [],
      // marital_status: [],
      users: [],
      showPassword: false,
      dialog: false,
      overlay: false,
      create: true,
      alert: false,
      alertTitle: "",
      alertMessage: "",
      alertIcon: "",
      teacher: {
        type: false,
        user_data: {},
      },
      message: {},
      teachers: [],
      tab: null,
      date_type: localStorage.getItem("date"),
      selected_date: new Date("2023-05-05"),
      is_loading: false,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },

    items() {
      return [
        {
          key: 0,
          value: this.$t("school.teacher_data.personal_information"),
        },
        {
          key: 1,
          value: this.$t("school.teacher_data.employment_information"),
        },
        {
          key: 2,
          value: this.$t("school.teacher_data.contact_information"),
        },
        {
          key: 3,
          value: this.$t("school.teacher_data.user_data"),
        },
      ];
    },
    ...mapState({
      nationalities: (state) => state.school.countries,
      maritalStatus: (state) => state.globals.maritalStatus,
    }),
    // dateToday() {
    //   var date = moment().locale("en").format("iYYYY-iMM-iDD");
    //   if (this.id) {
    //     date = moment(this.teacher.date_of_employment)
    //       .locale("en")
    //       .format("iYYYY-iMM-iDD");
    //   }
    //   return date;
    // },
  },

  methods: {
    ...mapActions({
      getNationalities: "school/getCountries",
      getMaritalStatus: "globals/getMaritalStatus",
      getAllTeachers: "school/getAllTeachers",
    }),
    // async getMaritalStatus() {
    //   await this.axios(`${this.base_url}/marital-status/`, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   }).then((response) => {
    //     this.marital_status = response.data;
    //   });
    // },

    setDatePicker() {
      // this.selected_date = new Date('2023-05-05')
      const date = moment(this.teacher.birthdate, "iYYYY-iMM-iDD");
      if (date.isValid()) {
        this.selected_date = date.toDate();
      } else {
        this.selected_date = moment().locale("en").format("iYYYY-iMM-iDD");
      }
    },
    back() {
      this.addDialog = false;
      this.create = true;
      this.clean();
    },
    getPermissionTime(e) {
      let date = this.toEnglishString(e);
      this.teacher.date_of_employment = date;
    },
    getPermissionTimeBirthDate(e) {
      let date = this.toEnglishString(e);
      this.teacher.birthdate = date;
    },
    getTeacher(id) {
      this.axios
        .get(`${this.base_url}/api/teachers/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          setTimeout(() => {
            this.tab = 3;
          }, 1);
          setTimeout(() => {
            this.tab = 2;
          }, 2);
          setTimeout(() => {
            this.tab = 1;
          }, 3);
          setTimeout(() => {
            this.tab = 0;
          }, 4);
          this.teacher = response.data;
        });
    },

    async saveTeacher() {
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.teacher.name_ar &&
        this.teacher.job_title &&
        this.teacher.address &&
        this.teacher.user_data.username
      ) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}/api/teachers/`, this.teacher, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.clean();
            this.getAllTeachers({});
            // this.$router.push({name:'teacherview'})
          })
          .catch((error) => {
            if (error.response.data?.email) {
              const messages = error.response.data;
              this.message.email = messages?.email[0]?.replace(".", "");
              // this.message.first_name = messages?.first_name[0];
              // this.message.last_name = messages?.last_name[0];
              this.$emit(
                "errorAlert",
                this.$t(`global.error.failure.${this.message.email}`)
              );
            }
          });
      } else {
        if (valid && !this.teacher.name_ar) {
          this.tab = 0;
        } else if (valid && !this.teacher.job_title) {
          this.tab = 1;
        } else if (valid && !this.teacher.address) {
          this.tab = 2;
        } else if (valid && !this.teacher.user_data.username) {
          this.tab = 3;
        } else {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        }
      }
      this.is_loading = false;
    },
    async updateTeacher(id) {
      const { valid } = await this.$refs.form.validate();
      if (
        valid &&
        this.teacher.name_ar &&
        this.teacher.job_title &&
        this.teacher.address &&
        this.teacher.user_data.username
      ) {
        this.is_loading = true;
        return await this.axios
          .put(`${this.base_url}/api/teachers/${id}/`, this.teacher, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.clean();
            this.getAllTeachers({});
            // setTimeout(() => {
            //   this.$router.push({ name: "teacherview" });
            // }, 1500);
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      } else {
        if (valid && !this.teacher.name_ar) {
          this.tab = 0;
        } else if (valid && !this.teacher.job_title) {
          this.tab = 1;
        } else if (valid && !this.teacher.address) {
          this.tab = 2;
        } else if (valid && !this.teacher.user_data.username) {
          this.tab = 3;
        } else {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        }
      }
      this.is_loading = false;
    },
    clean() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "teacherview" });
      } else {
        this.dialog = false;
        this.$errors = {};
        this.$refs.form.reset();
        this.update = false;
        this.$router.push({ name: "teacheradd" });
      }
    },
  },
  watch: {
    id() {
      this.clean();
    },
  },
};
</script>
<style scoped>
div > input[type="date"] {
  padding: 0 !important;
  color: inherit;
}
.v-btn .v-icon {
  font-size: 18px !important;
}
</style>

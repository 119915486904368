<template>
  <v-card>
    <v-form
      v-if="
        CheckGetPermission('school.add_homework') ||
        (update && CheckGetPermission('school.change_homework'))
      "
      ref="form"
      @submitHomeworkForm.prevent="saveHomework"
    >
      <v-card-text class="card-fields">
        <v-row>
          <v-col cols="12">
            <!-- <v-card class="pa-2 ma-auto" > -->
            <v-row class="mt-4">
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_class"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-google-classroom"
                  clearable
                  :items="classes"
                  :label="$t('school.class.select')"
                  @update:modelValue="
                      (homework.fk_division = null),
                      (homework.fk_subject = null)
                  "
                  :rules="[$required]"
                ></v-autocomplete>
                <!-- (homework.fk_teacher = null), -->
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_division"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-format-list-bulleted"
                  clearable
                  :no-data-text="$t('school.division.not_found')"
                  :items="getClassDivisions(homework.fk_class)"
                  :label="$t('school.division.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_semester"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-format-list-bulleted"
                  clearable
                  :items="semesters"
                  :label="$t('school.semester.select')"
                  @update:modelValue="homework.fk_teacher = null"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_subject"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-book-open-variant"
                  clearable
                  :items="class_subject"
                  :label="$t('school.subject.select')"
                  :rules="[$required]"
                  @update:model-value="getLectures()"
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_teacher"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-format-list-bulleted"
                  clearable
                  :items="subject_teachers"
                  :label="$t('school.teacher.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col> -->
              <div class="w-100"></div>
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="homework.fk_lecture"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-book-open-variant"
                  clearable
                  :items="lectures"
                  :label="$t('school.lecture.select')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-text-field
                  color="indigo"
                  v-model="homework.name"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-notebook-outline"
                  :label="$t('school.homework.name')"
                  :rules="[$required, $max_length()]"
                />
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-text-field
                  v-model="homework.deadline"
                  density="compact"
                  :placeholder="$t('school.homework.deadline')"
                  clearable
                  prepend-inner-icon="mdi-calendar-clock-outline"
                  id="deadline"
                  readonly
                  :rules="[$required, $min_date(dateToday)]"
                >
                </v-text-field>
                <Datetime
                  v-model="dateToday"
                  type="date"
                  element="deadline"
                  :color="'#0747a6'"
                  @input="(e) => getPermissionTime(e)"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('school.homework.deadline')"
                  :calendar="date_type == 'h' ? 'hijri' : ''"
                  :min="dateToday"
                ></Datetime>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  color="indigo"
                  v-model="homework.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  rows="3"
                  clearable
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
            <!-- <small v-if="!checkSchedules&&!update" >shehab alfareh</small> -->

            <!-- </v-card> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        v-if="
          CheckGetPermission('school.add_homework') ||
          (update && CheckGetPermission('school.change_homework'))
        "
        class="px-8"
      >
        <v-btn
          v-if="!update && CheckGetPermission('school.add_homework')"
          @click="saveHomework"
          :loading="is_loading"
          class="bg-indigo"
          size="small"
        >
          <span class="text-white">
            {{ $t("global.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn
          v-if="update && CheckGetPermission('school.change_homework')"
          @click="updateHomework(id)"
          :loading="is_loading"
          class="bg-success"
          size="small"
        >
          <span class="text-white">
            {{ $t("global.edit") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn @click="cleanHomeworkForm" class="mx-3" size="small">
          {{ !id ? $t("global.clear") : $t("global.cancel") }}
          <VIcon
            :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
            :color="!id ? 'golden' : 'gray'"
            end
          ></VIcon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
  <div></div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
import moment from "moment-hijri";

export default {
  name: "HomeWorkAdd",
  props: {
    id: String,
  },
  components: { Datetime },
  async mounted() {
    if (this.id) {
      await this.axios
        .get(`${this.base_url}api/homework/${this.id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.homework = response.data;
          this.temp_lecture = this.homework.fk_lecture
          this.homework.fk_lecture = this.homework.lecture_name
          this.update = true;
          this.getLectures()
        });
    }
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      //   if (!this.semesters.length) await this.getSemesters();
      // await this.getTeacherClass({}),
      //   await this.getAllTeachers({}),

      //   if (!this.academic_years.length) await this.getAcademicYears();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      uniqueData: [null, null, null],
      schedules_data: [],
      update: false,
      user: null,
      user: null,
      breadCrumbs: [],
      homework: {
        name: "",
        deadline: undefined,
        note: "",
        fk_class: undefined,
        fk_division: undefined,
        fk_subject: undefined,
        fk_lecture: undefined,
        // fk_semester: undefined,
        // fk_teacher: undefined,
        // data_entry: undefined,
        // updated_by: undefined,
      },
      lectures : [],
      temp_lecture : undefined,
      is_loading: false,
      date_type: localStorage.getItem("date"),
      deadline: undefined,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      subjects: (state) => state.school.subjects,
      class_assign_hall: (state) => state.school.class_assign_hall,
      //   semesters: (state) => state.school.semesters,
      //   teacher_class: (state) => state.school.teacher_class,
      academic_years: (state) => state.school.academic_years,
    }),
    ...mapGetters({
      getSubjectName: "school/getSubjectName",
      getTeacherName: "school/getTeacherName",
      getClassName: "school/getClassName",
      getSubjectsByClass: "school/getSubjectsByClass",
      getClassDivisions: "school/getClassDivisions",
    }),
    dateToday() {
      var date = moment().locale("en").format("iYYYY-iMM-iDD");
      if (this.id) {
        date = moment(this.homework.created_at)
          .locale("en")
          .format("iYYYY-iMM-iDD");
      }
      return date;
    },
    // subject_teachers() {
    //   if (
    //     this.homework.fk_division &&
    //     this.homework.fk_semester &&
    //     this.homework.fk_subject
    //   ) {
    //     var division = this.homework.fk_division;
    //     var class_id = this.homework.fk_class;
    //     var teacher_id = this.teacher_class.results
    //       ? this.teacher_class.results
    //           .filter((item) => {
    //             if (
    //               item.fk_class == class_id &&
    //               item.fk_division == division &&
    //               item.fk_semester == this.homework.fk_semester &&
    //               item.fk_subject == this.homework.fk_subject
    //             ) {
    //               return item;
    //             }
    //           })
    //           .map((item) => item.fk_teacher)
    //       : "";
    //     var teacher_unique = [...new Set(teacher_id)];
    //     var teacher_data = [];
    //     teacher_unique.forEach((el) => {
    //       teacher_data.push({ id: el, name: this.getTeacherName(el) });
    //     });
    //   }
    //   return teacher_data;
    // },
    class_subject() {
      if (this.homework.fk_class) {
        //   var subject_id = this.classes
        //     ? this.classes.find((item) => item.id == this.homework.fk_class)
        //         .subjects
        //     : "";
        //   var subjects_uniqe = [...new Set(subject_id)];
        //   var subject_data = [];
        //   subjects_uniqe.forEach((el) => {
        //     subject_data.push({ id: el, name: this.getSubjectName(el) });
        //   });
        // } else {
        //   subject_data = [];
        return this.getSubjectsByClass(this.homework.fk_class);
      }
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
      //   getSemesters: "school/getSemesters",
      //   getTeacherClass: "school/getTeacherClass",
      //   getAllTeachers: "school/getAllTeachers",
    }),
    getPermissionTime(e) {
      let date = this.toEnglishString(e);
      this.homework.deadline = date;
    },
    async saveHomework() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.homework.data_entry = this.user;
        // this.homework.fk_year=localStorage.getItem('current_year')
        await this.axios
          .post(`${this.base_url}api/homework/`, this.homework, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.cleanHomeworkForm();
          })
          .catch((error) => {
            let message = error.response.data;
            if (message.fk_semester) {
              this.$emit("errorAlert", "يرجى ملئ الترم الحالي من شاشة التهيئة");
            } else if (message.fk_month) {
              this.$emit("errorAlert", "يرجى ملئ الشهر الحالي من شاشة التهيئة");
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    cleanHomeworkForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "homework_list" });
      } else {
        this.update = false;
        this.$refs.form.reset();
      }
      // if(this.update){
      // }
      // this.v$.homework.$reset()
    },
    async updateHomework(id) {
      // this.submitHomeworkForm()
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.homework.fk_year=localStorage.getItem('current_year')
        // this.homework.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}api/homework/${id}/`, this.homework, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.cleanHomeworkForm();
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async getLectures(){
      if(this.homework.fk_class && this.homework.fk_division){
        this.lectures = []
        await this.axios(`${this.base_url}/api/lecture-attendance/select/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_class: this.homework.fk_class,
            fk_subject: this.homework.fk_subject,
            fk_division: this.homework.fk_division,
          },
        }).then((response) => {
          this.lectures = response.data 
          if(this.id)
            this.homework.fk_lecture = this.temp_lecture
        }).catch(error =>{
        });
      }
    }
  },
  watch: {
    id() {
      this.cleanHomeworkForm();
    },
  },
};
</script>
